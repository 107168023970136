import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { ContactHistory } from '../../../../../../interfaces/client/contact.interface';

import { useAppDispatch } from '../../../../../../hooks';

import { AddContactAction } from '../../../../../../actions/contact/contact.types';
import { ClientContactActions } from '../../../../../../actions/client/contact/contact.actions';

import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import ContactHistoryForm from '../../../../../../components/clients/details/contact-histories/form/ContactHistoryForm';
import { useClient } from '../../../../../../hooks/clients';

interface Props {
  contactId: string | number;
  setShow: (value: boolean) => void;
}

const AddContactHistoryContainer = ({ contactId, setShow }: Props) => {
  const { client } = useClient();
  const clientId = client.id;
  const dispatch: Dispatch<AddContactAction> = useAppDispatch();

  const onClose = () => setShow(false);

  const handleSave = (payload: ContactHistory, onComplete: () => void) => {
    dispatch(ClientContactActions.addContactHistory(clientId, contactId, payload, onClose, onComplete));
  };

  return (
    <ModalLayout onClose={onClose} title="Add Contact History" large>
      <ContactHistoryForm onSave={handleSave} />
    </ModalLayout>
  );
};

export default AddContactHistoryContainer;
