import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddProspectRequest,
  getProspectRequest,
  getProspectsRequest,
  getRemoveProspectRequest,
  getUpdateProspectRequest,
  RequestOptions
} from '../tools/api';

import {
  AddProspectAction,
  ConvertProspectAction,
  LoadProspectAction,
  LoadProspectsAction,
  RemoveProspectDocumentAction,
  UpdateProspectAction
} from '../actions/prospect/prospect.types';
import { ProspectActions } from '../actions/prospect/prospect.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';
import { getConvertProspectRequest, getRemoveProspectDocumentRequest } from '../tools/api/prospect.endpoints';

export function* performLoadProspects({ onComplete }: LoadProspectsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getProspectsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ProspectActions.SET_PROSPECTS, prospects: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadProspectsRequest() {
  yield takeLatest(ProspectActions.LOAD_PROSPECTS, performLoadProspects);
}

export function* performLoadProspect({ id, onComplete }: LoadProspectAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getProspectRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ProspectActions.SET_PROSPECT, prospect: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadProspectRequest() {
  yield takeLatest(ProspectActions.LOAD_PROSPECT, performLoadProspect);
}

export function* performUpdateProspect({ id, payload, onComplete }: UpdateProspectAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateProspectRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.full_name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: ProspectActions.SET_PROSPECT, prospect: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateProspectRequest() {
  yield takeLatest(ProspectActions.UPDATE_PROSPECT, performUpdateProspect);
}

export function* performConvertProspect({ id, payload, onComplete }: ConvertProspectAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getConvertProspectRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        `${data.full_name} has been converted to an employee successfully!`,
        SNACK_SUCCESS
      )
    );
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForConvertProspectRequest() {
  yield takeLatest(ProspectActions.CONVERT_PROSPECT, performConvertProspect);
}

export function* performAddProspect({ payload, navigate, onComplete }: AddProspectAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddProspectRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new prospect has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/human-resources/prospects');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddProspectRequest() {
  yield takeLatest(ProspectActions.ADD_PROSPECT, performAddProspect);
}

export function* performRemoveProspect({ id, onComplete }: AddProspectAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveProspectRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The prospect has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(ProspectActions.loadProspects(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveProspectRequest() {
  yield takeLatest(ProspectActions.REMOVE_PROSPECT, performRemoveProspect);
}

export function* performRemoveProspectDocument({ id, documentId }: RemoveProspectDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveProspectDocumentRequest(
      id,
      documentId
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'The prospect ID document has been removed successfully!',
        SNACK_SUCCESS
      )
    );

    yield put(ProspectActions.loadProspects(() => {}));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveProspectDocumentRequest() {
  yield takeLatest(ProspectActions.REMOVE_PROSPECT_DOCUMENT, performRemoveProspectDocument);
}

export default function* prospectSaga() {
  yield all([
    watchForLoadProspectsRequest(),
    watchForLoadProspectRequest(),
    watchForUpdateProspectRequest(),
    watchForAddProspectRequest(),
    watchForRemoveProspectRequest(),
    watchForConvertProspectRequest(),
    watchForRemoveProspectDocumentRequest()
  ]);
}
