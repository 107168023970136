import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Contact } from '../../interfaces/client/contact.interface';
import { AppState } from '../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ContactContext, ContactProps } from '../../contexts/client/Contact.context';

import { LoadContactsAction } from '../../actions/client/contact/contact.types';
import { ClientContactActions } from '../../actions/client/contact/contact.actions';

interface Props {
  children: ReactNode | ReactNode[];
}

export const ContactProvider = ({ children }: Props) => {
  const dispatch: Dispatch<LoadContactsAction> = useAppDispatch();
  const { id } = useParams();

  const contacts: Contact[] = useAppSelector(({ client_store }: AppState) => client_store.contacts);

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [contactOverride, setContact] = useState<Contact>();

  useEffect(() => {
    dispatch(ClientContactActions.loadContacts(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const contact: Contact = useMemo(() => {
    return contacts[index];
  }, [contacts, index]);

  const handleIndexChange = (value: number) => {
    setIndex(value);
  };

  const value: ContactProps = {
    index,
    handleIndexChange,
    loading,
    contacts,
    contact,
    contactOverride,
    setContact
  };

  return (
    <ContactContext.Provider value={value}>
      <article>
        {children}
      </article>
    </ContactContext.Provider>
  );
};
