import React from 'react';

import { ReactComponent as CheckIcon } from '../../../../icons/check-circle-icon.svg';

import AuthLayout from '../../../../components/layout/auth/AuthLayout';

const VerifyAccountContainer = () => (
  <AuthLayout
    title="Account Confirmation"
    subtitle="Check your email to verify your newly created account!"
    linkTo="/"
    linkText="Already confirmed? Sign in"
  >
    <CheckIcon style={{ color: '#c4960c', width: 200, height: 200, margin: 'auto' }} />
  </AuthLayout>
);

export default VerifyAccountContainer;
