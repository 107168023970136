import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { DealSheet } from '../../../interfaces/client/deal-sheet.interface';

import { getAuthHeaders } from '../../auth.util';

import { API_HOST, RequestOptions } from '../index';

const getClientEndpoint = (clientId: number | string): string => `${API_HOST}/clients/${clientId}`;
const getClientDealSheetsEndpoint = (clientId: number | string): string =>
  `${getClientEndpoint(clientId)}/deal_sheets`;
export const getClientDealSheetsRequest = (clientId: number | string): RequestOptions => [
  getClientDealSheetsEndpoint(clientId),
  getHttpGetOptions(getAuthHeaders())
];

const getClientDealSheetEndpoint = (clientId: number | string, id: number | string): string =>
  `${getClientDealSheetsEndpoint(clientId)}/${id}`;
export const getClientDealSheetRequest = (
  clientId: number | string,
  id: number | string
): RequestOptions => [
  getClientDealSheetEndpoint(clientId, id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateClientDealSheetRequest = (
  clientId: number | string,
  id: number | string,
  payload: DealSheet
): RequestOptions => [
  getClientDealSheetEndpoint(clientId, id),
  getHttpPutOptions({ deal_sheet: payload }, getAuthHeaders())
];

export const getAddClientDealSheetRequest = (
  clientId: number | string,
  payload: DealSheet
): RequestOptions => [
  getClientDealSheetsEndpoint(clientId),
  getHttpPostOptions({ deal_sheet: payload }, getAuthHeaders())
];

export const getRemoveClientDealSheetRequest = (
  clientId: number | string,
  id: string | number
): RequestOptions => [
  getClientDealSheetEndpoint(clientId, id),
  getHttpDeleteOptions(getAuthHeaders())
];
