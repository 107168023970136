import React from 'react';

import { noOp } from '@neslotech/utils';

import { ApiElement } from '../types/api-element.interface';

export interface ConvertProps {
  element?: ApiElement;
  setElement: (element: ApiElement) => void;
  showCModal: boolean;
  setShowCModal: (value: boolean) => void;
}

export const ConvertContext = React.createContext<ConvertProps>({
  setElement: noOp,
  showCModal: false,
  setShowCModal: noOp
});
