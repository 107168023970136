import React from 'react';

import { ApiElement } from '../../types/api-element.interface';

import { useConvert } from '../../hooks';

import { ReactComponent as PeopleIcon } from '../../icons/people-icon.svg';

import './remove-element.scss';

interface Props {
  element: ApiElement;
}

const ConvertElement = ({ element }: Props) => {
  const { setElement, setShowCModal } = useConvert();

  const handleConvert = (event: { stopPropagation: () => void }): void => {
    event.stopPropagation();
    setElement(element);
    setShowCModal(true);
  };

  return (
    <article className="remove-element">
      <PeopleIcon onClick={handleConvert} />
    </article>
  );
};

export default ConvertElement;
