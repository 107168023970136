import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Sector } from '../interfaces/config/sector.interface';

import { SectorActions } from '../actions/sector/sector.actions';

export interface SectorState extends State {
  sectors: Sector[];
  sector?: Sector;
}

export const initialState: SectorState = {
  sectors: []
};

export const sectorReducer = (state: SectorState = initialState, action: AnyAction) => {
  if (action.type === SectorActions.SET_SECTORS) {
    const { sectors } = action;
    return {
      ...state,
      sectors
    };
  } else if (action.type === SectorActions.SET_SECTOR) {
    const { sector } = action;
    return {
      ...state,
      sector
    };
  }

  return state;
};
