import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { Employee } from '../../../../interfaces/employee/employee.interface';

import { RemoveEmployeeAction } from '../../../../actions/employee/employee.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveEmployeeContainer = () => {
  const dispatch: Dispatch<RemoveEmployeeAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      EmployeeActions.removeEmployee((element as Employee).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  const title = showModal && (element as Employee).active_status === 'active' ? 'Deactivate Employee' : 'Activate Employee';

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title={title}>
            <RemoveModal title={(element as Employee).full_name} status={(element as Employee).active_status} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveEmployeeContainer;
