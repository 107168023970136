import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { User } from '../../../interfaces/user/user.interface';
import { ConfirmationRequest } from '../../../interfaces/auth/confirmation-request.interface';

import Avatar from '../../avatar/Avatar';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';
import ConvertElement from '../../table/ConvertEmployeeElement';
import { ReactComponent as TickIcon } from '../../../icons/tick-icon.svg';
import { ReactComponent as ReSend } from '../../../icons/mail-resend-icon.svg';
import AlphabetLayout from '../../../components/layout/filter/AlphabetLayout';

import './user-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'avatar'
  },
  {
    Header: 'Email Address',
    accessor: 'email'
  },
  {
    Header: 'Role',
    accessor: 'role'
  },
  {
    Header: 'Account Status',
    accessor: 'status'
  },
  {
    Header: 'Status',
    accessor: 'isconfirmed'
  },
  {
    Header: 'Employee',
    accessor: 'state'
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  role: {
    capitalize: true
  }
};

interface RowData extends TableData {
  avatar: ReactNode;
  email: string;
  role: 'agent' | 'admin' | 'employee';
  isconfirmed: string;
}
type resend = (payload: ConfirmationRequest) => void;

interface Props {
  users: User[];
  resend: resend;
  loading: boolean;
}

const UserList = ({ users, resend, loading }: Props) => {
  const handleSubmit = (email: string) => {
    const request: ConfirmationRequest = { email };
    resend(request);
  };
  const navigate: NavigateFunction = useNavigate();
  const [sortedUsers, setSortedUsers] = useState(users);
  const [selectedLetter, setSelectedLetter] = useState(null);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(() => {
    if (selectedLetter) {
      users = users.filter(user => user.full_name[0].toUpperCase() === selectedLetter);
    }

    setSortedUsers(users);
  }, [users, selectedLetter]);

  const rowData = useMemo(() => {
    return sortedUsers.map((user: User) => {
      const avatar = (
        <Avatar filled image={user.image} name={user.full_name} subtitle={user.contact_number} />
      );

      const email = user.email;
      const role = user.role;
      const isconfirmed = user.isconfirmed;
      const status = capitalizeFirstLetter(user.status);

      let actions;
      const state = user.role === 'agent' ? '' :
        user.type === 'Employee' || (user.role === 'admin' && user.is_employee) ?
        <TickIcon /> : <ConvertElement element={user} />;

      if (user.isconfirmed === 'Unconfirmed') {
        actions = (
          <div className="flex">
            <RemoveElement element={user} />
            <ReSend
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit(user.email);
              }}
            />
          </div>
        );
      } else {
        actions = <RemoveElement element={user} />;
      }

      return {
        avatar,
        email,
        role,
        isconfirmed,
        status,
        state,
        actions,
        onClick: () => navigate(`${user.id}`)
      };
    }) as RowData[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedUsers]);

  return (
    <div className="filter-layout__content">
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="user-list">
        <Table
          loading={loading}
          cols={headers}
          emptyTitle="No users could be found!"
          rowData={rowData}
          columnModifiers={columnModifiers}
        />
      </article>
    </div>
  );
};

export default UserList;
