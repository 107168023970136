import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { ClientType } from '../../interfaces/config/client-type.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getClientTypesEndpoint = (): string => `${API_HOST}/client_types`;
export const getClientTypesRequest = (): RequestOptions => [
  getClientTypesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddClientTypeRequest = (payload: ClientType): RequestOptions => [
  getClientTypesEndpoint(),
  getHttpPostOptions({ client_type: payload }, getAuthHeaders())
];

const getClientTypeEndpoint = (id: number | string): string => `${API_HOST}/client_types/${id}`;
export const getClientTypeRequest = (id: number | string): RequestOptions => [
  getClientTypeEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateClientTypeRequest = (
  id: number | string,
  payload: ClientType
): RequestOptions => [
  getClientTypeEndpoint(id),
  getHttpPutOptions({ client_type: payload }, getAuthHeaders())
];

export const getRemoveClientTypeRequest = (id: string | number): RequestOptions => [
  getClientTypeEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
