import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { LeaveRequest } from '../../../interfaces/employee/leave-request.interface';

import { useAppSelector } from '../../../hooks';

const LeaveRequestCrumb = () => {
  const leaveRequest: LeaveRequest = useAppSelector(
    ({ leave_request_store }: AppState) => leave_request_store.leaveRequest
  );

  return (
    <NavLink to={`/human-resources/leave-requests/${leaveRequest?.id}`}>
      {leaveRequest?.employee}
    </NavLink>
  );
};

export default LeaveRequestCrumb;
