import { NavigateFunction } from 'react-router-dom';

import { Irp5 } from '../../interfaces/employee/irp5.interface';

import {
  AddIrp5Action,
  LoadIrp5Action,
  LoadIrp5sAction,
  OnComplete,
  RemoveIrp5Action,
  UpdateIrp5Action
} from './irp5.types';

export class Irp5Actions {
  static readonly LOAD_IRP5S: string = '[IRP5_STORE] Load irp5s';
  static readonly SET_IRP5S: string = '[IRP5_STORE] Set irp5s';

  static readonly LOAD_IRP5: string = '[IRP5_STORE] Load irp5';
  static readonly SET_IRP5: string = '[IRP5_STORE] Set irp5';

  static readonly UPDATE_IRP5: string = '[IRP5_STORE] Update irp5';
  static readonly ADD_IRP5: string = '[IRP5_STORE] Add irp5';
  static readonly REMOVE_IRP5: string = '[IRP5_STORE] Remove irp5';

  static loadIrp5s = (onComplete: OnComplete): LoadIrp5sAction => ({
    type: Irp5Actions.LOAD_IRP5S,
    onComplete
  });

  static loadIrp5 = (id: number | string, onComplete: OnComplete): LoadIrp5Action => ({
    type: Irp5Actions.LOAD_IRP5,
    id,
    onComplete
  });

  static addIrp5 = (
    payload: Irp5,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddIrp5Action => ({
    type: Irp5Actions.ADD_IRP5,
    payload,
    navigate,
    onComplete
  });

  static updateIrp5 = (
    id: number | string,
    payload: Irp5,
    onComplete: OnComplete
  ): UpdateIrp5Action => ({
    type: Irp5Actions.UPDATE_IRP5,
    id,
    payload,
    onComplete
  });

  static removeIrp5 = (id: number | string, onComplete: OnComplete): RemoveIrp5Action => ({
    type: Irp5Actions.REMOVE_IRP5,
    id,
    onComplete
  });
}
