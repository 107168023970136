import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../types/state.interface';

import { Client } from '../../../interfaces/client/client.interface';

import { LoadClientAction } from '../../../actions/client/client.types';
import { ClientActions } from '../../../actions/client/client.actions';

import { useAppDispatch, useAppSelector } from '../../../hooks';

const ClientCrumb = () => {
  const { id } = useParams();
  const dispatch: Dispatch<LoadClientAction> = useAppDispatch();
  const client: Client = useAppSelector(
    ({ client_store }: AppState) => client_store.client
  ) as Client;

  useEffect(() => {
    if (!client) {
      dispatch(ClientActions.loadClient(id as string, noOp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <NavLink to={`/core/clients/${client?.id}`}>{client?.name}</NavLink>;
};

export default ClientCrumb;
