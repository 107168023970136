import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { LeaveRequest } from '../../../../interfaces/employee/leave-request.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';

import { LeaveRequestActions } from '../../../../actions/leave-request/leave-request.actions';
import { AddLeaveRequestAction } from '../../../../actions/leave-request/leave-request.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import LeaveRequestForm from '../../../../components/leave-requests/form/LeaveRequestForm';

const AddLeaveRequestContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddLeaveRequestAction | LoadEmployeesAction> = useAppDispatch();

  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: LeaveRequest, onComplete: () => void): void => {
    dispatch(LeaveRequestActions.addLeaveRequest(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Leave Request">
      <LeaveRequestForm employees={employees} onSave={onSave} />
    </PageLayout>
  );
};

export default AddLeaveRequestContainer;
