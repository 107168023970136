import React, { useEffect, useState } from 'react';

import { filterElement } from '../../../tools/filter.util';

import { useFilters } from '../../../hooks/useFilters';

import { Employee } from '../../../interfaces/employee/employee.interface';
import { Filter } from '../../../types/filter.interface';

import EmployeeCard from '../card/EmployeeCard';

import './employee-list.scss';
import AlphabetLayout from '../../layout/filter/AlphabetLayout';

interface Props {
  employees: Employee[];
  loading: boolean;
}

const formify = (employees: Employee[], filters: Filter): Employee[] =>
  employees.filter((employee: Employee) =>
    // @ts-ignore
    filterElement(employee, filters)
  );

const EmployeeList = ({ employees }: Props) => {
  const { filters, setFilterDefaults } = useFilters();
  const [sortedEmployees, setSortedEmployees] = useState(employees);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ employeerole: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLetter) {
      employees = formify(employees, filters).filter(employee => employee.full_name[0].toUpperCase() === selectedLetter);
    }

    setSortedEmployees(employees);
  }, [employees, selectedLetter]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="employee-list">
        {sortedEmployees.map((employee: Employee) => (
          <EmployeeCard key={employee.id} employee={employee} />
        ))}
      </article>
    </>
  );
};

export default EmployeeList;
