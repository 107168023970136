import { NavigateFunction } from 'react-router-dom';

import { User } from '../../interfaces/user/user.interface';

import {
  AddUserAction,
  LoadUserAction,
  LoadUsersAction,
  OnComplete,
  RemoveUserAction,
  UpdateUserAction
} from './user.types';

export class UserActions {
  static readonly LOAD_USERS: string = '[USER_STORE] Load users';
  static readonly SET_USERS: string = '[USER_STORE] Set users';

  static readonly LOAD_USER: string = '[USER_STORE] Load user';
  static readonly SET_USER: string = '[USER_STORE] Set user';

  static readonly UPDATE_USER: string = '[USER_STORE] Update user';
  static readonly ADD_USER: string = '[USER_STORE] Add user';
  static readonly REMOVE_USER: string = '[USER_STORE] Remove user';

  static loadUsers = (onComplete: OnComplete): LoadUsersAction => ({
    type: UserActions.LOAD_USERS,
    onComplete
  });

  static loadUser = (id: number | string, onComplete: OnComplete): LoadUserAction => ({
    type: UserActions.LOAD_USER,
    id,
    onComplete
  });

  static addUser = (
    payload: User,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddUserAction => ({
    type: UserActions.ADD_USER,
    payload,
    navigate,
    onComplete
  });

  static updateUser = (
    id: number | string,
    payload: User,
    onComplete: OnComplete
  ): UpdateUserAction => ({
    type: UserActions.UPDATE_USER,
    id,
    payload,
    onComplete
  });

  static removeUser = (id: number | string, onComplete: OnComplete): RemoveUserAction => ({
    type: UserActions.REMOVE_USER,
    id,
    onComplete
  });
}
