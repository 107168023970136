import React, { createRef, useEffect, useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId, isUndefined } from '@neslotech/utils';

import { useAuth } from '../../../hooks';

import { LeaveRequest } from '../../../interfaces/employee/leave-request.interface';
import { Employee } from '../../../interfaces/employee/employee.interface';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import { OnChangeType } from '../../input/Input';
import { Datepicker, OnChangeType as DateChangeType } from '../../datepicker/Datepicker';
import FormAction from '../../form/form-action/FormAction';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';
import { RadioChangeType, RadioGroup } from '../../radio/RadioGroup';

import './leave-request-form.scss';
import { FileData } from '../../../interfaces/config/file.interface';
import { toBase64 } from '../../../tools/file.util';
import { ExpenseClaimState } from '../../expense-claims/form/ExpenseClaimForm';
import { FileUpload } from '../../upload/FileUpload';
import Button from '../../button/Button';
import InfoDetail from '../../clients/details/InfoDetail';
import { Profile } from '../../../interfaces/profile/profile.interface';
import DocumentIcon from '../../document-icon/DocumentIcon';
import LeaveCalendar, { DateState } from '../calendar/LeaveCalendar';
import moment from 'moment';

interface FileProps {
  file: FileData;
}

const UploadedFile = ({ file }: FileProps) => {
  const openFile = () => {
    window.open(file.url, '_blank');
  };

  return (
    <article className="uploaded-file" onClick={openFile}>
      <section className="uploaded-file__name">
        <DocumentIcon file={file} />
        <p>{file.name}</p>
      </section>
      <small>
        Uploaded by {file.uploaded_by ?? 'you'} on {new Date(file.date).toLocaleDateString()}
      </small>
    </article>
  );
};

type OnAction = (payload: LeaveRequest, onComplete: () => void) => void;

interface Props {
  employees: Employee[];
  leaveRequest?: LeaveRequest;
  onSave: OnAction;
}

export interface LeaveRequestState extends StateForm {
  employeeId: string | number;
  startDate: string | Date;
  endDate: string | Date;
  category: string;
  status?: string;
  reason?: string;
  allow_reason?: string;
  documents?: FileData[];
  dateStates?: string;
  totalDays?: number;
}

const rules: ValidationRules<LeaveRequestState> = {
  validates: {
    employeeId: ['isPresent'],
    startDate: ['isPresent'],
    endDate: ['isPresent'],
    category: ['isPresent']
  }
};

const formify = (
  isAdmin: Boolean,
  leaveRequest?: LeaveRequest,
  profile?: Profile
): LeaveRequestState =>
  ({
    employeeId: isAdmin ? leaveRequest?.user_id : profile?.id,
    startDate: isUndefined(leaveRequest?.start_date)
      ? undefined
      : new Date(leaveRequest?.start_date),
    endDate: isUndefined(leaveRequest?.end_date) ? undefined : new Date(leaveRequest?.end_date),
    category: leaveRequest?.category,
    status: leaveRequest?.status,
    reason: leaveRequest?.reason,
    allow_reason: leaveRequest?.allow_reason,
    documents: leaveRequest?.documents ?? [],
    totalDays: leaveRequest?.total_days,
    dateStates: leaveRequest?.date_states
  } as LeaveRequestState);

const serverify = (form: LeaveRequestState) => ({
  user_id: form.employeeId,
  start_date: moment(form.startDate).format('YYYY-MM-DD'),
  end_date: moment(form.endDate).format('YYYY-MM-DD'),
  category: form.category,
  status: form.status,
  reason: form.reason,
  allow_reason: form.allow_reason,
  documents: form.documents,
  total_days: form.totalDays,
  date_states: form.dateStates
});

const categories: SelectItem[] = [
  {
    label: 'Annual Leave',
    value: 'annual'
  },
  {
    label: 'Family Responsibility Leave',
    value: 'family_responsibility'
  },
  {
    label: 'Sick Leave',
    value: 'sick'
  }
];

const LeaveRequestForm = ({ employees, leaveRequest, onSave }: Props) => {
  const { isAdmin, profile } = useAuth();
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  /* eslint-disable */
  const dateSatesData = leaveRequest?.date_states ? JSON.parse(leaveRequest?.date_states) : [];
  const [dateStates, setDateStates] = useState<DateState[]>(dateSatesData);
  const [totalDays, setTotalDays] = useState<number>(leaveRequest?.total_days);

  const [form, setForm] = useFormState<LeaveRequestState>(
    formify(isAdmin, leaveRequest, profile),
    rules
  );
  const handleChange = (newState: OnChangeType | DateChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as LeaveRequestState) });
  const handleStatusChange = (newState: RadioChangeType) => setForm({ ...form, ...newState });

  useEffect(() => {
    const tempState = { dateStates: JSON.stringify(dateStates), totalDays: totalDays };
    setForm({ ...form, ...(tempState as LeaveRequestState) });
  }, [dateStates, totalDays]);

  const [loading, setLoading] = useState<boolean>(false);

  const fileRef = createRef<HTMLInputElement>();

  const mappedEmployees = useMemo(() => {
    return employees
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((employee: Employee) => ({
        label: employee.full_name,
        value: employee.id
      }));
  }, [employees]);

  const handleSubmit = (): void => {
    setLoading(true);
    const payload: LeaveRequest = serverify(form);

    /* eslint-disable */
    console.log(form, payload);
    onSave(payload, () => setLoading(false));
  };

  const handleFileChange = async (file: File) => {
    const base64Data = await toBase64(file);
    handleChange({
      documents: [
        ...form?.documents,
        { id: generateId(), name: file.name, data: base64Data, date: new Date() }
      ]
    } as ExpenseClaimState);
  };

  return (
    <article className="leave-request-form">
      <DetailsCard>
        <Form>
          <FormRow>
            {isAdmin && (
              <Select
                name="employeeId"
                label="Employee"
                placeholder="Employee"
                value={form?.employeeId}
                error={form?.messages.employeeId}
                items={mappedEmployees}
                onChange={handleChange}
              />
            )}
          </FormRow>
          <FormRow>
            <Datepicker
              name="startDate"
              label="From Date"
              placeholder="From Date"
              value={form?.startDate}
              error={form?.messages.startDate}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Datepicker
              name="endDate"
              label="To Date"
              placeholder="To Date"
              value={form?.endDate}
              error={form?.messages.endDate}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow>
            {form?.startDate && form?.endDate ? (
              <LeaveCalendar
                startDate={form.startDate}
                endDate={form.endDate}
                calendarDate={calendarDate}
                setCalendarDate={setCalendarDate}
                dateStates={dateStates}
                setDateStates={setDateStates}
                totalDays={totalDays}
                setTotalDays={setTotalDays}
              />
            ) : null}
          </FormRow>

          <FormRow>
            <Select
              name="category"
              label="Category"
              placeholder="Category"
              value={form?.category}
              error={form?.messages.category}
              items={categories}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="reason"
              label="Reason (if any)"
              placeholder="Reason"
              onChange={handleChange}
              value={form?.reason}
            />
          </FormRow>
          <InfoDetail
            title="Add Supporting Document"
            editMode
            value=""
            editableElement={
              <FileUpload fileRef={fileRef} fileTypes="*/*" onChange={handleFileChange}>
                <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
              </FileUpload>
            }
          />
          {isAdmin && (
            <>
              <FormRow>
                <RadioGroup
                  fluid
                  name="status"
                  value={form?.status}
                  onChange={handleStatusChange}
                  items={[
                    { label: 'Approved', value: 'approved', success: true },
                    { label: 'Denied', value: 'denied', danger: true }
                  ]}
                />
              </FormRow>

              <FormRow>
                <TextArea
                  name="allow_reason"
                  label="Allow Reason"
                  placeholder="Allow Reason"
                  onChange={handleChange}
                  value={form?.allow_reason}
                />
              </FormRow>
            </>
          )}
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
      <DetailsCard title="Supporting Documents">
        <section className="leave-request-form__uploads">
          {(form?.documents ?? []).map((upload: FileData) => (
            <UploadedFile file={upload} />
          ))}
        </section>
      </DetailsCard>
    </article>
  );
};

export default LeaveRequestForm;
