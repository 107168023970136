import React from 'react';

import Button from '../../button/Button';

import './form-action.scss';

interface Props {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  hollow?: boolean;
  onClick?: () => void;
}

const FormAction = ({ label, loading, hollow, disabled, onClick }: Props) => (
  <article className="form-action">
    <Button
      hollow={hollow}
      loading={loading}
      onClick={onClick}
      label={label}
      disabled={disabled}
      type="submit"
    />
  </article>
);

FormAction.defaultProps = {
  disabled: false
};

export default FormAction;
