import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { PerformanceReview } from '../../../../interfaces/employee/performance-review.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { LoadPerformanceReviewsAction } from '../../../../actions/performance-review/performance-review.types';
import { PerformanceReviewActions } from '../../../../actions/performance-review/performance-review.actions';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import PerformanceReviewForm from '../../../../components/performance-reviews/form/PerformanceReviewForm';

const PerformanceReviewContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadPerformanceReviewsAction> = useAppDispatch();
  const performanceReview: PerformanceReview = useAppSelector(
    ({ performance_review_store }: AppState) => performance_review_store.performanceReview
  );
  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: PerformanceReview, onComplete: () => void): void => {
    dispatch(PerformanceReviewActions.updatePerformanceReview(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(PerformanceReviewActions.loadPerformanceReview(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && performanceReview?.id === Number(id);
  }, [id, loading, performanceReview?.id]);

  return (
    <PageLayout
      title={`Performance Review Details - ${doneLoading ? performanceReview?.employee : ''}`}
    >
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <PerformanceReviewForm
          employees={employees}
          performanceReview={performanceReview}
          onSave={onSave}
        />
      )}
    </PageLayout>
  );
};

export default PerformanceReviewContainer;
