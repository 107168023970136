import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import './modal.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

export const Modal = ({ children }: Props) => {
  const mount = document.getElementById('modal-root') as HTMLElement;

  const overlay = document.createElement('section');
  overlay.setAttribute('class', 'modal-overlay');

  const modalEl = document.createElement('section');
  modalEl.setAttribute('class', 'modal');

  const modalContent = document.createElement('section');
  modalContent.setAttribute('class', 'modal-content');

  useEffect(() => {
    mount.appendChild(overlay);
    overlay.appendChild(modalEl);
    modalEl.appendChild(modalContent);

    return () => {
      mount.removeChild(overlay);
    };
  }, [modalEl, mount, overlay, modalContent]);
  return createPortal(children, modalContent);
};
