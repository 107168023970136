import { Mandate } from '../../../interfaces/client/mandate.interface';

import {
  AddMandateAction,
  CompleteMandateAction,
  LoadMandateAction,
  LoadMandatesAction,
  OnComplete,
  RemoveMandateAction,
  UpdateMandateAction
} from './mandate.types';

export class MandateActions {
  static readonly LOAD_MANDATES: string = '[CLIENT_STORE] Load mandates';
  static readonly SET_MANDATES: string = '[CLIENT_STORE] Set mandates';

  static readonly LOAD_MANDATE: string = '[CLIENT_STORE] Load mandate';
  static readonly SET_MANDATE: string = '[CLIENT_STORE] Set mandate';

  static readonly ADD_MANDATE: string = '[CLIENT_STORE] Add mandate';
  static readonly UPDATE_MANDATE: string = '[CLIENT_STORE] Update mandate';
  static readonly REMOVE_MANDATE: string = '[CLIENT_STORE] Remove mandate';

  static readonly COMPLETE_MANDATE: string = '[CLIENT_STORE] Complete mandate';

  static loadMandates = (id: number | string, onComplete: OnComplete): LoadMandatesAction => ({
    type: MandateActions.LOAD_MANDATES,
    id,
    onComplete
  });

  static loadMandate = (
    id: number | string,
    mandateId: number | string,
    onComplete: OnComplete
  ): LoadMandateAction => ({
    type: MandateActions.LOAD_MANDATE,
    id,
    mandateId,
    onComplete
  });

  static addMandate = (
    id: number | string,
    payload: Mandate,
    onComplete: OnComplete
  ): AddMandateAction => ({
    type: MandateActions.ADD_MANDATE,
    id,
    payload,
    onComplete
  });

  static updateMandate = (
    id: number | string,
    mandateId: number | string,
    payload: Mandate,
    onComplete: OnComplete
  ): UpdateMandateAction => ({
    type: MandateActions.UPDATE_MANDATE,
    id,
    mandateId,
    payload,
    onComplete
  });

  static removeMandate = (
    id: number | string,
    mandateId: number | string,
    onComplete: OnComplete
  ): RemoveMandateAction => ({
    type: MandateActions.REMOVE_MANDATE,
    id,
    mandateId,
    onComplete
  });

  static completeMandate = (
    id: number | string,
    mandateId: number | string,
    onComplete: OnComplete
  ): CompleteMandateAction => ({
    type: MandateActions.COMPLETE_MANDATE,
    id,
    mandateId,
    onComplete
  });
}
