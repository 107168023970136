import React from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import { Prospect } from '../../../../interfaces/employee/prospect.interface';
import { ProspectActions } from '../../../../actions/prospect/prospect.actions';
import {
  LoadProspectsAction,
  RemoveProspectDocumentAction
} from '../../../../actions/prospect/prospect.types';
import ProspectDocumentForm from '../../../../components/prospects/form/ProspectDocumentForm';

interface Props {
  doneLoading?: boolean;
}

const ProspectDocumentsContainer = (props: Props) => {
  const { id } = useParams<string>();
  const { doneLoading } = props;

  const dispatch: Dispatch<LoadProspectsAction | RemoveProspectDocumentAction> = useAppDispatch();

  const prospect: Prospect = useAppSelector(
    ({ prospect_store }: AppState) => prospect_store.prospect
  ) as Prospect;

  const onSave = (payload: Prospect, onComplete: () => void) => {
    dispatch(ProspectActions.updateProspect(id as string, payload, onComplete));
  };

  const onRemoveDocument = (documentId: number) => {
    dispatch(ProspectActions.removeProspectDocument(id as string, documentId));
  };

  return (
    <PageLayout title="">
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <ProspectDocumentForm
          type="documents"
          documents={prospect.documents}
          onSave={onSave}
          prospect={prospect}
          onRemoveDocument={onRemoveDocument}
        />
      )}
    </PageLayout>
  );
};

export default ProspectDocumentsContainer;
