import React, { useEffect, useMemo, useState } from 'react';

import { Form as StateForm, useFormState } from '@neslotech/hooks';

import { Sector } from '../../../../interfaces/config/sector.interface';
import { Client } from '../../../../interfaces/client/client.interface';

import { useClient, useSectors } from '../../../../hooks/clients';

import { ReactComponent as SaveIcon } from '../../../../icons/save-icon.svg';
import { ReactComponent as PencilIcon } from '../../../../icons/pencil-icon.svg';

import Select, { OnChangeType, SelectItem } from '../../../select/Select';
import DetailsCard from '../../../card/DetailsCard';
import Input from '../../../input/Input';
import InfoDetail from '../InfoDetail';

type OnSave = (payload: Client, onComplete: () => void) => void;
interface Props {
  onSave: OnSave;
}

interface DetailState extends StateForm {
  primarySectors?: string;
  primarySectorsId?: string;
  subSectors?: string;
  subSectorsId?: string;
  geographicFocus?: string;
  location?: string;
  excludedIndustries?: string[];
  excludedIndustriesIds?: string[] | number[];
}

const formify = (client: Client): DetailState =>
  ({
    primarySectors: client.primary_sectors,
    primarySectorsId: client.primary_sectors_id,
    subSectors: client.sub_sectors,
    subSectorsId: client.sub_sectors_id,
    geographicFocus: client.geographic_focus,
    location: client.location,
    excludedIndustries: client.excluded_industries,
    excludedIndustriesIds: client.excluded_industries_ids
  } as DetailState);

const serverify = (form: DetailState): Client => ({
  primary_sectors_id: form.primarySectorsId,
  sub_sectors_id: form.subSectorsId,
  geographic_focus: form.geographicFocus,
  location: form.location,
  excluded_industries_ids: form.excludedIndustriesIds
});

const OtherInfoDetail = ({ onSave }: Props) => {
  const { client } = useClient();
  const sectors: Sector[] = useSectors();

  const [form, setForm] = useFormState<DetailState>(formify(client), { validates: {} });
  const handleChange = (newState: OnChangeType) =>
    setForm({ ...form, ...(newState as DetailState) });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setForm(formify(client));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const action = useMemo(() => {
    const handleSave = () => {
      setLoading(true);
      const payload: Client = serverify(form);
      onSave(payload, () => {
        setLoading(false);
        setEditMode(false);
      });
    };

    return editMode ? (
      <SaveIcon onClick={handleSave} />
    ) : (
      <PencilIcon onClick={() => setEditMode(true)} />
    );
  }, [editMode, form, onSave]);

  const mappedSectors: SelectItem[] = useMemo(() => {
    if (!editMode) {
      return [];
    }

    return sectors
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map((sector: Sector) => ({
        label: sector.name,
        value: sector.id!
      }));
  }, [editMode, sectors]);

  return (
    <DetailsCard title="Other" centered action={action} loading={loading} fitted>
      <InfoDetail
        title="Primary Sectors"
        value={form.primarySectors}
        editMode={editMode}
        editableElement={
          <Select
            name="primarySectorsId"
            placeholder="Select primary sector"
            items={mappedSectors}
            value={form.primarySectorsId}
            onChange={handleChange}
          />
        }
      />
      <InfoDetail
        title="Sub-sectors"
        value={form.subSectors}
        editMode={editMode}
        editableElement={
          <Select
            name="subSectorsId"
            placeholder="Select sub-sector"
            items={mappedSectors}
            value={form.subSectorsId}
            onChange={handleChange}
          />
        }
      />
      <InfoDetail
        title="Main Geographic Focus"
        value={form.geographicFocus}
        editMode={editMode}
        editableElement={
          <Input
            name="geographicFocus"
            placeholder="Main Geographic Focus"
            value={form.geographicFocus}
            onChange={handleChange}
          />
        }
      />
      <InfoDetail
        title="Location"
        value={form.location}
        editMode={editMode}
        editableElement={
          <Input
            name="location"
            placeholder="Location"
            value={form.location}
            onChange={handleChange}
          />
        }
      />
      <InfoDetail
        title="Industries Excluded"
        value={form.excludedIndustries}
        editMode={editMode}
        editableElement={
          <Select
            name="excludedIndustriesIds"
            placeholder="Select Industries Excluded"
            value={form.excludedIndustriesId}
            items={mappedSectors}
            onChange={handleChange}
            multiple
          />
        }
      />
    </DetailsCard>
  );
};

export default OtherInfoDetail;
