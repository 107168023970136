import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Action, Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch, useAuth } from '../../../hooks';

import { Credentials } from '../../../interfaces/auth/credentials.interface';

import { AuthActions } from '../../../actions/auth/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Login from '../../../components/auth/login/Login';

const LoginContainer = () => {
  const dispatch: Dispatch<Action> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const { setToken } = useAuth();

  const handleLogin = (request: Credentials, onComplete: () => void) => {
    dispatch(
      AuthActions.authenticate(request, navigate, (token: string) => setToken(token), onComplete)
    );
  };

  return (
    <AuthLayout
      title="Sign In"
      subtitle="Just sign in if you have an account in here. Enjoy our Website"
    >
      <Login onLogin={handleLogin} />
    </AuthLayout>
  );
};

export default LoginContainer;
