import React, { useMemo } from 'react';

import { getClassNames } from '@neslotech/utils';

import { ReactComponent as ProfileIcon } from '../../icons/profile-icon.svg';

import './avatar.scss';

interface Props {
  image?: string;
  name?: string;
  subtitle?: string;
  filled?: boolean;
}

const Avatar = ({ image, name, subtitle, filled }: Props) => {
  const avatar = useMemo(() => {
    return !!image ? <img alt={name} src={image} /> : <ProfileIcon />;
  }, [image, name]);

  return (
    <article className={getClassNames('avatar', { filled })}>
      {avatar}
      <section className="avatar__name">
        <p>{name}</p>
        {subtitle && <p className="avatar__subtitle">{subtitle}</p>}
      </section>
    </article>
  );
};

export default Avatar;
