import { NavigateFunction } from 'react-router-dom';

import { Prospect } from '../../interfaces/employee/prospect.interface';

import {
  AddProspectAction,
  ConvertProspectAction,
  LoadProspectAction,
  LoadProspectsAction,
  OnComplete,
  RemoveProspectAction,
  RemoveProspectDocumentAction,
  UpdateProspectAction
} from './prospect.types';

export class ProspectActions {
  static readonly LOAD_PROSPECTS: string = '[PROSPECT_STORE] Load prospects';
  static readonly SET_PROSPECTS: string = '[PROSPECT_STORE] Set prospects';

  static readonly LOAD_PROSPECT: string = '[PROSPECT_STORE] Load prospect';
  static readonly SET_PROSPECT: string = '[PROSPECT_STORE] Set prospect';

  static readonly UPDATE_PROSPECT: string = '[PROSPECT_STORE] Update prospect';
  static readonly CONVERT_PROSPECT: string = '[PROSPECT_STORE] Convert prospect';
  static readonly ADD_PROSPECT: string = '[PROSPECT_STORE] Add prospect';
  static readonly REMOVE_PROSPECT: string = '[PROSPECT_STORE] Remove prospect';

  static readonly REMOVE_PROSPECT_DOCUMENT: string = '[PROSPECT_STORE] Remove prospect document';

  static loadProspects = (onComplete: OnComplete): LoadProspectsAction => ({
    type: ProspectActions.LOAD_PROSPECTS,
    onComplete
  });

  static loadProspect = (id: number | string, onComplete: OnComplete): LoadProspectAction => ({
    type: ProspectActions.LOAD_PROSPECT,
    id,
    onComplete
  });

  static addProspect = (
    payload: Prospect,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddProspectAction => ({
    type: ProspectActions.ADD_PROSPECT,
    payload,
    navigate,
    onComplete
  });

  static updateProspect = (
    id: number | string,
    payload: Prospect,
    onComplete: OnComplete
  ): UpdateProspectAction => ({
    type: ProspectActions.UPDATE_PROSPECT,
    id,
    payload,
    onComplete
  });

  static convertProspect = (
    id: number | string,
    payload: Prospect,
    onComplete: OnComplete
  ): ConvertProspectAction => ({
    type: ProspectActions.CONVERT_PROSPECT,
    id,
    payload,
    onComplete
  });

  static removeProspect = (id: number | string, onComplete: OnComplete): RemoveProspectAction => ({
    type: ProspectActions.REMOVE_PROSPECT,
    id,
    onComplete
  });

  static removeProspectDocument = (
    id: number | string,
    documentId: number
  ): RemoveProspectDocumentAction => ({
    type: ProspectActions.REMOVE_PROSPECT_DOCUMENT,
    id,
    documentId
  });
}
