import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { ExpenseClaim } from '../../../../interfaces/employee/expense-claim.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { Client } from '../../../../interfaces/client/client.interface';

import { ExpenseClaimActions } from '../../../../actions/expense-claim/expense-claim.actions';
import { AddExpenseClaimAction } from '../../../../actions/expense-claim/expense-claim.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';
import { ClientActions } from '../../../../actions/client/client.actions';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import ExpenseClaimForm from '../../../../components/expense-claims/form/ExpenseClaimForm';

const AddExpenseClaimsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddExpenseClaimAction | LoadEmployeesAction> = useAppDispatch();

  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );
  const clients: Client[] = useAppSelector(({ client_store }: AppState) => client_store.clients);

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    dispatch(ClientActions.loadClients(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: ExpenseClaim, onComplete: () => void): void => {
    dispatch(ExpenseClaimActions.addExpenseClaim(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Expense Claim">
      <ExpenseClaimForm clients={clients} employees={employees} onSave={onSave} />
    </PageLayout>
  );
};

export default AddExpenseClaimsContainer;
