import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddClientRequest,
  getClientRequest,
  getClientsRequest,
  getRemoveClientRequest,
  getUpdateClientRequest,
  RequestOptions
} from '../../tools/api';

import {
  AddClientAction,
  DownloadClientsAction,
  LoadClientsAction,
  UpdateClientAction
} from '../../actions/client/client.types';
import { ClientActions } from '../../actions/client/client.actions';
import { SystemActions } from '../../actions/system/system.actions';

import { ResponseError } from '../../types/response-error.interface';
import { getDownlaodClientsRequests } from '../../tools/api/clients/client.endpoints';

export function* performLoadClients({ onComplete }: LoadClientsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ClientActions.SET_CLIENTS, clients: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadClientsRequest() {
  yield takeLatest(ClientActions.LOAD_CLIENTS, performLoadClients);
}

export function* performLoadClient({ id, onComplete }: LoadClientsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ClientActions.SET_CLIENT, client: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForDoanloadClientsRequest() {
  yield takeLatest(ClientActions.DOWNLOAD_CLIENTS, performDownloadClients);
}

export function* performDownloadClients({ client_id, payload, onComplete }: DownloadClientsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getDownlaodClientsRequests(
      client_id,
      payload
    ) as RequestOptions;

    const { data, headers } = yield call(axios, endpoint, requestOptions);

    // Get the Content-Disposition header value from the response
    const contentDisposition = headers['content-disposition'];

    // Use a regular expression to extract the file name from the header value
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';

    const csvBlob = new Blob([data], { type: 'text/csv' });
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(csvBlob);
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadClientRequest() {
  yield takeLatest(ClientActions.LOAD_CLIENT, performLoadClient);
}

export function* performUpdateClient({ id, payload, onComplete }: UpdateClientAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateClientRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: ClientActions.SET_CLIENT, client: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateClientRequest() {
  yield takeLatest(ClientActions.UPDATE_CLIENT, performUpdateClient);
}

export function* performAddClient({ payload, navigate, onComplete }: AddClientAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddClientRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new client has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/core/clients');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddClientRequest() {
  yield takeLatest(ClientActions.ADD_CLIENT, performAddClient);
}

export function* performRemoveClient({ id, navigate, onComplete }: AddClientAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveClientRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The client has been removed successfully!', SNACK_SUCCESS));

    yield navigate('/core/clients');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveClientRequest() {
  yield takeLatest(ClientActions.REMOVE_CLIENT, performRemoveClient);
}

export default function* clientSaga() {
  yield all([
    watchForLoadClientsRequest(),
    watchForLoadClientRequest(),
    watchForUpdateClientRequest(),
    watchForAddClientRequest(),
    watchForRemoveClientRequest(),
    watchForDoanloadClientsRequest()
  ]);
}
