import { NavigateFunction } from 'react-router-dom';

import { Timesheet } from '../../interfaces/employee/timesheet.interface';

import {
  AddTimesheetAction,
  AddAnotherTimesheetAction,
  LoadTimesheetAction,
  LoadTimesheetsAction,
  OnComplete,
  RemoveTimesheetAction,
  UpdateTimesheetAction,
  DownloadTimesheetsAction
} from './timesheet.types';

export class TimesheetActions {
  static readonly LOAD_TIMESHEETS: string = '[TIMESHEET_STORE] Load timesheets';
  static readonly DOWNLOAD_TIMESHEETS: string = '[TIMESHEET_STORE] Download timesheets';
  static readonly SET_TIMESHEETS: string = '[TIMESHEET_STORE] Set timesheets';

  static readonly LOAD_TIMESHEET: string = '[TIMESHEET_STORE] Load timesheet';
  static readonly SET_TIMESHEET: string = '[TIMESHEET_STORE] Set timesheet';

  static readonly UPDATE_TIMESHEET: string = '[TIMESHEET_STORE] Update timesheet';
  static readonly ADD_TIMESHEET: string = '[TIMESHEET_STORE] Add timesheet';
  static readonly ADD_ANOTHER_TIMESHEET: string = '[TIMESHEET_STORE] Add another timesheet';
  static readonly REMOVE_TIMESHEET: string = '[TIMESHEET_STORE] Remove timesheet';

  static loadTimesheets = (onComplete: OnComplete): LoadTimesheetsAction => ({
    type: TimesheetActions.LOAD_TIMESHEETS,
    onComplete
  });

  static downloadTimesheets = (onComplete: OnComplete): DownloadTimesheetsAction => ({
    type: TimesheetActions.DOWNLOAD_TIMESHEETS,
    onComplete
  });

  static loadTimesheet = (id: number | string, onComplete: OnComplete): LoadTimesheetAction => ({
    type: TimesheetActions.LOAD_TIMESHEET,
    id,
    onComplete
  });

  static addTimesheet = (
    payload: Timesheet,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddTimesheetAction => ({
    type: TimesheetActions.ADD_TIMESHEET,
    payload,
    navigate,
    onComplete
  });

  static addAnotherTimesheet = (
    payload: Timesheet,
    onComplete: OnComplete
  ): AddAnotherTimesheetAction => ({
    type: TimesheetActions.ADD_ANOTHER_TIMESHEET,
    payload,
    onComplete
  });

  static updateTimesheet = (
    id: number | string,
    payload: Timesheet,
    onComplete: OnComplete
  ): UpdateTimesheetAction => ({
    type: TimesheetActions.UPDATE_TIMESHEET,
    id,
    payload,
    onComplete
  });

  static removeTimesheet = (
    id: number | string,
    onComplete: OnComplete
  ): RemoveTimesheetAction => ({
    type: TimesheetActions.REMOVE_TIMESHEET,
    id,
    onComplete
  });
}
