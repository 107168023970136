import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { User } from '../../interfaces/user/user.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getUsersEndpoint = (): string => `${API_HOST}/users`;
export const getUsersRequest = (): RequestOptions => [
  getUsersEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getUserEndpoint = (id: number | string): string => `${API_HOST}/users/${id}`;
export const getUserRequest = (id: number | string): RequestOptions => [
  getUserEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateUserRequest = (id: number | string, payload: User): RequestOptions => [
  getUserEndpoint(id),
  getHttpPutOptions({ user: payload }, getAuthHeaders())
];

export const getAddUserRequest = (payload: User): RequestOptions => [
  getUsersEndpoint(),
  getHttpPostOptions({ user: { ...payload, self_service: false } }, getAuthHeaders())
];

export const getRemoveUserRequest = (id: string | number): RequestOptions => [
  getUserEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
