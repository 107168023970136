import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Category } from '../interfaces/config/category.interface';

import { CategoryActions } from '../actions/category/category.actions';

export interface CategoryState extends State {
  categories: Category[];
  category?: Category;
}

export const initialState: CategoryState = {
  categories: []
};

export const categoryReducer = (state: CategoryState = initialState, action: AnyAction) => {
  if (action.type === CategoryActions.SET_CATEGORIES) {
    const { categories } = action;
    return {
      ...state,
      categories
    };
  } else if (action.type === CategoryActions.SET_CATEGORY) {
    const { category } = action;
    return {
      ...state,
      category
    };
  }

  return state;
};
