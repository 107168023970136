import { NavigateFunction } from 'react-router-dom';

import { Employeerole } from '../../interfaces/employeerole/employeerole.interface';

import {
  AddEmployeeroleAction,
  LoadEmployeeroleAction,
  LoadEmployeerolesAction,
  OnComplete,
  RemoveEmployeeroleAction,
  UpdateEmployeeroleAction
} from './employeerole.types';

export class EmployeeroleActions {
  static readonly LOAD_EMPLOYEEROLES: string = '[EMPLOYEEROLE_STORE] Load employeeroles';
  static readonly SET_EMPLOYEEROLES: string = '[EMPLOYEEROLE_STORE] Set employeeroles';

  static readonly LOAD_EMPLOYEEROLE: string = '[EMPLOYEEROLE_STORE] Load employeerole';
  static readonly SET_EMPLOYEEROLE: string = '[EMPLOYEEROLE_STORE] Set employeerole';

  static readonly UPDATE_EMPLOYEEROLE: string = '[EMPLOYEEROLE_STORE] Update employeerole';
  static readonly ADD_EMPLOYEEROLE: string = '[EMPLOYEEROLE_STORE] Add employeerole';
  static readonly REMOVE_EMPLOYEEROLE: string = '[EMPLOYEEROLE_STORE] Remove employeerole';

  static loadEmployeeroles = (onComplete: OnComplete): LoadEmployeerolesAction => ({
    type: EmployeeroleActions.LOAD_EMPLOYEEROLES,
    onComplete
  });

  static loadEmployeerole = (id: number | string, onComplete: OnComplete): LoadEmployeeroleAction => ({
    type: EmployeeroleActions.LOAD_EMPLOYEEROLE,
    id,
    onComplete
  });

  static addEmployeerole = (
    payload: Employeerole,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddEmployeeroleAction => ({
    type: EmployeeroleActions.ADD_EMPLOYEEROLE,
    payload,
    navigate,
    onComplete
  });

  static updateEmployeerole = (
    id: number | string,
    payload: Employeerole,
    onComplete: OnComplete
  ): UpdateEmployeeroleAction => ({
    type: EmployeeroleActions.UPDATE_EMPLOYEEROLE,
    id,
    payload,
    onComplete
  });

  static removeEmployeerole = (id: number | string, onComplete: OnComplete): RemoveEmployeeroleAction => ({
    type: EmployeeroleActions.REMOVE_EMPLOYEEROLE,
    id,
    onComplete
  });
}
