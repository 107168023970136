import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { statusItems } from '../../../tools/dropdown.util';

import { Fund } from '../../../interfaces/fund/fund.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType } from '../../select/Select';
import AvatarLayout from '../../layout/avatar/AvatarLayout';

import './fund-form.scss';

type OnSave = (payload: Fund, onComplete: () => void) => void;
type Status = 'active' | 'inactive';

interface Props {
  fund?: Fund;
  onSave: OnSave;
}

export interface FundState extends StateForm {
  name: string;
  image: string;
  status: Status;
}

const rules: ValidationRules<FundState> = {
  validates: {
    name: ['isPresent'],
    status: ['isPresent']
  }
};

const formify = (fund?: Fund): FundState =>
  ({
    name: fund?.name,
    image: fund?.image,
    status: fund?.status
  } as FundState);

const serverify = (form: FundState) => ({
  name: form.name,
  image: form.image,
  status: form.status
});

const FundForm = ({ fund, onSave }: Props) => {
  const [form, setForm] = useFormState<FundState>(formify(fund), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as FundState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Fund = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <AvatarLayout
      title={fund?.name ?? form?.name}
      subtitle={fund?.status ?? form?.status}
      image={form?.image ?? fund?.image}
      handleChange={handleChange}
    >
      <Form>
        <FormRow>
          <Input
            name="name"
            label="Fund Name"
            placeholder="Fund Name"
            value={form?.name}
            error={form?.messages.name}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Select
            name="status"
            label="Status"
            placeholder="Select a status"
            items={statusItems}
            value={form?.status}
            error={form?.messages.status}
            onChange={handleChange}
          />
        </FormRow>
        <FormAction
          loading={loading}
          label="Save Changes"
          onClick={handleSubmit}
          disabled={!form?.valid}
        />
      </Form>
    </AvatarLayout>
  );
};

export default FundForm;
