import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';
import { Contact } from '../interfaces/client/contact.interface';

import { ContactActions } from '../actions/contact/contact.actions';

export interface ContactState extends State {
  contacts: Contact[];
  contact?: Contact;
}

export const initialState: ContactState = {
  contacts: []
};

export const contactReducer = (state: ContactState = initialState, action: AnyAction) => {
  switch (action.type) {
    case ContactActions.SET_CONTACTS:
      const { contacts } = action;
      return {
        ...state,
        contacts
      };
    case ContactActions.SET_CONTACT:
      const { contact } = action;
      return {
        ...state,
        contact
      };
    default:
      return state;
  }
};
