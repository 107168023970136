import React from 'react';

import { Contact } from '../../../interfaces/client/contact.interface';

import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';
import Button from '../../button/Button';
import Checkbox from '../../checkbox/Checkbox';

import './contact-card.scss';
import { capitalise } from '@neslotech/utils';

interface Props {
  contact: Contact;
  onView?: () => void;
  onRemove?: () => void;
}

export const ClientCheckboxes = ({ contact }: Props) => (
  <article className="contact-card__checkboxes">
    <Checkbox success name="yes" checked={contact.linked_client} disabled label="Yes" />
    <Checkbox danger name="no" checked={!contact.linked_client} disabled label="No" />
  </article>
);

const ContactCard = ({ contact, onView, onRemove }: Props) => (
  <DetailsCard fitted action={<RemoveIcon onClick={onRemove} />}>
    <article className="contact-card">
      <InfoDetail title="Name" value={contact.key_contact} />
      <InfoDetail title="Client" value={<ClientCheckboxes contact={contact} />} />
      <InfoDetail title="Classification" value={capitalise(contact.classification ?? '')} />
      <footer>
        <Button label="Details" hollow onClick={onView} />
      </footer>
    </article>
  </DetailsCard>
);

export default ContactCard;
