import { NavigateFunction } from 'react-router-dom';

import { Event } from '../../interfaces/event/event.interface';

import {
  AddEventAction,
  LoadEventAction,
  LoadEventsAction,
  OnComplete,
  RemoveEventAction,
  UpdateEventAction
} from './event.types';

export class EventActions {
  static readonly LOAD_EVENTS: string = '[EVENT_STORE] Load events';
  static readonly SET_EVENTS: string = '[EVENT_STORE] Set events';

  static readonly LOAD_EVENT: string = '[EVENT_STORE] Load event';
  static readonly SET_EVENT: string = '[EVENT_STORE] Set event';

  static readonly UPDATE_EVENT: string = '[EVENT_STORE] Update event';
  static readonly ADD_EVENT: string = '[EVENT_STORE] Add event';
  static readonly REMOVE_EVENT: string = '[EVENT_STORE] Remove event';

  static loadEvents = (onComplete: OnComplete): LoadEventsAction => ({
    type: EventActions.LOAD_EVENTS,
    onComplete
  });

  static loadEvent = (id: number | string, onComplete: OnComplete): LoadEventAction => ({
    type: EventActions.LOAD_EVENT,
    id,
    onComplete
  });

  static addEvent = (
    payload: Event,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddEventAction => ({
    type: EventActions.ADD_EVENT,
    payload,
    navigate,
    onComplete
  });

  static updateEvent = (
    id: number | string,
    payload: Event,
    onComplete: OnComplete
  ): UpdateEventAction => ({
    type: EventActions.UPDATE_EVENT,
    id,
    payload,
    onComplete
  });

  static removeEvent = (id: number | string, onComplete: OnComplete): RemoveEventAction => ({
    type: EventActions.REMOVE_EVENT,
    id,
    onComplete
  });
}
