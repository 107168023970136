import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { ContactHistory } from '../../../../../../interfaces/client/contact.interface';

import { useAppDispatch } from '../../../../../../hooks';

import { UpdateContactAction } from '../../../../../../actions/contact/contact.types';
import { ClientContactActions } from '../../../../../../actions/client/contact/contact.actions';

import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import ContactHistoryForm from '../../../../../../components/clients/details/contact-histories/form/ContactHistoryForm';
import { useClient } from '../../../../../../hooks/clients';

interface Props {
  contactId: string | number;
  history: ContactHistory;
  setShow: (value: boolean) => void;
}

const EditContactHistoryContainer = ({ contactId, history, setShow }: Props) => {
  const { client } = useClient();
  const clientId = client.id;

  const dispatch: Dispatch<UpdateContactAction> = useAppDispatch();

  const onClose = () => setShow(false);

  const handleSave = (payload: ContactHistory, onComplete: () => void) => {
    dispatch(
      ClientContactActions.updateContactHistory(clientId, contactId, history.id, payload, onClose, onComplete)
    );
  };

  return (
    <ModalLayout onClose={onClose} title="Edit Contact History" large>
      <ContactHistoryForm contactHistory={history} onSave={handleSave} />
    </ModalLayout>
  );
};

export default EditContactHistoryContainer;
