import React from 'react';
import { RouteObject } from 'react-router-dom';

import PerformanceReviewsContainer from '../../containers/human-resources/performance-reviews/list/PerformanceReviews.container';
// eslint-disable-next-line max-len
import AddPerformanceReviewContainer from '../../containers/human-resources/performance-reviews/add/AddPerformanceReviewContainer.container';
// eslint-disable-next-line max-len
import PerformanceReviewContainer from '../../containers/human-resources/performance-reviews/details/PerformanceReview.container';

import PerformanceReviewsCrumb from '../../crumbs/human-resources/performance-reviews/PerformanceReviews.crumb';
import AddPerformanceReviewCrumb from '../../crumbs/human-resources/performance-reviews/AddPerformanceReview.crumb';
import PerformanceReviewCrumb from '../../crumbs/human-resources/performance-reviews/PerformanceReview.crumb';

import ProtectedRoute from '../ProtectedRoute';

const performanceReviewsRoutes: RouteObject[] = [
  {
    path: 'performance-reviews',
    handle: {
      crumb: () => <PerformanceReviewsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <PerformanceReviewsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute adminRequired>
            <AddPerformanceReviewContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddPerformanceReviewCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <PerformanceReviewContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <PerformanceReviewCrumb />
        }
      }
    ]
  }
];

export default performanceReviewsRoutes;
