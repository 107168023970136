import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Tombstone } from '../../../interfaces/client/tombstone.interface';

import { useRemoval } from '../../../hooks';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';

interface Props {
  tombstone: Tombstone;
}

const TombstoneCard = ({ tombstone }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const { setElement, setShowModal } = useRemoval();

  const actions = useMemo(() => {
    const handleRemove = () => {
      setShowModal(true);
      setElement(tombstone);
    };

    return (
      <>
        <EditIcon
          onClick={() =>
            navigate(`/core/clients/${tombstone.client_id}/tombstones/${tombstone.id}`)
          }
        />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tombstone]);

  return (
    <DetailsCard action={actions} small>
      <article className="mandate-card">
        <InfoDetail title="Client Name" value={tombstone.client} />
        <InfoDetail title="Category" value={tombstone.category} />
        <InfoDetail title="Subcategory" value={tombstone.sub_category} />
        <InfoDetail title="Date" value={tombstone.date} />
        <InfoDetail
          title="Size of Transaction"
          value={
            tombstone.size_of_transaction
              ? parseFloat(tombstone.size_of_transaction.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : null
          }
        />
        <InfoDetail
          title="Fee of Transaction"
          value={
            tombstone.fee_of_transaction
              ? parseFloat(tombstone.fee_of_transaction.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : null
          }
        />
      </article>
    </DetailsCard>
  );
};

export default TombstoneCard;
