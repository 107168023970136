import React, { useEffect, useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { DealSheet } from '../../../../../interfaces/client/deal-sheet.interface';
import { Category } from '../../../../../interfaces/config/category.interface';
import { Tombstone } from '../../../../../interfaces/client/tombstone.interface';
import { User } from '../../../../../interfaces/user/user.interface';

import { useCategories, useUsers } from '../../../../../hooks/clients';

import Form from '../../../../form/Form';
import FormRow from '../../../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../../../input/Input';
import FormAction from '../../../../form/form-action/FormAction';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../../../select/Select';
import { default as ReactSelect } from 'react-select';
import DetailsCard from '../../../../card/DetailsCard';
import { Datepicker, OnChangeType as DateChangeType } from '../../../../datepicker/Datepicker';

import './deal-sheet-form.scss';
import { SubCategory } from '../../../../../interfaces/config/sub_category.interface';
import { isUndefined } from '@neslotech/utils';

type OnSave = (payload: DealSheet, onComplete: () => void) => void;

interface Props {
  tombstones: Tombstone[];
  dealSheet?: DealSheet;
  onSave: OnSave;
}

export interface DealSheetState extends StateForm {
  name: string;
  expectedClosingDate?: string | Date;
  feeStructure?: string;
  personnel?: string;
  personnelIds?: string[] | number[];
  client?: string;
  categoryId?: number | string;
  category?: string;
  subCategory?: string;
  subCategoryId?: number | string;
  customCategory?: string;
  tombstoneId?: number | string;
}

const rules: ValidationRules<DealSheetState> = {
  validates: {
    name: ['isPresent']
  }
};

const formify = (dealSheet?: DealSheet): DealSheetState =>
  ({
    name: dealSheet?.name,
    expectedClosingDate: dealSheet?.closing_date ? new Date(dealSheet.closing_date) : '',
    feeStructure: dealSheet?.fee_structure,
    personnelIds: ((dealSheet?.personnel as User[]) ?? []).map((person: User) => person.id),
    personnel: ((dealSheet?.personnel as User[]) ?? [])
      .map((person: User) => person.full_name)
      .join(', '),
    client: dealSheet?.client,
    categoryId: dealSheet?.category_id,
    category: dealSheet?.category,
    subCategory: dealSheet?.sub_category,
    subCategoryId: dealSheet?.sub_category_id,
    tombstoneId: dealSheet?.tombstone_id
  } as DealSheetState);

const serverify = (form: DealSheetState) => ({
  name: form.name,
  closing_date: form.expectedClosingDate,
  fee_structure: form.feeStructure,
  category_id: form.categoryId,
  sub_category_id: form.subCategoryId,
  category: form.customCategory,
  tombstone_id: form.tombstoneId,
  personnel_ids: form.personnelIds
});

const DealSheetForm = ({ tombstones, dealSheet, onSave }: Props) => {
  const [form, setForm] = useFormState<DealSheetState>(formify(dealSheet), rules);
  const handleChange = (newState: OnChangeType | DateChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as DealSheetState) });

  const categories: Category[] = useCategories();
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const users: User[] = useUsers();

  const [loading, setLoading] = useState<boolean>(false);

  const defaultSelectUsers = useMemo(() => {
    return users
      .filter((user: User) => form.personnelIds.map(String)?.includes(user.id.toString()))
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map((user: User) => ({ label: user.full_name, value: user.id }));
  }, [form.personnelIds, users]);

  const [selectedOptions, setSelectedOptions] = useState(defaultSelectUsers);

  const handlePersonnelRemove = (personId: string | number) => {
    const selectedUserOptions = selectedOptions.filter((user: any) => user.value !== personId);
    setSelectedOptions(selectedUserOptions);
    handleChange({ personnelIds: selectedUserOptions.map((item: any) => item.value) });
  };

  const handleSelectChange = (data: any) => {
    setSelectedOptions(data);
    handleChange({ personnelIds: data.map((item: any) => item.value) });
  };

  const SelectUsers = useMemo(() => {
    // eslint-disable-next-line no-console
    console.log('users', users);
    return users
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map((user: User) => ({ label: user.full_name, value: user.id }));
  }, [users]);

  useEffect(() => {
    const selectedUserOptions = users
      .filter((user: User) => form.personnelIds.map(String).includes(user.id.toString()))
      .map((user: User) => ({ label: user.full_name, value: user.id }));
    setSelectedOptions(selectedUserOptions);
  }, [form.personnelIds, users]);

  useEffect(() => {
    const selectedCategory = categories.find((cat) => cat.id === Number(form.categoryId));

    if (selectedCategory && selectedCategory.sub_categories) {
      const activeSubCategories = selectedCategory.sub_categories.filter(
        (subCat) => subCat['status'] === 'active'
      );
      setSubCategories(activeSubCategories);
    } else {
      setSubCategories([]);
    }
  }, [categories, form.categoryId]);

  const mappedCategories: SelectItem[] = useMemo<SelectItem[]>(() => {
    return categories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((category: Category) => ({
        label: category.name,
        value: category.name === 'Other' ? -100 : category.id
      }));
  }, [categories]);

  const mappedSubCategories: SelectItem[] = useMemo(() => {
    return subCategories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((subCategory: SubCategory) => ({
        label: subCategory.name,
        value: subCategory.id
      }));
  }, [subCategories]);

  const mappedTombstones: SelectItem[] = useMemo<SelectItem[]>(() => {
    return tombstones
      .sort((a, b) => (a.description?.toLowerCase() > b.description?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((tombstone: Tombstone) => ({
        label: tombstone.description,
        value: tombstone.id
      }));
  }, [tombstones]);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: DealSheet = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="deal-sheet-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Deal Name"
              placeholder="Deal Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="categoryId"
              label="Category"
              placeholder="Select a category"
              items={mappedCategories}
              value={form?.categoryId}
              error={form?.messages.categoryId}
              onChange={handleChange}
            />
          </FormRow>
          {subCategories.length > 0 && (
            <FormRow>
              <Select
                name="subCategoryId"
                label="Sub Category"
                placeholder="Select a sub category"
                items={mappedSubCategories}
                value={form?.subCategoryId}
                error={form?.messages.subCategoryId}
                onChange={handleChange}
              />
            </FormRow>
          )}
          {Number(form?.categoryId) === -100 && (
            <FormRow>
              <Input
                name="customCategory"
                label="Enter a custom Category"
                placeholder="Enter a custom Category"
                value={form?.customCategory}
                error={form?.messages.customCategory}
                onChange={handleChange}
              />
            </FormRow>
          )}
          <FormRow>
            <Datepicker
              name="expectedClosingDate"
              label="Expected Closing Date"
              placeholder="Expected Closing Date"
              value={form?.expectedClosingDate}
              error={form?.messages.categoryId}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="feeStructure"
              label="Fee Structure"
              placeholder="Fee Structure"
              value={form?.feeStructure}
              error={form?.messages.feeStructure}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <ReactSelect
              isMulti
              isSearchable={true}
              placeholder="Select Personnel"
              options={SelectUsers}
              onChange={handleSelectChange}
              name="personnelIds"
              defaultValue={defaultSelectUsers}
              closeMenuOnSelect={false}
              classNamePrefix="input"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: '#c4960c'
                }
              })}
            />
          </FormRow>
          { !isUndefined(selectedOptions) && (
            <div className="personnel-list">
              {selectedOptions.map(person => (
                <div key={person.value} className="personnel-pill">
                  {person.label}
                  <button onClick={() => handlePersonnelRemove(person.value)}>x</button>
                </div>
              ))}
            </div>
          )}
          <FormRow>
            <Select
              name="tombstoneId"
              label="Tombstone"
              placeholder="Select a tombstone"
              items={mappedTombstones}
              value={form?.tombstoneId}
              error={form?.messages.tombstoneId}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default DealSheetForm;
