import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { PerformanceReview } from '../../../interfaces/employee/performance-review.interface';

import { useAppSelector } from '../../../hooks';

const PerformanceReviewCrumb = () => {
  const performanceReview: PerformanceReview = useAppSelector(
    ({ performance_review_store }: AppState) => performance_review_store.performanceReview
  );

  return (
    <NavLink to={`/human-resources/performance-reviews/${performanceReview?.id}`}>
      {performanceReview?.employee}
    </NavLink>
  );
};

export default PerformanceReviewCrumb;
