import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddEmployeeroleRequest,
  getRemoveEmployeeroleRequest,
  getUpdateEmployeeroleRequest,
  getEmployeeroleRequest,
  getEmployeerolesRequest,
  RequestOptions
} from '../tools/api';

import { AddEmployeeroleAction, LoadEmployeerolesAction, UpdateEmployeeroleAction } from '../actions/employeerole/employeerole.types';
import { EmployeeroleActions } from '../actions/employeerole/employeerole.actions';
import { SystemActions } from '../actions/system/system.actions';
import { ProfileActions } from '../actions/profile/profile.actions';

import { ResponseError } from '../types/response-error.interface';
// import { AuthActions } from '../actions/auth/auth.actions';

export function* performLoadEmployeeroles({ onComplete }: LoadEmployeerolesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getEmployeerolesRequest();

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: EmployeeroleActions.SET_EMPLOYEEROLES, employeeroles: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadEmployeerolesRequest() {
  yield takeLatest(EmployeeroleActions.LOAD_EMPLOYEEROLES, performLoadEmployeeroles);
}

export function* performLoadEmployeerole({ id, onComplete }: LoadEmployeerolesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getEmployeeroleRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: EmployeeroleActions.SET_EMPLOYEEROLE, employeerole: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadEmployeeroleRequest() {
  yield takeLatest(EmployeeroleActions.LOAD_EMPLOYEEROLE, performLoadEmployeerole);
}

export function* performUpdateEmployeerole({ id, payload, onComplete }: UpdateEmployeeroleAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateEmployeeroleRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.full_name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: EmployeeroleActions.SET_EMPLOYEEROLE, employeerole: data });

    yield put(ProfileActions.loadProfile());
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateEmployeeroleRequest() {
  yield takeLatest(EmployeeroleActions.UPDATE_EMPLOYEEROLE, performUpdateEmployeerole);
}

export function* performAddEmployeerole({ payload, navigate, onComplete }: AddEmployeeroleAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddEmployeeroleRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    // yield put(
    //   SystemActions.addNotice(
    //     'Successfully registered this account, the email address will receive an email to confirm the account.',
    //     SNACK_SUCCESS
    //   )
    // );

    // yield put(AuthActions.forgotPassword({ email: payload.email as string }));

    yield navigate('/admin/employeeroles');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddEmployeeroleRequest() {
  yield takeLatest(EmployeeroleActions.ADD_EMPLOYEEROLE, performAddEmployeerole);
}

export function* performRemoveEmployeerole({ id, onComplete }: AddEmployeeroleAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveEmployeeroleRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The employeerole has been removed successfully!', SNACK_SUCCESS));

    yield put(EmployeeroleActions.loadEmployeeroles(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveEmployeeroleRequest() {
  yield takeLatest(EmployeeroleActions.REMOVE_EMPLOYEEROLE, performRemoveEmployeerole);
}

export default function* employeeroleSaga() {
  yield all([
    watchForLoadEmployeerolesRequest(),
    watchForLoadEmployeeroleRequest(),
    watchForUpdateEmployeeroleRequest(),
    watchForAddEmployeeroleRequest(),
    watchForRemoveEmployeeroleRequest()
  ]);
}
