import React, { createRef, useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId, isUndefined } from '@neslotech/utils';

import { toBase64 } from '../../../tools/file.util';

import { useAuth } from '../../../hooks';

import { ExpenseClaim } from '../../../interfaces/employee/expense-claim.interface';
import { Employee } from '../../../interfaces/employee/employee.interface';
import { Client } from '../../../interfaces/client/client.interface';
import { FileData } from '../../../interfaces/config/file.interface';
import { Profile } from '../../../interfaces/profile/profile.interface';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import { Datepicker, OnChangeType as DateChangeType } from '../../datepicker/Datepicker';
import FormAction from '../../form/form-action/FormAction';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';
import { RadioChangeType, RadioGroup } from '../../radio/RadioGroup';
import { FileUpload } from '../../upload/FileUpload';
import Button from '../../button/Button';
import InfoDetail from '../../clients/details/InfoDetail';

import './expense-claim-form.scss';
import DocumentIcon from '../../document-icon/DocumentIcon';

interface FileProps {
  file: FileData;
}

const UploadedFile = ({ file }: FileProps) => {
  const openFile = () => {
    window.open(file.url, '_blank');
  };

  return (
    <article className="uploaded-file" onClick={openFile}>
      <section className="uploaded-file__name">
        <DocumentIcon file={file} />
        <p>{file.name}</p>
      </section>
      <small>
        Uploaded by {file.uploaded_by ?? 'you'} on {new Date(file.date).toLocaleDateString()}
      </small>
    </article>
  );
};

type OnAction = (payload: ExpenseClaim, onComplete: () => void) => void;

interface Props {
  clients: Client[];
  employees: Employee[];
  expenseClaim?: ExpenseClaim;
  onSave: OnAction;
}

export interface ExpenseClaimState extends StateForm {
  employeeId: string | number;
  date: string | Date;
  endDate: string | Date;
  clientId: string | number;
  category: string;
  status?: string;
  reason?: string;
  documents?: FileData[];
  amount?: number;
}

const rules: ValidationRules<ExpenseClaimState> = {
  validates: {
    employeeId: ['isPresent'],
    // clientId: ['isPresent'],
    date: ['isPresent'],
    category: ['isPresent']
  }
};

const formify = (isAdmin: Boolean, expenseClaim?: ExpenseClaim, profile?: Profile): ExpenseClaimState =>
  ({
    employeeId: isAdmin ? expenseClaim?.user_id : profile?.id,
    clientId: expenseClaim?.client_id,
    date: isUndefined(expenseClaim?.date) ? undefined : new Date(expenseClaim?.date),
    endDate:
      isUndefined(expenseClaim?.end_date) || expenseClaim?.end_date === null
        ? undefined
        : new Date(expenseClaim?.end_date),
    category: expenseClaim?.category,
    status: expenseClaim?.status,
    amount: expenseClaim?.amount,
    reason: expenseClaim?.reason,
    documents: expenseClaim?.documents ?? []
  } as ExpenseClaimState);

const serverify = (form: ExpenseClaimState) => ({
  user_id: form.employeeId,
  client_id: form.clientId,
  date: form.date,
  end_date: form.endDate,
  category: form.category,
  status: form.status,
  amount: form.amount,
  reason: form.reason,
  documents: form.documents
});

const categories: SelectItem[] = [
  {
    label: 'For Food',
    value: 'food'
  },
  {
    label: 'For Travel',
    value: 'travel'
  }  
];

const ExpenseClaimForm = ({ clients, employees, expenseClaim, onSave }: Props) => {
  const { isAdmin, profile } = useAuth();
  const [form, setForm] = useFormState<ExpenseClaimState>(formify(isAdmin, expenseClaim, profile), rules);
  const handleChange = (newState: OnChangeType | DateChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as ExpenseClaimState) });
  const handleStatusChange = (newState: RadioChangeType) => setForm({ ...form, ...newState });

  const [loading, setLoading] = useState<boolean>(false);

  const fileRef = createRef<HTMLInputElement>();

  const mappedClients = useMemo(() => {
    return clients
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((client: Client) => ({
      label: client.name,
      value: client.id
    }));
  }, [clients]);

  const mappedEmployees = useMemo(() => {
    return employees

    .map<SelectItem>((employee: Employee) => ({
      label: employee.full_name,
      value: employee.id
    }));
  }, [employees]);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: ExpenseClaim = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  const handleFileChange = async (file: File) => {
    const base64Data = await toBase64(file);
    handleChange({
      documents: [
        ...form?.documents,
        { id: generateId(), name: file.name, data: base64Data, date: new Date() }
      ]
    } as ExpenseClaimState);
  };

  return (
    <article className="expense-claim-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Datepicker
              name="date"
              label="Start Date"
              placeholder="Start Date"
              value={form?.date}
              error={form?.messages.date}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Datepicker
              name="endDate"
              label="End Date"
              placeholder="End Date"
              value={form?.endDate}
              error={form?.messages.endDate}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            { isAdmin && <Select
              name="employeeId"
              label="Employee"
              placeholder="Employee"
              value={form?.employeeId}
              error={form?.messages.employeeId}
              items={mappedEmployees}
              onChange={handleChange}
            /> }
          </FormRow>
          <FormRow>
            <Select
              name="clientId"
              label="For Client"
              placeholder="For Client"
              value={form?.clientId}
              error={form?.messages.clientId}
              items={mappedClients}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="category"
              label="Category"
              placeholder="Category"
              value={form?.category}
              error={form?.messages.category}
              items={categories}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="amount"
              label="Amount"
              placeholder="Amount"
              value={form?.amount}
              error={form?.messages.amount}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="reason"
              label="Reason (if any)"
              placeholder="Reason"
              onChange={handleChange}
              value={form?.reason}
            />
          </FormRow>
          <InfoDetail
            title="Add Supporting Document"
            editMode
            value=""
            editableElement={
              <FileUpload fileRef={fileRef} fileTypes="*/*" onChange={handleFileChange}>
                <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
              </FileUpload>
            }
          />
          {isAdmin && (
            <FormRow>
              <RadioGroup
                fluid
                name="status"
                value={form?.status}
                onChange={handleStatusChange}
                items={[
                  { label: 'Approved', value: 'approved', success: true },
                  { label: 'Denied', value: 'denied', danger: true }
                ]}
              />
            </FormRow>
          )}
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
      <DetailsCard title="Supporting Documents">
        <section className="expense-claim-form__uploads">
          {(form?.documents ?? []).map((upload: FileData) => (
            <UploadedFile file={upload} />
          ))}
        </section>
      </DetailsCard>
    </article>
  );
};

export default ExpenseClaimForm;
