import React, { useEffect, useMemo, useState } from 'react';

import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';
import { filterElement } from '../../../tools/filter.util';

import { Mandate } from '../../../interfaces/client/mandate.interface';
import { Loader } from '../../loader/Loader';

import './mandate-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useRemoval } from '../../../hooks';
import AlphabetLayout from '../../layout/filter/AlphabetLayout';

interface Props {
  mandates: Mandate[];
  loading: boolean;
}

const formify = (mandates: Mandate[], filters: Filter): Mandate[] =>
  mandates.filter((mandate: Mandate) =>
    // @ts-ignore
    filterElement(mandate, filters)
  );

const mandateHeaders: TableHeader[] = [
  {
    Header: 'Client Name',
    accessor: 'client'
  },
  {
    Header: 'Mandate Name',
    accessor: 'name'
  },
  {
    Header: 'Sectors/Assets',
    accessor: 'sector'
  },
  {
    Header: 'Transaction Type',
    accessor: 'transaction_type'
  },
  {
    Header: 'Transaction Size',
    accessor: 'transaction_size_zar'
  },
  {
    Header: 'Transaction Fee',
    accessor: 'transaction_fee_zar'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const MandateTable = ({ mandates, loading }: Props) => {
  const { filters, setFilterDefaults } = useFilters();
  const navigate: NavigateFunction = useNavigate();
  const [sortedMandates, setSortedMandates] = useState(mandates);
  const [selectedLetter, setSelectedLetter] = useState(null);

  const { setElement, setShowModal } = useRemoval();

  const handleRemove = (mandate: Mandate) => {
    setShowModal(true);
    setElement(mandate);
  };

  useEffect(() => {    
    if (selectedLetter) {
      mandates = mandates.filter(mandate => mandate.client[0].toUpperCase() === selectedLetter);
    }

    setSortedMandates(mandates);
  }, [mandates, selectedLetter]);

  const mandatesRows: TableData[] = useMemo(() => {
    return formify(sortedMandates, filters).map<TableData>((mandate: Mandate) => ({
      ...mandate,
      transaction_size_zar: mandate.transaction_size
        ? parseFloat(mandate.transaction_size.toString()).toLocaleString('en-ZA', {
            style: 'currency',
            currency: 'ZAR'
          })
        : null,
      transaction_fee_zar: mandate.transaction_fee
        ? parseFloat(mandate.transaction_fee.toString()).toLocaleString('en-ZA', {
            style: 'currency',
            currency: 'ZAR'
          })
        : null,
      action: (
        <div className="mandate_action">
          <EditIcon
            onClick={() => navigate(`/core/clients/${mandate.client_id}/mandates/${mandate.id}`)}
          />
          <RemoveIcon onClick={() => handleRemove(mandate)} />
        </div>
      ),
      onClick: () => {}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMandates, filters]);

  useEffect(() => {
    setFilterDefaults({ status: '', sector_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="mandate-list">
        {loading ? <Loader filled /> : <Table cols={mandateHeaders} rowData={mandatesRows} />}
      </article>
    </>
  );
};

export default MandateTable;
