import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddClientDealSheetRequest,
  getClientDealSheetRequest,
  getClientDealSheetsRequest,
  getRemoveClientDealSheetRequest,
  getUpdateClientDealSheetRequest,
  RequestOptions
} from '../../tools/api';

import {
  AddDealSheetAction,
  LoadDealSheetsAction,
  UpdateDealSheetAction
} from '../../actions/client/deal-sheet/deal-sheet.types';
import { ClientDealSheetActions } from '../../actions/client/deal-sheet/deal-sheet.actions';
import { SystemActions } from '../../actions/system/system.actions';

import { ResponseError } from '../../types/response-error.interface';

export function* performLoadDealSheets({ clientId, onComplete }: LoadDealSheetsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientDealSheetsRequest(clientId);

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ClientDealSheetActions.SET_DEAL_SHEETS, dealSheets: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadDealSheetsRequest() {
  yield takeLatest(ClientDealSheetActions.LOAD_DEAL_SHEETS, performLoadDealSheets);
}

export function* performLoadDealSheet({ clientId, id, onComplete }: LoadDealSheetsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientDealSheetRequest(clientId, id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ClientDealSheetActions.SET_DEAL_SHEET, dealSheet: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadDealSheetRequest() {
  yield takeLatest(ClientDealSheetActions.LOAD_DEAL_SHEET, performLoadDealSheet);
}

export function* performUpdateDealSheet({
  clientId,
  id,
  payload,
  onSuccess,
  onComplete
}: UpdateDealSheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateClientDealSheetRequest(
      clientId,
      id,
      payload
    );

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('This deal-sheet has been updated successfully!', SNACK_SUCCESS)
    );

    yield put({ type: ClientDealSheetActions.SET_DEAL_SHEET, dealSheet: data });

    yield put(ClientDealSheetActions.loadDealSheets(clientId, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateDealSheetRequest() {
  yield takeLatest(ClientDealSheetActions.UPDATE_DEAL_SHEET, performUpdateDealSheet);
}

export function* performAddDealSheet({
  clientId,
  payload,
  onSuccess,
  onComplete
}: AddDealSheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddClientDealSheetRequest(
      clientId,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new deal sheet has been created successfully!', SNACK_SUCCESS)
    );

    yield put(ClientDealSheetActions.loadDealSheets(clientId, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddDealSheetRequest() {
  yield takeLatest(ClientDealSheetActions.ADD_DEAL_SHEET, performAddDealSheet);
}

export function* performRemoveDealSheet({ clientId, id, onComplete }: AddDealSheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveClientDealSheetRequest(
      clientId,
      id
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The deal sheet has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(ClientDealSheetActions.loadDealSheets(clientId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveDealSheetRequest() {
  yield takeLatest(ClientDealSheetActions.REMOVE_DEAL_SHEET, performRemoveDealSheet);
}

export default function* dealSheetSaga() {
  yield all([
    watchForLoadDealSheetsRequest(),
    watchForLoadDealSheetRequest(),
    watchForUpdateDealSheetRequest(),
    watchForAddDealSheetRequest(),
    watchForRemoveDealSheetRequest()
  ]);
}
