import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Precedent } from '../../../../../../interfaces/client/precedent.interface';
import { AddPrecedentAction } from '../../../../../../actions/client/precedent/precedent.types';
import { LoadTombstonesAction } from '../../../../../../actions/tombstone/tombstone.types';
import { PrecedentActions } from '../../../../../../actions/client/precedent/precedent.actions';

import { useAppDispatch } from '../../../../../../hooks';
import { useClient } from '../../../../../../hooks/clients';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../../components/loader/Loader';
import PrecedentForm from '../../../../../../components/clients/details/precedents/PrecedentForm';

const AddPrecedentContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddPrecedentAction | LoadTombstonesAction> = useAppDispatch();

  const { id } = useParams<string>();
  const { client, loading } = useClient();

  const onSave = (payload: Precedent): void => {
    dispatch(
      PrecedentActions.addPrecedent(id, payload, () => navigate(`/core/clients/${id}/precedents`))
    );
  };

  return (
    <PageLayout title="Add Precedent">
      {loading && <Loader filled />}
      {!loading && <PrecedentForm client={client} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddPrecedentContainer;
