import { AddNoticeAction, RemoveNoticeAction } from './system.types';
import { SNACK_SUCCESS } from '@neslotech/utils';

export class SystemActions {
  static readonly ADD_SYSTEM_NOTICE: string = '[SYSTEM_STORE] Add system notice';
  static readonly REMOVE_SYSTEM_NOTICE = '[SYSTEM_STORE] Remove system notice';

  static addNotice = (title: string, alertType: string = SNACK_SUCCESS): AddNoticeAction => ({
    type: SystemActions.ADD_SYSTEM_NOTICE,
    title,
    alertType
  });

  static removeNotice = (id: string | number): RemoveNoticeAction => ({
    type: SystemActions.REMOVE_SYSTEM_NOTICE,
    id
  });
}
