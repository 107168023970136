import React, { useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Contact } from '../../../../../../interfaces/client/contact.interface';
import { Designation } from '../../../../../../interfaces/config/designation.interface';
import { AppState } from '../../../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { useContactData } from '../../../../../../hooks/clients';

import { UpdateContactAction } from '../../../../../../actions/contact/contact.types';
import { ClientContactActions } from '../../../../../../actions/client/contact/contact.actions';
import { ContactActions } from '../../../../../../actions/contact/contact.actions';

import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import ContactForm from '../../../../../../components/clients/details/contacts/form/ContactForm';
import AddMandateContainer from './add-mandate/AddMandate.container';

interface Props {
  contact?: Contact;
  canAddMandate?: boolean;
  setShow: (value: boolean) => void;
  mode?: string;
}

const EditContactContainer = ({ contact, canAddMandate = true, setShow, mode }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<UpdateContactAction> = useAppDispatch();
  const { id } = useParams();

  const designations: Designation[] = useAppSelector(
    ({ designation_store }: AppState) => designation_store.designations
  );

  const { contact: contactData, contactOverride } = useContactData();

  const [showClientModal, setShowClientModal] = useState<boolean>(false);

  const onClose = () => {
    navigate(
      contactOverride || id ? `/core/clients/${contactOverride?.client_id ?? id}/contacts/${contact.id}` : '/core/contacts'
    );
    setShow(false);
  };

  const handleSave = (payload: Contact, onComplete: () => void) => {
    if (id) {
      dispatch(
        ClientContactActions.updateContact(
          id,
          contactOverride?.id ?? contactData?.id ?? contact?.id,
          payload,
          onClose,
          onComplete
        )
      );
    } else {
      dispatch(
        ContactActions.updateContact(
          contactOverride?.id ?? contactData?.id ?? contact?.id,
          payload,
          onClose,
          onComplete
        )
      );
    }
  };

  const contactProp = mode === 'edit' ? contactData ?? contact : contactOverride ?? contact;

  return (
    <>
      {!showClientModal && (
        <ModalLayout onClose={onClose} title="Edit Contact" large>
          <ContactForm
            canAddMandate={canAddMandate}
            designations={designations}
            contact={contactProp}
            onSave={handleSave}
            onAddMandate={() => setShowClientModal(true)}
          />
        </ModalLayout>
      )}
      {showClientModal && <AddMandateContainer contact={contact} setShow={setShowClientModal} />}
    </>
  );
};

export default EditContactContainer;
