import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';
import { Mandate } from '../interfaces/client/mandate.interface';

import { MandateActions } from '../actions/mandate/mandate.actions';

export interface MandateState extends State {
  mandates: Mandate[];
  mandate?: Mandate;
}

export const initialState: MandateState = {
  mandates: []
};

export const mandateReducer = (state: MandateState = initialState, action: AnyAction) => {
  switch (action.type) {
    case MandateActions.SET_MANDATES:
      const { mandates } = action;
      return {
        ...state,
        mandates
      };
    case MandateActions.SET_MANDATE:
      const { mandate } = action;
      return {
        ...state,
        mandate
      };
    default:
      return state;
  }
};
