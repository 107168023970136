import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { User } from '../../../../interfaces/user/user.interface';

import { UserActions } from '../../../../actions/user/user.actions';
import { AddUserAction } from '../../../../actions/user/user.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import UserForm from '../../../../components/users/form/UserForm';

const AddUserContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddUserAction> = useAppDispatch();

  const onSave = (payload: User, onComplete: () => void): void => {
    dispatch(UserActions.addUser(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add User">
      <UserForm mode="add" onSave={onSave} />
    </PageLayout>
  );
};

export default AddUserContainer;
