import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { SubCategory } from '../../../interfaces/config/sub_category.interface';

import { useAppSelector } from '../../../hooks';

const SubCategoryCrumb = () => {
  const sub_category: SubCategory = useAppSelector(
    ({ sub_category_store }: AppState) => sub_category_store.sub_category
  ) as SubCategory;

  return <NavLink to={`/admin/sub-categories/${sub_category?.id}`}>{sub_category?.name}</NavLink>;
};

export default SubCategoryCrumb;
