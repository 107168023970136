import React, { useEffect } from 'react';

import { Document } from '../../../interfaces/document/document.interface';
import { Filter } from '../../../types/filter.interface';

import { filterElement } from '../../../tools/filter.util';

import { useFilters } from '../../../hooks/useFilters';

import DocumentCard from '../card/DocumentCard';

import './document-list.scss';

interface Props {
  documents: Document[];
  loading: boolean;
}

const formify = (documents: Document[], filters: Filter): Document[] =>
  documents.filter((document: Document) =>
    // @ts-ignore
    filterElement(document, filters)
  );

const DocumentList = ({ documents }: Props) => {
  const { filters, setFilterDefaults } = useFilters();

  useEffect(() => {
    setFilterDefaults({ type: '', category_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className="document-list">
      {formify(documents, filters).map((document: Document) => (
        <DocumentCard key={document.id} document={document} />
      ))}
    </article>
  );
};

export default DocumentList;
