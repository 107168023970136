import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

import './sub-category-list.scss';
import { SubCategory } from '../../../interfaces/config/sub_category.interface';

interface Props {
  sub_categories: SubCategory[];
  loading: boolean;
}

const headers: TableHeader[] = [
  // {
  //   Header: 'Index',
  //   accessor: 'index'
  // },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  index: number | string;
  name: string;
  status: string;
}

const SubCategoryList = ({ sub_categories, loading }: Props) => {
  const { id } = useParams<string>();
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return (sub_categories ?? []).map(
      (sub_category: SubCategory) =>
        ({
          index: sub_category.id,
          name: sub_category.name,
          status: sub_category.status,
          actions: <RemoveElement element={sub_category} />,
          onClick: () => navigate(`/admin/categories/${id}/sub-categories/${sub_category.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_categories]);

  return (
    <article className="sub_category-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No Sub Categories could be found!"
        rowData={rowData}
      />
    </article>
  );
};

export default SubCategoryList;
