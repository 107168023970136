import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Event } from '../../interfaces/event/event.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getEventsEndpoint = (): string => `${API_HOST}/events`;
export const getEventsRequest = (): RequestOptions => [
  getEventsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddEventRequest = (payload: Event): RequestOptions => [
  getEventsEndpoint(),
  getHttpPostOptions({ event: payload }, getAuthHeaders())
];

const getEventEndpoint = (id: number | string): string => `${API_HOST}/events/${id}`;
export const getEventRequest = (id: number | string): RequestOptions => [
  getEventEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateEventRequest = (id: number | string, payload: Event): RequestOptions => [
  getEventEndpoint(id),
  getHttpPutOptions({ event: payload }, getAuthHeaders())
];

export const getRemoveEventRequest = (id: string | number): RequestOptions => [
  getEventEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
