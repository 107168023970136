import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { SubCategory } from '../../../../interfaces/config/sub_category.interface';

import { SubCategoryActions } from '../../../../actions/sub-category/sub-category.actions';
import { AddSubCategoryAction } from '../../../../actions/sub-category/sub-category.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import SubCategoryForm from '../../../../components/sub-categories/form/SubCategoryForm';

const AddSubCategoryContainer = () => {
  const { id } = useParams<string>();
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddSubCategoryAction> = useAppDispatch();

  const onSave = (payload: SubCategory, onComplete: () => void): void => {
    dispatch(SubCategoryActions.addSubCategory(id, payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add SubCategory">
      <SubCategoryForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddSubCategoryContainer;
