import React, { Dispatch, useEffect, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { Fund } from '../../../interfaces/fund/fund.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType, SelectItem } from '../../select/Select';

import './fund-form.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { AppState } from '../../../types/state.interface';
import AddContactContainer from '../../../containers/core/clients/details/contacts/add/AddContact.container';
import { Modal } from '../../modal/Modal';
import { LoadDesignationsAction } from '../../../actions/designation/designation.types';
import { DesignationActions } from '../../../actions/designation/designation.actions';
import { capitalise, noOp } from '@neslotech/utils';
import { Contact } from '../../../interfaces/client/contact.interface';
import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';
import { ClientCheckboxes } from '../../contacts/card/ContactCard';

type OnSave = (payload: Fund, onComplete: () => void) => void;

interface Props {
  fund?: Fund;
  onSave: OnSave;
}

export interface FundState extends StateForm {
  contact_id: number;
}

const rules: ValidationRules<FundState> = {
  validates: {}
};

const formify = (fund?: Fund): FundState =>
  ({
    contact_id: fund?.contact_id
  } as FundState);

const serverify = (fund: Fund, form: FundState) => ({
  ...fund,
  contact_id: form.contact_id
});

const FundContactForm = ({ fund, onSave }: Props) => {
  const [form, setForm] = useFormState<FundState>(formify(fund), rules);
  const handleChange = (newState: SelectChangeType) =>
    setForm({ ...form, ...(newState as FundState) });

  const [loading, setLoading] = useState<boolean>(false);
  // const [oldContactId, setOldContactId] = useState(0);
  const [showAddContactModal, setShowAddContactModal] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Fund = serverify(fund, form);
    onSave(payload, () => setLoading(false));
  };

  const contacts: Contact[] = useAppSelector(
    ({ contact_store }: AppState) => contact_store.contacts
  );

  const dispatch: Dispatch<LoadDesignationsAction> = useAppDispatch();

  useEffect(() => {
    dispatch(DesignationActions.loadDesignations(noOp));
    // setOldContactId(form?.contact_id);
  }, []);

  const mappedContacts: SelectItem[] = contacts
    .sort((a, b) => (a.key_contact?.toLowerCase() > b.key_contact?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((item: Contact) => ({
      label: item.key_contact,
      value: item.id
    }));

  const currentContacts: Contact[] = contacts.filter((contact: Contact) => {
    // return contact.id === oldContactId;
    return contact.id == form?.contact_id;
  });
  const currentContact = currentContacts.length > 0 ? currentContacts[0] : null;

  return (
    <article className="page-layout">
      <Form>
        <FormRow>
          <Select
            name="contact_id"
            label="Contact"
            placeholder="Select Other Contact"
            items={mappedContacts}
            value={form?.contact_id}
            error={form?.messages.contact_id}
            onChange={handleChange}
          />
        </FormRow>

        {currentContact ? (
          <FormRow>
            <DetailsCard fitted>
              <article className="contact-card">
                <InfoDetail title="Name" value={currentContact?.key_contact} />
                <InfoDetail title="Client" value={<ClientCheckboxes contact={currentContact} />} />
                

                <InfoDetail title="Email Address" value={currentContact?.email} />
                <InfoDetail title="Contact Number" value={currentContact?.contact_number} />

                <InfoDetail
                  title="Designation"
                  value={capitalise(currentContact?.designation ?? '')}
                />
                <InfoDetail
                  title="Classification"
                  value={capitalise(currentContact?.classification ?? '')}
                />
                <InfoDetail title="Website" value={currentContact?.website} />
                <InfoDetail title="Office Address" value={currentContact?.address} />
                <InfoDetail title="Office City" value={currentContact?.city} />
                <InfoDetail title="Office Country" value={currentContact?.country} />
                <InfoDetail title="Office Continent" value={currentContact?.continent} />
              </article>
            </DetailsCard>
          </FormRow>
        ) : null}

        <FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
          &nbsp;
          <FormAction label="Add Other Contact" onClick={() => setShowAddContactModal(true)} />
        </FormRow>

        {showAddContactModal && (
          <Modal>
            <AddContactContainer setShow={setShowAddContactModal} />
          </Modal>
        )}
      </Form>
    </article>
  );
};

export default FundContactForm;
