import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector, useAuth } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Category } from '../../../../interfaces/config/category.interface';
import { Document } from '../../../../interfaces/document/document.interface';
import { Filter } from '../../../../types/filter.interface';

import { LoadDocumentsAction } from '../../../../actions/document/document.types';
import { DocumentActions } from '../../../../actions/document/document.actions';
import { CategoryActions } from '../../../../actions/category/category.actions';

import { ReactComponent as ChevronRightIcon } from '../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../components/layout/page/PageLayout';
import DocumentList from '../../../../components/documents/list/DocumentList';
import Button from '../../../../components/button/Button';
import FilterLayout from '../../../../components/layout/filter/FilterLayout';
import { DropdownMenuItem } from '../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../components/radio/RadioGroup';

import RemoveDocumentContainer from '../remove/RemoveDocument.container';
import Select from '../../../../components/select/Select';
import {
  getCookieListTableViewStyle,
  setCookieListTableViewStyle
} from '../../../../tools/filter.util';
import DocumentTable from '../../../../components/documents/list/DocumentTable';

const DocumentsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadDocumentsAction> = useAppDispatch();

  const cookieViewStyle = getCookieListTableViewStyle();
  const [view_style, setViewStyle] = useState<string>(cookieViewStyle ?? 'list_view');

  const documents: Document[] = useAppSelector(
    ({ document_store }: AppState) => document_store.documents
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(CategoryActions.loadDocumentCategories(noOp));
    dispatch(DocumentActions.loadDocuments(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewChange = (object: any) => {
    setViewStyle(object.view_style);
    setCookieListTableViewStyle(object.view_style);
  };

  const { isAdmin } = useAuth();

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Type',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="document_type"
            onChange={onChange}
            value={filters?.document_type as string}
            items={[
              { label: 'Contract', value: 'contract' },
              { label: 'Informational', value: 'info' },
              { label: 'Presentation', value: 'presentation' }
            ]}
          />
        )
      },
      {
        text: 'Category',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="category_id"
            onChange={onChange}
            value={filters?.category_id as string}
            items={categories
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((category: Category) => ({
                label: category.name,
                value: category.id
              }))}
          />
        )
      }
    ];
  }, [categories]);

  return (
    <PageLayout title="Company Documents">
      {isAdmin ? (
        <FilterLayout
          actions={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button label="Add Document" onClick={() => navigate('add')} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '130px',
                  marginLeft: '10px',
                  marginTop: '15px'
                }}
              >
                <Select
                  name="view_style"
                  placeholder="Select View"
                  value={view_style}
                  items={[
                    { label: 'List View', value: 'list_view' },
                    { label: 'Table View', value: 'table_view' }
                  ]}
                  onChange={handleViewChange}
                />
              </div>
            </div>
          }
          menuItems={menuItems}
        >
          <RemovalProvider>
            {view_style === 'list_view' ? (
              <DocumentList documents={documents} loading={loading} />
            ) : (
              <DocumentTable documents={documents} />
            )}
            <RemoveDocumentContainer />
          </RemovalProvider>
        </FilterLayout>
      ) : (
        <FilterLayout
          actions={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '130px',
                marginLeft: '10px',
                marginTop: '15px'
              }}
            >
              <Select
                name="view_style"
                placeholder="Select View"
                value={view_style}
                items={[
                  { label: 'List View', value: 'list_view' },
                  { label: 'Table View', value: 'table_view' }
                ]}
                onChange={handleViewChange}
              />
            </div>
          }
          menuItems={menuItems}
        >
          {view_style === 'list_view' ? (
            <DocumentList documents={documents} loading={loading} />
          ) : (
            <DocumentTable documents={documents} />
          )}
          <RemoveDocumentContainer />
        </FilterLayout>
      )}
    </PageLayout>
  );
};

export default DocumentsContainer;
