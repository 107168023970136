import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, RootState } from '../reducers';

import { useAuth } from './useAuth';
import { useRemoval } from './useRemoval';
import { useConvert } from './useConvert';
import { useSidebar } from './useSidebar';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { useAuth, useRemoval, useSidebar, useConvert };
