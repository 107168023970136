import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Designation } from '../../../../interfaces/config/designation.interface';

import { LoadDesignationsAction } from '../../../../actions/designation/designation.types';
import { DesignationActions } from '../../../../actions/designation/designation.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import DesignationForm from '../../../../components/designations/form/DesignationForm';

const DesignationContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadDesignationsAction> = useAppDispatch();
  const designation: Designation = useAppSelector(
    ({ designation_store }: AppState) => designation_store.designation
  ) as Designation;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Designation, onComplete: () => void): void => {
    dispatch(DesignationActions.updateDesignation(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(DesignationActions.loadDesignation(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && designation?.id === Number(id);
  }, [id, loading, designation?.id]);

  return (
    <PageLayout title={`Designation Details - ${doneLoading ? designation?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <DesignationForm designation={designation} onSave={onSave} />}
    </PageLayout>
  );
};

export default DesignationContainer;
