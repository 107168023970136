import React, { useEffect, useMemo, useState } from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../types/state.interface';
import { User } from '../../interfaces/user/user.interface';
import { Profile } from '../../interfaces/profile/profile.interface';

import { useAppDispatch, useAppSelector } from '../../hooks';

import { LoadUsersAction } from '../../actions/user/user.types';
import { UserActions } from '../../actions/user/user.actions';

import PageLayout from '../../components/layout/page/PageLayout';
import UserForm from '../../components/users/form/UserForm';
import { Loader } from '../../components/loader/Loader';

const ProfileContainer = () => {
  const dispatch: Dispatch<LoadUsersAction> = useAppDispatch();
  const profile: Profile = useAppSelector(
    ({ profile_store }: AppState) => profile_store.profile
  ) as Profile;
  const user: User = useAppSelector(({ user_store }: AppState) => user_store.user) as User;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: User, onComplete: () => void): void => {
    dispatch(UserActions.updateUser(profile.id, payload, onComplete));
  };

  useEffect(() => {
    if (profile?.id) {
      dispatch(UserActions.loadUser(profile.id, () => setLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const doneLoading = useMemo(() => {
    return !loading && user?.id === Number(profile.id);
  }, [profile?.id, loading, user?.id]);

  return (
    <PageLayout title="My Profile">
      {!doneLoading && <Loader filled />}
      {doneLoading && <UserForm isProfile user={user} onSave={onSave} />}
    </PageLayout>
  );
};

export default ProfileContainer;
