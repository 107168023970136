/**
 * @module Loader Component
 * @description
 * To be displayed when waiting for a response from an asynchronous call.
 */
import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './loader.scss';

interface Props {
  filled: boolean;
}

export const Loader = ({ filled }: Props) => (
  <div className={getClassNames('loader', { filled })}>
    <div className="loader-ellipsis">
      <div />
      <div />
      <div />
    </div>
  </div>
);

Loader.defaultProps = {
  filled: false
};
