import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { ExpenseClaim } from '../../../../interfaces/employee/expense-claim.interface';

import { LoadExpenseClaimsAction } from '../../../../actions/expense-claim/expense-claim.types';
import { ExpenseClaimActions } from '../../../../actions/expense-claim/expense-claim.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import ExpenseClaimList from '../../../../components/expense-claims/list/ExpenseClaimList';
import Button from '../../../../components/button/Button';

import RemoveExpenseClaimContainer from '../remove/RemoveExpenseClaim.container';
import { noOp } from '@neslotech/utils';

const ExpenseClaimsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadExpenseClaimsAction> = useAppDispatch();
  const expenseClaims: ExpenseClaim[] = useAppSelector(
    ({ expense_claim_store }: AppState) => expense_claim_store.expenseClaims
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(ExpenseClaimActions.loadExpenseClaims(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleExport = () => {
    dispatch(ExpenseClaimActions.downloadExpenseClaims(noOp));
  };

  return (
    <PageLayout
      title="Expense Claims"
      action={
        <div>
          <Button label="Export" onClick={onHandleExport} />
          &nbsp;
          <Button label="Add Expense Claim" onClick={() => navigate('add')} />
        </div>
      }
    >
      <RemovalProvider>
        <ExpenseClaimList expenseClaims={expenseClaims} loading={loading} />
        <RemoveExpenseClaimContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default ExpenseClaimsContainer;
