import { NavigateFunction } from 'react-router-dom';

import { Client } from '../../interfaces/client/client.interface';
import { ExportClientItems } from '../../interfaces/client/export_client_items.interface';

import {
  AddClientAction,
  DownloadClientsAction,
  LoadClientAction,
  LoadClientsAction,
  OnComplete,
  RemoveClientAction,
  UpdateClientAction
} from './client.types';

export class ClientActions {
  static readonly LOAD_CLIENTS: string = '[CLIENT_STORE] Load clients';
  static readonly SET_CLIENTS: string = '[CLIENT_STORE] Set clients';

  static readonly DOWNLOAD_CLIENTS: string = '[CLIENT_STORE] Dowanload clients';

  static readonly LOAD_CLIENT: string = '[CLIENT_STORE] Load client';
  static readonly SET_CLIENT: string = '[CLIENT_STORE] Set client';

  static readonly UPDATE_CLIENT: string = '[CLIENT_STORE] Update client';
  static readonly ADD_CLIENT: string = '[CLIENT_STORE] Add client';
  static readonly REMOVE_CLIENT: string = '[CLIENT_STORE] Remove client';

  static loadClients = (onComplete: OnComplete): LoadClientsAction => ({
    type: ClientActions.LOAD_CLIENTS,
    onComplete
  });

  static loadClient = (id: number | string, onComplete: OnComplete): LoadClientAction => ({
    type: ClientActions.LOAD_CLIENT,
    id,
    onComplete
  });

  static downloadClients = (
    client_id: number | string,
     payload: ExportClientItems,
    onComplete: OnComplete
  ): DownloadClientsAction => ({
    type: ClientActions.DOWNLOAD_CLIENTS,
    client_id,
    payload,
    onComplete
  });

  static addClient = (
    payload: Client,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddClientAction => ({
    type: ClientActions.ADD_CLIENT,
    payload,
    navigate,
    onComplete
  });

  static updateClient = (
    id: number | string,
    payload: Client,
    onComplete: OnComplete
  ): UpdateClientAction => ({
    type: ClientActions.UPDATE_CLIENT,
    id,
    payload,
    onComplete
  });

  static removeClient = (
    id: number | string,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): RemoveClientAction => ({
    type: ClientActions.REMOVE_CLIENT,
    id,
    navigate,
    onComplete
  });
}
