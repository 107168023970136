import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Designation } from '../../../interfaces/config/designation.interface';

import { useAppSelector } from '../../../hooks';

const DesignationCrumb = () => {
  const designation: Designation = useAppSelector(
    ({ designation_store }: AppState) => designation_store.designation
  ) as Designation;

  return <NavLink to={`/admin/designations/${designation?.id}`}>{designation?.name}</NavLink>;
};

export default DesignationCrumb;
