import { getHttpGetOptions, getHttpPostOptions } from '@neslotech/utils';

import { Note } from '../../../interfaces/client/note.interface';

import { getAuthHeaders } from '../../auth.util';

import { API_HOST, RequestOptions } from '../index';

const getClientEndpoint = (id: number | string): string => `${API_HOST}/clients/${id}`;
const getNotesEndpoint = (id: number | string): string => `${getClientEndpoint(id)}/notes`;
export const getNotesRequest = (id: number | string): RequestOptions => [
  getNotesEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddNoteRequest = (id: number | string, payload: Note): RequestOptions => [
  getNotesEndpoint(id),
  getHttpPostOptions({ note: payload }, getAuthHeaders())
];
