import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector, useAuth } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { PerformanceReview } from '../../../../interfaces/employee/performance-review.interface';

import { LoadPerformanceReviewsAction } from '../../../../actions/performance-review/performance-review.types';
import { PerformanceReviewActions } from '../../../../actions/performance-review/performance-review.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import PerformanceReviewList from '../../../../components/performance-reviews/list/PerformanceReviewList';
import Button from '../../../../components/button/Button';

import RemovePerformanceReviewContainer from '../remove/RemovePerformanceReview.container';

const PerformanceReviewsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadPerformanceReviewsAction> = useAppDispatch();
  const performanceReviews: PerformanceReview[] = useAppSelector(
    ({ performance_review_store }: AppState) => performance_review_store.performanceReviews
  );
  const { isAdmin } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(PerformanceReviewActions.loadPerformanceReviews(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Performance Reviews"
      action={ isAdmin && (<Button label="Add PDR" onClick={() => navigate('add')} />)}
    >
      <RemovalProvider>
        <PerformanceReviewList performanceReviews={performanceReviews} loading={loading} />
        <RemovePerformanceReviewContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default PerformanceReviewsContainer;
