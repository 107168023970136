import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Action, Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch } from '../../../../hooks';

import { ResetPasswordRequest } from '../../../../interfaces/auth/reset-password-request.interface';

import { AuthActions } from '../../../../actions/auth/auth.actions';

import AuthLayout from '../../../../components/layout/auth/AuthLayout';
import ResetPassword from '../../../../components/auth/reset-password/ResetPassword';

const ResetPasswordContainer = () => {
  const dispatch: Dispatch<Action> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const { id, token } = useParams();

  const handleReset = (request: ResetPasswordRequest) => {
    dispatch(AuthActions.resetPassword(id, token, request, navigate));
  };

  return (
    <AuthLayout
      title="Reset Password"
      subtitle="Enter your new password that you would like us to reset your account password to"
      linkTo="/"
      linkText="Remember your BSM password? Sign in"
    >
      <ResetPassword onReset={handleReset} />
    </AuthLayout>
  );
};

export default ResetPasswordContainer;
