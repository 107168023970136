import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../types/state.interface';

import { Fund } from '../../../interfaces/fund/fund.interface';

import { LoadFundAction } from '../../../actions/fund/fund.types';
import { FundActions } from '../../../actions/fund/fund.actions';

import { useAppDispatch, useAppSelector } from '../../../hooks';

const FundCrumb = () => {
  const { id } = useParams();
  const dispatch: Dispatch<LoadFundAction> = useAppDispatch();
  const fund: Fund = useAppSelector(({ fund_store }: AppState) => fund_store.fund) as Fund;

  useEffect(() => {
    if (!fund) {
      dispatch(FundActions.loadFund(id as string, noOp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <NavLink to={`/core/funds/${fund?.id}`}>{fund?.name}</NavLink>;
};

export default FundCrumb;
