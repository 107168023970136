import React from 'react';

import { Note } from '../../../../interfaces/client/note.interface';

import './note-detail.scss';

interface Props {
  note: Note;
}

const NoteDetail = ({ note }: Props) => (
  <article className="note">
    <main className="note__content">{note.note}</main>
  </article>
);

export default NoteDetail;
