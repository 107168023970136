import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Fund } from '../../interfaces/fund/fund.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getFundsEndpoint = (): string => `${API_HOST}/funds`;
export const getFundsRequest = (): RequestOptions => [
  getFundsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getFundEndpoint = (id: number | string): string => `${API_HOST}/funds/${id}`;
export const getFundRequest = (id: number | string): RequestOptions => [
  getFundEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateFundRequest = (id: number | string, payload: Fund): RequestOptions => [
  getFundEndpoint(id),
  getHttpPutOptions({ fund: payload }, getAuthHeaders())
];

export const getAddFundRequest = (payload: Fund): RequestOptions => [
  getFundsEndpoint(),
  getHttpPostOptions({ fund: payload }, getAuthHeaders())
];

export const getRemoveFundRequest = (id: string | number): RequestOptions => [
  getFundEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getFundDocumentEndpoint = (id: number | string, documentId: number): string =>
  `${API_HOST}/funds/${id}/documents/${documentId}`;
export const getRemoveFundDocumentRequest = (
  id: string | number,
  documentId: number
): RequestOptions => [
  getFundDocumentEndpoint(id, documentId),
  getHttpDeleteOptions(getAuthHeaders())
];
