import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Prospect } from '../../../interfaces/employee/prospect.interface';

import { useRemoval } from '../../../hooks';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';

interface Props {
  prospect: Prospect;
}

const ProspectCard = ({ prospect }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const { setElement, setShowModal } = useRemoval();

  const actions = useMemo(() => {
    const handleRemove = () => {
      setShowModal(true);
      setElement(prospect);
    };

    return (
      <>
        <EditIcon onClick={() => navigate(`/human-resources/prospects/${prospect.id}`)} />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospect]);

  return (
    <DetailsCard action={actions} small>
      <article className="prospect-card">
        <InfoDetail title="Prospect Name" value={prospect.full_name} />
        <InfoDetail title="Prospect Email" value={prospect.email} />
        <InfoDetail title="Prospect Mobile Number" value={prospect.id_number} />
      </article>
    </DetailsCard>
  );
};

export default ProspectCard;
