import React from 'react';

import { ApiElement } from '../../types/api-element.interface';

import { useRemoval } from '../../hooks';

import { ReactComponent as RemoveIcon } from '../../icons/remove-icon.svg';

import './remove-element.scss';

interface Props {
  element: ApiElement;
}

const RemoveElement = ({ element }: Props) => {
  const { setElement, setShowModal } = useRemoval();

  const handleRemove = (event: { stopPropagation: () => void }): void => {
    event.stopPropagation();
    setElement(element);
    setShowModal(true);
  };

  return (
    <article className="remove-element">
      <RemoveIcon onClick={handleRemove} />
    </article>
  );
};

export default RemoveElement;
