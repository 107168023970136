import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { ExpenseClaim } from '../interfaces/employee/expense-claim.interface';

import { ExpenseClaimActions } from '../actions/expense-claim/expense-claim.actions';

export interface ExpenseClaimState extends State {
  expenseClaims: ExpenseClaim[];
  expenseClaim?: ExpenseClaim;
}

export const initialState: ExpenseClaimState = {
  expenseClaims: []
};

export const expenseClaimReducer = (state: ExpenseClaimState = initialState, action: AnyAction) => {
  if (action.type === ExpenseClaimActions.SET_EXPENSE_CLAIMS) {
    const { expenseClaims } = action;
    return {
      ...state,
      expenseClaims
    };
  } else if (action.type === ExpenseClaimActions.SET_EXPENSE_CLAIM) {
    const { expenseClaim } = action;
    return {
      ...state,
      expenseClaim
    };
  }

  return state;
};
