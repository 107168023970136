import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { useAuth } from '../../../hooks';

import { Prospect } from '../../../interfaces/employee/prospect.interface';
import { FileData } from '../../../interfaces/config/file.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import { OnChangeType as DateChangeType } from '../../datepicker/Datepicker';
import FormAction from '../../form/form-action/FormAction';
import DetailsCard from '../../card/DetailsCard';
import Button from '../../button/Button';

import './prospect-form.scss';
import { noOp } from '@neslotech/utils';

type OnSave = (payload: Prospect, onComplete: () => void) => void;

interface Props {
  prospect?: Prospect;
  onSave: OnSave;
  onConvert?: OnSave;
  editMode?: boolean;
}

export interface ProspectState extends StateForm {
  firstName: string;
  lastName: string;
  email: string;
  idNumber?: string;
}

const rules: ValidationRules<ProspectState> = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    email: ['isEmail']
  }
};

const formify = (prospect?: Prospect): ProspectState =>
  ({
    firstName: prospect?.first_name,
    lastName: prospect?.last_name,
    role: prospect?.role,
    email: prospect?.email,
    idNumber: prospect?.id_number
  } as ProspectState);

const serverify = (form: ProspectState) => ({
  first_name: form.firstName,
  last_name: form.lastName,
  role: form.role,
  email: form.email,
  id_number: form.idNumber
});

const ProspectForm = ({ prospect, onSave, editMode = false, onConvert = noOp }: Props) => {
  const [form, setForm] = useFormState<ProspectState>(formify(prospect), rules);
  const handleChange = (newState: OnChangeType | DateChangeType | { file: FileData }) =>
    setForm({ ...form, ...(newState as ProspectState) });

  const [loading, setLoading] = useState<boolean>(false);

  const { isAdmin } = useAuth();

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Prospect = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  const handleConvert = () => {
    setLoading(true);

    const payload: Prospect = serverify(form);
    onConvert(payload, () => setLoading(false));
  };

  return (
    <article className="prospect-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="firstName"
              label="Prospect First Name"
              placeholder="Prospect First Name"
              value={form?.firstName}
              error={form?.messages.firstName}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="lastName"
              label="Prospect Last Name"
              placeholder="Prospect Last Name"
              value={form?.lastName}
              error={form?.messages.lastName}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="role"
              label="Prospect Role"
              placeholder="Prospect Role"
              value={form?.role}
              error={form?.messages.role}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="email"
              type="email"
              label="Prospect Email Address"
              placeholder="Prospect Email Address"
              value={form?.email}
              error={form?.messages.email}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="idNumber"
              label="Prospect Mobile Number"
              placeholder="Prospect Mobile Number"
              value={form?.idNumber}
              error={form?.messages.idNumber}
              onChange={handleChange}
            />
          </FormRow>
          {isAdmin && editMode && (
            <FormRow>
              <Button hollow label="Convert to Employee" onClick={handleConvert} />
            </FormRow>
          )}
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default ProspectForm;
