import { NavigateFunction } from 'react-router-dom';

import { Payslip } from '../../interfaces/employee/payslip.interface';

import {
  AddPayslipAction,
  LoadPayslipAction,
  LoadPayslipsAction,
  OnComplete,
  RemovePayslipAction,
  UpdatePayslipAction
} from './payslip.types';

export class PayslipActions {
  static readonly LOAD_PAYSLIPS: string = '[PAYSLIP_STORE] Load payslips';
  static readonly SET_PAYSLIPS: string = '[PAYSLIP_STORE] Set payslips';

  static readonly LOAD_PAYSLIP: string = '[PAYSLIP_STORE] Load payslip';
  static readonly SET_PAYSLIP: string = '[PAYSLIP_STORE] Set payslip';

  static readonly UPDATE_PAYSLIP: string = '[PAYSLIP_STORE] Update payslip';
  static readonly ADD_PAYSLIP: string = '[PAYSLIP_STORE] Add payslip';
  static readonly REMOVE_PAYSLIP: string = '[PAYSLIP_STORE] Remove payslip';

  static loadPayslips = (onComplete: OnComplete): LoadPayslipsAction => ({
    type: PayslipActions.LOAD_PAYSLIPS,
    onComplete
  });

  static loadPayslip = (id: number | string, onComplete: OnComplete): LoadPayslipAction => ({
    type: PayslipActions.LOAD_PAYSLIP,
    id,
    onComplete
  });

  static addPayslip = (
    payload: Payslip,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddPayslipAction => ({
    type: PayslipActions.ADD_PAYSLIP,
    payload,
    navigate,
    onComplete
  });

  static updatePayslip = (
    id: number | string,
    payload: Payslip,
    onComplete: OnComplete
  ): UpdatePayslipAction => ({
    type: PayslipActions.UPDATE_PAYSLIP,
    id,
    payload,
    onComplete
  });

  static removePayslip = (id: number | string, onComplete: OnComplete): RemovePayslipAction => ({
    type: PayslipActions.REMOVE_PAYSLIP,
    id,
    onComplete
  });
}
