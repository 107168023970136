import React from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { ForgotPasswordRequest } from '../../../interfaces/auth/forgot-password-request.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';

import './forgot-password.scss';

interface ForgotPasswordState extends StateForm {
  email: string;
}

const rules: ValidationRules<ForgotPasswordState> = {
  validates: {
    email: ['isPresent', 'isEmail']
  }
};

type ForgotPasswordFunction = (request: ForgotPasswordRequest) => void;
interface Props {
  onForgotPassword: ForgotPasswordFunction;
}

const serverify = ({ email }: ForgotPasswordState): ForgotPasswordRequest => ({
  email
});

const ForgotPassword = ({ onForgotPassword }: Props) => {
  const [form, setForm] = useFormState<ForgotPasswordState>(undefined, rules);
  const handleChange = (newState: OnChangeType) =>
    setForm({ ...form, ...(newState as ForgotPasswordState) });

  const handleForgotPassword = () => {
    const request: ForgotPasswordRequest = serverify(form);
    onForgotPassword(request);
  };

  return (
    <article className="forgot-password">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Your Email/Username"
            placeholder="Enter your email/username"
            onChange={handleChange}
            error={form?.messages?.email}
          />
        </FormRow>
        <FormAction
          onClick={handleForgotPassword}
          label="Request Password Reset"
          disabled={!form?.valid}
        />
      </Form>
    </article>
  );
};

export default ForgotPassword;
