import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Employee } from '../../../../interfaces/employee/employee.interface';

import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import EmployeeDetail from '../../../../components/employees/detail/EmployeeDetail';

import { ReactComponent as EditIcon } from '../../../../icons/pencil-icon.svg';

const EmployeeContainer = () => {
  const { id } = useParams<string>();

  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadEmployeesAction> = useAppDispatch();

  const employee: Employee = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employee
  ) as Employee;

  const [loading, setLoading] = useState<boolean>(true);

  const onReload = () => {
    dispatch(EmployeeActions.loadEmployee(id as string, () => setLoading(false)));
  };

  useEffect(() => {
    onReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && employee?.id === Number(id);
  }, [id, loading, employee?.id]);

  return (
    <PageLayout
      title={`Employee Details - ${doneLoading ? employee?.full_name : ''}`}
      action={<EditIcon onClick={() => navigate('edit')} />}
    >
      {!doneLoading && <Loader filled />}
      {doneLoading && <EmployeeDetail onReload={onReload} employee={employee} />}
    </PageLayout>
  );
};

export default EmployeeContainer;
