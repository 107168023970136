import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { Irp5 } from '../../../../interfaces/employee/irp5.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { LoadIrp5sAction } from '../../../../actions/irp5/irp5.types';
import { Irp5Actions } from '../../../../actions/irp5/irp5.actions';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import Irp5Form from '../../../../components/irp5s/form/Irp5Form';

const Irp5Container = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadIrp5sAction> = useAppDispatch();
  const irp5: Irp5 = useAppSelector(({ irp5_store }: AppState) => irp5_store.irp5);
  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Irp5, onComplete: () => void): void => {
    dispatch(Irp5Actions.updateIrp5(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(Irp5Actions.loadIrp5(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && irp5?.id === Number(id);
  }, [id, loading, irp5?.id]);

  return (
    <PageLayout title={`Irp5 Details - ${doneLoading ? irp5?.employee : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <Irp5Form employees={employees} irp5={irp5} onSave={onSave} />}
    </PageLayout>
  );
};

export default Irp5Container;
