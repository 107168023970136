import React, { ReactNode, useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Fund } from '../../../interfaces/fund/fund.interface';

import Avatar from '../../avatar/Avatar';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

import './fund-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'avatar'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  }
};

interface RowData extends TableData {
  avatar: ReactNode;
  status: string;
}

interface Props {
  funds: Fund[];
  loading: boolean;
}

const FundList = ({ funds, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return funds.map(
      (fund: Fund) =>
        ({
          avatar: <Avatar filled image={fund.image} name={fund.name} />,
          status: fund.status,
          actions: <RemoveElement element={fund} />,
          onClick: () => navigate(`${fund.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funds]);

  return (
    <article className="fund-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No funds could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default FundList;
