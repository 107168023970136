import React from 'react';

import { Filter } from '../types/filter.interface';
import { OnChangeType } from '../components/select/Select';

export type FilterType = {
  filters?: Filter;
  filtering?: boolean;
  sort?: string;
  setSort?: (newSort: OnChangeType) => void;
  setFilterDefaults?: (filters: Filter) => void;
  setFilters?: (filters: Filter) => void;
  onClearFilters?: () => void;
  getFilter?: (key: string, value: unknown[] | string) => string;
};

export const FilterContext = React.createContext<FilterType>({});
