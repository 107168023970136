import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Action, Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { AppState } from '../../../../types/state.interface';
import { User } from '../../../../interfaces/user/user.interface';
import { ConfirmationRequest } from '../../../../interfaces/auth/confirmation-request.interface';

import { LoadUsersAction } from '../../../../actions/user/user.types';
import { UserActions } from '../../../../actions/user/user.actions';
import { AuthActions } from '../../../../actions/auth/auth.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import UserList from '../../../../components/users/list/UserList';
import Button from '../../../../components/button/Button';

import RemoveUserContainer from '../remove/RemoveUser.container';
import ConvertUserContainer from '../convert/ConvertUser.container';
import RemovalProvider from '../../../../providers/Removal.provider';
import ConvertProvider from '../../../../providers/Convert.provider';

const UsersContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadUsersAction> = useAppDispatch();
  const dispatch1: Dispatch<Action> = useAppDispatch();
  const users: User[] = useAppSelector(({ user_store }: AppState) => user_store.users);

  const [loading, setLoading] = useState<boolean>(true);

  const resend = (request: ConfirmationRequest): void => {
    dispatch1(AuthActions.confirmAccount(request, navigate));
  };

  useEffect(() => {
    dispatch(UserActions.loadUsers(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="User Management"
      action={<Button label="Add User" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <RemoveUserContainer />
        <ConvertProvider>
          <UserList users={users} resend={resend} loading={loading} />
        <ConvertUserContainer />
        </ConvertProvider>
       </RemovalProvider>
    </PageLayout>
  );
};

export default UsersContainer;
