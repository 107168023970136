import React from 'react';
import { RouteObject } from 'react-router-dom';

import ProspectsContainer from '../../containers/human-resources/prospects/list/Prospects.container';
import AddProspectContainer from '../../containers/human-resources/prospects/add/AddProspect.container';
import ProspectContainer from '../../containers/human-resources/prospects/details/Prospect.container';

import ProspectsCrumb from '../../crumbs/human-resources/prospects/Prospects.crumb';
import AddProspectCrumb from '../../crumbs/human-resources/prospects/AddProspect.crumb';
import ProspectCrumb from '../../crumbs/human-resources/prospects/Prospect.crumb';

import ProtectedRoute from '../ProtectedRoute';

const prospectRoutes: RouteObject[] = [
  {
    path: 'prospects',
    handle: {
      crumb: () => <ProspectsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <ProspectsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddProspectContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddProspectCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <ProspectContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <ProspectCrumb />
        }
      }
    ]
  }
];

export default prospectRoutes;
