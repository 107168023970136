import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './radio-button.scss';

interface Props {
  label?: string;
  checked?: boolean;
  name: string;
  value: string | boolean | number;
  onChange: (value: string | boolean | number) => void;
  disabled?: boolean;
  danger?: boolean;
  success?: boolean;
}

export const RadioButton = ({
  label,
  checked = false,
  name,
  onChange,
  value,
  disabled,
  danger,
  success
}: Props) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <section
      className={getClassNames('radio-button', { disabled, danger, success })}
      onClick={handleChange}
    >
      <input name={name} type="radio" hidden />
      <div className="radio-button__blip">
        {checked && <span className="radio-button__blip--checked" />}
      </div>
      <label htmlFor={name} className="radio-button__label">
        {label}
      </label>
    </section>
  );
};
