import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getAllPrecedentsRequest, RequestOptions } from '../tools/api';

import { LoadPrecedentsAction } from '../actions/precedent/precedent.types';
import { PrecedentActions } from '../actions/precedent/precedent.actions';
import { SystemActions } from '../actions/system/system.actions';

export function* performLoadPrecedents({ onComplete }: LoadPrecedentsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAllPrecedentsRequest();

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: PrecedentActions.SET_PRECEDENTS, precedents: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPrecedentsRequest() {
  yield takeLatest(PrecedentActions.LOAD_PRECEDENTS, performLoadPrecedents);
}

export default function* precedentSaga() {
  yield all([watchForLoadPrecedentsRequest()]);
}
