import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Precedent } from '../../../../../../interfaces/client/precedent.interface';

import { useAppDispatch, useRemoval } from '../../../../../../hooks';

import { PrecedentActions } from '../../../../../../actions/client/precedent/precedent.actions';
import { RemovePrecedentAction } from '../../../../../../actions/client/precedent/precedent.types';

import { Modal } from '../../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../../../components/modal/remove/RemoveModal';

const RemovePrecedentContainer = () => {
  const dispatch: Dispatch<RemovePrecedentAction> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const { showModal, setShowModal, element } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      PrecedentActions.removePrecedent((element as Precedent).client_id, element.id, () => {
        onComplete();
        onClose();
        navigate(`/core/clients/${(element as Precedent).client_id}/precedents`);
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Precedent">
            <RemoveModal title="this precedent" onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemovePrecedentContainer;
