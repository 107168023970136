import { NavigateFunction } from 'react-router-dom';

import { Category } from '../../interfaces/config/category.interface';

import {
  AddCategoryAction,
  LoadCategoriesAction,
  LoadCategoryAction,
  LoadDocumentCategoriesAction,
  OnComplete,
  RemoveCategoryAction,
  UpdateCategoryAction
} from './category.types';

export class CategoryActions {
  static readonly LOAD_CATEGORIES: string = '[CATEGORY_STORE] Load categories';
  static readonly SET_CATEGORIES: string = '[CATEGORY_STORE] Set categories';

  static readonly LOAD_CATEGORY: string = '[CATEGORY_STORE] Load category';
  static readonly SET_CATEGORY: string = '[CATEGORY_STORE] Set category';

  static readonly UPDATE_CATEGORY: string = '[CATEGORY_STORE] Update category';
  static readonly ADD_CATEGORY: string = '[CATEGORY_STORE] Add category';
  static readonly REMOVE_CATEGORY: string = '[CATEGORY_STORE] Remove category';

  static loadCategories = (onComplete: OnComplete, filter?: string): LoadCategoriesAction => ({
    type: CategoryActions.LOAD_CATEGORIES,
    filter: filter === 'all' ? undefined : 'general',
    onComplete
  });

  static loadDocumentCategories = (onComplete: OnComplete): LoadDocumentCategoriesAction => ({
    type: CategoryActions.LOAD_CATEGORIES,
    filter: 'document',
    onComplete
  });

  static loadCategory = (id: number | string, onComplete: OnComplete): LoadCategoryAction => ({
    type: CategoryActions.LOAD_CATEGORY,
    id,
    onComplete
  });

  static addCategory = (
    payload: Category,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddCategoryAction => ({
    type: CategoryActions.ADD_CATEGORY,
    payload,
    navigate,
    onComplete
  });

  static updateCategory = (
    id: number | string,
    payload: Category,
    onComplete: OnComplete
  ): UpdateCategoryAction => ({
    type: CategoryActions.UPDATE_CATEGORY,
    id,
    payload,
    onComplete
  });

  static removeCategory = (id: number | string, onComplete: OnComplete): RemoveCategoryAction => ({
    type: CategoryActions.REMOVE_CATEGORY,
    id,
    onComplete
  });
}
