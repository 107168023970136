import React, { useMemo } from 'react';

import { PerformanceReview } from '../../../interfaces/employee/performance-review.interface';
import { FileData } from '../../../interfaces/config/file.interface';

import { Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

// import { ReactComponent as DocumentIcon } from '../../../icons/word-icon.svg';
// import { ReactComponent as DocumentIcon } from '../../../icons/pdf-icon.svg';
import DocumentIcon from '../../document-icon/DocumentIcon';

import './performance-review-list.scss';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface Props {
  performanceReviews: PerformanceReview[];
  loading: boolean;
}

const headers: TableHeader[] = [
  // {
  //   Header: 'Index',
  //   accessor: 'index'
  // },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Start Date',
    accessor: 'start_date'
  },
  {
    Header: 'End Date',
    accessor: 'end_date'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  index: number | string;
  name: string;
  start_date: string;
  end_date: string;
}

const PerformanceReviewList = ({ performanceReviews, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return (performanceReviews ?? []).map(
      (performanceReview: PerformanceReview) =>
        ({
          index: performanceReview.id,
          name: performanceReview.employee,
          start_date: performanceReview.start_date,
          end_date: performanceReview.end_date,
          actions: (
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <DocumentIcon
                file={performanceReview.document as FileData}
                color="#003768"
                width={24}
                height={24}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open((performanceReview.document as FileData).url, '_blank');
                }}
              />
              <RemoveElement element={performanceReview} />
            </div>
          ),
          onClick: () => navigate(`/human-resources/performance-reviews/${performanceReview.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceReviews]);

  return (
    <article className="performance-review-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No performance reviews could be found!"
        rowData={rowData}
      />
    </article>
  );
};

export default PerformanceReviewList;
