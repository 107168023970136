import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Employee } from '../../../../interfaces/employee/employee.interface';

import {
  LoadEmployeesAction,
  RemoveEmployeeDocumentAction
} from '../../../../actions/employee/employee.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import EmployeeDocumentForm from '../../../../components/employees/form/EmployeeDocumentForm';

const EmployeeOtherDocumentsContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadEmployeesAction | RemoveEmployeeDocumentAction> = useAppDispatch();

  const employee: Employee = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employee
  ) as Employee;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Employee, onComplete: () => void) => {
    dispatch(EmployeeActions.updateEmployee(id as string, payload, onComplete));
  };

  const onRemoveDocument = (documentId: number) => {
    dispatch(EmployeeActions.removeEmployeeDocument(id as string, documentId));
  };

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployee(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && employee?.id === Number(id);
  }, [id, loading, employee?.id]);

  return (
    // <PageLayout title={`Employee Other Documents - ${doneLoading ? employee?.full_name : ''}`}>
    <PageLayout title="">
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <EmployeeDocumentForm
          type="other_documents"
          documents={employee.other_documents}
          onSave={onSave}
          employee={employee}
          onRemoveDocument={onRemoveDocument}
        />
      )}
    </PageLayout>
  );
};

export default EmployeeOtherDocumentsContainer;
