import React, { useState } from 'react';

import Button from '../../button/Button';

import './remove-modal.scss';

type OnConfirm = (onComplete: () => void) => void;

interface Props {
  title?: string;
  onConfirm: OnConfirm;
  status?: string;
}

const RemoveModal = ({ title, status, onConfirm }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = (): void => {
    onConfirm(() => setLoading(false));
  };

  const action = status ? (status === 'active' ? 'deactivate' : 'activate') : 'delete';

  return (
    <article className="remove-modal">
      <main className="remove-modal__message">
        <p>
          Are you sure you want to {action} <span>{title}</span>?
        </p>
      </main>
      <footer className="remove-modal__actions">
        <Button label="Confirm" onClick={handleConfirm} loading={loading} hollow />
      </footer>
    </article>
  );
};

export default RemoveModal;
