import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { statusItems } from '../../../tools/dropdown.util';

import { Designation } from '../../../interfaces/config/designation.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';

import './designation-form.scss';

type OnSave = (payload: Designation, onComplete: () => void) => void;
type Status = 'active' | 'inactive';

interface Props {
  designation?: Designation;
  onSave: OnSave;
}

export interface DesignationState extends StateForm {
  name: string;
  description: string;
  status: Status;
}

const rules: ValidationRules<DesignationState> = {
  validates: {
    name: ['isPresent'],
    status: ['isPresent']
  }
};

const formify = (designation?: Designation): DesignationState =>
  ({
    name: designation?.name,
    description: designation?.description,
    status: designation?.status
  } as DesignationState);

const serverify = (form: DesignationState) => ({
  name: form.name,
  description: form.description,
  status: form.status
});

const DesignationForm = ({ designation, onSave }: Props) => {
  const [form, setForm] = useFormState<DesignationState>(formify(designation), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as DesignationState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Designation = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="designation-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Designation Name"
              placeholder="Designation Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="description"
              label="Description"
              placeholder="Description"
              value={form?.description}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="status"
              label="Status"
              placeholder="Select a status"
              items={statusItems}
              value={form?.status}
              error={form?.messages.status}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default DesignationForm;
