import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Mandate } from '../../../../../../interfaces/client/mandate.interface';
import { AddMandateAction } from '../../../../../../actions/client/mandate/mandate.types';
import { LoadTombstonesAction } from '../../../../../../actions/tombstone/tombstone.types';
import { MandateActions } from '../../../../../../actions/client/mandate/mandate.actions';

import { useAppDispatch } from '../../../../../../hooks';
import { useClient } from '../../../../../../hooks/clients';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../../components/loader/Loader';
import MandateForm from '../../../../../../components/clients/details/mandates/MandateForm';

const AddMandateContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddMandateAction | LoadTombstonesAction> = useAppDispatch();

  const { id } = useParams<string>();
  const { client, loading } = useClient();

  const onSave = (payload: Mandate): void => {
    dispatch(
      MandateActions.addMandate(id, payload, () => navigate(`/core/clients/${id}/mandates`))
    );
  };

  return (
    <PageLayout title="Add Mandate">
      {loading && <Loader filled />}
      {!loading && <MandateForm client={client} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddMandateContainer;
