import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../../../types/state.interface';
import { Tombstone } from '../../../../../../interfaces/client/tombstone.interface';

import RemovalProvider from '../../../../../../providers/Removal.provider';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { useClient } from '../../../../../../hooks/clients';

import { TombstoneActions } from '../../../../../../actions/client/tombstone/tombstone.actions';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../../components/loader/Loader';
import TombstoneForm from '../../../../../../components/clients/details/tombstones/TombstoneForm';

import RemoveTombstoneContainer from '../remove/RemoveTombstone.container';
import { UserActions } from '../../../../../../actions/user/user.actions';
import { noOp } from '@neslotech/utils';
import { User } from '../../../../../../interfaces/user/user.interface';

const TombstoneContainer = () => {
  const dispatch: Dispatch = useAppDispatch();

  const { id, tombstoneId } = useParams<string>();

  const { client } = useClient();

  const [loading, setLoading] = useState<boolean>(true);

  const users: User[] = useAppSelector(({ user_store }: AppState) => user_store.users);
  const tombstone: Tombstone = useAppSelector(
    ({ client_store }: AppState) => client_store.tombstone
  );

  useEffect(() => {
    dispatch(UserActions.loadUsers(noOp));
    dispatch(
      TombstoneActions.loadTombstone(client?.id ?? id, tombstoneId ?? id, () => setLoading(false))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id]);

  const doneLoading = useMemo(() => {
    return !loading && client?.id === Number(id) && !!tombstone;
  }, [loading, client?.id, id, tombstone]);

  const handleOnSave = (payload: Tombstone, onComplete: () => void) => {
    dispatch(
      TombstoneActions.updateTombstone(client?.id ?? id, tombstoneId ?? id, payload, onComplete)
    );
  };

  return (
    <PageLayout title={`${client?.name} - Tombstone`} bordered>
      <RemovalProvider>
        {!doneLoading && <Loader filled />}
        {doneLoading && (
          <TombstoneForm
            users={users}
            tombstone={tombstone}
            onSave={handleOnSave}
            client={client}
          />
        )}
        <RemoveTombstoneContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default TombstoneContainer;
