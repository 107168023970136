/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Prospect } from '../../../../interfaces/employee/prospect.interface';

import { LoadProspectsAction } from '../../../../actions/prospect/prospect.types';
import { ProspectActions } from '../../../../actions/prospect/prospect.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import ProspectForm from '../../../../components/prospects/form/ProspectForm';
import Tabs, { TabItem } from '../../../../components/tab/Tabs';
import ProspectDocumentsContainer from '../documents/ProspectDocuments.container';

const ProspectContainer = () => {
  const { id } = useParams<string>();

  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadProspectsAction> = useAppDispatch();
  const prospect: Prospect = useAppSelector(
    ({ prospect_store }: AppState) => prospect_store.prospect
  ) as Prospect;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Prospect, onComplete: () => void): void => {
    dispatch(ProspectActions.updateProspect(id as string, payload, onComplete));
  };

  const onConvert = (payload: Prospect, onComplete: () => void): void => {
    dispatch(
      ProspectActions.convertProspect(id as string, payload, () => {
        onComplete();
        navigate('/human-resources/employees');
      })
    );
  };

  useEffect(() => {
    dispatch(ProspectActions.loadProspect(id as string, () => setLoading(false)));
  }, [id]);

  const doneLoading = !loading && prospect?.id === Number(id);

  const tabs: TabItem[] = [
    {
      id: 0,
      title: 'Basic Info',
      Component: (
        <>
          {doneLoading ? (
            <ProspectForm prospect={prospect} onSave={onSave} onConvert={onConvert} editMode />
          ) : (
            <Loader filled />
          )}
        </>
      )
    },
    {
      id: 1,
      title: 'Documents',
      Component: <ProspectDocumentsContainer doneLoading={doneLoading} />
    }
  ];

  return (
    <PageLayout title={`Prospect Details - ${doneLoading ? prospect?.full_name : ''}`}>
      <Tabs tabs={tabs} />
    </PageLayout>
  );
};

export default ProspectContainer;
