import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { ExpenseClaim } from '../../../interfaces/employee/expense-claim.interface';

import { useAppSelector } from '../../../hooks';

const ExpenseClaimCrumb = () => {
  const expenseClaim: ExpenseClaim = useAppSelector(
    ({ expense_claim_store }: AppState) => expense_claim_store.expenseClaim
  );

  return (
    <NavLink to={`/human-resources/expense-claims/${expenseClaim?.id}`}>
      {expenseClaim?.employee}
    </NavLink>
  );
};

export default ExpenseClaimCrumb;
