import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Payslip } from '../../../interfaces/employee/payslip.interface';

import { useAppSelector } from '../../../hooks';

const PayslipCrumb = () => {
  const payslip: Payslip = useAppSelector(({ payslip_store }: AppState) => payslip_store.payslip);

  return <NavLink to={`/human-resources/payslips/${payslip?.id}`}>{payslip?.employee}</NavLink>;
};

export default PayslipCrumb;
