import React, { useMemo } from 'react';
import { Event } from '../../interfaces/event/event.interface';
import { Employee } from '../../interfaces/employee/employee.interface';
import './calendar.scss';

interface Props {
  events: Event[];
  employees: Employee[];
  calendarDate: Date;
  setCalendarDate: (date: Date) => void;
}

const Calendar = ({ events, employees, calendarDate, setCalendarDate }: Props) => {
  const eventDate = useMemo(() => {
    const eventDates = events.map((event) => ({
      date: new Date(event.date)
    }));

    employees.forEach((employee) => {
      const birthday = new Date(employee.birthday);
      const currentYear = new Date().getFullYear();
      birthday.setFullYear(currentYear); // Set the year to 2023

      eventDates.push({
        date: birthday
      });
    });
    return eventDates;
  }, [events, employees]);
  const handlePreviousMonth = () => {
    setCalendarDate(new Date(calendarDate.getFullYear(), calendarDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCalendarDate(new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1));
  };

  const monthOptions: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfWeek = (year: number, month: number): number => {
    const firstDay = new Date(year, month, 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1; // Adjust Sunday (0) to 6 and shift other days back by one
  };

  const renderCalendar = (): JSX.Element[] => {
    const month = calendarDate.getMonth();
    const year = calendarDate.getFullYear();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfWeek = getFirstDayOfWeek(year, month);

    const calendar: JSX.Element[] = [];
    let dayCount = 1;

    // Iterate for each row (week) in the calendar
    for (let week = 0; dayCount <= daysInMonth; week++) {
      const days: JSX.Element[] = [];

      // Fill in the days of the week for the current row
      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        if ((week === 0 && dayOfWeek < firstDayOfWeek) || dayCount > daysInMonth) {
          // Render empty cells for days before the first day of the month or after the last day of the month
          days.push(<td key={`empty-${week}-${dayOfWeek}`} className="empty-cell" />);
        } else {
          // Render cells for the days of the month
          const currentDate = new Date(year, month, dayCount);
          const isCurrentDate = currentDate.toDateString() === new Date().toDateString();

          // Check if the current date matches any event dates
          const isEventDate = eventDate.some(
            (event: any) => event.date?.toDateString() === currentDate.toDateString()
          );

          days.push(
            <td
              key={`current-${week}-${dayOfWeek}`}
              className={`${isCurrentDate ? 'current-date' : ''} ${
                isEventDate ? 'event-date' : ''
              }`}
            >
              {dayCount}
            </td>
          );
          dayCount++;
        }
      }

      // Add the row to the calendar
      calendar.push(
        <tr key={`week-${week}`} className="pad">
          {days}
        </tr>
      );
    }

    return calendar;
  };

  return (
    <div className="calen">
      <div className="calendar-header">
        <div>
          <span>{`${monthOptions[calendarDate.getMonth()]} ${calendarDate.getFullYear()}`}</span>
        </div>
        <div>
          <button onClick={handlePreviousMonth}>&#60;</button>
          <button onClick={handleNextMonth}>&#62;</button>
        </div>
      </div>
      <table className="calendar-table">
        <thead>
          <tr>
            <th>Mo</th>
            <th>Tu</th>
            <th>We</th>
            <th>Th</th>
            <th>Fr</th>
            <th>Sa</th>
            <th>Su</th>
          </tr>
        </thead>
        <tbody>{renderCalendar()}</tbody>
      </table>
    </div>
  );
};

export default Calendar;
