import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { Employeerole } from '../../../../interfaces/employeerole/employeerole.interface';
import { Filter } from '../../../../types/filter.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import RemovalProvider from '../../../../providers/Removal.provider';

import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { EmployeeroleActions } from '../../../../actions/employeerole/employeerole.actions';

import { ReactComponent as ChevronRightIcon } from '../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import EmployeeList from '../../../../components/employees/list/EmployeeList';
import { DropdownMenuItem } from '../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../components/radio/RadioGroup';
import FilterLayout from '../../../../components/layout/filter/FilterLayout';
import Button from '../../../../components/button/Button';

import RemoveEmployeeContainer from '../remove/RemoveEmployee.container';
import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';
import Select from '../../../../components/select/Select';
import {
  getCookieListTableViewStyle,
  setCookieListTableViewStyle
} from '../../../../tools/filter.util';
import EmployeeTable from '../../../../components/employees/list/EmployeeTable';

const EmployeesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadEmployeesAction> = useAppDispatch();
  const cookieViewStyle = getCookieListTableViewStyle();
  const [view_style, setViewStyle] = useState<string>(cookieViewStyle ?? 'list_view');

  const [loading, setLoading] = useState<boolean>(true);

  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );
  const employeeroles: Employeerole[] = useAppSelector(
    ({ employeerole_store }: AppState) => employeerole_store.employeeroles
  );

  useEffect(() => {
    dispatch(EmployeeroleActions.loadEmployeeroles(noOp));
    dispatch(EmployeeActions.loadEmployees(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean | Date }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Employee Role',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="erole"
            onChange={onChange}
            value={filters?.erole as string}
            items={employeeroles
              .sort((a, b) =>
                a.employeerole.toLowerCase() > b.employeerole.toLowerCase() ? 1 : -1
              )
              .map((employeerole: Employeerole) => ({
                label: employeerole.employeerole,
                value: employeerole.employeerole
              }))}
          />
        )
      }
    ];
  }, [employeeroles]);

  const doneLoading = useMemo(() => {
    return !loading && !!employees;
  }, [loading, employees]);

  const onHandleExport = () => {
    dispatch(EmployeeActions.downloadEmployees(noOp));
  };

  const handleViewChange = (object: any) => {
    setViewStyle(object.view_style);
    setCookieListTableViewStyle(object.view_style);
  };

  return (
    <PageLayout
      title="Human Resources - Employees"
      action={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button label="Export" onClick={onHandleExport} />
          &nbsp;
          <Button label="Add Employee" onClick={() => navigate('add')} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '130px',
              marginLeft: '10px',
              marginBottom: '10px'
            }}
          >
            <Select
              name="view_style"
              placeholder="Select View"
              value={view_style}
              items={[
                { label: 'List View', value: 'list_view' },
                { label: 'Table View', value: 'table_view' }
              ]}
              onChange={handleViewChange}
            />
          </div>
        </div>
      }
    >
      <FilterLayout menuItems={menuItems}>
        <RemovalProvider>
          {!doneLoading && <Loader filled />}
          {doneLoading &&
            (view_style === 'list_view' ? (
              <EmployeeList employees={employees} loading={loading} />
            ) : (
              <EmployeeTable employees={employees} />
            ))}
          <RemoveEmployeeContainer />
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default EmployeesContainer;
