import React from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { DealSheet } from '../../../../../../interfaces/client/deal-sheet.interface';

import { RemoveDealSheetAction } from '../../../../../../actions/client/deal-sheet/deal-sheet.types';
import { ClientDealSheetActions } from '../../../../../../actions/client/deal-sheet/deal-sheet.actions';

import { useAppDispatch, useRemoval } from '../../../../../../hooks';

import { Modal } from '../../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../../../components/modal/remove/RemoveModal';

const RemoveDealSheetContainer = () => {
  const dispatch: Dispatch<RemoveDealSheetAction> = useAppDispatch();
  const { id } = useParams<string>();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      ClientDealSheetActions.removeDealSheet(id, (element as DealSheet).id, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Deal Sheet">
            <RemoveModal title={(element as DealSheet).name} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveDealSheetContainer;
