import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Timesheet } from '../../../../interfaces/employee/timesheet.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { Client } from '../../../../interfaces/client/client.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';

import { TimesheetActions } from '../../../../actions/timesheet/timesheet.actions';
import { AddTimesheetAction } from '../../../../actions/timesheet/timesheet.types';
import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';
import { CategoryActions } from '../../../../actions/category/category.actions';
import { ClientActions } from '../../../../actions/client/client.actions';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import TimesheetForm from '../../../../components/timesheets/form/TimesheetForm';

const AddTimesheetContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddTimesheetAction | LoadEmployeesAction> = useAppDispatch();

  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );
  const clients: Client[] = useAppSelector(({ client_store }: AppState) => client_store.clients);

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(ClientActions.loadClients(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: Timesheet, resetForm: boolean, onComplete: () => void): void => {
    if(resetForm) {
      dispatch(TimesheetActions.addAnotherTimesheet(payload, onComplete));
    } else {
      dispatch(TimesheetActions.addTimesheet(payload, navigate, onComplete));
    }
  };

  return (
    <PageLayout title="Add Time Sheet">
      <TimesheetForm
        employees={employees}
        clients={clients}
        categories={categories}
        onSave={onSave}
        resetForm={true}
      />
    </PageLayout>
  );
};

export default AddTimesheetContainer;
