import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddContactRequest,
  getContactRequest,
  getContactsRequest,
  getRemoveContactRequest,
  getUpdateContactRequest,
  RequestOptions
} from '../tools/api';

import {
  AddContactAction,
  LoadContactsAction,
  UpdateContactAction
} from '../actions/client/contact/contact.types';
import { ContactActions } from '../actions/contact/contact.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';
import { AddMandateToContactAction } from '../actions/contact/contact.types';
import { getConvertContactRequest } from '../tools/api/contact.endpoints';

export function* performLoadContacts({ filter, onComplete }: LoadContactsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getContactsRequest(filter);

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ContactActions.SET_CONTACTS, contacts: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadContactsRequest() {
  yield takeLatest(ContactActions.LOAD_CONTACTS, performLoadContacts);
}

export function* performLoadContact({ id, onComplete }: LoadContactsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getContactRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ContactActions.SET_CONTACT, contact: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadContactRequest() {
  yield takeLatest(ContactActions.LOAD_CONTACT, performLoadContact);
}

export function* performUpdateContact({ id, payload, onSuccess, onComplete }: UpdateContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateContactRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('This contact has been updated successfully!', SNACK_SUCCESS)
    );

    yield put({ type: ContactActions.SET_CONTACT, contact: data });

    yield put(ContactActions.loadContacts(true, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateContactRequest() {
  yield takeLatest(ContactActions.UPDATE_CONTACT, performUpdateContact);
}

export function* performAddContact({ payload, onSuccess, onComplete }: AddContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddContactRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new contact has been created successfully!', SNACK_SUCCESS)
    );

    yield put(ContactActions.loadContacts(true, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddContactRequest() {
  yield takeLatest(ContactActions.ADD_CONTACT, performAddContact);
}

export function* performRemoveContact({ id, onComplete }: AddContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveContactRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The contact has been removed successfully!', SNACK_SUCCESS));

    yield put(ContactActions.loadContacts(true, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveContactRequest() {
  yield takeLatest(ContactActions.REMOVE_CONTACT, performRemoveContact);
}

export function* performAddMandateToContact({
  id,
  payload,
  onComplete
}: AddMandateToContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getConvertContactRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'The contact has been converted to a client successfully!',
        SNACK_SUCCESS
      )
    );

    yield call(onComplete, data.client_id);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForAddMandateToContactRequest() {
  yield takeLatest(ContactActions.ADD_MANDATE, performAddMandateToContact);
}

export default function* contactSaga() {
  yield all([
    watchForLoadContactsRequest(),
    watchForLoadContactRequest(),
    watchForUpdateContactRequest(),
    watchForAddContactRequest(),
    watchForRemoveContactRequest(),
    watchForAddMandateToContactRequest()
  ]);
}
