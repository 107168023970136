import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { ExpenseClaim } from '../../../../interfaces/employee/expense-claim.interface';

import { RemoveExpenseClaimAction } from '../../../../actions/expense-claim/expense-claim.types';
import { ExpenseClaimActions } from '../../../../actions/expense-claim/expense-claim.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

interface Props {
  onRemoval?: () => void;
}

const RemoveExpenseClaimContainer = ({ onRemoval }: Props) => {
  const dispatch: Dispatch<RemoveExpenseClaimAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      ExpenseClaimActions.removeExpenseClaim((element as ExpenseClaim).id as number, () => {
        onComplete();
        onClose();

        if (onRemoval) {
          onRemoval();
        }
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Expense Claim">
            <RemoveModal title="this expense claim" onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveExpenseClaimContainer;
