import React, { useEffect, useMemo } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import RemovalProvider from '../../../../../../providers/Removal.provider';

import { Category } from '../../../../../../interfaces/config/category.interface';
import { Tombstone } from '../../../../../../interfaces/client/tombstone.interface';
import { Filter } from '../../../../../../types/filter.interface';

import { CategoryActions } from '../../../../../../actions/category/category.actions';
import { LoadTombstonesAction } from '../../../../../../actions/client/tombstone/tombstone.types';
import { TombstoneActions } from '../../../../../../actions/client/tombstone/tombstone.actions';
import { LoadSectorsAction } from '../../../../../../actions/sector/sector.types';

import { ReactComponent as ChevronRightIcon } from '../../../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import Button from '../../../../../../components/button/Button';
import TombstoneList from '../../../../../../components/tombstones/list/TombstoneList';
import FilterLayout from '../../../../../../components/layout/filter/FilterLayout';
import { DropdownMenuItem } from '../../../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../../../components/radio/RadioGroup';

import RemoveTombstoneContainer from '../remove/RemoveTombstone.container';
import { formatCurrency } from '../../../../../../tools/currency.util';

const TombstonesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadTombstonesAction | LoadSectorsAction> = useAppDispatch();
  const { id } = useParams<string>();

  const tombstones: Tombstone[] = useAppSelector(
    ({ client_store }: AppState) => client_store.tombstones
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(TombstoneActions.loadTombstones(id, noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tombstoneTotal = useMemo(() => {
    return tombstones
      .filter((tombstone: Tombstone) => !isNaN(tombstone.size_of_transaction))
      .reduce((accum, tombstone) => accum + Number(tombstone.size_of_transaction), 0);
  }, [tombstones]);

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Category',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="category_id"
            onChange={onChange}
            value={filters?.category_id as string}
            items={categories
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((category: Category) => ({
                label: category.name,
                value: category.id
              }))}
          />
        )
      }
    ];
  }, [categories]);

  return (
    <PageLayout
      title="Tombstones"
      action={<Button label="Add Tombstone" onClick={() => navigate('add')} />}
    >
      <FilterLayout
        menuItems={menuItems}
        actions={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginRight: 10 }}>Tombstone Total Fee: {formatCurrency(tombstoneTotal)}</p>
            <Button
              label="Export"
              onClick={() => dispatch(TombstoneActions.downloadTombstones(id, noOp))}
            />
          </div>
        }
      >
        <RemovalProvider>
          <TombstoneList tombstones={tombstones} />
          <RemoveTombstoneContainer />
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default TombstonesContainer;
