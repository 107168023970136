import { NavigateFunction } from 'react-router-dom';

import { PerformanceReview } from '../../interfaces/employee/performance-review.interface';

import {
  AddPerformanceReviewAction,
  LoadPerformanceReviewAction,
  LoadPerformanceReviewsAction,
  OnComplete,
  RemovePerformanceReviewAction,
  UpdatePerformanceReviewAction
} from './performance-review.types';

export class PerformanceReviewActions {
  static readonly LOAD_PERFORMANCE_REVIEWS: string =
    '[PERFORMANCE_REVIEW_STORE] Load performance reviews';
  static readonly SET_PERFORMANCE_REVIEWS: string =
    '[PERFORMANCE_REVIEW_STORE] Set performance reviews';

  static readonly LOAD_PERFORMANCE_REVIEW: string =
    '[PERFORMANCE_REVIEW_STORE] Load performance review';
  static readonly SET_PERFORMANCE_REVIEW: string =
    '[PERFORMANCE_REVIEW_STORE] Set performance review';

  static readonly UPDATE_PERFORMANCE_REVIEW: string =
    '[PERFORMANCE_REVIEW_STORE] Update performance review';
  static readonly ADD_PERFORMANCE_REVIEW: string =
    '[PERFORMANCE_REVIEW_STORE] Add performance review';
  static readonly REMOVE_PERFORMANCE_REVIEW: string =
    '[PERFORMANCE_REVIEW_STORE] Remove performance review';

  static loadPerformanceReviews = (onComplete: OnComplete): LoadPerformanceReviewsAction => ({
    type: PerformanceReviewActions.LOAD_PERFORMANCE_REVIEWS,
    onComplete
  });

  static loadPerformanceReview = (
    id: number | string,
    onComplete: OnComplete
  ): LoadPerformanceReviewAction => ({
    type: PerformanceReviewActions.LOAD_PERFORMANCE_REVIEW,
    id,
    onComplete
  });

  static addPerformanceReview = (
    payload: PerformanceReview,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddPerformanceReviewAction => ({
    type: PerformanceReviewActions.ADD_PERFORMANCE_REVIEW,
    payload,
    navigate,
    onComplete
  });

  static updatePerformanceReview = (
    id: number | string,
    payload: PerformanceReview,
    onComplete: OnComplete
  ): UpdatePerformanceReviewAction => ({
    type: PerformanceReviewActions.UPDATE_PERFORMANCE_REVIEW,
    id,
    payload,
    onComplete
  });

  static removePerformanceReview = (
    id: number | string,
    onComplete: OnComplete
  ): RemovePerformanceReviewAction => ({
    type: PerformanceReviewActions.REMOVE_PERFORMANCE_REVIEW,
    id,
    onComplete
  });
}
