import axios, { AxiosError, AxiosResponse, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { delay, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAuthenticateRequest,
  getConfirmationRequest,
  getSetConfirmationRequest,
  getForgotPasswordRequest,
  getRegisterRequest,
  getResetPasswordRequest,
  RequestOptions
} from '../tools/api';
import { setAuthTokenCookie, SetToken } from '../tools/auth.util';

import { AuthActions } from '../actions/auth/auth.actions';
import { SystemActions } from '../actions/system/system.actions';
import {
  AuthenticateAction,
  ForgotPasswordAction,
  RegisterAction,
  ResetPasswordAction,
  VerifyAccountAction,
  ConfirmAccountAction
} from '../actions/auth/auth.types';

import { ResponseError } from '../types/response-error.interface';

export function* performRegisterUser({ request, navigate }: RegisterAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRegisterRequest(request);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'Successfully registered your account, you will receive an email to confirm your account.',
        SNACK_SUCCESS
      )
    );

    // navigate to the confirmation page
    yield navigate('/accounts/success');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRegisterUserRequest() {
  yield takeLatest(AuthActions.REGISTER, performRegisterUser);
}

export function* performAuthenticateUser({
  request,
  navigate,
  onSuccess,
  onComplete
}: AuthenticateAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAuthenticateRequest(request);

    // make the request, retrieve the token from the response headers
    const response: AxiosResponse = yield call<AxiosStatic>(axios, endpoint, requestOptions);
    const token: string = response.headers['authorization']?.split('Bearer ')[1];
    yield call<SetToken>(setAuthTokenCookie, token, request.remember_me);

    yield call(onSuccess, token);

    yield call(delay, 500);

    // navigate to the dashboard page
    yield navigate('/dashboard');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message ?? error.response.data.error;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAuthenticateUserRequest() {
  yield takeLatest(AuthActions.AUTHENTICATE, performAuthenticateUser);
}

export function* performForgotPassword({ request, navigate }: ForgotPasswordAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getForgotPasswordRequest(request);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    // navigate to the login page and notify user
    if (navigate) {
      yield put(
        SystemActions.addNotice(
          'You will receive an email with password reset instructions.',
          SNACK_SUCCESS
        )
      );

      yield navigate('/');
    }
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForForgotPasswordRequest() {
  yield takeLatest(AuthActions.FORGOT_PASSWORD, performForgotPassword);
}

export function* performResetPassword({ id, token, request, navigate }: ResetPasswordAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getResetPasswordRequest(request, id, token);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('Your password has been reset successfully.', SNACK_SUCCESS));

    // navigate to the login page
    yield navigate('/');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForResetPasswordRequest() {
  yield takeLatest(AuthActions.RESET_PASSWORD, performResetPassword);
}

export function* performVerifyAccount({ id, token, navigate }: VerifyAccountAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getConfirmationRequest(id, token);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your account has been verified successfully.', SNACK_SUCCESS)
    );

    // navigate to the login page
    yield navigate('/');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForVerifyAccountRequest() {
  yield takeLatest(AuthActions.VERIFY_ACCOUNT, performVerifyAccount);
}

export function* performConfirmUser({ request }: ConfirmAccountAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getSetConfirmationRequest(request);

    // make the request, retrieve the token from the response headers
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('Confirmation mail sent successfully', SNACK_SUCCESS));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message ?? error.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForConfirmUserRequest() {
  yield takeLatest(AuthActions.CONFIRM_ACCOUNT, performConfirmUser);
}

export default function* authSaga() {
  yield all([
    watchForRegisterUserRequest(),
    watchForAuthenticateUserRequest(),
    watchForForgotPasswordRequest(),
    watchForResetPasswordRequest(),
    watchForVerifyAccountRequest(),
    watchForConfirmUserRequest()
  ]);
}
