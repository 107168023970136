/**
 * @module Dropdown Component
 * @description
 * Wrapper around PopperJS (https://github.com/popperjs/react-popper), the element
 *  positioning library.
 * Will accept `children` that serve as the trigger element.
 * The popper element is the `DropdownMenu` component, constructed from provided
 *  `menuItems`, and is visible upon clicking the trigger element.
 */
import React, { ReactNode, RefObject, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { getClassNames, noOp } from '@neslotech/utils';

import { DropdownMenu, DropdownMenuItem } from './DropdownMenu';

import './dropdown.scss';

type PlacementType = 'top' | 'top-start' | 'bottom-end' | 'left' | 'right' | 'bottom';

/**
 * Popper options setup with placement and offset (menu distance from trigger).
 *
 * @param {String} placement - where to place the popper, relative to trigger
 *  ('top', 'top-start', 'bottom-end', 'left', 'right', etc.)
 */
const getPopperOptions = (placement: PlacementType) => ({
  placement,
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 4]
      }
    }
  ]
});

/**
 * Decide whether to display the overlay, based on `popperOpen`. Will
 *  hide the popper menu if clicked.
 *
 * @param {Boolean} popperOpen - state of whether the popper menu is visible
 * @param {Function} setPopperOpen - function to set value of popperOpen
 */
const renderOverlay = (popperOpen: boolean, setPopperOpen: (value: boolean) => void) =>
  popperOpen && (
    <section
      className="dropdown__overlay"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        setPopperOpen(false);
      }}
    />
  );

interface Props {
  placement: PlacementType;
  children: ReactNode | ReactNode[];
  menuItems: DropdownMenuItem[];
  open?: boolean;
  expandable?: boolean;
  isOpen?: boolean;
  hideButton? :boolean;
}

export const Dropdown = ({ menuItems, placement, children, open, expandable, isOpen, hideButton }: Props) => {
  // reference and setter to the dropdown trigger element:
  const [referenceElement, setReferenceElement] = usePopperRef();

  // reference and setter to the popup/dropdown element:
  const [popperElement, setPopperElement] = usePopperRef();

  // dropdown visibility control:
  const [popperOpen, setPopperOpen] = useState(open ?? false);

  // initialise popper, with custom options
  const { styles, attributes } = usePopper(
    referenceElement?.current,
    popperElement?.current,
    getPopperOptions(placement)
  );

  return (
    <section className="dropdown">
      <section
        className="dropdown__trigger"
        ref={setReferenceElement}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (expandable) {
            return;
          }

          setPopperOpen(!popperOpen);
        }}
      >
        {children}
      </section>

      {renderOverlay(popperOpen, setPopperOpen)}

      <section
        className={getClassNames('dropdown__popper', { open: popperOpen })}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {menuItems.length > 0 ? (
          <DropdownMenu
            menuItems={menuItems}
            selectItem={() => (expandable ? noOp() : setPopperOpen(false))}
            hideButton={hideButton}
          />
        ) : isOpen ? (
          <ul className="dropdown-menu">
            <li className="dropdown-menu__item">
              <button>
                <span>
                  <p>No data found</p>
                </span>
                <span className="dropdown-menu__item-suffix">&nbsp;</span>
              </button>
            </li>
          </ul>
        ) : (
          <></>
        )}
      </section>
    </section>
  );
};

const usePopperRef = (): [RefObject<HTMLElement>, (node: HTMLElement) => void] => {
  const ref = useRef<HTMLElement | null>(null);
  const setRef = useCallback((node: HTMLElement) => {
    ref.current = node;
  }, []);

  return [ref, setRef];
};

Dropdown.defaultProps = {
  placement: 'bottom',
  expandable: false,
  justified: false,
  children: []
};
