import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Irp5 } from '../../../../interfaces/employee/irp5.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';

import { Irp5Actions } from '../../../../actions/irp5/irp5.actions';
import { AddIrp5Action } from '../../../../actions/irp5/irp5.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import Irp5Form from '../../../../components/irp5s/form/Irp5Form';

const AddIrp5Container = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddIrp5Action | LoadEmployeesAction> = useAppDispatch();

  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: Irp5, onComplete: () => void): void => {
    dispatch(Irp5Actions.addIrp5(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Irp5">
      <Irp5Form employees={employees} onSave={onSave} />
    </PageLayout>
  );
};

export default AddIrp5Container;
