import React from 'react';
import { RouteObject } from 'react-router-dom';

import HrCrumb from '../../crumbs/human-resources/Hr.crumb';

import employeeRoutes from './employee.routes';
import leaveRequestsRoutes from './leave-requests.routes';
import expenseClaimsRoutes from './expense-claims.routes';
import payslipRoutes from './payslips.routes';
import performanceReviewsRoutes from './performance-reviews.routes';
import timesheetRoutes from './timesheets.routes';
import prospectRoutes from './prospect.routes';
import irp5Routes from './irp5s.routes';

const hrRoutes: RouteObject[] = [
  {
    path: 'human-resources',
    handle: {
      crumb: () => <HrCrumb />
    },
    children: [
      ...employeeRoutes,
      ...leaveRequestsRoutes,
      ...expenseClaimsRoutes,
      ...payslipRoutes,
      ...irp5Routes,
      ...performanceReviewsRoutes,
      ...timesheetRoutes,
      ...prospectRoutes
    ]
  }
];

export default hrRoutes;
