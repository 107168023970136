import React, { useEffect, useMemo, useState } from 'react';

import { Employee } from '../../../interfaces/employee/employee.interface';
import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';

import { filterElement } from '../../../tools/filter.util';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';

import './employee-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';
import Button from '../../button/Button';
import AlphabetLayout from '../../layout/filter/AlphabetLayout';

interface Props {
  employees: Employee[];
}

const formify = (employees: Employee[], filters: Filter): Employee[] =>
  employees.filter((employee: Employee) =>
    // @ts-ignore
    filterElement(employee, filters)
  );

const employeeHeaders: TableHeader[] = [
  {
    Header: 'Employee Name',
    accessor: 'full_name'
  },
  {
    Header: 'Role',
    accessor: 'role'
  },
  {
    Header: 'Employee Status',
    accessor: 'active_status'
  },
  {
    Header: 'Contract',
    accessor: 'contract'
  },
  {
    Header: 'Disciplinary',
    accessor: 'disciplinary'
  },
  {
    Header: 'Documents',
    accessor: 'documents'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const EmployeeTable = ({ employees }: Props) => {
  const { filters, setFilterDefaults, sort } = useFilters();
  const navigate: NavigateFunction = useNavigate();
  const [sortedEmployees, setSortedEmployees] = useState(employees);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ employee_type_id: '', category_id: '', sector_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {    
    if (selectedLetter) {
      employees = employees.filter(employee => employee.first_name[0].toUpperCase() === selectedLetter);
    }

    setSortedEmployees(employees);
  }, [employees, selectedLetter]);

  const employeesRows: TableData[] = useMemo(() => {
    return formify(sortedEmployees, filters)
      .sort(
        sort === 'descending'
          ? (a: Employee, b: Employee) => {
              return (
                a.first_name.localeCompare(b.first_name) && a.last_name.localeCompare(b.first_name)
              );
            }
          : (a: Employee, b: Employee) => {
              return (
                b.first_name.localeCompare(a.first_name) && b.last_name.localeCompare(a.last_name)
              );
            }
      )
      .map<TableData>((employee: Employee) => ({
        ...employee,
        name: employee.first_name + ' ' + employee.last_name,
        contract: (
          <Button
            hollow
            label="Contract"
            onClick={() => navigate(`/human-resources/employees/${employee.id}/contracts`)}
          />
        ),
        disciplinary: (
          <Button
            hollow
            label="Disciplinary"
            onClick={() => navigate(`/human-resources/employees/${employee.id}/disciplines`)}
          />
        ),
        documents: (
          <Button
            hollow
            label="Documents"
            onClick={() => navigate(`/human-resources/employees/${employee.id}/other-documents`)}
          />
        ),
        action: (
          <div className="employee_action">
            <EditIcon onClick={() => navigate(`/human-resources/employees/${employee.id}`)} />
          </div>
        ),
        onClick: () => {}
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedEmployees, filters]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="employee-list">
        <Table cols={employeeHeaders} rowData={employeesRows} />
      </article>
    </>
  );
};

export default EmployeeTable;
