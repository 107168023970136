import React from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import { Loader } from '../loader/Loader';

import './button.scss';

interface Props {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  secondary?: boolean;
  hollow?: boolean;
  onClick?: () => void;
}

const Button = ({ label, type, disabled, loading, secondary, hollow, onClick }: Props) => (
  <button
    className={getClassNames('button', { disabled, secondary, hollow })}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    {loading && <Loader />}
    {!loading && label}
  </button>
);

Button.defaultProps = {
  type: 'button',
  disabled: false,
  onClick: noOp
};

export default Button;
