import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';
import { DealSheet } from '../interfaces/client/deal-sheet.interface';

import { DealSheetActions } from '../actions/deal-sheet/deal-sheet.actions';

export interface DealSheetState extends State {
  dealSheets: DealSheet[];
}

export const initialState: DealSheetState = {
  dealSheets: []
};

export const dealSheetReducer = (state: DealSheetState = initialState, action: AnyAction) => {
  switch (action.type) {
    case DealSheetActions.SET_DEAL_SHEETS:
      const { dealSheets } = action;
      return {
        ...state,
        dealSheets
      };
    default:
      return state;
  }
};
