import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { PerformanceReview } from '../../../../interfaces/employee/performance-review.interface';

import { PerformanceReviewActions } from '../../../../actions/performance-review/performance-review.actions';
import { RemovePerformanceReviewAction } from '../../../../actions/performance-review/performance-review.types';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

interface Props {
  onRemoval?: () => void;
}

const RemovePerformanceReviewContainer = ({ onRemoval }: Props) => {
  const dispatch: Dispatch<RemovePerformanceReviewAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      PerformanceReviewActions.removePerformanceReview(
        (element as PerformanceReview).id as number,
        () => {
          onComplete();
          onClose();
          if (onRemoval) {
            onRemoval();
          }
        }
      )
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Performance Review">
            <RemoveModal title="this performance review" onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemovePerformanceReviewContainer;
