import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../header/Header';

import './auth-layout.scss';

interface Props {
  title: string;
  subtitle?: string;
  linkText?: string;
  linkTo?: string;
  children: ReactNode;
}

const AuthLayout = ({ title, subtitle, linkTo, linkText, children }: Props) => (
  <article className="auth-layout">
    <Header />
    <main className="auth-layout__content">
      <header className="auth-layout__content-header">
        <h1 className="auth-layout__title">{title}</h1>
        <h5 className="auth-layout__subtitle">{subtitle}</h5>
      </header>
      <section className="auth-layout__form">{children}</section>
      {linkTo && (
        <footer className="auth-layout__action">
          <Link to={linkTo}>{linkText}</Link>
        </footer>
      )}
    </main>
  </article>
);

export default AuthLayout;
