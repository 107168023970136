import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';
import { Irp5Actions } from '../../../../actions/irp5/irp5.actions';
import { RemoveIrp5Action } from '../../../../actions/irp5/irp5.types';
import { Irp5 } from '../../../../interfaces/employee/irp5.interface';

interface Props {
  onRemoval?: () => void;
}

const RemoveIrp5Container = ({ onRemoval }: Props) => {
  const dispatch: Dispatch<RemoveIrp5Action> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      Irp5Actions.removeIrp5((element as Irp5).id as number, () => {
        onComplete();
        onClose();
        if (onRemoval) {
          onRemoval();
        }
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Irp5">
            <RemoveModal title="this irp5" onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveIrp5Container;
