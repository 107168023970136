import React, { forwardRef } from 'react';
import DatePickerEl from 'react-datepicker';

import { noOp } from '@neslotech/utils';

import Input from '../input/Input';

import './datepicker.scss';

type NamedChangeType = { [key: string]: Date | string };
export type OnChangeType = NamedChangeType | (Date | string);

declare const top: 'top';
declare const bottom: 'bottom';
declare const right: 'right';
declare const left: 'left';
type BasePlacement = typeof top | typeof bottom | typeof right | typeof left;
type VariationPlacement =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';
type AutoPlacement = 'auto' | 'auto-start' | 'auto-end';
type Placement = AutoPlacement | BasePlacement | VariationPlacement;

interface Props {
  name?: string;
  placeholder: string;
  label?: string;
  value?: Date | string;
  onChange: (value: OnChangeType) => void;
  popperPlacement?: Placement;
  disabled?: boolean;
  showTime?: boolean;
  dateFormat?: string;
  error?: string;
}

export const Datepicker = ({
  name,
  placeholder,
  label,
  value,
  onChange,
  popperPlacement,
  disabled,
  dateFormat,
  showTime,
  error
}: Props) => {
  const handleChange = (date: Date | string) => {

    // Fix for date offset
    if (date instanceof Date) {
      date.setHours(date.getHours() + 2);
    }

    const val = getChangeValue(date, name);
    onChange(val);
  };

  const DateInput = forwardRef<HTMLInputElement, { value?: Date | string; onClick?: () => void }>(
    ({ value, onClick }, ref) => (
      <Input
        inputRef={ref}
        name={name}
        placeholder={placeholder}
        label={label ?? ' '}
        value={value}
        onFocus={disabled ? noOp : onClick}
        disabled={disabled}
        error={error}
        clearable
        onChange={onChange}
      />
    )
  );

  return (
    <section className="datepicker">
      <DatePickerEl
        showTimeSelect={showTime}
        showTimeSelectOnly={showTime}
        timeIntervals={15}
        dateFormat={dateFormat}
        selected={value as Date}
        onChange={handleChange}
        customInput={<DateInput />}
        calendarClassName="datepicker__calendar"
        showPopperArrow={false}
        popperPlacement={popperPlacement}
        showYearDropdown
        showMonthDropdown
        dayClassName={(date: Date) => {
          if (!value) {
            return 'datepicker__calendar-day';
          }

          return date.getDate() === (value as Date).getDate() &&
            date.getMonth() === (value as Date).getMonth()
            ? 'datepicker__calendar-day--today'
            : 'datepicker__calendar-day';
        }}
      />
    </section>
  );
};

const getChangeValue = (value: Date | string, name?: string): OnChangeType => {
  return name ? { [name]: value } : value;
};

Datepicker.defaultProps = {
  popperPlacement: 'bottom-end',
  onChange: noOp,
  onFocus: noOp,
  disabled: false,
  yearOnly: false,
  preventOpen: false,
  dateFormat: 'dd/MM/yyyy',
  dropdownMode: 'select' || 'scroll',
  showYearDropdown: false,
  yearDropdownItemNumber: 15,
  scrollableYearDropdown: false
};
