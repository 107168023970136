import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { LeaveRequest } from '../../interfaces/employee/leave-request.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getLeaveRequestsEndpoint = (): string => `${API_HOST}/leave_requests`;
export const getLeaveRequestsRequest = (): RequestOptions => [
  getLeaveRequestsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddLeaveRequestRequest = (payload: LeaveRequest): RequestOptions => [
  getLeaveRequestsEndpoint(),
  getHttpPostOptions({ leave_request: payload }, getAuthHeaders())
];

const getLeaveRequestEndpoint = (id: number | string): string => `${API_HOST}/leave_requests/${id}`;
export const getLeaveRequestRequest = (id: number | string): RequestOptions => [
  getLeaveRequestEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateLeaveRequestRequest = (
  id: number | string,
  payload: LeaveRequest
): RequestOptions => [
  getLeaveRequestEndpoint(id),
  getHttpPutOptions({ leave_request: payload }, getAuthHeaders())
];

export const getRemoveLeaveRequestRequest = (id: string | number): RequestOptions => [
  getLeaveRequestEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
