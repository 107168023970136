import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../hooks';

import AuthenticatedLayout from '../components/layout/authenticated/AuthenticatedLayout';

interface Props {
  children: ReactNode | ReactNode[];
  adminRequired?: boolean;
}

const ProtectedRoute = ({ children, adminRequired=false }: Props) => {
  const { token, isAdmin } = useAuth();

  if (!token) {
    return <Navigate to="/" replace />;
  }

  if (adminRequired && !isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
};

export default ProtectedRoute;
