import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Employeerole } from '../../interfaces/employeerole/employeerole.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getEmployeerolesEndpoint = (): string => `${API_HOST}/employeeroles`;
export const getEmployeerolesRequest = (): RequestOptions => [
  getEmployeerolesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getEmployeeroleEndpoint = (id: number | string): string => `${API_HOST}/employeeroles/${id}`;
export const getEmployeeroleRequest = (id: number | string): RequestOptions => [
  getEmployeeroleEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateEmployeeroleRequest = (id: number | string, payload: Employeerole): RequestOptions => [
  getEmployeeroleEndpoint(id),
  getHttpPutOptions({ employeerole: payload }, getAuthHeaders())
];

export const getAddEmployeeroleRequest = (payload: Employeerole): RequestOptions => [
  getEmployeerolesEndpoint(),
  getHttpPostOptions({ employeerole: payload }, getAuthHeaders())
];

export const getRemoveEmployeeroleRequest = (id: string | number): RequestOptions => [
  getEmployeeroleEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
