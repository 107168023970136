import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Timesheet } from '../../interfaces/employee/timesheet.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getTimesheetsEndpoint = (): string => `${API_HOST}/timesheets`;
export const getTimesheetsRequest = (): RequestOptions => [
  getTimesheetsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getTimesheetsCsvEndpoint = (): string => `${getTimesheetsEndpoint()}.csv`;
export const getDownloadTimesheetsRequest = () => [
  getTimesheetsCsvEndpoint(),
  { ...getHttpGetOptions({ ...getAuthHeaders(), Accept: 'text/csv' }), responseType: 'blob' }
];

export const getAddTimesheetRequest = (payload: Timesheet): RequestOptions => [
  getTimesheetsEndpoint(),
  getHttpPostOptions({ timesheet: payload }, getAuthHeaders())
];

const getTimesheetEndpoint = (id: number | string): string => `${API_HOST}/timesheets/${id}`;
export const getTimesheetRequest = (id: number | string): RequestOptions => [
  getTimesheetEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateTimesheetRequest = (
  id: number | string,
  payload: Timesheet
): RequestOptions => [
  getTimesheetEndpoint(id),
  getHttpPutOptions({ timesheet: payload }, getAuthHeaders())
];

export const getRemoveTimesheetRequest = (id: string | number): RequestOptions => [
  getTimesheetEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
