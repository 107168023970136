import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Timesheet } from '../interfaces/employee/timesheet.interface';

import { TimesheetActions } from '../actions/timesheet/timesheet.actions';

export interface TimesheetState extends State {
  timesheets: Timesheet[];
  timesheet?: Timesheet;
}

export const initialState: TimesheetState = {
  timesheets: []
};

export const timesheetReducer = (state: TimesheetState = initialState, action: AnyAction) => {
  if (action.type === TimesheetActions.SET_TIMESHEETS) {
    const { timesheets } = action;
    return {
      ...state,
      timesheets
    };
  } else if (action.type === TimesheetActions.SET_TIMESHEET) {
    const { timesheet } = action;
    return {
      ...state,
      timesheet
    };
  }

  return state;
};
