import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Employeerole } from '../../../../interfaces/employeerole/employeerole.interface';

import { LoadEmployeerolesAction } from '../../../../actions/employeerole/employeerole.types';
import { EmployeeroleActions } from '../../../../actions/employeerole/employeerole.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import EmployeeroleList from '../../../../components/employeeroles/list/EmployeeroleList';
import Button from '../../../../components/button/Button';

import RemoveEmployeeroleContainer from '../remove/RemoveEmployeerole.container';
import RemovalProvider from '../../../../providers/Removal.provider';

const EmployeerolesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadEmployeerolesAction> = useAppDispatch();
  const employeeroles: Employeerole[] = useAppSelector(({ employeerole_store }: AppState) => employeerole_store.employeeroles);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(EmployeeroleActions.loadEmployeeroles(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Employee Role Management"
      action={<Button label="Add Employee Role" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <EmployeeroleList employeeroles={employeeroles} loading={loading} />
        <RemoveEmployeeroleContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default EmployeerolesContainer;
