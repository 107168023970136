import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../../../types/state.interface';
import { Mandate } from '../../../../../../interfaces/client/mandate.interface';

import RemovalProvider from '../../../../../../providers/Removal.provider';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { useClient } from '../../../../../../hooks/clients';

import { MandateActions } from '../../../../../../actions/client/mandate/mandate.actions';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../../components/loader/Loader';
import MandateForm from '../../../../../../components/clients/details/mandates/MandateForm';

import RemoveMandateContainer from '../remove/RemoveMandate.container';

const MandateContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useAppDispatch();

  const { id, mandateId } = useParams<string>();

  const { client } = useClient();

  const [loading, setLoading] = useState<boolean>(true);

  const mandate: Mandate = useAppSelector(({ client_store }: AppState) => client_store.mandate);

  useEffect(() => {
    dispatch(
      MandateActions.loadMandate(client?.id ?? id, mandateId ?? id, () => setLoading(false))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id]);

  const doneLoading = useMemo(() => {
    return !loading && client?.id === Number(id) && !!mandate;
  }, [loading, client?.id, id, mandate]);

  const handleOnSave = (payload: Mandate, onComplete: () => void) => {
    dispatch(MandateActions.updateMandate(client?.id ?? id, mandateId ?? id, payload, onComplete));
  };

  const handleOnComplete = (onComplete: () => void) => {
    dispatch(
      MandateActions.completeMandate(client?.id ?? id, mandateId ?? id, () => {
        onComplete();
        navigate(`/core/clients/${client?.id ?? id}/mandates`);
      })
    );
  };

  return (
    <PageLayout title={`${client?.name} - Mandate`} bordered>
      <RemovalProvider>
        {!doneLoading && <Loader filled />}
        {doneLoading && (
          <MandateForm
            mandate={mandate}
            onSave={handleOnSave}
            onComplete={handleOnComplete}
            client={client}
          />
        )}
        <RemoveMandateContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default MandateContainer;
