import React from 'react';
import { RouteObject } from 'react-router-dom';

import SectorsContainer from '../../containers/admin/sectors/list/Sectors.container';
import AddSectorContainer from '../../containers/admin/sectors/add/AddSector.container';
import SectorContainer from '../../containers/admin/sectors/details/Sector.container';

import SectorsCrumb from '../../crumbs/admin/sectors/Sectors.crumb';
import AddSectorCrumb from '../../crumbs/admin/sectors/AddSector.crumb';
import SectorCrumb from '../../crumbs/admin/sectors/Sector.crumb';

import AdminRoute from '../AdminRoute';

const sectorRoutes: RouteObject[] = [
  {
    path: 'sectors',
    handle: {
      crumb: () => <SectorsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <SectorsContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddSectorContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddSectorCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <SectorContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <SectorCrumb />
        }
      }
    ]
  }
];

export default sectorRoutes;
