import { AnyAction } from '@reduxjs/toolkit';

import { Investment } from '../interfaces/investment/investment.interface';

import { InvestmentActions } from '../actions/investment/investment.actions';

import { State } from '../types/state.interface';

export interface InvestmentState extends State {
  investments: Investment[];
  investment?: Investment;
}

export const initialState: InvestmentState = {
  investments: []
};

export const investmentReducer = (state: InvestmentState = initialState, action: AnyAction) => {
  if (action.type === InvestmentActions.SET_INVESTMENTS) {
    const { investments } = action;
    return {
      ...state,
      investments
    };
  } else if (action.type === InvestmentActions.SET_INVESTMENT) {
    const { investment } = action;
    return {
      ...state,
      investment
    };
  }

  return state;
};
