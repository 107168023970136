import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../../hooks';

import RemovalProvider from '../../../../../providers/Removal.provider';

import { Investment } from '../../../../../interfaces/investment/investment.interface';

import { LoadInvestmentsAction } from '../../../../../actions/investment/investment.types';
import { InvestmentActions } from '../../../../../actions/investment/investment.actions';

import PageLayout from '../../../../../components/layout/page/PageLayout';
import InvestmentList from '../../../../../components/funds/investments/list/InvestmentList';
import Button from '../../../../../components/button/Button';

import RemoveInvestmentContainer from '../remove/RemoveInvestment.container';

const InvestmentsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadInvestmentsAction> = useAppDispatch();
  const investments: Investment[] = useAppSelector(
    ({ investment_store }: AppState) => investment_store.investments
  );
  const { id } = useParams<string>();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      dispatch(InvestmentActions.loadInvestments(id as string, () => setLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <PageLayout
      title="Investment Management"
      action={<Button label="Add Investment" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <InvestmentList investments={investments} loading={loading} />
        <RemoveInvestmentContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default InvestmentsContainer;
