import React, { createRef, useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId, isUndefined } from '@neslotech/utils';

import { toBase64 } from '../../../tools/file.util';

import { Irp5 } from '../../../interfaces/employee/irp5.interface';
import { Employee } from '../../../interfaces/employee/employee.interface';
import { FileData } from '../../../interfaces/config/file.interface';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import { OnChangeType } from '../../input/Input';
import { Datepicker, OnChangeType as DateChangeType } from '../../datepicker/Datepicker';
import FormAction from '../../form/form-action/FormAction';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import { FileUpload } from '../../upload/FileUpload';
import Button from '../../button/Button';

import './irp5-form.scss';
import DocumentIcon from '../../document-icon/DocumentIcon';

type OnAction = (payload: Irp5, onComplete: () => void) => void;

interface Props {
  employees: Employee[];
  irp5?: Irp5;
  onSave: OnAction;
}

export interface Irp5State extends StateForm {
  employeeId: string | number;
  startDate: string | Date;
  endDate: string | Date;
  document: FileData;
}

const rules: ValidationRules<Irp5State> = {
  validates: {
    employeeId: ['isPresent'],
    startDate: ['isPresent'],
    endDate: ['isPresent'],
    document: ['isPresent']
  }
};

const formify = (irp5?: Irp5): Irp5State =>
  ({
    employeeId: irp5?.user_id,
    startDate: isUndefined(irp5?.start_date) ? undefined : new Date(irp5?.start_date),
    endDate: isUndefined(irp5?.end_date) ? undefined : new Date(irp5?.end_date),
    document: isUndefined(irp5?.document)
      ? undefined
      : {
          name: (irp5.document as FileData).name,
          url: (irp5.document as FileData).url,
          date: new Date((irp5.document as FileData)?.date),
          uploaded_by: (irp5.document as FileData)?.uploaded_by
        }
  } as Irp5State);

const serverify = (form: Irp5State) => ({
  user_id: form.employeeId,
  start_date: form.startDate,
  end_date: form.endDate,
  document: form.document.data
});

const Irp5Form = ({ employees, irp5, onSave }: Props) => {
  const [form, setForm] = useFormState<Irp5State>(formify(irp5), rules);
  const handleChange = (
    newState: OnChangeType | DateChangeType | { document: FileData } | SelectChangeType
  ) => setForm({ ...form, ...(newState as Irp5State) });

  const [loading, setLoading] = useState<boolean>(false);

  const mappedEmployees = useMemo(() => {
    return employees.map<SelectItem>((employee: Employee) => ({
      label: employee.full_name,
      value: employee.id
    }));
  }, [employees]);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Irp5 = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  const handleFileChange = async (file: File) => {
    const base64Data = await toBase64(file);
    const fileData: FileData = {
      id: generateId(),
      name: file.name,
      uploaded_by: 'you',
      date: new Date(),
      data: base64Data
    };

    handleChange({ document: fileData });
  };

  const fileRef = createRef<HTMLInputElement>();

  return (
    <article className="irp5-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Select
              name="employeeId"
              label="Employee"
              placeholder="Employee"
              value={form?.employeeId}
              error={form?.messages.employeeId}
              items={mappedEmployees}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Datepicker
              name="startDate"
              label="From Date"
              placeholder="From Date"
              value={form?.startDate}
              error={form?.messages.startDate}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Datepicker
              name="endDate"
              label="To Date"
              placeholder="To Date"
              value={form?.endDate}
              error={form?.messages.endDate}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            {!form?.document && (
              <FileUpload fileRef={fileRef} fileTypes="application/pdf" onChange={handleFileChange}>
                <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
              </FileUpload>
            )}
            {!!form?.document && <UploadedFile file={form?.document} />}
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

interface FileProps {
  file: FileData;
}

const UploadedFile = ({ file }: FileProps) => (
  <article className="uploaded-file" onClick={() => window.open(file.url, '_blank')}>
    <section className="uploaded-file__name">
      <DocumentIcon file={file} />
      <p>{file.name}</p>
    </section>
    <small>
      Uploaded by {file.uploaded_by ?? 'you'} on {file.date.toLocaleDateString()}
    </small>
  </article>
);

export default Irp5Form;
