import { getHttpGetOptions, getHttpPutOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { ResetPasswordRequest } from '../../interfaces/auth/reset-password-request.interface';

import { API_HOST, RequestOptions } from './index';

const getProfileEndpoint = (): string => `${API_HOST}/profile`;
export const getProfileRequest = (): RequestOptions => [
  getProfileEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getPasswordEndpoint = (): string => `${API_HOST}/profile/password`;
export const getUpdatePasswordRequest = (request: ResetPasswordRequest): RequestOptions => [
  getPasswordEndpoint(),
  getHttpPutOptions(request, getAuthHeaders())
];
