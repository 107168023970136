import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { Precedent } from '../../../../interfaces/client/precedent.interface';
import { Client } from '../../../../interfaces/client/client.interface';
import { Category } from '../../../../interfaces/config/category.interface';

import { AddPrecedentAction } from '../../../../actions/client/precedent/precedent.types';
import { LoadTombstonesAction } from '../../../../actions/tombstone/tombstone.types';
import { PrecedentActions } from '../../../../actions/client/precedent/precedent.actions';
import { ClientActions } from '../../../../actions/client/client.actions';
import { CategoryActions } from '../../../../actions/category/category.actions';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import PrecedentForm from '../../../../components/clients/details/precedents/PrecedentForm';

const AddPrecedentContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddPrecedentAction | LoadTombstonesAction> = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const clients: Client[] = useAppSelector(({ client_store }) => client_store.clients);
  const categories: Category[] = useAppSelector(({ category_store }) => category_store.categories);

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(ClientActions.loadClients(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: Precedent): void => {
    dispatch(
      PrecedentActions.addPrecedent(payload.client_id, payload, () =>
        navigate(`/core/clients/${payload.client_id}/precedents`)
      )
    );
  };

  return (
    <PageLayout title="Add Precedent">
      {loading && <Loader filled />}
      {!loading && <PrecedentForm categories={categories} clients={clients} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddPrecedentContainer;
