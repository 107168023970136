import React from 'react';
import { RouteObject } from 'react-router-dom';

import Irp5Container from '../../containers/human-resources/irp5s/details/Irp5.container';
import AddIrp5Container from '../../containers/human-resources/irp5s/add/AddIrp5Container.container';
import Irp5sCrumb from '../../crumbs/human-resources/irp5s/Irp5s.crumb';
import AddIrp5Crumb from '../../crumbs/human-resources/irp5s/AddIrp5.crumb';
import Irp5Crumb from '../../crumbs/human-resources/irp5s/Irp5.crumb';

import ProtectedRoute from '../ProtectedRoute';
import Irp5sContainer from '../../containers/human-resources/irp5s/list/Irp5s.container';

const irp5Routes: RouteObject[] = [
  {
    path: 'irp5s',
    handle: {
      crumb: () => <Irp5sCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <Irp5sContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute adminRequired>
            <AddIrp5Container />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddIrp5Crumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <Irp5Container />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <Irp5Crumb />
        }
      }
    ]
  }
];

export default irp5Routes;
