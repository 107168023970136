import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Sector } from '../../../interfaces/config/sector.interface';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

import './sector-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  }
};

interface RowData extends TableData {
  name: string;
  description: string;
  status: string;
}

interface Props {
  sectors: Sector[];
  loading: boolean;
}

const SectorList = ({ sectors, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return sectors.map(
      (sector: Sector) =>
        ({
          name: sector.name,
          description: sector.description,
          status: sector.status,
          actions: <RemoveElement element={sector} />,
          onClick: () => navigate(`${sector.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  return (
    <article className="sector-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No sectors could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default SectorList;
