/**
 * @module DropdownMenu Component
 * @description
 * Utilised by `Dropdown` to display a menu.
 */
import React, { ReactNode, useState } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import './dropdown.scss';

interface ItemProps {
  text: string | ReactNode;
  icon?: ReactNode;
  onClick: () => void;
  selectItem?: () => void;
  suffix?: ReactNode;
  expandable?: boolean;
  expandedItems?: ReactNode | ReactNode[];
  hideButton?: boolean;
}

const MenuItem = ({
  text,
  icon,
  onClick,
  selectItem,
  expandable,
  suffix,
  expandedItems,
  hideButton
}: ItemProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <li className="dropdown-menu__item">
      {!hideButton ? (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (expandable) {
              setExpanded(!expanded);
            } else {
              onClick();
              selectItem();
            }
          }}
        >
          {icon}
          <span>{text}</span>
          <span className={getClassNames('dropdown-menu__item-suffix', { expanded })}>
            {suffix}
          </span>
        </button>
      ) : (
        <></>
      )}
      {(hideButton || expanded) && (
        <section className="dropdown-menu__expanded-items">{expandedItems}</section>
      )}
    </li>
  );
};

/**
 * Renders each menu item. Uses a button instead of links for more general use.
 *  (links have to contain hrefs).
 *
 * @param {Object[]} menuItems - list of {text:, onClick:} pairs
 * @param {Function} selectItem - the broad function that is performed on click
 */
const renderMenuItems = (
  menuItems: DropdownMenuItem[],
  selectItem?: () => void,
  hideButton?: boolean
) =>
  menuItems.map(
    (
      { text, icon, onClick, suffix, expandable, expandedItems }: DropdownMenuItem,
      index: number
    ) => (
      <MenuItem
        hideButton={hideButton}
        key={index}
        text={text}
        icon={icon}
        onClick={onClick}
        selectItem={selectItem}
        suffix={suffix}
        expandable={expandable}
        expandedItems={expandedItems}
      />
    )
  );

export interface DropdownMenuItem {
  text: string | ReactNode;
  icon?: ReactNode;
  suffix?: ReactNode;
  expandable?: boolean;
  expandedItems?: ReactNode[] | ReactNode;
  onClick: () => void;
}

interface Props {
  menuItems: DropdownMenuItem[];
  selectItem?: () => void;
  hideButton?: boolean;
}

export const DropdownMenu = ({ menuItems, selectItem, hideButton }: Props) => (
  <ul className="dropdown-menu">{renderMenuItems(menuItems, selectItem, hideButton)}</ul>
);

DropdownMenu.defaultProps = {
  selectItem: noOp
};
