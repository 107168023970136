import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddSubCategoryRequest,
  getSubCategoriesRequest,
  getSubCategoryRequest,
  getRemoveSubCategoryRequest,
  getUpdateSubCategoryRequest,
  RequestOptions
} from '../tools/api';

import {
  AddSubCategoryAction,
  LoadSubCategoriesAction,
  LoadSubCategoryAction,
  UpdateSubCategoryAction
} from '../actions/sub-category/sub-category.types';
import { SubCategoryActions } from '../actions/sub-category/sub-category.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadSubCategories({ category_id, onComplete }: LoadSubCategoriesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getSubCategoriesRequest(category_id);

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: SubCategoryActions.SET_SUB_CATEGORIES, sub_categories: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadSubCategoriesRequest() {
  yield takeLatest(SubCategoryActions.LOAD_SUB_CATEGORIES, performLoadSubCategories);
}

export function* performLoadSubCategory({ category_id, id, onComplete }: LoadSubCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getSubCategoryRequest(category_id, id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: SubCategoryActions.SET_SUB_CATEGORY, sub_category: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadSubCategoryRequest() {
  yield takeLatest(SubCategoryActions.LOAD_SUB_CATEGORY, performLoadSubCategory);
}

export function* performUpdateSubCategory({
  category_id,
  id,
  payload,
  onComplete
}: UpdateSubCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateSubCategoryRequest(
      category_id,
      id,
      payload
    );

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: SubCategoryActions.SET_SUB_CATEGORY, sub_category: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateSubCategoryRequest() {
  yield takeLatest(SubCategoryActions.UPDATE_SUB_CATEGORY, performUpdateSubCategory);
}

export function* performAddSubCategory({
  category_id,
  payload,
  navigate,
  onComplete
}: AddSubCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddSubCategoryRequest(
      category_id,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new sub-category has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/admin/categories');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddSubCategoryRequest() {
  yield takeLatest(SubCategoryActions.ADD_SUB_CATEGORY, performAddSubCategory);
}

export function* performRemoveSubCategory({ category_id, id, onComplete }: AddSubCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveSubCategoryRequest(category_id, id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The sub-category has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(SubCategoryActions.loadSubCategories(onComplete, category_id));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveSubCategoryRequest() {
  yield takeLatest(SubCategoryActions.REMOVE_SUB_CATEGORY, performRemoveSubCategory);
}

export default function* sub_categorySaga() {
  yield all([
    watchForLoadSubCategoriesRequest(),
    watchForLoadSubCategoryRequest(),
    watchForUpdateSubCategoryRequest(),
    watchForAddSubCategoryRequest(),
    watchForRemoveSubCategoryRequest()
  ]);
}
