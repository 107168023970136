import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { SubCategory } from '../interfaces/config/sub_category.interface';
import { SubCategoryActions } from '../actions/sub-category/sub-category.actions';

export interface SubCategoryState extends State {
  sub_categories: SubCategory[];
  sub_category?: SubCategory;
}

export const initialState: SubCategoryState = {
  sub_categories: []
};

export const subCategoryReducer = (state: SubCategoryState = initialState, action: AnyAction) => {
  if (action.type === SubCategoryActions.SET_SUB_CATEGORIES) {
    const { sub_categories } = action;
    return {
      ...state,
      sub_categories
    };
  } else if (action.type === SubCategoryActions.SET_SUB_CATEGORY) {
    const { sub_category } = action;
    return {
      ...state,
      sub_category
    };
  }

  return state;
};
