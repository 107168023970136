import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Category } from '../../../interfaces/config/category.interface';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

import './category-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Type',
    accessor: 'categoryType'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  },
  categoryType: {
    capitalize: true
  }
};

interface RowData extends TableData {
  name: string;
  description: string;
  status: string;
  categoryType: string;
}

interface Props {
  categories: Category[];
  loading: boolean;
}

const CategoryList = ({ categories, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return categories.map(
      (category: Category) =>
        ({
          name: category.name,
          description: category.description,
          status: category.status,
          actions: category.name === 'Other' ? <></> : <RemoveElement element={category} />,
          categoryType: category.category_type,
          onClick: () => navigate(`${category.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <article className="category-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No categories could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default CategoryList;
