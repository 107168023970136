import React, { ReactNode, useEffect, useState } from 'react';

import { Filter } from '../types/filter.interface';

import { isEmptyFilter } from '../tools/filter.util';

import { FilterContext, FilterType } from '../contexts/Filter.context';
import { ClientTypeActions } from '../actions/client-type/client-type.actions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { capitalise, noOp } from '@neslotech/utils';
import { ClientType } from '../interfaces/config/client-type.interface';
import { SectorActions } from '../actions/sector/sector.actions';
import { Sector } from '../interfaces/config/sector.interface';
import { Category } from '../interfaces/config/category.interface';
import { CategoryActions } from '../actions/category/category.actions';
import { OnChangeType } from '../components/select/Select';

interface Props {
  children: ReactNode | ReactNode[];
}

const MAPPINGS: { [key: string]: string } = {
  client_type_id: 'Client Type',
  sector_id: 'Sector',
  category_id: 'Category',
  status: 'Status',
  classification: 'Classification',
  document_type: 'Type',
  start_date: 'Start Date',
  end_date: 'End Date',
  erole: 'Employee Role'
};

const FilterProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const [filterDefaults, setFilterDefaults] = useState<Filter>();
  const [filters, setFilters] = useState<Filter>();
  const [filtering, setFiltering] = useState<boolean>(false);
  const [sort, setSort] = useState<string>('descending');

  const clientTypes: ClientType[] = useAppSelector(
    ({ client_type_store }) => client_type_store.clientTypes
  );
  const sectors: Sector[] = useAppSelector(({ sector_store }) => sector_store.sectors);
  const categories: Category[] = useAppSelector(({ category_store }) => category_store.categories);

  useEffect(() => {
    dispatch(ClientTypeActions.loadClientTypes(noOp));
    dispatch(SectorActions.loadSectors(noOp));
    dispatch(CategoryActions.loadCategories(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (newSort: OnChangeType) => setSort(newSort as string);

  const handleFilters = (filterValue: Filter) => {
    setFilters(filterValue);
    setFiltering(!isEmptyFilter(filterValue));
  };

  const handleFilterDefaults = (defaults: Filter) => {
    setFilters(defaults);
    setFilterDefaults(defaults);
  };

  const handleClearFilters = () => {
    setFilters(filterDefaults);
    setFiltering(false);
  };

  const getFilter = (key: string, value: unknown | string) => {
    let label = MAPPINGS[key];
    switch (key) {
      case 'client_type_id':
        label +=
          ': ' + clientTypes.find((clientType: ClientType) => clientType.id === Number(value)).name;
        break;
      case 'sector_id':
        label += ': ' + sectors.find((sector: Sector) => sector.id === Number(value)).name;
        break;
      case 'category_id':
        label += ': ' + categories.find((category: Category) => category.id === Number(value)).name;
        break;
      case 'end_date':
      case 'start_date':
        label += ': ' + value;
        break;
      default:
        label += ': ' + capitalise(value as string);
        break;
    }

    return label;
  };

  const value: FilterType = {
    filters,
    filtering,
    setFilterDefaults: handleFilterDefaults,
    setFilters: handleFilters,
    onClearFilters: handleClearFilters,
    getFilter,
    sort,
    setSort: handleSort
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterProvider;
