import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddIrp5Request,
  getIrp5Request,
  getIrp5sRequest,
  getRemoveIrp5Request,
  getUpdateIrp5Request,
  RequestOptions
} from '../tools/api';

import {
  AddIrp5Action,
  LoadIrp5Action,
  LoadIrp5sAction,
  UpdateIrp5Action
} from '../actions/irp5/irp5.types';

import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';
import { Irp5Actions } from '../actions/irp5/irp5.actions';

export function* performLoadIrp5s({ onComplete }: LoadIrp5sAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getIrp5sRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: Irp5Actions.SET_IRP5S, irp5s: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadIrp5sRequest() {
  yield takeLatest(Irp5Actions.LOAD_IRP5S, performLoadIrp5s);
}

export function* performLoadIrp5({ id, onComplete }: LoadIrp5Action) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getIrp5Request(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: Irp5Actions.SET_IRP5, irp5: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadIrp5Request() {
  yield takeLatest(Irp5Actions.LOAD_IRP5, performLoadIrp5);
}

export function* performUpdateIrp5({ id, payload, onComplete }: UpdateIrp5Action) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateIrp5Request(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The irp5 has been updated successfully!', SNACK_SUCCESS));

    yield put({ type: Irp5Actions.SET_IRP5, irp5: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateIrp5Request() {
  yield takeLatest(Irp5Actions.UPDATE_IRP5, performUpdateIrp5);
}

export function* performAddIrp5({ payload, navigate, onComplete }: AddIrp5Action) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddIrp5Request(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new irp5 has been created successfully!', SNACK_SUCCESS)
    );

    yield put(Irp5Actions.loadIrp5s(noOp));
    yield navigate('/human-resources/irp5s');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddIrp5Request() {
  yield takeLatest(Irp5Actions.ADD_IRP5, performAddIrp5);
}

export function* performRemoveIrp5({ id, onComplete }: AddIrp5Action) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveIrp5Request(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The irp5 has been removed successfully!', SNACK_SUCCESS));

    yield put(Irp5Actions.loadIrp5s(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveIrp5Request() {
  yield takeLatest(Irp5Actions.REMOVE_IRP5, performRemoveIrp5);
}

export default function* irp5Saga() {
  yield all([
    watchForLoadIrp5sRequest(),
    watchForLoadIrp5Request(),
    watchForUpdateIrp5Request(),
    watchForAddIrp5Request(),
    watchForRemoveIrp5Request()
  ]);
}
