import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Designation } from '../../interfaces/config/designation.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getDesignationsEndpoint = (): string => `${API_HOST}/designations`;
export const getDesignationsRequest = (): RequestOptions => [
  getDesignationsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddDesignationRequest = (payload: Designation): RequestOptions => [
  getDesignationsEndpoint(),
  getHttpPostOptions({ designation: payload }, getAuthHeaders())
];

const getDesignationEndpoint = (id: number | string): string => `${API_HOST}/designations/${id}`;
export const getDesignationRequest = (id: number | string): RequestOptions => [
  getDesignationEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateDesignationRequest = (
  id: number | string,
  payload: Designation
): RequestOptions => [
  getDesignationEndpoint(id),
  getHttpPutOptions({ designation: payload }, getAuthHeaders())
];

export const getRemoveDesignationRequest = (id: string | number): RequestOptions => [
  getDesignationEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
