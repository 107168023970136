import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Category } from '../../../interfaces/config/category.interface';

import { useAppSelector } from '../../../hooks';

const CategoryCrumb = () => {
  const category: Category = useAppSelector(
    ({ category_store }: AppState) => category_store.category
  ) as Category;

  return <NavLink to={`/admin/categories/${category?.id}`}>{category?.name}</NavLink>;
};

export default CategoryCrumb;
