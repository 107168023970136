import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddDesignationRequest,
  getRemoveDesignationRequest,
  getDesignationRequest,
  getDesignationsRequest,
  getUpdateDesignationRequest,
  RequestOptions
} from '../tools/api';

import {
  AddDesignationAction,
  LoadDesignationAction,
  LoadDesignationsAction,
  UpdateDesignationAction
} from '../actions/designation/designation.types';
import { DesignationActions } from '../actions/designation/designation.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadDesignations({ onComplete }: LoadDesignationsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getDesignationsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: DesignationActions.SET_DESIGNATIONS, designations: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadDesignationsRequest() {
  yield takeLatest(DesignationActions.LOAD_DESIGNATIONS, performLoadDesignations);
}

export function* performLoadDesignation({ id, onComplete }: LoadDesignationAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getDesignationRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: DesignationActions.SET_DESIGNATION, designation: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadDesignationRequest() {
  yield takeLatest(DesignationActions.LOAD_DESIGNATION, performLoadDesignation);
}

export function* performUpdateDesignation({ id, payload, onComplete }: UpdateDesignationAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateDesignationRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: DesignationActions.SET_DESIGNATION, designation: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateDesignationRequest() {
  yield takeLatest(DesignationActions.UPDATE_DESIGNATION, performUpdateDesignation);
}

export function* performAddDesignation({ payload, navigate, onComplete }: AddDesignationAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddDesignationRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new designation has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/admin/designations');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddDesignationRequest() {
  yield takeLatest(DesignationActions.ADD_DESIGNATION, performAddDesignation);
}

export function* performRemoveDesignation({ id, onComplete }: AddDesignationAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveDesignationRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The designation has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(DesignationActions.loadDesignations(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveDesignationRequest() {
  yield takeLatest(DesignationActions.REMOVE_DESIGNATION, performRemoveDesignation);
}

export default function* designationSaga() {
  yield all([
    watchForLoadDesignationsRequest(),
    watchForLoadDesignationRequest(),
    watchForUpdateDesignationRequest(),
    watchForAddDesignationRequest(),
    watchForRemoveDesignationRequest()
  ]);
}
