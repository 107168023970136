import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { capitalise } from '@neslotech/utils';

import { Contact } from '../../../../../../interfaces/client/contact.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import { ReactComponent as PencilIcon } from '../../../../../../icons/pencil-icon.svg';

import DetailsCard from '../../../../../../components/card/DetailsCard';
import InfoDetail from '../../../../../../components/clients/details/InfoDetail';
import { Modal } from '../../../../../../components/modal/Modal';

import AddContactContainer from '../add/AddContact.container';
import EditContactContainer from '../edit/EditContact.container';
import  Tabs, { TabItem } from '../../../../../../components/tab/Tabs';
import ContactHistories from '../../contact_histories/list/ContactHistories.container';
import RemovalProvider from '../../../../../../providers/Removal.provider';
import RemoveContactHistoryContainer from '../../contact_histories/remove/RemoveContactHistory.container';
import Button from '../../../../../../components/button/Button';
// import './contacts.scss';
import { ClientContactActions } from '../../../../../../actions/client/contact/contact.actions';
import { AppState } from '../../../../../../types/state.interface';
import PageLayout from '../../../../../../components/layout/page/PageLayout';

const ContactContainer = () => {
  const dispatch = useAppDispatch();
  const { id, contactId } = useParams<{ id: string, contactId: string }>();
  const contact: Contact = useAppSelector(({ client_store }: AppState) => client_store.contact);

  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const [mode, setMode] = useState<string>();

  useEffect(() => {
	dispatch(ClientContactActions.loadContact(id, contactId, onComplete));
  }, [id, contactId, dispatch]);


  const handleOpenModal = (mode: string) => {
    setMode(mode);
    setShowFormModal(true);
  };

  const noContact = useMemo(() => {
    return contact === null || contact === undefined;
  }, [contact]);

  const onComplete = () => {
  };

  const onSetPrimaryContact = (contact: Contact) => {
    dispatch(ClientContactActions.setPrimaryContact(contact.client_id, contact.id, onComplete));
  };

  const tabs: TabItem[] = [
    {
      id: 0,
      title: 'Contact Details',
      Component: (
        <>
          <DetailsCard
            title="Contact Details"
            centered
            action={
              <>
                {!noContact && <PencilIcon onClick={() => handleOpenModal('edit')} />}
              </>
            }
            fitted
          >
            {noContact && (
              <p className="details-card__empty">There are no contact details to display!</p>
            )}
            {!noContact && (
              <>
                <div className="primary_wrapper">
                  {contact.is_primary ? (
                    <div className="primary_label primary">Primary Contact</div>
                  ) : (
                    <>
                      <div className="primary_label">Secondary Contact</div>
                      <Button label="Set Primary" onClick={() => onSetPrimaryContact(contact)} />
                    </>
                  )}
                </div>

                <InfoDetail title="Key Contact" value={contact?.key_contact} />
                <InfoDetail title="Designation" value={contact?.designation} />
                <InfoDetail title="Email Address" value={contact?.email} />
                <InfoDetail title="Contact Number" value={contact?.contact_number} />
                <InfoDetail title="Classification" value={capitalise(contact?.classification)} />
                <InfoDetail title="Website" value={contact?.website} />
                <InfoDetail title="Office Address" value={contact?.address} />
                <InfoDetail title="Office City" value={contact?.city} />
                <InfoDetail title="Office Country" value={contact?.country} />
                <InfoDetail title="Office Continent" value={capitalise(contact?.continent)} />
              </>
            )}
          </DetailsCard>
          {showFormModal && (
            <Modal>
              {mode === 'add' && <AddContactContainer setShow={setShowFormModal} />}
              {mode === 'edit' && (
                <EditContactContainer canAddMandate={false} setShow={setShowFormModal} mode={mode} contact={contact} />
              )}
            </Modal>
          )}
        </>
      )
    },
    {
      id: 1,
      title: 'Contact History',
      Component: (
        <>
          <RemovalProvider>
            <ContactHistories  contact={contact} loading={false} />
            <RemoveContactHistoryContainer contactId={contactId} />
          </RemovalProvider>
        </>
      )
    }
  ];

  return (
    <PageLayout title={` Contact details - ${contact?.key_contact}`}>
      <Tabs tabs={tabs} />
    </PageLayout>
  );
};

export default ContactContainer;

