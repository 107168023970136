import React from 'react';
import { RouteObject } from 'react-router-dom';

import MandatesContainer from '../../containers/core/mandates/list/Mandates.container';
import AddMandateContainer from '../../containers/core/mandates/add/AddMandate.container';

import MandatesCrumb from '../../crumbs/core/mandates/Mandates.crumb';
import AddMandateCrumb from '../../crumbs/core/mandates/AddMandate.crumb';

import ProtectedRoute from '../ProtectedRoute';

const mandateRoutes: RouteObject[] = [
  {
    path: 'mandates',
    handle: {
      crumb: () => <MandatesCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <MandatesContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        handle: {
          crumb: () => <AddMandateCrumb />
        },
        element: (
          <ProtectedRoute>
            <AddMandateContainer />
          </ProtectedRoute>
        )
      }
    ]
  }
];

export default mandateRoutes;
