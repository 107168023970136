import React, { useEffect, useState } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import { ReactComponent as IconCheck } from '../../icons/check-icon.svg';

import './checkbox.scss';

export type NamedChangeType = { [key: string]: boolean };
export type OnChangeType = NamedChangeType | boolean;

interface Props {
  name: string;
  label?: string;
  checked: boolean;
  disabled?: boolean;
  success?: boolean;
  danger?: boolean;
  onChange?: (value: OnChangeType) => void;
}

const Checkbox = ({ label, name, checked, disabled, success, danger, onChange = noOp }: Props) => {
  const [isChecked, setChecked] = useState<boolean>(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const handleChange = () => {
    if (disabled) {
      return;
    }

    setChecked(!isChecked);

    const val = getChangeValue(!isChecked, name);
    onChange(val);
  };

  return (
    <label className={getClassNames('checkbox', { success, danger, disabled })}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        className="checkbox__native"
        onChange={handleChange}
        disabled={disabled}
      />
      <div className={getClassNames('checkbox__indicator', { checked: isChecked })}>
        {isChecked && <IconCheck />}
      </div>
      {label && <p className="checkbox__label">{label}</p>}
    </label>
  );
};

const getChangeValue = (value: boolean, name?: string): OnChangeType => {
  return name ? { [name]: value } : value;
};

export default Checkbox;
