import React, { useEffect, useState } from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch, useConvert } from '../../../../hooks';

import { User } from '../../../../interfaces/user/user.interface';

import { LoadUsersAction } from '../../../../actions/user/user.types';
import { UserActions } from '../../../../actions/user/user.actions';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import ConvertModal from '../../../../components/modal/convert/ConvertModal';

const ConvertUserContainer = () => {
  const dispatch: Dispatch<LoadUsersAction> = useAppDispatch();
  const { element, showCModal, setShowCModal } = useConvert();

  const onClose = () => setShowCModal(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(true);
  const handleConfirm = (onComplete: () => void): void => {
    const payload = element as User;
    payload.is_employee = true;
    dispatch(
      UserActions.updateUser((element as User).id as number, payload, () => {
        onComplete();
        onClose();
      })
    );
    setChecked(false);
  };
  useEffect(() => {
    dispatch(UserActions.loadUsers(() => setLoading(false)));
    setChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <>
      {showCModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Convert User">
            <ConvertModal
              title={(element as User).full_name}
              loading={loading}
              onConfirm={handleConfirm}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default ConvertUserContainer;
