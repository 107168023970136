import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Document } from '../../../interfaces/document/document.interface';

import { useAuth, useRemoval } from '../../../hooks';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';

interface Props {
  document: Document;
}

const DocumentCard = ({ document }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const { setElement, setShowModal } = useRemoval();
  const { isAdmin } = useAuth();

  const actions = useMemo(() => {
    const handleRemove = () => {
      setShowModal(true);
      setElement(document);
    };

    return (
      <>
        <EditIcon onClick={() => navigate(`/admin/documents/${document.id}`)} />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <>
      {isAdmin ? (
        <DetailsCard action={actions} small>
          <article className="document-card">
            <InfoDetail title="Document Name" value={document.name} />
            <InfoDetail title="Type" value={document.document_type} />
            <InfoDetail title="Category" value={document.category} />
            <InfoDetail title="SubCategory" value={document.sub_category} />
            <InfoDetail title="Date" value={document.created_at} />
            <InfoDetail title="Uploaded By" value={document.user} />
          </article>
        </DetailsCard>
      ) : (
        <DetailsCard small>
          <article className="document-card">
            <InfoDetail title="Document Name" value={document.name} />
            <InfoDetail title="Type" value={document.document_type} />
            <InfoDetail title="Category" value={document.category} />
            <InfoDetail title="SubCategory" value={document.sub_category} />
            <InfoDetail title="Date" value={document.created_at} />
            <InfoDetail title="Uploaded By" value={document.user} />
          </article>
        </DetailsCard>
      )}
    </>
  );
};

export default DocumentCard;
