import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { capitalise } from '@neslotech/utils';

import { Client } from '../../../interfaces/client/client.interface';

import { ReactComponent as PencilIcon } from '../../../icons/pencil-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import Avatar from '../../avatar/Avatar';
import { ButtonGroup } from '../../button-group/ButtonGroup';
import Button from '../../button/Button';

import './client-card.scss';

interface Props {
  client: Client;
}

const ClientCard = ({ client }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <DetailsCard fitted>
      <article className="client-card">
        <header>
          <Avatar
            filled
            name={client.name}
            subtitle={capitalise(client.client_type)}
            image={client.image}
          />
          <PencilIcon onClick={() => navigate(`${client.id}`)} />
        </header>
        <main>
          <ButtonGroup>
            <Button hollow label="Mandates" onClick={() => navigate(`${client.id}/mandates`)} />
            <Button hollow label="Precedents" onClick={() => navigate(`${client.id}/precedents`)} />
            <Button hollow label="Tombstones" onClick={() => navigate(`${client.id}/tombstones`)} />
            <Button hollow label="Details" onClick={() => navigate(`${client.id}`)} />
            <Button
              hollow
              label="Deal Sheets"
              onClick={() => navigate(`${client.id}/deal-sheets`)}
            />
          </ButtonGroup>
        </main>
      </article>
    </DetailsCard>
  );
};

export default ClientCard;
