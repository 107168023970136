import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddSectorRequest,
  getRemoveSectorRequest,
  getSectorRequest,
  getSectorsRequest,
  getUpdateSectorRequest,
  RequestOptions
} from '../tools/api';

import {
  AddSectorAction,
  LoadSectorAction,
  LoadSectorsAction,
  UpdateSectorAction
} from '../actions/sector/sector.types';
import { SectorActions } from '../actions/sector/sector.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadSectors({ onComplete }: LoadSectorsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getSectorsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: SectorActions.SET_SECTORS, sectors: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadSectorsRequest() {
  yield takeLatest(SectorActions.LOAD_SECTORS, performLoadSectors);
}

export function* performLoadSector({ id, onComplete }: LoadSectorAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getSectorRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: SectorActions.SET_SECTOR, sector: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadSectorRequest() {
  yield takeLatest(SectorActions.LOAD_SECTOR, performLoadSector);
}

export function* performUpdateSector({ id, payload, onComplete }: UpdateSectorAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateSectorRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: SectorActions.SET_SECTOR, sector: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateSectorRequest() {
  yield takeLatest(SectorActions.UPDATE_SECTOR, performUpdateSector);
}

export function* performAddSector({ payload, navigate, onComplete }: AddSectorAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddSectorRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new sector has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/admin/sectors');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddSectorRequest() {
  yield takeLatest(SectorActions.ADD_SECTOR, performAddSector);
}

export function* performRemoveSector({ id, onComplete }: AddSectorAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveSectorRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The sector has been removed successfully!', SNACK_SUCCESS));

    yield put(SectorActions.loadSectors(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveSectorRequest() {
  yield takeLatest(SectorActions.REMOVE_SECTOR, performRemoveSector);
}

export default function* sectorSaga() {
  yield all([
    watchForLoadSectorsRequest(),
    watchForLoadSectorRequest(),
    watchForUpdateSectorRequest(),
    watchForAddSectorRequest(),
    watchForRemoveSectorRequest()
  ]);
}
