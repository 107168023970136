import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { RemoveUserAction } from '../../../../../../actions/user/user.types';

import { useAppDispatch, useRemoval } from '../../../../../../hooks';

import { Modal } from '../../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../../../components/modal/remove/RemoveModal';
import { ClientContactActions } from '../../../../../../actions/client/contact/contact.actions';
import { ContactHistory } from '../../../../../../interfaces/client/contact.interface';
import { useClient } from '../../../../../../hooks/clients';

interface Props {
  contactId: string | number;
}

const RemoveContactHistoryContainer = ({ contactId }: Props) => {
  const { client } = useClient();
  const clientId = client.id;

  const dispatch: Dispatch<RemoveUserAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      ClientContactActions.removeContactHistory(
        clientId,
        contactId,
        (element as ContactHistory).id as number,
        () => {
          onComplete();
          onClose();
        }
      )
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Contact History">
            <RemoveModal title="Contact History" onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveContactHistoryContainer;
