import { Precedent } from '../../../interfaces/client/precedent.interface';

import {
  AddPrecedentAction,
  LoadPrecedentAction,
  LoadPrecedentsAction,
  OnComplete,
  RemovePrecedentAction,
  UpdatePrecedentAction
} from './precedent.types';

export class PrecedentActions {
  static readonly LOAD_PRECEDENTS: string = '[CLIENT_STORE] Load precedents';
  static readonly SET_PRECEDENTS: string = '[CLIENT_STORE] Set precedents';

  static readonly LOAD_PRECEDENT: string = '[CLIENT_STORE] Load precedent';
  static readonly SET_PRECEDENT: string = '[CLIENT_STORE] Set precedent';

  static readonly ADD_PRECEDENT: string = '[CLIENT_STORE] Add precedent';
  static readonly UPDATE_PRECEDENT: string = '[CLIENT_STORE] Update precedent';
  static readonly REMOVE_PRECEDENT: string = '[CLIENT_STORE] Remove precedent';

  static loadPrecedents = (id: number | string, onComplete: OnComplete): LoadPrecedentsAction => ({
    type: PrecedentActions.LOAD_PRECEDENTS,
    id,
    onComplete
  });

  static loadPrecedent = (
    id: number | string,
    precedentId: number | string,
    onComplete: OnComplete
  ): LoadPrecedentAction => ({
    type: PrecedentActions.LOAD_PRECEDENT,
    id,
    precedentId,
    onComplete
  });

  static addPrecedent = (
    id: number | string,
    payload: Precedent,
    onComplete: OnComplete
  ): AddPrecedentAction => ({
    type: PrecedentActions.ADD_PRECEDENT,
    id,
    payload,
    onComplete
  });

  static updatePrecedent = (
    id: number | string,
    precedentId: number | string,
    payload: Precedent,
    onComplete: OnComplete
  ): UpdatePrecedentAction => ({
    type: PrecedentActions.UPDATE_PRECEDENT,
    id,
    precedentId,
    payload,
    onComplete
  });

  static removePrecedent = (
    id: number | string,
    precedentId: number | string,
    onComplete: OnComplete
  ): RemovePrecedentAction => ({
    type: PrecedentActions.REMOVE_PRECEDENT,
    id,
    precedentId,
    onComplete
  });
}
