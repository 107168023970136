import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Precedent } from '../../../interfaces/client/precedent.interface';

import { useRemoval } from '../../../hooks';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';

interface Props {
  precedent: Precedent;
}

const PrecedentCard = ({ precedent }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const { setElement, setShowModal } = useRemoval();

  const actions = useMemo(() => {
    const handleRemove = () => {
      setShowModal(true);
      setElement(precedent);
    };

    return (
      <>
        <EditIcon
          onClick={() =>
            navigate(`/core/clients/${precedent.client_id}/precedents/${precedent.id}`)
          }
        />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precedent]);

  return (
    <DetailsCard action={actions} small>
      <article className="precedent-card">
        <InfoDetail title="Client Name" value={precedent.client} />
        <InfoDetail title="Category" value={precedent.category} />
        <InfoDetail title="Sub Category" value={precedent.sub_category} />
        <InfoDetail title="Date of Presentation" value={precedent.presentation_date} />
      </article>
    </DetailsCard>
  );
};

export default PrecedentCard;
