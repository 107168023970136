import React, { createRef, ReactNode, useMemo } from 'react';

import { capitalise } from '@neslotech/utils';

import { toBase64 } from '../../../tools/file.util';

import { ReactComponent as CameraIcon } from '../../../icons/camera-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../icons/profile-icon.svg';

import { FileUpload } from '../../upload/FileUpload';
import { OnChangeType } from '../../input/Input';

import './avatar-layout.scss';

interface Props {
  title: string;
  subtitle?: string;
  image?: string;
  handleChange: (value: OnChangeType) => void;
  children: ReactNode | ReactNode[];
}

const AvatarLayout = ({ title, subtitle, image, handleChange, children }: Props) => {
  const fileRef = createRef<HTMLInputElement>();

  const handleImageChange = async (file: File) => {
    const image = await toBase64(file);
    handleChange({ image });
  };

  const Image = useMemo(() => {
    return image ? <img alt={title} src={image} /> : <ProfileIcon />;
  }, [image, title]);

  return (
    <article className="avatar-layout">
      <section className="avatar-layout__form">
        <section className="avatar-layout__form-action">
          {Image}
          <FileUpload fileRef={fileRef} onChange={handleImageChange}>
            <CameraIcon onClick={() => fileRef.current?.click()} />
          </FileUpload>
        </section>
        {title && <p>{title}</p>}
        {subtitle && <small>{capitalise(subtitle)}</small>}
      </section>
      <section className="avatar-layout__content">{children}</section>
    </article>
  );
};

export default AvatarLayout;
