import React, { ChangeEvent, LegacyRef, ReactNode } from 'react';

import { noOp } from '@neslotech/utils';

import './file-upload.scss';

interface Props {
  fileTypes?: string;
  fileRef: LegacyRef<HTMLInputElement>;
  children: ReactNode | ReactNode[];
  onChange: (files: FileList) => void;
}

export const MultiFileUpload = ({ fileTypes, fileRef, children, onChange }: Props) => {
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const selectedFiles: FileList = event.target.files;
    onChange(selectedFiles);
  };

  return (
    <article className="file-upload">
      {children}
      <input accept={fileTypes} multiple type="file" hidden ref={fileRef} onChange={onFileChange} />
    </article>
  );
};

MultiFileUpload.defaultProps = {
  onChange: noOp,
  fileTypes: 'image/*'
};
