import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';
import { Results } from '../interfaces/search/results.interface';

import { SearchActions } from '../actions/search/search.actions';

export interface SearchState extends State {
  results?: Results;
}

export const initialState: SearchState = {};

export const searchReducer = (state = initialState, action: AnyAction) => {
  if (SearchActions.SET_RESULTS === action.type) {
    const { results } = action;
    return {
      ...state,
      results
    };
  }

  return state;
};
