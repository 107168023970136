import React from 'react';
import { RouteObject } from 'react-router-dom';

import MyProfileCrumb from '../../crumbs/profile/MyProfile.crumb';
import ProfileCrumb from '../../crumbs/profile/Profile.crumb';
import PasswordCrumb from '../../crumbs/profile/password/Password.crumb';

import ProfileContainer from '../../containers/profile/Profile.container';
import ProfilePasswordContainer from '../../containers/profile/password/ProfilePassword.container';

import ProtectedRoute from '../ProtectedRoute';

const profileRoutes: RouteObject[] = [
  {
    path: '/profile',
    handle: {
      crumb: () => <MyProfileCrumb />
    },
    children: [
      {
        path: '',
        handle: {
          crumb: () => <ProfileCrumb />
        },
        children: [
          {
            path: 'details',
            index: true,
            element: (
              <ProtectedRoute>
                <ProfileContainer />
              </ProtectedRoute>
            )
          },
          {
            path: 'password',
            handle: {
              crumb: () => <PasswordCrumb />
            },
            element: (
              <ProtectedRoute>
                <ProfilePasswordContainer />
              </ProtectedRoute>
            )
          }
        ]
      }
    ]
  }
];

export default profileRoutes;
