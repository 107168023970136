import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';
import { Precedent } from '../interfaces/client/precedent.interface';

import { PrecedentActions } from '../actions/precedent/precedent.actions';

export interface PrecedentState extends State {
  precedents: Precedent[];
  precedent?: Precedent;
}

export const initialState: PrecedentState = {
  precedents: []
};

export const precedentReducer = (state: PrecedentState = initialState, action: AnyAction) => {
  switch (action.type) {
    case PrecedentActions.SET_PRECEDENTS:
      const { precedents } = action;
      return {
        ...state,
        precedents
      };
    case PrecedentActions.SET_PRECEDENT:
      const { precedent } = action;
      return {
        ...state,
        precedent
      };
    default:
      return state;
  }
};
