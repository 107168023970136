import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Timesheet } from '../../../interfaces/employee/timesheet.interface';
import { Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

import './timesheet-list.scss';

interface Props {
  timesheets: Timesheet[];
  loading: boolean;
}

const headers: TableHeader[] = [
  // {
  //   Header: 'Index',
  //   accessor: 'index'
  // },
  {
    Header: 'Employee',
    accessor: 'employee'
  },
  {
    Header: 'Client',
    accessor: 'client'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Billable Hours',
    accessor: 'billable_hours'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  index: number | string;
  employee: string;
  client: string;
  category: string;
  date: string;
  billable_hours: number | string;
}

const TimesheetList = ({ timesheets, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return (timesheets ?? []).map(
      (timesheet: Timesheet) =>
        ({
          index: timesheet.id,
          employee: timesheet.employee,
          client: timesheet.client,
          category: timesheet.category,
          date: timesheet.date,
          billable_hours: timesheet.billable_hours,
          actions: <RemoveElement element={timesheet} />,
          onClick: () => navigate(`/human-resources/timesheets/${timesheet.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheets]);

  return (
    <article className="timesheet-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No timesheets could be found!"
        rowData={rowData}
      />
    </article>
  );
};

export default TimesheetList;
