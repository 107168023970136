import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';
import { Tombstone } from '../interfaces/client/tombstone.interface';

import { TombstoneActions } from '../actions/tombstone/tombstone.actions';

export interface TombstoneState extends State {
  tombstones: Tombstone[];
  tombstone?: Tombstone;
}

export const initialState: TombstoneState = {
  tombstones: []
};

export const tombstoneReducer = (state: TombstoneState = initialState, action: AnyAction) => {
  switch (action.type) {
    case TombstoneActions.SET_TOMBSTONES:
      const { tombstones } = action;
      return {
        ...state,
        tombstones
      };
    case TombstoneActions.SET_TOMBSTONE:
      const { tombstone } = action;
      return {
        ...state,
        tombstone
      };
    default:
      return state;
  }
};
