import { NavigateFunction } from 'react-router-dom';

import { Credentials } from '../../interfaces/auth/credentials.interface';
import { ConfirmationRequest } from '../../interfaces/auth/confirmation-request.interface';
import { RegisterRequest } from '../../interfaces/auth/register-request.interface';
import { ForgotPasswordRequest } from '../../interfaces/auth/forgot-password-request.interface';
import { ResetPasswordRequest } from '../../interfaces/auth/reset-password-request.interface';

import {
  AuthenticateAction,
  ForgotPasswordAction,
  OnComplete,
  OnSuccess,
  RegisterAction,
  ResetPasswordAction,
  VerifyAccountAction,
  ConfirmAccountAction
} from './auth.types';

export class AuthActions {
  static readonly AUTHENTICATE: string = '[AUTH_STORE] Authenticate User';
  static readonly REGISTER: string = '[AUTH_STORE] Register User';
  static readonly FORGOT_PASSWORD: string = '[AUTH_STORE] Forgot password';
  static readonly RESET_PASSWORD: string = '[AUTH_STORE] Reset password';
  static readonly VERIFY_ACCOUNT: string = '[AUTH_STORE] Verify user account';
  static readonly CONFIRM_ACCOUNT: string = '[AUTH_STORE] Confirm user account';

  static authenticate = (
    request: Credentials,
    navigate: NavigateFunction,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): AuthenticateAction => ({
    type: AuthActions.AUTHENTICATE,
    request,
    navigate,
    onSuccess,
    onComplete
  });

  static register = (request: RegisterRequest, navigate: NavigateFunction): RegisterAction => ({
    type: AuthActions.REGISTER,
    request,
    navigate
  });

  static forgotPassword = (
    request: ForgotPasswordRequest,
    navigate?: NavigateFunction
  ): ForgotPasswordAction => ({
    type: AuthActions.FORGOT_PASSWORD,
    request,
    navigate
  });

  static resetPassword = (
    id: string,
    token: string,
    request: ResetPasswordRequest,
    navigate: NavigateFunction
  ): ResetPasswordAction => ({
    type: AuthActions.RESET_PASSWORD,
    id,
    token,
    request,
    navigate
  });

  static verifyAccount = (
    id: string,
    token: string,
    navigate: NavigateFunction
  ): VerifyAccountAction => ({
    type: AuthActions.VERIFY_ACCOUNT,
    id,
    token,
    navigate
  });

  static confirmAccount = (
    request: ConfirmationRequest,
    navigate: NavigateFunction
  ): ConfirmAccountAction => ({
    type: AuthActions.CONFIRM_ACCOUNT,
    request,
    navigate
  });
}
