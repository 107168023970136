import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { statusItems } from '../../../tools/dropdown.util';

import { SubCategory } from '../../../interfaces/config/sub_category.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';

import './sub-category-form.scss';

type OnSave = (payload: SubCategory, onComplete: () => void) => void;
type Status = 'active' | 'inactive';

interface Props {
  sub_category?: SubCategory;
  onSave: OnSave;
}

export interface SubCategoryState extends StateForm {
  name: string;
  status: Status;
}

const rules: ValidationRules<SubCategoryState> = {
  validates: {
    name: ['isPresent'],
    status: ['isPresent']
  }
};

const formify = (sub_category?: SubCategory): SubCategoryState =>
  ({
    name: sub_category?.name,
    status: sub_category?.status
  } as SubCategoryState);

const serverify = (form: SubCategoryState) => ({
  name: form.name,
  status: form.status
});

const SubCategoryForm = ({ sub_category, onSave }: Props) => {
  const [form, setForm] = useFormState<SubCategoryState>(formify(sub_category), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as SubCategoryState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: SubCategory = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="sub-category-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Sub Category Name"
              placeholder="Sub Category Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="status"
              label="Status"
              placeholder="Select a status"
              items={statusItems}
              value={form?.status}
              error={form?.messages.status}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default SubCategoryForm;
