import { DealSheet } from '../../../interfaces/client/deal-sheet.interface';

import {
  AddDealSheetAction,
  LoadDealSheetAction,
  LoadDealSheetsAction,
  OnComplete,
  OnSuccess,
  RemoveDealSheetAction,
  UpdateDealSheetAction
} from './deal-sheet.types';

export class ClientDealSheetActions {
  static readonly LOAD_DEAL_SHEETS: string = '[CLIENT_STORE] Load deal sheets';
  static readonly SET_DEAL_SHEETS: string = '[CLIENT_STORE] Set deal sheets';

  static readonly LOAD_DEAL_SHEET: string = '[CLIENT_STORE] Load deal sheet';
  static readonly SET_DEAL_SHEET: string = '[CLIENT_STORE] Set deal sheet';

  static readonly UPDATE_DEAL_SHEET: string = '[CLIENT_STORE] Update deal sheet';
  static readonly ADD_DEAL_SHEET: string = '[CLIENT_STORE] Add deal sheet';
  static readonly REMOVE_DEAL_SHEET: string = '[CLIENT_STORE] Remove deal sheet';

  static loadDealSheets = (
    clientId: number | string,
    onComplete: OnComplete
  ): LoadDealSheetsAction => ({
    type: ClientDealSheetActions.LOAD_DEAL_SHEETS,
    clientId,
    onComplete
  });

  static loadDealSheet = (
    clientId: number | string,
    id: number | string,
    onComplete: OnComplete
  ): LoadDealSheetAction => ({
    type: ClientDealSheetActions.LOAD_DEAL_SHEET,
    clientId,
    id,
    onComplete
  });

  static addDealSheet = (
    clientId: number | string,
    payload: DealSheet,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): AddDealSheetAction => ({
    type: ClientDealSheetActions.ADD_DEAL_SHEET,
    clientId,
    payload,
    onSuccess,
    onComplete
  });

  static updateDealSheet = (
    clientId: number | string,
    id: number | string,
    payload: DealSheet,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): UpdateDealSheetAction => ({
    type: ClientDealSheetActions.UPDATE_DEAL_SHEET,
    clientId,
    id,
    payload,
    onSuccess,
    onComplete
  });

  static removeDealSheet = (
    clientId: number | string,
    id: number | string,
    onComplete: OnComplete
  ): RemoveDealSheetAction => ({
    type: ClientDealSheetActions.REMOVE_DEAL_SHEET,
    clientId,
    id,
    onComplete
  });
}
