import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getSearchRequest, RequestOptions } from '../tools/api';

import { SystemActions } from '../actions/system/system.actions';
import { ClearResultsAction, SearchAction } from '../actions/search/search.types';
import { SearchActions } from '../actions/search/search.actions';

export function* performSearch({ term }: SearchAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getSearchRequest(term);

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: SearchActions.SET_RESULTS, results: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForSearchRequest() {
  yield takeLatest(SearchActions.SEARCH, performSearch);
}

export function* performClearResults({ onComplete }: ClearResultsAction) {
  yield put({ type: SearchActions.SET_RESULTS, results: undefined });
  yield call(onComplete);
}

export function* watchForClearResultsRequest() {
  yield takeLatest(SearchActions.CLEAR_RESULTS, performClearResults);
}

export default function* searchSaga() {
  yield all([watchForSearchRequest(), watchForClearResultsRequest()]);
}
