import React from 'react';

import { noOp } from '@neslotech/utils';

import { ApiElement } from '../types/api-element.interface';

export interface RemovalProps {
  element?: ApiElement;
  setElement: (element: ApiElement) => void;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export const RemovalContext = React.createContext<RemovalProps>({
  setElement: noOp,
  showModal: false,
  setShowModal: noOp
});
