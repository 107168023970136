/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './leave_calendar.scss';
import moment from 'moment';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../select/Select';
import { OnChangeType } from '../../input/Input';
import { Form as StateForm } from '@neslotech/hooks';

export interface DateState extends StateForm {
  date: string | Date;
  state: string;
}

interface Props {
  calendarDate: Date;
  startDate: string | Date;
  endDate: string | Date;
  setCalendarDate: (date: Date) => void;
  dateStates: DateState[];
  setDateStates: (dateStates: DateState[]) => void;
  totalDays: number;
  setTotalDays: (totalDays: number) => void;
}

export const statusItems: SelectItem[] = [
  {
    label: 'Full Day',
    value: 'full'
  },
  {
    label: 'Half Day',
    value: 'half'
  }
];

const LeaveCalendar = ({
  calendarDate,
  setCalendarDate,
  startDate,
  endDate,
  dateStates,
  setDateStates,
  totalDays,
  setTotalDays
}: Props) => {
  const [dateState, setDateState] = useState<string>('full');
  const [selDate, setSelDate] = useState<Date>(null);

  const handlePreviousMonth = () => {
    setCalendarDate(new Date(calendarDate.getFullYear(), calendarDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCalendarDate(new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1));
  };

  const monthOptions: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfWeek = (year: number, month: number): number => {
    const firstDay = new Date(year, month, 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1; // Adjust Sunday (0) to 6 and shift other days back by one
  };

  // TODO: Use API/library to get the holidays
  const SAHolidays: Date[] = [
    new Date(2024, 0, 1), // 1 January: New Year’s Day
    new Date(2024, 2, 21), // 21 March: Human Rights Day
    new Date(2024, 2, 29), // 29 March: Good Friday *
    new Date(2024, 3, 1), // 1 April: Family Day
    new Date(2024, 3, 27), // 27 April: Freedom Day
    new Date(2024, 4, 1), // 1 May: Workers' Day
    new Date(2024, 4, 29), // 29 May: Public holiday (General Elections)
    new Date(2024, 5, 16), // 16 June: Youth Day
    new Date(2024, 5, 17), // 17 June: Public holiday Youth Day observed **
    new Date(2024, 7, 9), // 9 August: National Women’s Day
    new Date(2024, 8, 24), // 24 September: Heritage Day
    new Date(2024, 11, 16), // 16 December: Day of Reconciliation
    new Date(2024, 11, 25), // 25 December: Christmas Day
    new Date(2024, 11, 26) // 26 December: Day of Goodwill
  ];

  const onDateRangeClick = (date: Date) => {
    setSelDate(date);

    if (dateStates === null) {
      return;
    }

    const filteredDate = dateStates.filter(
      (item) => new Date(item?.date).toDateString() === date?.toDateString()
    );
    const dateState = filteredDate.length > 0 ? filteredDate[0]?.state : 'full';
    setDateState(dateState);
  };

  useEffect(() => {
    /* eslint-disable */
    let date = new Date(startDate);
    let total = 0;

    while (date <= new Date(endDate)) {
      const isWeekEnd = date.getDay() > 5 || date.getDay() === 0;
      const isHoliday = SAHolidays.some(holiday => 
        holiday.getDate() === date.getDate() &&
        holiday.getMonth() === date.getMonth() &&
        holiday.getFullYear() === date.getFullYear()
      );
      if (dateStates !== null) {
        const filteredDate = dateStates.filter(
          (item) => new Date(item?.date).toDateString() === date?.toDateString()
        );
        const dateState = filteredDate.length > 0 ? filteredDate[0]?.state : 'full';
        total += isWeekEnd || isHoliday ? 0 : dateState === 'half' ? 0.5 : 1;
      } else {
        total += isWeekEnd || isHoliday ? 0 : 1;
      }

      var newDate = date.setDate(date.getDate() + 1);
      date = new Date(newDate);
    }
    setTotalDays(total);
  }, [startDate, endDate, dateStates]);

  const onChangeDateState = (newState: OnChangeType | SelectChangeType) => {
    const tempState = newState as DateState;
    let isExisting = false;
    const tempDateStates = dateStates
      ? dateStates.map((item) => {
          if (new Date(item?.date).toDateString() === new Date(selDate)?.toDateString()) {
            isExisting = true;
            return { ...item, ...tempState };
          }
          return item;
        })
      : [];

    if (isExisting === false) {
      tempDateStates.push({
        date: moment(selDate).format('YYYY-MM-DD'),
        state: tempState.state
      });
    }

    setDateStates(tempDateStates);
    setDateState(tempState.state);
  };

  const renderCalendar = (): JSX.Element[] => {
    const month = calendarDate.getMonth();
    const year = calendarDate.getFullYear();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfWeek = getFirstDayOfWeek(year, month);

    const calendar: JSX.Element[] = [];
    let dayCount = 1;

    // Iterate for each row (week) in the calendar
    for (let week = 0; dayCount <= daysInMonth; week++) {
      const days: JSX.Element[] = [];

      // Fill in the days of the week for the current row
      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        if ((week === 0 && dayOfWeek < firstDayOfWeek) || dayCount > daysInMonth) {
          // Render empty cells for days before the first day of the month or after the last day of the month
          days.push(<td key={`empty-${week}-${dayOfWeek}`} className="empty-cell" />);
        } else {
          // Render cells for the days of the month
          const currentDate = new Date(year, month, dayCount);
          const isCurrentDate = currentDate.toDateString() === new Date().toDateString();

          const isDateRange =
            dayOfWeek < 5 &&
            moment(new Date(startDate)).format('YYYY-MM-DD') <=
              moment(new Date(currentDate)).format('YYYY-MM-DD') &&
            moment(new Date(currentDate)).format('YYYY-MM-DD') <=
              moment(new Date(endDate)).format('YYYY-MM-DD');
          const isHalfDay =
            dateStates &&
            dateStates.filter(
              (dateState) => new Date(dateState?.date).toDateString() === currentDate.toDateString()
            )[0]?.state === 'half';

          // Check if the current date matches any event dates
          const isHoliday = SAHolidays.some(holiday => 
            holiday.getDate() === currentDate.getDate() &&
            holiday.getMonth() === currentDate.getMonth() &&
            holiday.getFullYear() === currentDate.getFullYear()
          );

          days.push(
            <td
              key={`current-${week}-${dayOfWeek}`}
              className={`
                ${isCurrentDate ? 'current-date' : ''}
                ${isDateRange && !isHoliday ? (isHalfDay ? 'half-day' : 'full-day') : ''}
              `}
              onClick={() => (isDateRange && !isHoliday ? onDateRangeClick(currentDate) : null)}
            >
              {dayCount}
            </td>
          );
          dayCount++;
        }
      }

      // Add the row to the calendar
      calendar.push(
        <tr key={`week-${week}`} className="pad">
          {days}
        </tr>
      );
    }

    return calendar;
  };

  return (
    <div className="calen">
      <div className="calendar-header">
        <div>
          <span>{`${monthOptions[calendarDate.getMonth()]} ${calendarDate.getFullYear()}`}</span>
        </div>
        <div>
          <button onClick={handlePreviousMonth}>&#60;</button>
          <button onClick={handleNextMonth}>&#62;</button>
        </div>
      </div>
      <div className="calen-desc">
        Please select a day if you wish to change it to a half day and select 1/2 day from the
        dropdown.
      </div>
      <div style={{ display: 'flex' }}>
        <table className="calendar-table">
          <thead>
            <tr>
              <th>Mo</th>
              <th>Tu</th>
              <th>We</th>
              <th>Th</th>
              <th>Fr</th>
              <th>Sa</th>
              <th>Su</th>
            </tr>
          </thead>
          <tbody>{renderCalendar()}</tbody>
        </table>

        <div className="date-info">
          <div className="total-days">Total Days: {totalDays}</div>

          {selDate !== null ? (
            <>
              <div className="selected-date">{moment(selDate).format('DD/MM/YYYY')}</div>

              <Select
                name="state"
                placeholder="Select Day"
                items={statusItems}
                value={dateState}
                onChange={onChangeDateState}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LeaveCalendar;
