import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Tombstone } from '../../../../interfaces/client/tombstone.interface';
import { User } from '../../../../interfaces/user/user.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { Client } from '../../../../interfaces/client/client.interface';

import { AddTombstoneAction } from '../../../../actions/client/tombstone/tombstone.types';
import { LoadTombstonesAction } from '../../../../actions/tombstone/tombstone.types';
import { TombstoneActions } from '../../../../actions/client/tombstone/tombstone.actions';
import { UserActions } from '../../../../actions/user/user.actions';
import { CategoryActions } from '../../../../actions/category/category.actions';
import { ClientActions } from '../../../../actions/client/client.actions';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import TombstoneForm from '../../../../components/clients/details/tombstones/TombstoneForm';

const AddTombstoneContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddTombstoneAction | LoadTombstonesAction> = useAppDispatch();

  const clients: Client[] = useAppSelector(({ client_store }: AppState) => client_store.clients);
  const users: User[] = useAppSelector(({ user_store }: AppState) => user_store.users);
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(UserActions.loadUsers(noOp));
    dispatch(ClientActions.loadClients(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCompleteAddTombstone = (data: any): any => {
    navigate(`/core/clients/${data.client_id}/tombstones/${data.id}`);
  };

  const onSave = (payload: Tombstone): void => {
    dispatch(TombstoneActions.addTombstone(payload.client_id, payload, onCompleteAddTombstone));
  };

  return (
    <PageLayout title="Add Tombstone">
      {loading && <Loader filled />}
      {!loading && (
        <TombstoneForm clients={clients} categories={categories} users={users} onSave={onSave} />
      )}
    </PageLayout>
  );
};

export default AddTombstoneContainer;
