import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Fund } from '../../../../interfaces/fund/fund.interface';

import { LoadFundsAction } from '../../../../actions/fund/fund.types';
import { FundActions } from '../../../../actions/fund/fund.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import FundForm from '../../../../components/funds/form/FundForm';
import Button from '../../../../components/button/Button';
import Tabs, { TabItem } from '../../../../components/tab/Tabs';
import FundDocumentsContainer from '../documents/FundDocuments.container';
import FundContactForm from '../../../../components/funds/form/FundContactForm';
import { ContactActions } from '../../../../actions/contact/contact.actions';

const FundContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadFundsAction> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const fund: Fund = useAppSelector(({ fund_store }: AppState) => fund_store.fund) as Fund;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Fund, onComplete: () => void): void => {
    dispatch(FundActions.updateFund(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(FundActions.loadFund(id as string, () => setLoading(false)));
    dispatch(ContactActions.loadContacts(true, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && fund?.id === Number(id);
  }, [id, loading, fund?.id]);

  const tabs: TabItem[] = [
    {
      id: 0,
      title: 'Basic Info',
      Component: <FundForm fund={fund} onSave={onSave} />
    },
    {
      id: 1,
      title: 'Documents',
      Component: <FundDocumentsContainer />
    },
    {
      id: 2,
      title: 'Contacts',
      Component: <FundContactForm fund={fund} onSave={onSave} />
    }
  ];

  return (
    <PageLayout
      title={`Fund Details - ${doneLoading ? fund?.name : ''}`}
      action={<Button label="View Investments" onClick={() => navigate('investments')} />}
    >
      {!doneLoading && <Loader filled />}
      {doneLoading && <Tabs tabs={tabs} />}
    </PageLayout>
  );
};

export default FundContainer;
