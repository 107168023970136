/* eslint-disable */
import React, { createRef, useEffect, useMemo, useState } from 'react';

import { Form as FormState, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId, isUndefined, noOp } from '@neslotech/utils';

import { Client } from '../../../../interfaces/client/client.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { FileData } from '../../../../interfaces/config/file.interface';
import { Precedent } from '../../../../interfaces/client/precedent.interface';

import { toBase64 } from '../../../../tools/file.util';

import { useRemoval } from '../../../../hooks';
import { useCategories } from '../../../../hooks/clients';

// import { ReactComponent as WordIcon } from '../../../../icons/word-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../icons/remove-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../icons/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../../icons/pencil-icon.svg';

import DetailsCard from '../../../card/DetailsCard';
import InfoDetail from '../InfoDetail';
import Select, { OnChangeType, SelectItem } from '../../../select/Select';
import Button from '../../../button/Button';
import { Datepicker, OnChangeType as DateChangeType } from '../../../datepicker/Datepicker';
import { FileUpload } from '../../../upload/FileUpload';
import Input from '../../../input/Input';

import './precedent-form.scss';
import { SubCategory } from '../../../../interfaces/config/sub_category.interface';
import DocumentIcon from '../../../document-icon/DocumentIcon';

interface FileProps {
  file: FileData;
}

const UploadedFile = ({ file }: FileProps) => {
  const openFile = () => {
    window.open(file.url, '_blank');
  };

  return (
    <article className="uploaded-file" onClick={openFile}>
      <section className="uploaded-file__name">
        <DocumentIcon file={file} />
        <p>{file.name}</p>
      </section>
      <small>
        Uploaded by {file.uploaded_by ?? 'you'} on {new Date(file.date).toLocaleDateString()}
      </small>
    </article>
  );
};

type OnComplete = () => void;

interface Props {
  precedent?: Precedent;
  client?: Client;
  clients?: Client[];
  categories?: Category[];
  onSave: (precedent: Precedent, onComplete: OnComplete) => void;
}

interface PrecedentState extends FormState {
  clientId?: string | number;
  category?: string;
  categoryId?: number;
  subCategoryId?: number;
  subCategory?: string;
  customCategory?: string;
  documents?: FileData[];
  presentationDate?: Date | string;
}

const rules: ValidationRules<PrecedentState> = {
  validates: {
    categoryId: ['isPresent'],
    presentationDate: ['isPresent']
  }
};

const formify = (precedent: Precedent, client?: Client): PrecedentState =>
  ({
    clientId: client?.id ?? precedent?.client_id,
    presentationDate: isUndefined(precedent?.presentation_date)
      ? undefined
      : new Date(precedent?.presentation_date),
    categoryId: precedent?.category_id,
    category: precedent?.category,
    subCategoryId: precedent?.sub_category_id,
    subCategory: precedent?.sub_category,
    documents: precedent?.documents ?? []
  } as PrecedentState);

const serverify = (form: PrecedentState): Precedent => ({
  client_id: form?.clientId,
  category_id: form.categoryId,
  category: form.customCategory,
  presentation_date: form.presentationDate,
  sub_category_id: form.subCategoryId,
  documents: form.documents
});

const PrecedentForm = ({ precedent, categories, clients, client, onSave }: Props) => {
  const [form, setForm] = useFormState<PrecedentState>(formify(precedent), rules);
  const handleChange = (newState: OnChangeType | DateChangeType) =>
    setForm({ ...form, ...(newState as PrecedentState) });

  const fileRef = createRef<HTMLInputElement>();
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);

  const clientCategories: Category[] = useCategories();
  const { setElement, setShowModal } = useRemoval();

  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(!precedent);

  useEffect(() => {
    setForm(formify(precedent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precedent]);

  useEffect(() => {
    const selectedCategory = clientCategories.find((cat) => cat.id === Number(form.categoryId));
    if (selectedCategory && selectedCategory.sub_categories) {
      const activeSubCategories = selectedCategory.sub_categories.filter(
        (subCat) => subCat['status'] === 'active'
      );
      setSubCategories(activeSubCategories);
    } else {
      setSubCategories([]);
    }
  }, [categories, form.categoryId]);

  const mappedCategories: SelectItem[] = (categories ?? clientCategories)
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((category: Category) => ({
      label: category.name,
      value: category.name === 'Other' ? -100 : category.id
    }));

  const mappedSubCategories: SelectItem[] = useMemo(() => {
    return subCategories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((subCategory: SubCategory) => ({
        label: subCategory.name,
        value: subCategory.id
      }));
  }, [subCategories]);

  const mappedClients: SelectItem[] = (clients ?? [])
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((client: Client) => ({
      label: client.name,
      value: client.id
    }));

  const actions = useMemo(() => {
    const handleSave = () => {
      setLoading(true);
      const payload: Precedent = serverify(form);
      onSave(payload, () => {
        setLoading(false);
        setEditMode(false);
      });
    };

    const handleRemove = () => {
      setElement(client);
      setShowModal(true);
    };

    return editMode ? (
      <>
        <SaveIcon onClick={!form?.valid ? noOp : handleSave} />
        {precedent?.id ? <RemoveIcon onClick={handleRemove} /> : <></>}
      </>
    ) : (
      <>
        <EditIcon onClick={() => setEditMode(true)} />
        {precedent?.id ? <RemoveIcon onClick={handleRemove} /> : <></>}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, editMode, form, onSave]);

  const handleFileChange = async (file: File) => {
    const base64Data = await toBase64(file);
    handleChange({
      documents: [
        ...form?.documents,
        { id: generateId(), name: file.name, data: base64Data, date: new Date() }
      ]
    } as PrecedentState);
  };

  return (
    <article className="precedent-form">
      <DetailsCard action={actions} loading={loading}>
        {!client && (
          <InfoDetail
            title="Client Name"
            editMode={editMode}
            editableElement={
              <Select
                name="clientId"
                label=""
                items={mappedClients}
                placeholder="Select Client"
                value={form?.clientId}
                onChange={handleChange}
              />
            }
          />
        )}
        {!!client && <InfoDetail title="Client Name" value={client?.name} />}
        {editMode && (
          <InfoDetail
            title="File Upload"
            editMode
            value=""
            editableElement={
              <FileUpload fileRef={fileRef} fileTypes="*/*" onChange={handleFileChange}>
                <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
              </FileUpload>
            }
          />
        )}
        <InfoDetail
          title="Category/Tag"
          editMode={editMode}
          value={form?.category}
          editableElement={
            <Select
              name="categoryId"
              placeholder="Select Category/Tag"
              items={mappedCategories}
              value={form?.categoryId}
              error={form?.messages?.categoryId}
              onChange={handleChange}
            />
          }
        />
        {subCategories.length > 0 && (
          <InfoDetail
            title="Sub Category"
            value={form?.subCategory}
            editMode={editMode}
            editableElement={
              <Select
                name="subCategoryId"
                placeholder="Select a sub category"
                items={mappedSubCategories}
                value={form.subCategoryId}
                error={form.messages.subCategoryId}
                onChange={handleChange}
              />
            }
          />
        )}
        {Number(form?.categoryId) === -100 && (
          <InfoDetail
            title="Custom Category/Tag"
            editMode={editMode}
            value={form?.customCategory}
            editableElement={
              <Input
                name="customCategory"
                placeholder="Enter a custom Category"
                value={form?.customCategory}
                error={form?.messages.customCategory}
                onChange={handleChange}
              />
            }
          />
        )}
        <InfoDetail
          title="Date of Presentation"
          value={form?.presentationDate}
          editMode={editMode}
          editableElement={
            <Datepicker
              name="presentationDate"
              value={form?.presentationDate}
              error={form?.messages?.presentationDate}
              placeholder="Select a date"
              onChange={handleChange}
            />
          }
        />
      </DetailsCard>
      <DetailsCard title="Uploaded Files">
        <section className="precedent-form__uploads">
          {(form?.documents ?? []).map((upload: FileData) => (
            <UploadedFile file={upload} />
          ))}
        </section>
      </DetailsCard>
    </article>
  );
};

export default PrecedentForm;
