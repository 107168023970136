import React, { ChangeEvent, LegacyRef, ReactNode } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import './input.scss';

type NamedChangeType = { [key: string]: string };
export type OnChangeType = NamedChangeType | string;

interface Props {
  type?: 'text' | 'password' | 'number' | 'email' | 'link';
  name: string;
  label?: string;
  placeholder: string;
  value?: Date | string | number;
  error?: string;
  disabled?: boolean;
  onChange: (value: OnChangeType) => void;
  onFocus?: () => void;
  inputRef?: LegacyRef<HTMLInputElement>;
  clearable?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  autocomplete?: 'on' | 'off';
}

const Input = ({
  type,
  name,
  value,
  label,
  placeholder,
  error,
  disabled,
  onChange,
  onFocus = noOp,
  inputRef,
  clearable,
  hidden,
  icon,
  autocomplete = 'off'
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = getChangeValue(event.target.value, name);
    onChange(value);
  };

  return (
    !hidden && (
      <article className="input">
        {label && (
          <section className="input__label">
            <label htmlFor={name}>{label}</label>
            {clearable && <small onClick={() => onChange(getChangeValue('', name))}>Clear</small>}
          </section>
        )}
        <section
          className={getClassNames('input__wrapper', { disabled })}
          style={{ display: 'flex' }}
        >
          <input
            ref={inputRef}
            name={name}
            type={type}
            value={value as string}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleChange}
            onFocus={onFocus}
            hidden={hidden}
            autoComplete={autocomplete}
          />
          {icon}
        </section>
        <section className="input__error">{error && <small>{error}</small>}</section>
      </article>
    )
  );
};

const getChangeValue = (value: string, name?: string): OnChangeType => {
  return name ? { [name]: value } : value;
};

Input.defaultProps = {
  type: 'text',
  disabled: false,
  onChange: noOp
};

export default Input;
