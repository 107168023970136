import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { User } from '../../../interfaces/user/user.interface';

import { useAppSelector } from '../../../hooks';

const UserCrumb = () => {
  const user: User = useAppSelector(({ user_store }: AppState) => user_store.user) as User;

  return <NavLink to={`/admin/users/${user?.id}`}>{user?.full_name}</NavLink>;
};

export default UserCrumb;
