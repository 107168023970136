import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';

import { Client } from '../../../../interfaces/client/client.interface';
import { DealSheet } from '../../../../interfaces/client/deal-sheet.interface';

import { LoadClientAction } from '../../../../actions/client/client.types';
import { ClientActions } from '../../../../actions/client/client.actions';
import { ClientDealSheetActions } from '../../../../actions/client/deal-sheet/deal-sheet.actions';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

const DealSheetCrumb = () => {
  const { dealSheetId, id } = useParams<string>();
  const dispatch: Dispatch<LoadClientAction> = useAppDispatch();
  const client: Client = useAppSelector(({ client_store }: AppState) => client_store.client);
  const dealSheet: DealSheet = useAppSelector(
    ({ client_store }: AppState) => client_store.dealSheet
  );

  useEffect(() => {
    if (!client) {
      dispatch(ClientActions.loadClient(id, noOp));
    }

    if (!dealSheet) {
      dispatch(ClientDealSheetActions.loadDealSheet(id, dealSheetId, noOp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealSheetId, id]);

  return (
    <NavLink to={`/core/clients/${client?.id}/deal-sheets/${dealSheet?.id}`}>
      {dealSheet?.name}
    </NavLink>
  );
};

export default DealSheetCrumb;
