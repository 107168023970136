import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Prospect } from '../../../../interfaces/employee/prospect.interface';

import { LoadProspectsAction } from '../../../../actions/prospect/prospect.types';
import { ProspectActions } from '../../../../actions/prospect/prospect.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import Button from '../../../../components/button/Button';
import ProspectList from '../../../../components/prospects/list/ProspectList';

import RemoveProspectContainer from '../remove/RemoveProspect.container';
import { getCookieListTableViewStyle, setCookieListTableViewStyle } from '../../../../tools/filter.util';
import Select from '../../../../components/select/Select';
import ProspectTable from '../../../../components/prospects/list/ProspectTable';

const ProspectsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadProspectsAction> = useAppDispatch();
  const prospects: Prospect[] = useAppSelector(
    ({ prospect_store }: AppState) => prospect_store.prospects
  );
  const cookieViewStyle = getCookieListTableViewStyle();
  const [view_style, setViewStyle] = useState<string>(cookieViewStyle ?? 'list_view');

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(ProspectActions.loadProspects(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewChange = (object: any) => {
    setViewStyle(object.view_style);
    setCookieListTableViewStyle(object.view_style);
  };

  return (
    <PageLayout
      title="Human Resources - Prospects"
      action={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button label="Add Prospect" onClick={() => navigate('add')} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '130px',
              marginLeft: '10px',
              marginBottom: '10px'
            }}
          >
            <Select
              name="view_style"
              placeholder="Select View"
              value={view_style}
              items={[
                { label: 'List View', value: 'list_view' },
                { label: 'Table View', value: 'table_view' }
              ]}
              onChange={handleViewChange}
            />
          </div>
        </div>
      }
    >
      <RemovalProvider>
        {view_style === 'list_view' ? (
          <ProspectList prospects={prospects} loading={loading} />
        ) : (
          <ProspectTable prospects={prospects} />
        )}
        <RemoveProspectContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default ProspectsContainer;
