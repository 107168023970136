import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Contact } from '../../interfaces/client/contact.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';
import { Client } from '../../interfaces/client/client.interface';

const getContactsEndpoint = (filter: boolean = false): string =>
  filter ? `${API_HOST}/contacts?filter=true` : `${API_HOST}/contacts`;
export const getContactsRequest = (filter: boolean): RequestOptions => [
  getContactsEndpoint(filter),
  getHttpGetOptions(getAuthHeaders())
];

const getContactEndpoint = (id: number | string): string => `${getContactsEndpoint()}/${id}`;
export const getContactRequest = (id: number | string): RequestOptions => [
  getContactEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateContactRequest = (id: number | string, payload: Contact): RequestOptions => [
  getContactEndpoint(id),
  getHttpPutOptions({ contact: payload }, getAuthHeaders())
];

export const getAddContactRequest = (payload: Contact): RequestOptions => [
  getContactsEndpoint(),
  getHttpPostOptions({ contact: payload }, getAuthHeaders())
];

export const getRemoveContactRequest = (id: string | number): RequestOptions => [
  getContactEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getConvertContactEndpoint = (id: number | string): string =>
  `${getContactEndpoint(id)}/convert`;
export const getConvertContactRequest = (id: string | number, payload: Client): RequestOptions => [
  getConvertContactEndpoint(id),
  getHttpPostOptions({ client: payload }, getAuthHeaders())
];
