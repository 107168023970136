import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import {
  getAllDownloadTombstonesRequest,
  getAllTombstonesRequest,
  RequestOptions
} from '../tools/api';

import { LoadTombstonesAction } from '../actions/tombstone/tombstone.types';
import { TombstoneActions } from '../actions/tombstone/tombstone.actions';
import { SystemActions } from '../actions/system/system.actions';
import { LoadTombstoneAction } from '../actions/client/tombstone/tombstone.types';

export function* performLoadTombstones({ onComplete }: LoadTombstonesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAllTombstonesRequest();

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: TombstoneActions.SET_TOMBSTONES, tombstones: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadTombstonesRequest() {
  yield takeLatest(TombstoneActions.LOAD_TOMBSTONES, performLoadTombstones);
}

export function* performDownloadTombstones({ onComplete }: LoadTombstoneAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions =
      getAllDownloadTombstonesRequest() as RequestOptions;

    const { data, headers } = yield call(axios, endpoint, requestOptions);

    // Get the Content-Disposition header value from the response
    const contentDisposition = headers['content-disposition'];

    // Use a regular expression to extract the file name from the header value
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';

    const csvBlob = new Blob([data], { type: 'text/csv' });
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(csvBlob);
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForDownloadTombstonesRequest() {
  yield takeLatest(TombstoneActions.DOWNLOAD_TOMBSTONES, performDownloadTombstones);
}

export default function* tombstoneSaga() {
  yield all([watchForLoadTombstonesRequest(), watchForDownloadTombstonesRequest()]);
}
