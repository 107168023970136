import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Mandate } from '../../../../interfaces/client/mandate.interface';
import { Sector } from '../../../../interfaces/config/sector.interface';
import { Filter } from '../../../../types/filter.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import RemovalProvider from '../../../../providers/Removal.provider';

import { MandateActions } from '../../../../actions/mandate/mandate.actions';
import { SectorActions } from '../../../../actions/sector/sector.actions';

import { ReactComponent as ChevronRightIcon } from '../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import MandateList from '../../../../components/mandates/list/MandateList';
import { DropdownMenuItem } from '../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../components/radio/RadioGroup';
import FilterLayout from '../../../../components/layout/filter/FilterLayout';
import Button from '../../../../components/button/Button';

import RemoveMandateContainer from '../../clients/details/mandates/remove/RemoveMandate.container';
import { formatCurrency } from '../../../../tools/currency.util';
import Select from '../../../../components/select/Select';
import MandateTable from '../../../../components/mandates/list/MandateTable';
import { getCookieListTableViewStyle, setCookieListTableViewStyle } from '../../../../tools/filter.util';

const MandatesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useAppDispatch();
  const cookieViewStyle = getCookieListTableViewStyle();
  const [view_style, setViewStyle] = useState<string>(cookieViewStyle ?? 'list_view');

  const [loading, setLoading] = useState<boolean>(true);

  const mandates: Mandate[] = useAppSelector(
    ({ mandate_store }: AppState) => mandate_store.mandates
  );
  const sectors: Sector[] = useAppSelector(({ sector_store }: AppState) => sector_store.sectors);

  useEffect(() => {
    dispatch(SectorActions.loadSectors(noOp));
    dispatch(MandateActions.loadMandates(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mandateTotal = useMemo(() => {
    return mandates
      .filter((mandate: Mandate) => !isNaN(mandate.fee_value))
      .reduce((accum, mandate) => accum + Number(mandate.fee_value), 0);
  }, [mandates]);

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Status',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="status"
            onChange={onChange}
            value={filters?.status as string}
            items={[
              { label: 'Completed', value: 'completed' },
              { label: 'Open', value: 'open' }
            ]}
          />
        )
      },
      {
        text: 'Sector',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="sector_id"
            onChange={onChange}
            value={filters?.sector_id as string}
            items={sectors
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((sector: Sector) => ({
                label: sector.name,
                value: sector.id
              }))}
          />
        )
      }
    ];
  }, [sectors]);

  const doneLoading = useMemo(() => {
    return !loading && !!mandates;
  }, [loading, mandates]);

  const handleViewChange = (object: any) => {
    setViewStyle(object.view_style);
    setCookieListTableViewStyle(object.view_style);
  };

  return (
    <PageLayout
      title="Mandates"
      action={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button label="Add Mandate" onClick={() => navigate('add')} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '130px',
              marginLeft: '10px',
              marginTop: '-5px'
            }}
          >
            <Select
              name="view_style"
              placeholder="Select View"
              value={view_style}
              items={[
                { label: 'List View', value: 'list_view' },
                { label: 'Table View', value: 'table_view' }
              ]}
              onChange={handleViewChange}
            />
          </div>
        </div>
      }
      bordered
    >
      <FilterLayout
        menuItems={menuItems}
        actions={<p>Mandate Total Fee: {formatCurrency(mandateTotal)}</p>}
      >
        <RemovalProvider>
          {!doneLoading && <Loader filled />}
          {doneLoading &&
            (view_style === 'list_view' ? (
              <MandateList mandates={mandates} loading={loading} />
            ) : (
              <MandateTable mandates={mandates} loading={loading} />
            ))}
          <RemoveMandateContainer />
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default MandatesContainer;
