import React, { useMemo } from 'react';

import { Event } from '../../../interfaces/event/event.interface';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import './event-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useRemoval } from '../../../hooks';

interface Props {
  events: Event[];
}

const eventHeaders: TableHeader[] = [
  {
    Header: 'Event Name',
    accessor: 'name'
  },
  {
    Header: 'Event Link',
    accessor: 'link'
  },
  {
    Header: 'Event Date',
    accessor: 'date'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const EventTable = ({ events }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const { setElement, setShowModal } = useRemoval();

  const handleRemove = (event: Event) => {
    setShowModal(true);
    setElement(event);
  };

  const eventsRows: TableData[] = useMemo(() => {
    return events.map<TableData>((event: Event) => ({
      ...event,

      action: (
        <div className="event_action">
          <EditIcon onClick={() => navigate(`/admin/events/${event.id}`)} />
          <RemoveIcon onClick={() => handleRemove(event)} />
        </div>
      ),
      onClick: () => {}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <article className="event-list">
      <Table cols={eventHeaders} rowData={eventsRows} />
    </article>
  );
};

export default EventTable;
