import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { LeaveRequest } from '../../../../interfaces/employee/leave-request.interface';

import { LoadLeaveRequestsAction } from '../../../../actions/leave-request/leave-request.types';
import { LeaveRequestActions } from '../../../../actions/leave-request/leave-request.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import LeaveRequestList from '../../../../components/leave-requests/list/LeaveRequestList';
import Button from '../../../../components/button/Button';

import RemoveLeaveRequestContainer from '../remove/RemoveLeaveRequest.container';

const LeaveRequestsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadLeaveRequestsAction> = useAppDispatch();
  const leaveRequests: LeaveRequest[] = useAppSelector(
    ({ leave_request_store }: AppState) => leave_request_store.leaveRequests
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(LeaveRequestActions.loadLeaveRequests(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Leave Requests"
      action={<Button label="Add Leave Request" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <LeaveRequestList leaveRequests={leaveRequests} loading={loading} />
        <RemoveLeaveRequestContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default LeaveRequestsContainer;
