/**
 * @module SnackAlert Component
 * @description
 * A single message that enters in from the edge of the screen.
 * Allows triggering of an exit animation before removing the SnackAlert.
 */
import React, { useEffect, useState } from 'react';

import { getClassNames, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { ReactComponent as IconCheckCircle } from '../../icons/check-circle-icon.svg';
import { ReactComponent as IconError } from '../../icons/error-icon.svg';

import './snack-alert.scss';

const renderIcon = (status: string) => {
  switch (status) {
    case SNACK_SUCCESS:
      return <IconCheckCircle />;
    case SNACK_CRITICAL:
      return <IconError />;
    default:
      throw Error(`Snack Alert type is not recognized: ${status}`);
  }
};

interface Props {
  status: string;
  title: string;
  closing: boolean;
}

/** On mount, add an `entering` modifier, to trigger the enter animation. */
export const SnackAlert = ({ status, title, closing }: Props) => {
  const [entering, setEntering] = useState(true);

  // On initial load, remove `entering` to remove the enter animation. Clear timeout on cleanup
  useEffect(() => {
    const timeout = setTimeout(() => setEntering(false), 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <section className={`${getClassNames('snack', { entering, closing })} ${status}`}>
      <div className="snack__title">
        <p>{title}</p>
      </div>
      <div className="snack__icon">{renderIcon(status)}</div>
    </section>
  );
};

SnackAlert.defaultProps = {
  status: SNACK_SUCCESS,
  closing: false
};
