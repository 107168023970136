import React from 'react';
import { RouteObject } from 'react-router-dom';

import ExpenseClaimsContainer from '../../containers/human-resources/expense-claims/list/ExpenseClaims.container';
import AddExpenseClaimContainer from '../../containers/human-resources/expense-claims/add/AddExpenseClaims.container';
import ExpenseClaimContainer from '../../containers/human-resources/expense-claims/details/ExpenseClaim.container';

import ExpenseClaimsCrumb from '../../crumbs/human-resources/expense-claims/ExpenseClaims.crumb';
import AddExpenseClaimCrumb from '../../crumbs/human-resources/expense-claims/AddExpenseClaim.crumb';
import ExpenseClaimCrumb from '../../crumbs/human-resources/expense-claims/ExpenseClaim.crumb';

import ProtectedRoute from '../ProtectedRoute';

const expenseClaimRoutes: RouteObject[] = [
  {
    path: 'expense-claims',
    handle: {
      crumb: () => <ExpenseClaimsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <ExpenseClaimsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddExpenseClaimContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddExpenseClaimCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <ExpenseClaimContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <ExpenseClaimCrumb />
        }
      }
    ]
  }
];

export default expenseClaimRoutes;
