import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Category } from '../../../../interfaces/config/category.interface';

import { LoadCategoriesAction } from '../../../../actions/category/category.types';
import { CategoryActions } from '../../../../actions/category/category.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import CategoryList from '../../../../components/categories/list/CategoryList';
import Button from '../../../../components/button/Button';
import RemoveSubCategoryContainer from '../remove/RemoveSubCategoryContainer';


const SubCategoriesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadCategoriesAction> = useAppDispatch();
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(() => setLoading(false), 'all'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Categories"
      action={<Button label="Add Category" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <CategoryList categories={categories} loading={loading} />
        <RemoveSubCategoryContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default SubCategoriesContainer;
