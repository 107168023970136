import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Prospect } from '../../../interfaces/employee/prospect.interface';

import { useAppSelector } from '../../../hooks';

const ProspectCrumb = () => {
  const prospect: Prospect = useAppSelector(
    ({ prospect_store }: AppState) => prospect_store.prospect
  );

  return <NavLink to={`/human-resources/prospects/${prospect?.id}`}>{prospect?.full_name}</NavLink>;
};

export default ProspectCrumb;
