import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Sector } from '../../../../interfaces/config/sector.interface';

import { SectorActions } from '../../../../actions/sector/sector.actions';
import { AddSectorAction } from '../../../../actions/sector/sector.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import SectorForm from '../../../../components/sectors/form/SectorForm';

const AddSectorContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddSectorAction> = useAppDispatch();

  const onSave = (payload: Sector, onComplete: () => void): void => {
    dispatch(SectorActions.addSector(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Sector">
      <SectorForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddSectorContainer;
