import { NavigateFunction } from 'react-router-dom';

import { LeaveRequest } from '../../interfaces/employee/leave-request.interface';

import {
  AddLeaveRequestAction,
  LoadLeaveRequestAction,
  LoadLeaveRequestsAction,
  OnComplete,
  RemoveLeaveRequestAction,
  UpdateLeaveRequestAction
} from './leave-request.types';

export class LeaveRequestActions {
  static readonly LOAD_LEAVE_REQUESTS: string = '[LEAVE_REQUEST_STORE] Load leave requests';
  static readonly SET_LEAVE_REQUESTS: string = '[LEAVE_REQUEST_STORE] Set leave requests';

  static readonly LOAD_LEAVE_REQUEST: string = '[LEAVE_REQUEST_STORE] Load leave request';
  static readonly SET_LEAVE_REQUEST: string = '[LEAVE_REQUEST_STORE] Set leave request';

  static readonly UPDATE_LEAVE_REQUEST: string = '[LEAVE_REQUEST_STORE] Update leave request';
  static readonly ADD_LEAVE_REQUEST: string = '[LEAVE_REQUEST_STORE] Add leave request';
  static readonly REMOVE_LEAVE_REQUEST: string = '[LEAVE_REQUEST_STORE] Remove leave request';

  static loadLeaveRequests = (onComplete: OnComplete): LoadLeaveRequestsAction => ({
    type: LeaveRequestActions.LOAD_LEAVE_REQUESTS,
    onComplete
  });

  static loadLeaveRequest = (
    id: number | string,
    onComplete: OnComplete
  ): LoadLeaveRequestAction => ({
    type: LeaveRequestActions.LOAD_LEAVE_REQUEST,
    id,
    onComplete
  });

  static addLeaveRequest = (
    payload: LeaveRequest,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddLeaveRequestAction => ({
    type: LeaveRequestActions.ADD_LEAVE_REQUEST,
    payload,
    navigate,
    onComplete
  });

  static updateLeaveRequest = (
    id: number | string,
    payload: LeaveRequest,
    onComplete: OnComplete
  ): UpdateLeaveRequestAction => ({
    type: LeaveRequestActions.UPDATE_LEAVE_REQUEST,
    id,
    payload,
    onComplete
  });

  static removeLeaveRequest = (
    id: number | string,
    onComplete: OnComplete
  ): RemoveLeaveRequestAction => ({
    type: LeaveRequestActions.REMOVE_LEAVE_REQUEST,
    id,
    onComplete
  });
}
