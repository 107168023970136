import React, { ReactElement, useState } from 'react';

import { SidebarContext, SidebarProps } from '../contexts/Sidebar.context';

interface Props {
  children: ReactElement[] | ReactElement;
}

const SidebarProvider = ({ children }: Props) => {
  const [openedItem, setOpenedItem] = useState<string>('');

  const value: SidebarProps = {
    openedItem,
    setOpenedItem
  };

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;
