import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddLeaveRequestRequest,
  getLeaveRequestRequest,
  getLeaveRequestsRequest,
  getRemoveLeaveRequestRequest,
  getUpdateLeaveRequestRequest,
  RequestOptions
} from '../tools/api';

import {
  AddLeaveRequestAction,
  LoadLeaveRequestAction,
  LoadLeaveRequestsAction,
  UpdateLeaveRequestAction
} from '../actions/leave-request/leave-request.types';

import { LeaveRequestActions } from '../actions/leave-request/leave-request.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadLeaveRequests({ onComplete }: LoadLeaveRequestsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getLeaveRequestsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: LeaveRequestActions.SET_LEAVE_REQUESTS, leaveRequests: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadLeaveRequestsRequest() {
  yield takeLatest(LeaveRequestActions.LOAD_LEAVE_REQUESTS, performLoadLeaveRequests);
}

export function* performLoadLeaveRequest({ id, onComplete }: LoadLeaveRequestAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getLeaveRequestRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: LeaveRequestActions.SET_LEAVE_REQUEST, leaveRequest: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadLeaveRequestRequest() {
  yield takeLatest(LeaveRequestActions.LOAD_LEAVE_REQUEST, performLoadLeaveRequest);
}

export function* performUpdateLeaveRequest({ id, payload, onComplete }: UpdateLeaveRequestAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateLeaveRequestRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The leave request has been updated successfully!', SNACK_SUCCESS)
    );

    yield put({ type: LeaveRequestActions.SET_LEAVE_REQUEST, leaveRequest: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateLeaveRequestRequest() {
  yield takeLatest(LeaveRequestActions.UPDATE_LEAVE_REQUEST, performUpdateLeaveRequest);
}

export function* performAddLeaveRequest({ payload, navigate, onComplete }: AddLeaveRequestAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddLeaveRequestRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new leave request has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/human-resources/leave-requests');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddLeaveRequestRequest() {
  yield takeLatest(LeaveRequestActions.ADD_LEAVE_REQUEST, performAddLeaveRequest);
}

export function* performRemoveLeaveRequest({ id, onComplete }: AddLeaveRequestAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveLeaveRequestRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The leave request has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(LeaveRequestActions.loadLeaveRequests(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveLeaveRequestRequest() {
  yield takeLatest(LeaveRequestActions.REMOVE_LEAVE_REQUEST, performRemoveLeaveRequest);
}

export default function* leaveRequestSaga() {
  yield all([
    watchForLoadLeaveRequestsRequest(),
    watchForLoadLeaveRequestRequest(),
    watchForUpdateLeaveRequestRequest(),
    watchForAddLeaveRequestRequest(),
    watchForRemoveLeaveRequestRequest()
  ]);
}
