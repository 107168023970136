import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Timesheet } from '../../../../interfaces/employee/timesheet.interface';

import { LoadTimesheetsAction } from '../../../../actions/timesheet/timesheet.types';
import { TimesheetActions } from '../../../../actions/timesheet/timesheet.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import TimesheetList from '../../../../components/timesheets/list/TimesheetList';
import Button from '../../../../components/button/Button';

import RemoveTimesheetContainer from '../remove/RemoveTimesheet.container';
import { noOp } from '@neslotech/utils';

const TimesheetsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadTimesheetsAction> = useAppDispatch();
  const timesheets: Timesheet[] = useAppSelector(
    ({ timesheet_store }: AppState) => timesheet_store.timesheets
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(TimesheetActions.loadTimesheets(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleExport = () => {
    dispatch(TimesheetActions.downloadTimesheets(noOp));
  };

  return (
    <PageLayout
      title="Time Sheets"
      action={
        <div>
          <Button label="Export" onClick={onHandleExport} />
          &nbsp;
          <Button label="Add Time Sheet" onClick={() => navigate('add')} />
        </div>
      }
    >
      <RemovalProvider>
        <TimesheetList timesheets={timesheets} loading={loading} />
        <RemoveTimesheetContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default TimesheetsContainer;
