import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import { DealSheet } from '../../../../../../interfaces/client/deal-sheet.interface';
import { Tombstone } from '../../../../../../interfaces/client/tombstone.interface';

import { RootState } from '../../../../../../reducers';

import { LoadDealSheetsAction } from '../../../../../../actions/client/deal-sheet/deal-sheet.types';
import { ClientDealSheetActions } from '../../../../../../actions/client/deal-sheet/deal-sheet.actions';
import { TombstoneActions } from '../../../../../../actions/tombstone/tombstone.actions';
import { LoadTombstonesAction } from '../../../../../../actions/tombstone/tombstone.types';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../../components/loader/Loader';
import DealSheetForm from '../../../../../../components/clients/details/deal-sheets/form/DealSheetForm';

const DealSheetContainer = () => {
  const { dealSheetId, id } = useParams<string>();

  const dispatch: Dispatch<LoadDealSheetsAction | LoadTombstonesAction> = useAppDispatch();
  const dealSheet: DealSheet = useAppSelector(
    ({ client_store }: AppState) => client_store.dealSheet
  );
  const tombstones: Tombstone[] = useAppSelector(
    ({ tombstone_store }: RootState) => tombstone_store.tombstones
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(TombstoneActions.loadTombstones(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(ClientDealSheetActions.loadDealSheet(id, dealSheetId, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSave = (payload: DealSheet, onComplete: () => void): void => {
    dispatch(ClientDealSheetActions.updateDealSheet(id, dealSheetId, payload, noOp, onComplete));
  };

  const doneLoading = useMemo(() => {
    return !loading && dealSheet?.id === Number(dealSheetId);
  }, [loading, dealSheet?.id, dealSheetId]);

  return (
    <PageLayout title={`Deal Sheet Details - ${doneLoading ? dealSheet?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <DealSheetForm tombstones={tombstones} dealSheet={dealSheet} onSave={onSave} />
      )}
    </PageLayout>
  );
};

export default DealSheetContainer;
