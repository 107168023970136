import { Note } from '../../../interfaces/client/note.interface';

import { AddNoteAction, LoadNotesAction, OnComplete, OnSuccess } from './note.types';

export class NoteActions {
  static readonly LOAD_NOTES: string = '[CLIENT_STORE] Load notes';
  static readonly SET_NOTES: string = '[CLIENT_STORE] Set notes';

  static readonly ADD_NOTE: string = '[CLIENT_STORE] Add note';

  static loadNotes = (id: number | string, onComplete: OnComplete): LoadNotesAction => ({
    type: NoteActions.LOAD_NOTES,
    id,
    onComplete
  });

  static addNote = (
    id: number | string,
    payload: Note,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): AddNoteAction => ({
    type: NoteActions.ADD_NOTE,
    id,
    payload,
    onSuccess,
    onComplete
  });
}
