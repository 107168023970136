import React from 'react';

import { getClassNames } from '@neslotech/utils';

import { RadioButton } from './RadioButton';

import './radio-group.scss';

export interface RadioItem {
  label?: string;
  value: string | boolean | number;
  danger?: boolean;
  success?: boolean;
}

export type RadioChangeType = { [name: string]: number | boolean | string };

interface Props {
  label?: string;
  description?: string;
  name: string;
  onChange: (value: RadioChangeType) => void;
  value: string | boolean | number;
  disabled?: boolean;
  fluid?: boolean;
  items: RadioItem[];
}

export const RadioGroup = ({
  label,
  description,
  name,
  onChange,
  value,
  items,
  disabled,
  fluid
}: Props) => {
  const handleChange = (value: number | boolean | string) => {
    onChange({ [`${name}`]: value });
  };

  return (
    <section className={getClassNames('radio-group', { fluid, disabled })}>
      {label && (
        <label className="radio-group__label" htmlFor={name}>
          {label}
        </label>
      )}
      {description && <p className="radio-group__description">{description}</p>}
      <section className="radio-group__buttons">
        {items.map((item: RadioItem) => (
          <RadioButton
            key={item.label}
            disabled={disabled}
            name={name}
            label={item.label}
            value={item.value}
            checked={value === item.value}
            danger={item.danger}
            success={item.success}
            onChange={handleChange}
          />
        ))}
      </section>
    </section>
  );
};
