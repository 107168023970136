import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Employee } from '../../interfaces/employee/employee.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getEmployeesEndpoint = (): string => `${API_HOST}/employees`;
export const getEmployeesRequest = (): RequestOptions => [
  getEmployeesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getEmployeesCsvEndpoint = (): string => `${getEmployeesEndpoint()}.csv`;
export const getDownloadEmployeesRequest = () => [
  getEmployeesCsvEndpoint(),
  { ...getHttpGetOptions({ ...getAuthHeaders(), Accept: 'text/csv' }), responseType: 'blob' }
];

export const getAddEmployeeRequest = (payload: Employee): RequestOptions => [
  getEmployeesEndpoint(),
  getHttpPostOptions({ employee: payload }, getAuthHeaders())
];

const getEmployeeEndpoint = (id: number | string): string => `${API_HOST}/employees/${id}`;
export const getEmployeeRequest = (id: number | string): RequestOptions => [
  getEmployeeEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateEmployeeRequest = (
  id: number | string,
  payload: Employee
): RequestOptions => [
  getEmployeeEndpoint(id),
  getHttpPutOptions({ employee: payload }, getAuthHeaders())
];

export const getRemoveEmployeeRequest = (id: string | number): RequestOptions => [
  getEmployeeEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getEmployeeDocumentEndpoint = (id: number | string, documentId: number): string =>
  `${API_HOST}/employees/${id}/documents/${documentId}`;
export const getRemoveEmployeeDocumentRequest = (
  id: string | number,
  documentId: number
): RequestOptions => [
  getEmployeeDocumentEndpoint(id, documentId),
  getHttpDeleteOptions(getAuthHeaders())
];
