import { Contact, ContactHistory } from '../../../interfaces/client/contact.interface';
import { SetPrimaryContactAction } from '../../contact/contact.types';

import {
  AddContactAction,
  AddContactHistoryAction,
  LoadContactAction,
  LoadContactsAction,
  OnComplete,
  OnSuccess,
  RemoveContactAction,
  RemoveContactHistoryAction,
  UpdateContactAction,
  UpdateContactHistoryAction
} from './contact.types';

export class ClientContactActions {
  static readonly LOAD_CONTACTS: string = '[CLIENT_STORE] Load contacts';
  static readonly SET_CONTACTS: string = '[CLIENT_STORE] Set contacts';

  static readonly LOAD_CONTACT: string = '[CLIENT_STORE] Load contact';
  static readonly SET_CONTACT: string = '[CLIENT_STORE] Set contact';

  static readonly UPDATE_CONTACT: string = '[CLIENT_STORE] Update contact';
  static readonly ADD_CONTACT: string = '[CLIENT_STORE] Add contact';
  static readonly REMOVE_CONTACT: string = '[CLIENT_STORE] Remove contact';
  static readonly SET_PRIMARY_CONTACT: string = '[CLIENT_STORE] Set Primary contact';

  static readonly UPDATE_CONTACT_HISOTRY: string = '[CLIENT_STORE] Update contact history';
  static readonly ADD_CONTACT_HISOTRY: string = '[CLIENT_STORE] Add contact history';
  static readonly REMOVE_CONTACT_HISOTRY: string = '[CLIENT_STORE] Remove contact history';

  static loadContacts = (
    clientId: number | string,
    onComplete: OnComplete
  ): LoadContactsAction => ({
    type: ClientContactActions.LOAD_CONTACTS,
    clientId,
    onComplete
  });

  static loadContact = (
    clientId: number | string,
    id: number | string,
    onComplete: OnComplete
  ): LoadContactAction => ({
    type: ClientContactActions.LOAD_CONTACT,
    clientId,
    id,
    onComplete
  });

  static addContact = (
    clientId: number | string,
    payload: Contact,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): AddContactAction => ({
    type: ClientContactActions.ADD_CONTACT,
    clientId,
    payload,
    onSuccess,
    onComplete
  });

  static setPrimaryContact = (
    clientId: string | number,
    contactId: string | number,
    onComplete: OnComplete
  ): SetPrimaryContactAction => ({
    type: ClientContactActions.SET_PRIMARY_CONTACT,
    clientId,
    contactId,
    onComplete
  });

  static updateContact = (
    clientId: number | string,
    id: number | string,
    payload: Contact,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): UpdateContactAction => ({
    type: ClientContactActions.UPDATE_CONTACT,
    clientId,
    id,
    payload,
    onSuccess,
    onComplete
  });

  static removeContact = (
    clientId: number | string,
    id: number | string,
    onComplete: OnComplete
  ): RemoveContactAction => ({
    type: ClientContactActions.REMOVE_CONTACT,
    clientId,
    id,
    onComplete
  });

  static addContactHistory = (
    clientId: number | string,
    contactId: number | string,
    payload: ContactHistory,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): AddContactHistoryAction => ({
    type: ClientContactActions.ADD_CONTACT_HISOTRY,
    clientId,
    contactId,
    payload,
    onSuccess,
    onComplete
  });

  static updateContactHistory = (
    clientId: number | string,
    contactId: number | string,
    id: number | string,
    payload: ContactHistory,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): UpdateContactHistoryAction => ({
    type: ClientContactActions.UPDATE_CONTACT_HISOTRY,
    clientId,
    contactId,
    id,
    payload,
    onSuccess,
    onComplete
  });

  static removeContactHistory = (
    clientId: number | string,
    contactId: number | string,
    id: number | string,
    onComplete: OnComplete
  ): RemoveContactHistoryAction => ({
    type: ClientContactActions.REMOVE_CONTACT_HISOTRY,
    clientId,
    contactId,
    id,
    onComplete
  });
}
