import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Designation } from '../interfaces/config/designation.interface';

import { DesignationActions } from '../actions/designation/designation.actions';

export interface DesignationState extends State {
  designations: Designation[];
  designation?: Designation;
}

export const initialState: DesignationState = {
  designations: []
};

export const designationReducer = (state: DesignationState = initialState, action: AnyAction) => {
  if (action.type === DesignationActions.SET_DESIGNATIONS) {
    const { designations } = action;
    return {
      ...state,
      designations
    };
  } else if (action.type === DesignationActions.SET_DESIGNATION) {
    const { designation } = action;
    return {
      ...state,
      designation
    };
  }

  return state;
};
