import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getMandatesEndpoint = (): string => `${API_HOST}/mandates`;
export const getMandatesRequest = (): RequestOptions => [
  getMandatesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];
