import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddClientContactRequest,
  getClientContactRequest,
  getClientContactsRequest,
  getRemoveClientContactRequest,
  getUpdateClientContactRequest,
  RequestOptions
} from '../../tools/api';

import {
  AddContactAction,
  AddContactHistoryAction,
  LoadContactsAction,
  RemoveContactHistoryAction,
  UpdateContactAction,
  UpdateContactHistoryAction
} from '../../actions/client/contact/contact.types';
import { ClientContactActions } from '../../actions/client/contact/contact.actions';
import { SystemActions } from '../../actions/system/system.actions';

import { ResponseError } from '../../types/response-error.interface';
import {
  getAddClientContactHistoryRequest,
  getClientSetPrimaryContactRequest,
  getRemoveClientContactHistoryRequest,
  getUpdateClientContactHistoryRequest
} from '../../tools/api/clients/contact.endpoints';
import { SetPrimaryContactAction } from '../../actions/contact/contact.types';
import { Contact } from '../../interfaces/client/contact.interface';

export function* performLoadContacts({ clientId, onComplete }: LoadContactsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientContactsRequest(clientId);

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);
    data.sort((a: Contact, b: Contact) => (a.is_primary < b.is_primary ? 1 : -1));

    yield put({ type: ClientContactActions.SET_CONTACTS, contacts: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadContactsRequest() {
  yield takeLatest(ClientContactActions.LOAD_CONTACTS, performLoadContacts);
}

export function* performLoadContact({ clientId, id, onComplete }: LoadContactsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientContactRequest(clientId, id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ClientContactActions.SET_CONTACT, contact: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadContactRequest() {
  yield takeLatest(ClientContactActions.LOAD_CONTACT, performLoadContact);
}

export function* performUpdateContact({
  clientId,
  id,
  payload,
  onSuccess,
  onComplete
}: UpdateContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateClientContactRequest(
      clientId,
      id,
      payload
    );

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('This contact has been updated successfully!', SNACK_SUCCESS)
    );

    yield put({ type: ClientContactActions.SET_CONTACT, contact: data });

    yield put(ClientContactActions.loadContacts(clientId, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateContactRequest() {
  yield takeLatest(ClientContactActions.UPDATE_CONTACT, performUpdateContact);
}

export function* performAddContact({ clientId, payload, onSuccess, onComplete }: AddContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddClientContactRequest(
      clientId,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new contact has been created successfully!', SNACK_SUCCESS)
    );

    yield put(ClientContactActions.loadContacts(clientId, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddContactRequest() {
  yield takeLatest(ClientContactActions.ADD_CONTACT, performAddContact);
}

export function* performSetPrimaryContact({
  clientId,
  contactId,
  onComplete
}: SetPrimaryContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientSetPrimaryContactRequest(
      clientId,
      contactId
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('A contact has been set to primary!', SNACK_SUCCESS));

    yield put(ClientContactActions.loadContacts(clientId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
  }
}

export function* watchForSetPrimaryContactRequest() {
  yield takeLatest(ClientContactActions.SET_PRIMARY_CONTACT, performSetPrimaryContact);
}

export function* performRemoveContact({ clientId, id, onComplete }: AddContactAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveClientContactRequest(clientId, id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The contact has been removed successfully!', SNACK_SUCCESS));

    yield put(ClientContactActions.loadContacts(clientId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveContactRequest() {
  yield takeLatest(ClientContactActions.REMOVE_CONTACT, performRemoveContact);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Contact History

export function* performAddContactHistory({
  clientId,
  contactId,
  payload,
  onSuccess,
  onComplete
}: AddContactHistoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddClientContactHistoryRequest(
      contactId,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new contact history has been created successfully!', SNACK_SUCCESS)
    );

    yield put(ClientContactActions.loadContacts(clientId, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddContactHistoryRequest() {
  yield takeLatest(ClientContactActions.ADD_CONTACT_HISOTRY, performAddContactHistory);
}

export function* performUpdateContactHistory({
  clientId,
  contactId,
  id,
  payload,
  onSuccess,
  onComplete
}: UpdateContactHistoryAction) {
  try {
    // get endpoint and http request options

    const [endpoint, requestOptions]: RequestOptions = getUpdateClientContactHistoryRequest(
      contactId,
      id,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('This contact history has been updated successfully!', SNACK_SUCCESS)
    );

    yield put(ClientContactActions.loadContacts(clientId, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateContactHistoryRequest() {
  yield takeLatest(ClientContactActions.UPDATE_CONTACT_HISOTRY, performUpdateContactHistory);
}

export function* performRemoveContactHistory({
  clientId,
  contactId,
  id,
  onComplete
}: RemoveContactHistoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveClientContactHistoryRequest(
      contactId,
      id
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The contact history has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(ClientContactActions.loadContacts(clientId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveContactHistoryRequest() {
  yield takeLatest(ClientContactActions.REMOVE_CONTACT_HISOTRY, performRemoveContactHistory);
}

export default function* contactSaga() {
  yield all([
    watchForLoadContactsRequest(),
    watchForLoadContactRequest(),
    watchForUpdateContactRequest(),
    watchForAddContactRequest(),
    watchForRemoveContactRequest(),
    watchForAddContactHistoryRequest(),
    watchForRemoveContactHistoryRequest(),
    watchForUpdateContactHistoryRequest(),
    watchForSetPrimaryContactRequest()
  ]);
}
