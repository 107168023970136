import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Employee } from '../interfaces/employee/employee.interface';

import { EmployeeActions } from '../actions/employee/employee.actions';

export interface EmployeeState extends State {
  employees: Employee[];
  employee?: Employee;
}

export const initialState: EmployeeState = {
  employees: []
};

export const employeeReducer = (state: EmployeeState = initialState, action: AnyAction) => {
  if (action.type === EmployeeActions.SET_EMPLOYEES) {
    const { employees } = action;
    return {
      ...state,
      employees
    };
  } else if (action.type === EmployeeActions.SET_EMPLOYEE) {
    const { employee } = action;
    return {
      ...state,
      employee
    };
  }

  return state;
};
