import React, { ReactNode, useEffect } from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../types/state.interface';

import { Profile } from '../../../interfaces/profile/profile.interface';

import { LoadProfileAction } from '../../../actions/profile/profile.types';
import { ProfileActions } from '../../../actions/profile/profile.actions';

import { useAppDispatch, useAppSelector } from '../../../hooks';

import SidebarProvider from '../../../providers/Sidebar.provider';

import Header from '../../header/Header';
import Sidebar from '../../sidebar/Sidebar';

import './authenticated-layout.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

const AuthenticatedLayout = ({ children }: Props) => {
  const dispatch: Dispatch<LoadProfileAction> = useAppDispatch();
  const profile: Profile | undefined = useAppSelector(
    ({ profile_store }: AppState) => profile_store.profile
  );

  useEffect(() => {
    if (!profile) {
      dispatch(ProfileActions.loadProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <article className="authenticated-layout">
      <Header authenticated profile={profile} />
      <main>
        <SidebarProvider>
          <Sidebar profile={profile} />
        </SidebarProvider>
        <section className="authenticated-layout__content">{children}</section>
      </main>
    </article>
  );
};

export default AuthenticatedLayout;
