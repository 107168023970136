import React from 'react';
import { NavLink } from 'react-router-dom';

import { Employee } from '../../../interfaces/employee/employee.interface';
import { AppState } from '../../../types/state.interface';

import { useAppSelector } from '../../../hooks';

const EditEmployeeCrumb = () => {
  const employee: Employee = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employee
  );

  return (
    <NavLink to={`/human-resources/employees/${employee?.id}`}>
      {employee?.full_name} | Edit
    </NavLink>
  );
};

export default EditEmployeeCrumb;
