import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Irp5 } from '../../../interfaces/employee/irp5.interface';

import { useAppSelector } from '../../../hooks';

const Irp5Crumb = () => {
  const irp5: Irp5 = useAppSelector(({ irp5_store }: AppState) => irp5_store.irp5);

  return <NavLink to={`/human-resources/irp5s/${irp5?.id}`}>{irp5?.employee}</NavLink>;
};

export default Irp5Crumb;
