import React from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppState } from './types/state.interface';

import { useAppSelector } from './hooks';

import AuthProvider from './providers/Auth.provider';

import { SystemActions } from './actions/system/system.actions';

import { SnackBar } from './components/snack-bar/SnackBar';

import {
  adminRoutes,
  authRoutes,
  coreRoutes,
  dashboardRoutes,
  profileRoutes,
  hrRoutes
} from './routes';

import './app.scss';

const router = createBrowserRouter([
  ...authRoutes,
  ...dashboardRoutes,
  ...profileRoutes,
  ...coreRoutes,
  ...adminRoutes,
  ...hrRoutes
]);

const App = () => {
  const dispatch = useDispatch();
  const notices = useAppSelector(({ system_store }: AppState) => system_store.notices);

  const handleCloseNotice = (noticeId: string | number) => {
    dispatch(SystemActions.removeNotice(noticeId));
  };

  return (
    <article className="app">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
      <SnackBar notices={notices} onCloseNotice={handleCloseNotice} />
    </article>
  );
};

export default App;
