import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddEmployeeRequest,
  getRemoveEmployeeRequest,
  getEmployeeRequest,
  getEmployeesRequest,
  getUpdateEmployeeRequest,
  RequestOptions,
  getAllDownloadEmployeesRequest
} from '../tools/api';

import {
  AddEmployeeAction,
  DownloadEmployeesAction,
  LoadEmployeeAction,
  LoadEmployeesAction,
  RemoveEmployeeDocumentAction,
  UpdateEmployeeAction
} from '../actions/employee/employee.types';
import { EmployeeActions } from '../actions/employee/employee.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';
import { getRemoveEmployeeDocumentRequest } from '../tools/api/employee.endpoints';

export function* performLoadEmployees({ onComplete }: LoadEmployeesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getEmployeesRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: EmployeeActions.SET_EMPLOYEES, employees: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadEmployeesRequest() {
  yield takeLatest(EmployeeActions.LOAD_EMPLOYEES, performLoadEmployees);
}

export function* watchForDownloadEmployeesRequest() {
  yield takeLatest(EmployeeActions.DOWNLOAD_EMPLOYEES, performDownloadEmployee);
}

export function* performDownloadEmployee({ id, onComplete }: DownloadEmployeesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions =
      getAllDownloadEmployeesRequest() as RequestOptions;

    const { data, headers } = yield call(axios, endpoint, requestOptions);

    // Get the Content-Disposition header value from the response
    const contentDisposition = headers['content-disposition'];

    // Use a regular expression to extract the file name from the header value
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';

    const csvBlob = new Blob([data], { type: 'text/csv' });
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(csvBlob);
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* performLoadEmployee({ id, onComplete }: LoadEmployeeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getEmployeeRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: EmployeeActions.SET_EMPLOYEE, employee: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadEmployeeRequest() {
  yield takeLatest(EmployeeActions.LOAD_EMPLOYEE, performLoadEmployee);
}

export function* performUpdateEmployee({ id, payload, onComplete }: UpdateEmployeeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateEmployeeRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.full_name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: EmployeeActions.SET_EMPLOYEE, employee: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateEmployeeRequest() {
  yield takeLatest(EmployeeActions.UPDATE_EMPLOYEE, performUpdateEmployee);
}

export function* performAddEmployee({ payload, navigate, onComplete }: AddEmployeeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddEmployeeRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new employee has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/human-resources/employees');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddEmployeeRequest() {
  yield takeLatest(EmployeeActions.ADD_EMPLOYEE, performAddEmployee);
}

export function* performRemoveEmployee({ id, onComplete }: AddEmployeeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveEmployeeRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The employee has been updated successfully!', SNACK_SUCCESS)
    );

    yield put(EmployeeActions.loadEmployees(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveEmployeeRequest() {
  yield takeLatest(EmployeeActions.REMOVE_EMPLOYEE, performRemoveEmployee);
}

export function* performRemoveEmployeeDocument({ id, documentId }: RemoveEmployeeDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveEmployeeDocumentRequest(
      id,
      documentId
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'The employee ID document has been removed successfully!',
        SNACK_SUCCESS
      )
    );

    yield put(EmployeeActions.loadEmployee(id, noOp));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveEmployeeDocumentRequest() {
  yield takeLatest(EmployeeActions.REMOVE_EMPLOYEE_DOCUMENT, performRemoveEmployeeDocument);
}

export default function* employeeSaga() {
  yield all([
    watchForLoadEmployeesRequest(),
    watchForLoadEmployeeRequest(),
    watchForUpdateEmployeeRequest(),
    watchForAddEmployeeRequest(),
    watchForRemoveEmployeeRequest(),
    watchForRemoveEmployeeDocumentRequest(),
    watchForDownloadEmployeesRequest()
  ]);
}
