import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Document } from '../interfaces/document/document.interface';

import { DocumentActions } from '../actions/document/document.actions';

export interface DocumentState extends State {
  documents: Document[];
  document?: Document;
}

export const initialState: DocumentState = {
  documents: []
};

export const documentReducer = (state: DocumentState = initialState, action: AnyAction) => {
  if (action.type === DocumentActions.SET_DOCUMENTS) {
    const { documents } = action;
    return {
      ...state,
      documents
    };
  } else if (action.type === DocumentActions.SET_DOCUMENT) {
    const { document } = action;
    return {
      ...state,
      document
    };
  }

  return state;
};
