import React, { useMemo } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../hooks';
import { useClient } from '../../../../hooks/clients';
import { Client } from '../../../../interfaces/client/client.interface';
import { LoadClientsAction } from '../../../../actions/client/client.types';
import { ClientActions } from '../../../../actions/client/client.actions';
import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import ClientDetails from '../../../../components/clients/details/ClientDetails';

const ClientContainer = () => {
  const dispatch: Dispatch<LoadClientsAction> = useAppDispatch();

  const { id, loading, client } = useClient();

  const doneLoading = useMemo(() => {
    return !loading && client?.id === Number(id);
  }, [id, loading, client?.id]);

  const handleSave = (payload: Client, onComplete: () => void) => {
    dispatch(ClientActions.updateClient(id as string, payload, onComplete));
  };

  return (
    <PageLayout title={`Client Details - ${doneLoading ? client?.name : ''}`} bordered>
      {!doneLoading && <Loader filled />}
      {doneLoading && <ClientDetails onSave={handleSave} />}
    </PageLayout>
  );
};

export default ClientContainer;
