import React from 'react';
import { RouteObject } from 'react-router-dom';

import CategoriesContainer from '../../containers/admin/categories/list/Categories.container';
import AddCategoryContainer from '../../containers/admin/categories/add/AddCategory.container';
// import CategoryContainer from '../../containers/admin/categories/details/Category.container';

import CategoriesCrumb from '../../crumbs/admin/categories/Categories.crumb';
import AddCategoryCrumb from '../../crumbs/admin/categories/AddCategory.crumb';
import CategoryCrumb from '../../crumbs/admin/categories/Category.crumb';

import AdminRoute from '../AdminRoute';
import AddSubCategoryCrumb from '../../crumbs/admin/sub-categories/AddSubCategory.crumb';
import SubCategoryCrumb from '../../crumbs/admin/sub-categories/SubCategory.crumb';
import SubCategoryContainer from '../../containers/admin/sub-categories/details/SubCategory.container';
import CategoryContainer from '../../containers/admin/categories/details/Category.container';
import AddSubCategoryContainer from '../../containers/admin/sub-categories/add/AddSubCategory.container';

const categoryRoutes: RouteObject[] = [
  {
    path: 'categories',
    handle: {
      crumb: () => <CategoriesCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <CategoriesContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddCategoryContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddCategoryCrumb />
        }
      },
      {
        path: ':id',
        handle: {
          crumb: () => <CategoryCrumb />
        },
        children: [
          {
            path: '',
            element: (
              <AdminRoute>
                <CategoryContainer />
              </AdminRoute>
            )
          },
          {
            path: 'sub-categories',
            handle: {
              // crumb: () => <SubCategoriesCrumb />
            },
            children: [
              {
                path: '',
                index: true,
                element: (
                  <AdminRoute>
                    <SubCategoryContainer />
                  </AdminRoute>
                )
              },
              {
                path: 'add',
                element: (
                  <AdminRoute>
                    <AddSubCategoryContainer />
                  </AdminRoute>
                ),
                handle: {
                  crumb: () => <AddSubCategoryCrumb />
                }
              },
              {
                path: ':sub_category_id',
                element: (
                  <AdminRoute>
                    <SubCategoryContainer />
                  </AdminRoute>
                ),
                handle: {
                  crumb: () => <SubCategoryCrumb />
                }
              }
            ]
          }
        ]
      }
    ]
  }
];

export default categoryRoutes;
