import React, { useMemo } from 'react';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import './prospect-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useRemoval } from '../../../hooks';
import { Prospect } from '../../../interfaces/employee/prospect.interface';

interface Props {
  prospects: Prospect[];
}

const prospectHeaders: TableHeader[] = [
  {
    Header: 'Prospect Name',
    accessor: 'full_name'
  },
  {
    Header: 'Prospect Email',
    accessor: 'email'
  },
  {
    Header: 'Prospect Mobile Number',
    accessor: 'id_number'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const ProspectTable = ({ prospects }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const { setElement, setShowModal } = useRemoval();

  const handleRemove = (prospect: Prospect) => {
    setShowModal(true);
    setElement(prospect);
  };

  const prospectsRows: TableData[] = useMemo(() => {
    return prospects.map<TableData>((prospect: Prospect) => ({
      ...prospect,

      action: (
        <div className="prospect_action">
          <EditIcon onClick={() => navigate(`/human-resources/prospects/${prospect.id}`)} />
          <RemoveIcon onClick={() => handleRemove(prospect)} />
        </div>
      ),
      onClick: () => {}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospects]);

  return (
    <article className="prospect-list">
      <Table cols={prospectHeaders} rowData={prospectsRows} />
    </article>
  );
};

export default ProspectTable;
