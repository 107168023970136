import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { statusItems } from '../../../tools/dropdown.util';

import { Sector } from '../../../interfaces/config/sector.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';

import './sector-form.scss';

type OnSave = (payload: Sector, onComplete: () => void) => void;
type Status = 'active' | 'inactive';

interface Props {
  sector?: Sector;
  onSave: OnSave;
}

export interface SectorState extends StateForm {
  name: string;
  description: string;
  status: Status;
}

const rules: ValidationRules<SectorState> = {
  validates: {
    name: ['isPresent'],
    status: ['isPresent']
  }
};

const formify = (sector?: Sector): SectorState =>
  ({
    name: sector?.name,
    description: sector?.description,
    status: sector?.status
  } as SectorState);

const serverify = (form: SectorState) => ({
  name: form.name,
  description: form.description,
  status: form.status
});

const SectorForm = ({ sector, onSave }: Props) => {
  const [form, setForm] = useFormState<SectorState>(formify(sector), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as SectorState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Sector = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="sector-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Sector Name"
              placeholder="Sector Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="description"
              label="Description"
              placeholder="Description"
              value={form?.description}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="status"
              label="Status"
              placeholder="Select a status"
              items={statusItems}
              value={form?.status}
              error={form?.messages.status}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default SectorForm;
