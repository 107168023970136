import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { Sector } from '../../../../interfaces/config/sector.interface';

import { RemoveSectorAction } from '../../../../actions/sector/sector.types';
import { SectorActions } from '../../../../actions/sector/sector.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveSectorContainer = () => {
  const dispatch: Dispatch<RemoveSectorAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      SectorActions.removeSector((element as Sector).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Sector">
            <RemoveModal title={(element as Sector).name} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveSectorContainer;
