import Cookies from 'js-cookie';

import { HeadersType } from '@neslotech/utils';

const ACCESS_TOKEN_ID = 'bsm-auth-token';

export type SetToken = (token: string, remember_me: boolean) => void;

/** Sets the auth token cookie */
export const setAuthTokenCookie: SetToken = (token: string, remember_me: boolean): void => {
  if (remember_me === true) {
    var inFifteenMinutes = new Date(new Date().getTime() + 7 * 24 * 3600 * 1000);
    Cookies.set(ACCESS_TOKEN_ID, token, {
      expires: inFifteenMinutes
    });
  } else {
    Cookies.set(ACCESS_TOKEN_ID, token);
  }
};

/** Get the auth token cookie */
export const getAuthTokenCookie = (): string => {
  return Cookies.get(ACCESS_TOKEN_ID) as string;
};

/** Remove the auth token cookie */
export const removeAuthTokenCookie = (): void => {
  Cookies.remove(ACCESS_TOKEN_ID);
};

export const getAuthHeaders = (): HeadersType => {
  return { authorization: `Bearer ${getAuthTokenCookie()}` };
};
