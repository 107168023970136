import React, { ReactNode, useState } from 'react';

import { ApiElement } from '../types/api-element.interface';

import { ConvertContext, ConvertProps } from '../contexts/Convert.context';

interface Props {
  children: ReactNode[] | ReactNode;
}

const ConvertProvider = ({ children }: Props) => {
  const [element, setElement] = useState<ApiElement>();
  const [showCModal, setShowCModal] = useState<boolean>(false);

  const value: ConvertProps = {
    element,
    setElement,
    showCModal,
    setShowCModal
  };

  return <ConvertContext.Provider value={value}>{children}</ConvertContext.Provider>;
};

export default ConvertProvider;
