import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Sector } from '../../../../interfaces/config/sector.interface';

import { LoadSectorsAction } from '../../../../actions/sector/sector.types';
import { SectorActions } from '../../../../actions/sector/sector.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import SectorForm from '../../../../components/sectors/form/SectorForm';

const SectorContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadSectorsAction> = useAppDispatch();
  const sector: Sector = useAppSelector(
    ({ sector_store }: AppState) => sector_store.sector
  ) as Sector;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Sector, onComplete: () => void): void => {
    dispatch(SectorActions.updateSector(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(SectorActions.loadSector(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && sector?.id === Number(id);
  }, [id, loading, sector?.id]);

  return (
    <PageLayout title={`Sector Details - ${doneLoading ? sector?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <SectorForm sector={sector} onSave={onSave} />}
    </PageLayout>
  );
};

export default SectorContainer;
