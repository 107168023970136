import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Employeerole } from '../../../../interfaces/employeerole/employeerole.interface';

import { EmployeeroleActions } from '../../../../actions/employeerole/employeerole.actions';
import { AddEmployeeroleAction } from '../../../../actions/employeerole/employeerole.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import EmployeeroleForm from '../../../../components/employeeroles/form/EmployeeroleForm';

const AddEmployeeroleActionContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddEmployeeroleAction> = useAppDispatch();

  const onSave = (payload: Employeerole, onComplete: () => void): void => {
    dispatch(EmployeeroleActions.addEmployeerole(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Employee Role">
      <EmployeeroleForm mode="add" onSave={onSave} />
    </PageLayout>
  );
};

export default AddEmployeeroleActionContainer;
