import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { ClientType } from '../../../../interfaces/config/client-type.interface';

import { LoadClientTypesAction } from '../../../../actions/client-type/client-type.types';
import { ClientTypeActions } from '../../../../actions/client-type/client-type.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import ClientTypeForm from '../../../../components/client-types/form/ClientTypeForm';

const ClientTypeContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadClientTypesAction> = useAppDispatch();
  const clientType: ClientType = useAppSelector(
    ({ client_type_store }: AppState) => client_type_store.clientType
  ) as ClientType;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: ClientType, onComplete: () => void): void => {
    dispatch(ClientTypeActions.updateClientType(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(ClientTypeActions.loadClientType(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && clientType?.id === Number(id);
  }, [id, loading, clientType?.id]);

  return (
    <PageLayout title={`Client Type Details - ${doneLoading ? clientType?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <ClientTypeForm clientType={clientType} onSave={onSave} />}
    </PageLayout>
  );
};

export default ClientTypeContainer;
