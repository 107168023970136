import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { LeaveRequest } from '../interfaces/employee/leave-request.interface';

import { LeaveRequestActions } from '../actions/leave-request/leave-request.actions';

export interface LeaveRequestState extends State {
  leaveRequests: LeaveRequest[];
  leaveRequest?: LeaveRequest;
}

export const initialState: LeaveRequestState = {
  leaveRequests: []
};

export const leaveRequestReducer = (state: LeaveRequestState = initialState, action: AnyAction) => {
  if (action.type === LeaveRequestActions.SET_LEAVE_REQUESTS) {
    const { leaveRequests } = action;
    return {
      ...state,
      leaveRequests
    };
  } else if (action.type === LeaveRequestActions.SET_LEAVE_REQUEST) {
    const { leaveRequest } = action;
    return {
      ...state,
      leaveRequest
    };
  }

  return state;
};
