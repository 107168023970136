import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { ClientType } from '../../../../interfaces/config/client-type.interface';

import { LoadClientTypesAction } from '../../../../actions/client-type/client-type.types';
import { ClientTypeActions } from '../../../../actions/client-type/client-type.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import ClientTypeList from '../../../../components/client-types/list/ClientTypeList';
import Button from '../../../../components/button/Button';

import RemoveClientTypeContainer from '../remove/RemoveClientType.container';

const ClientTypesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadClientTypesAction> = useAppDispatch();
  const clientTypes: ClientType[] = useAppSelector(
    ({ client_type_store }: AppState) => client_type_store.clientTypes
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(ClientTypeActions.loadClientTypes(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Client Types"
      action={<Button label="Add Client Type" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <ClientTypeList clientTypes={clientTypes} loading={loading} />
        <RemoveClientTypeContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default ClientTypesContainer;
