import { getHttpGetOptions, getHttpPostOptions, getHttpPutOptions } from '@neslotech/utils';

import { Credentials } from '../../interfaces/auth/credentials.interface';
import { ConfirmationRequest } from '../../interfaces/auth/confirmation-request.interface';
import { RegisterRequest } from '../../interfaces/auth/register-request.interface';
import { ForgotPasswordRequest } from '../../interfaces/auth/forgot-password-request.interface';
import { ResetPasswordRequest } from '../../interfaces/auth/reset-password-request.interface';

import { API_HOST, RequestOptions } from './index';

const getRegisterEndpoint = (): string => `${API_HOST}/users`;
export const getRegisterRequest = (request: RegisterRequest): RequestOptions => [
  getRegisterEndpoint(),
  getHttpPostOptions({ user: { ...request, self_service: true } })
];

const getAuthenticateEndpoint = (): string => `${API_HOST}/users/sign_in`;
export const getAuthenticateRequest = (request: Credentials): RequestOptions => [
  getAuthenticateEndpoint(),
  getHttpPostOptions({ user: request })
];

const getForgotPasswordEndpoint = (): string => `${API_HOST}/users/password`;
export const getForgotPasswordRequest = (request: ForgotPasswordRequest): RequestOptions => [
  getForgotPasswordEndpoint(),
  getHttpPostOptions({ user: request })
];

const getResetPasswordEndpoint = (id?: string, token?: string): string =>
  `${API_HOST}/users/password.${id}`;
export const getResetPasswordRequest = (
  request: ResetPasswordRequest,
  id?: string,
  token?: string
): RequestOptions => [
  getResetPasswordEndpoint(id),
  getHttpPutOptions({
    user: {
      ...request,
      reset_password_token: token
    }
  })
];

const getConfirmationEndpoint = (id?: string, token?: string): string =>
  `${API_HOST}/users/confirmation.${id}?confirmation_token=${token}`;
export const getConfirmationRequest = (id?: string, token?: string): RequestOptions => [
  getConfirmationEndpoint(id, token),
  getHttpGetOptions()
];

const getSetConfirmationEndpoint = (): string => `${API_HOST}/users/confirmation`;
export const getSetConfirmationRequest = (request: ConfirmationRequest): RequestOptions => [
  getSetConfirmationEndpoint(),
  getHttpPostOptions({ user: request })
];
