import React, { useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { Role, User } from '../../../interfaces/user/user.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType, SelectItem } from '../../select/Select';
import AvatarLayout from '../../layout/avatar/AvatarLayout';

import './user-form.scss';

type OnSave = (payload: User, onComplete: () => void) => void;
type Mode = 'edit' | 'add';

interface Props {
  user?: User;
  mode?: Mode;
  onSave: OnSave;
  isProfile?: boolean;
}

export interface UserState extends StateForm {
  firstName: string;
  lastName: string;
  role: Role;
  contactNumber: string;
  image: string;
  email: string;
  status: string;
  password: string;
  passwordConfirm: string;
}

interface CustomValidationRules extends ValidationRules<UserState> {
  arePasswordsEqual: (value: string, form: UserState) => string | undefined;
}

const rules: CustomValidationRules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    role: ['isPresent'],
    contactNumber: ['isNumeric'],
    email: ['isPresent', 'isEmail'],
    password: ['isPresent'],
    passwordConfirm: ['isPresent', 'arePasswordsEqual']
  },

  arePasswordsEqual(value, form) {
    if (value !== form?.password) {
      return 'Passwords do not match';
    }
  }
};

const editRules: ValidationRules<UserState> = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    role: ['isPresent'],
    contactNumber: ['isNumeric']
  }
};

const formify = (user?: User): UserState =>
  ({
    firstName: user?.first_name,
    lastName: user?.last_name,
    role: user?.role,
    contactNumber: user?.contact_number,
    image: user?.image
  } as UserState);

const serverify = (form: UserState, mode: Mode) =>{
  const baseObject = {
    first_name: form.firstName,
    last_name: form.lastName,
    role: form.role,
    contact_number: form.contactNumber,
    image: form.image,
    is_employee: form.is_employee,
    status: form.status
  };

  if (mode === 'edit') {
    return {
      ...baseObject,
      ...(form.password !== '' && { password: form.password }),
      password_confirmation: form.passwordConfirm
    };
  } else {
    return {
      ...baseObject,
      password: form.password,
      email: form.email
    };
  }
};

const items: SelectItem[] = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Agent',
    value: 'agent'
  },
  {
    label: 'Employee',
    value: 'employee'
  }
];

const UserForm = ({ user, mode = 'edit', onSave, isProfile = false }: Props) => {
  const editMode = useMemo(() => {
    return mode === 'edit';
  }, [mode]);

  const [form, setForm] = useFormState<UserState>(formify(user), editMode ? editRules : rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as UserState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: User = serverify(form, mode);
    onSave(payload, () => setLoading(false));
  };

  return (
    <AvatarLayout
      title={user?.full_name ?? `${form?.firstName ?? ''} ${form?.lastName ?? ''}`}
      subtitle={user?.role ?? form?.role}
      image={form?.image ?? user?.image}
      handleChange={handleChange}
    >
      <Form>
        <FormRow fluid>
          <Input
            name="firstName"
            label="First Name"
            placeholder="First Name"
            value={form?.firstName}
            error={form?.messages.firstName}
            onChange={handleChange}
          />
          <Input
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={form?.lastName}
            error={form?.messages.lastName}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluid>
          <Input
            name="contactNumber"
            label="Contact Number"
            placeholder="Contact Number"
            value={form?.contactNumber}
            error={form?.messages.contactNumber}
            onChange={handleChange}
          />
          <Select
            name="role"
            label="Role"
            disabled={isProfile && user?.role !== 'admin'}
            placeholder="Select a role"
            items={items}
            value={form?.role}
            error={form?.messages.role}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            onChange={handleChange}
            value={form?.email}
            error={form?.messages.email}
          />
        </FormRow>
        <FormRow fluid>
          <Input
            type="password"
            name="password"
            label="Password"
            placeholder="Password"
            onChange={handleChange}
            value={form?.password}
            error={form?.messages.password}
          />
          <Input
            type="password"
            name="passwordConfirm"
            label="Password Confirmation"
            placeholder="Password Confirmation"
            onChange={handleChange}
            value={form?.passwordConfirm}
            error={form?.messages.passwordConfirm}
          />
        </FormRow>
        <FormRow>
          <small className="user-form__password-message">
            When setting a password, you are required to have a password that is at least 8
            characters in length including a capital letter, a number and a special character
          </small>
        </FormRow>
        <FormAction
          loading={loading}
          label="Save Changes"
          onClick={handleSubmit}
          disabled={!form?.valid}
        />
      </Form>
    </AvatarLayout>
  );
};

export default UserForm;
