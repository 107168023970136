import React, { useMemo } from 'react';

import { Client } from '../../../interfaces/client/client.interface';

import { useClient } from '../../../hooks/clients';
import { ContactProvider } from '../../../providers/client/Contact.provider';
import RemovalProvider from '../../../providers/Removal.provider';

import NotesContainer from '../../../containers/core/clients/details/notes/list/Notes.container';
import ContactsContainer from '../../../containers/core/clients/details/contacts/list/Contacts.container';

import { ReactComponent as ProfileIcon } from '../../../icons/profile-icon.svg';

import BasicInfoDetail from './basic-info/BasicInfoDetail';
import OtherInfoDetail from './other-info/OtherInfoDetail';

import './client-details.scss';
import Tabs, { TabItem } from '../../tab/Tabs';
import RemoveClientContainer from '../../../containers/core/clients/remove/RemoveClient.container';
import RemoveContactContainer from '../../../containers/core/clients/details/contacts/remove/RemoveContact.container';

type OnSave = (payload: Client, onComplete: () => void) => void;

interface Props {
  onSave: OnSave;
}

const ClientDetails = ({ onSave }: Props) => {
  const { client } = useClient();
  const tabs: TabItem[] = useMemo(() => {
    return [
      {
        id: 0,
        title: 'Basic info',
        actionPath: '/human-resources/payslips/add',
        Component: (
          <>
          <RemovalProvider>
            <BasicInfoDetail onSave={onSave} />
            <RemoveClientContainer />
          </RemovalProvider>
          <br />
          <OtherInfoDetail onSave={onSave} />
          </>
        )
      },
      {
        id: 1,
        title: 'Contacts',
        actionLabel: 'Add contact',
        actionPath: '/clients/' + client.id + '/contacts/add',
        Component: (
          <ContactProvider>
            <RemovalProvider>
              <ContactsContainer />
              <RemoveContactContainer />
            </RemovalProvider>
          </ContactProvider>
        )
      },
      {
        id: 2,
        title: 'Notes',
        actionPath: '/human-resources/irp5s/add',
        Component: <NotesContainer />
      }
    ];
  }, [onSave, client.id]);

  const Image = useMemo(() => {
    return client.image ? (
      <img
        width={100}
        height={100}
        title={client.name}
        alt={client.name}
        src={client.image}
      />
    ) : (
      <ProfileIcon width={100} height={100} title={client.name} />
    );
  }, [client.image, client.name]);

  return (
    <article className="client-details">
      <h4 className="client-details__title">{Image}</h4>
      <main className="tabs__content">
        <Tabs tabs={tabs} />
      </main>
    </article>
  );
};

export default ClientDetails;
