import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddInvestmentRequest,
  getInvestmentRequest,
  getInvestmentsRequest,
  getRemoveInvestmentRequest,
  getUpdateInvestmentRequest,
  RequestOptions
} from '../tools/api';

import {
  AddInvestmentAction,
  LoadInvestmentsAction,
  UpdateInvestmentAction
} from '../actions/investment/investment.types';
import { InvestmentActions } from '../actions/investment/investment.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadInvestments({ fundId, onComplete }: LoadInvestmentsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getInvestmentsRequest(fundId);

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: InvestmentActions.SET_INVESTMENTS, investments: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadInvestmentsRequest() {
  yield takeLatest(InvestmentActions.LOAD_INVESTMENTS, performLoadInvestments);
}

export function* performLoadInvestment({ fundId, id, onComplete }: LoadInvestmentsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getInvestmentRequest(fundId, id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: InvestmentActions.SET_INVESTMENT, investment: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadInvestmentRequest() {
  yield takeLatest(InvestmentActions.LOAD_INVESTMENT, performLoadInvestment);
}

export function* performUpdateInvestment({
  fundId,
  id,
  payload,
  onComplete
}: UpdateInvestmentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateInvestmentRequest(
      fundId,
      id,
      payload
    );

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: InvestmentActions.SET_INVESTMENT, investment: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateInvestmentRequest() {
  yield takeLatest(InvestmentActions.UPDATE_INVESTMENT, performUpdateInvestment);
}

export function* performAddInvestment({
  fundId,
  payload,
  navigate,
  onComplete
}: AddInvestmentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddInvestmentRequest(fundId, payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new investment has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate(`/admin/funds/${fundId}/investments`);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddInvestmentRequest() {
  yield takeLatest(InvestmentActions.ADD_INVESTMENT, performAddInvestment);
}

export function* performRemoveInvestment({ fundId, id, onComplete }: AddInvestmentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveInvestmentRequest(fundId, id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The investment has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(InvestmentActions.loadInvestments(fundId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveInvestmentRequest() {
  yield takeLatest(InvestmentActions.REMOVE_INVESTMENT, performRemoveInvestment);
}

export default function* investmentSaga() {
  yield all([
    watchForLoadInvestmentsRequest(),
    watchForLoadInvestmentRequest(),
    watchForUpdateInvestmentRequest(),
    watchForAddInvestmentRequest(),
    watchForRemoveInvestmentRequest()
  ]);
}
