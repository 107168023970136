import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Event } from '../../../interfaces/event/event.interface';

import { useRemoval } from '../../../hooks';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';

interface Props {
  event: Event;
}

const EventCard = ({ event }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const { setElement, setShowModal } = useRemoval();

  const actions = useMemo(() => {
    const handleRemove = () => {
      setShowModal(true);
      setElement(event);
    };

    return (
      <>
        <EditIcon onClick={() => navigate(`/admin/events/${event.id}`)} />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  return (
    <DetailsCard action={actions} small>
      <article className="event-card">
        <InfoDetail title="Event Name" value={event.name} />
        <InfoDetail title="Event Link" value={event.link} />
        <InfoDetail title="Event Date" value={event.date} />
      </article>
    </DetailsCard>
  );
};

export default EventCard;
