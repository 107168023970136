import React, { useEffect, useMemo, useState } from 'react';

import { Client } from '../../../interfaces/client/client.interface';
import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';

import { filterElement } from '../../../tools/filter.util';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';

import './client-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';
import Button from '../../button/Button';
import { capitalise } from '@neslotech/utils';
import AlphabetLayout from '../../../components/layout/filter/AlphabetLayout'; 

interface Props {
  clients: Client[];
}

const formify = (clients: Client[], filters: Filter): Client[] =>
  clients.filter((client: Client) =>
    // @ts-ignore
    filterElement(client, filters)
  );

const clientHeaders: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Mandates',
    accessor: 'mandates'
  },
  {
    Header: 'Precedents',
    accessor: 'precedents'
  },
  {
    Header: 'Tombstones',
    accessor: 'tombstones'
  },
  {
    Header: 'Details',
    accessor: 'details'
  },
  {
    Header: 'Deal Sheets',
    accessor: 'deal_sheets'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const ClientTable = ({ clients }: Props) => {
  const { filters, setFilterDefaults, sort } = useFilters();
  const [sortedClients, setSortedClients] = useState(clients);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    setFilterDefaults({ client_type_id: '', category_id: '', sector_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sorted = formify(clients, filters).sort(
      sort === 'descending'
        ? (a: Client, b: Client) => a.name.localeCompare(b.name)
        : (a: Client, b: Client) => b.name.localeCompare(a.name)
    );
    
    if (selectedLetter) {
      sorted = sorted.filter(client => client.name[0].toUpperCase() === selectedLetter);
    }

    setSortedClients(sorted);
  }, [sort, clients, filters, selectedLetter]);

  const clientsRows: TableData[] = useMemo(() => {
    return sortedClients
      .map<TableData>((client: Client) => ({
        ...client,
        type: capitalise(client.client_type),
        mandates: (
          <Button hollow label="Mandates" onClick={() => navigate(`${client.id}/mandates`)} />
        ),
        precedents: (
          <Button hollow label="Precedents" onClick={() => navigate(`${client.id}/precedents`)} />
        ),
        tombstones: (
          <Button hollow label="Tombstones" onClick={() => navigate(`${client.id}/tombstones`)} />
        ),
        details: <Button hollow label="Details" onClick={() => navigate(`${client.id}`)} />,
        deal_sheets: (
          <Button hollow label="Deal Sheets" onClick={() => navigate(`${client.id}/deal-sheets`)} />
        ),
        action: (
          <div className="client_action">
            <EditIcon onClick={() => navigate(`/core/clients/${client.id}`)} />
          </div>
        ),
        onClick: () => {}
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedClients, sort]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="client-list">
        <Table cols={clientHeaders} rowData={clientsRows} shouldSetPageIndex={true} />
      </article>
    </>
  );
};

export default ClientTable;
