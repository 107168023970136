import React from 'react';

import { ReactComponent as FilterIcon } from '../../../icons/filter-icon.svg';

import './clear-filter.scss';

interface Props {
  onClick: () => void;
}

export const ClearFilters = ({ onClick }: Props) => (
  <section className="clear-filters" onClick={onClick}>
    <FilterIcon />
    <span className="clear-filters__title">Clear Filters</span>
  </section>
);
