import React from 'react';
import { RouteObject } from 'react-router-dom';

import InvestmentsContainer from '../../containers/admin/funds/investments/list/Investments.container';
import AddInvestmentContainer from '../../containers/admin/funds/investments/add/AddInvestment.container';
import InvestmentContainer from '../../containers/admin/funds/investments/details/Investment.container';

import InvestmentsCrumb from '../../crumbs/admin/funds/investments/Investments.crumb';
import AddInvestmentCrumb from '../../crumbs/admin/funds/investments/AddInvestment.crumb';
import InvestmentCrumb from '../../crumbs/admin/funds/investments/Investment.crumb';

import ProtectedRoute from '../ProtectedRoute';

const investmentRoutes: RouteObject[] = [
  {
    path: 'investments',
    handle: {
      crumb: () => <InvestmentsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <InvestmentsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddInvestmentContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddInvestmentCrumb />
        }
      },
      {
        path: ':investmentId',
        element: (
          <ProtectedRoute>
            <InvestmentContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <InvestmentCrumb />
        }
      }
    ]
  }
];

export default investmentRoutes;
