import React, { Fragment } from 'react';
import { useMatches } from 'react-router-dom';

import { Crumb } from '../../types/crumb.interface';

import { ReactComponent as ChevronIcon } from '../../icons/chevron-right-icon.svg';

import './breadcrumbs.scss';

const Breadcrumbs = () => {
  const matches = useMatches();
  const crumbs = matches
    .map((match) => match.handle as Crumb)
    // first get rid of any matches that don't have handle and crumb
    .filter((handle: Crumb) => !!handle?.crumb)
    // now map them into an array of elements
    .map((handle: Crumb) => handle.crumb());

  return (
    <ul className="breadcrumbs">
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          <li>{crumb}</li>
          {index < crumbs.length - 1 && <ChevronIcon />}
        </Fragment>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
