import React from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Contact } from '../../../../../../interfaces/client/contact.interface';

import { useAppDispatch, useRemoval } from '../../../../../../hooks';
import { useContactData } from '../../../../../../hooks/clients';

import { ClientContactActions } from '../../../../../../actions/client/contact/contact.actions';
import { RemoveContactAction } from '../../../../../../actions/contact/contact.types';
import { ContactActions } from '../../../../../../actions/contact/contact.actions';

import { Modal } from '../../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../../../components/modal/remove/RemoveModal';

const RemoveContactContainer = () => {
  const dispatch: Dispatch<RemoveContactAction> = useAppDispatch();
  const { id } = useParams();

  const { element, showModal, setShowModal } = useRemoval();
  const { handleIndexChange } = useContactData();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    if (id) {
      dispatch(
        ClientContactActions.removeContact(id, (element as Contact).id, () => {
          onComplete();
          onClose();
          handleIndexChange(0);
        })
      );
    } else {
      dispatch(
        ContactActions.removeContact((element as Contact).id, () => {
          onComplete();
          onClose();
        })
      );
    }
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Contact">
            <RemoveModal title={(element as Contact).key_contact} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveContactContainer;
