import { Request } from '@neslotech/utils';

import {
  getAuthenticateRequest,
  getRegisterRequest,
  getConfirmationRequest,
  getSetConfirmationRequest,
  getForgotPasswordRequest,
  getResetPasswordRequest
} from './auth.endpoints';
import {
  getCategoriesRequest,
  getCategoryRequest,
  getAddCategoryRequest,
  getRemoveCategoryRequest,
  getUpdateCategoryRequest
} from './category.endpoints';
import {
  getSubCategoriesRequest,
  getSubCategoryRequest,
  getAddSubCategoryRequest,
  getRemoveSubCategoryRequest,
  getUpdateSubCategoryRequest
} from './sub-category.endpoints';
import {
  getAddClientRequest,
  getClientRequest,
  getClientsRequest,
  getRemoveClientRequest,
  getUpdateClientRequest
} from './clients/client.endpoints';
import { getAddNoteRequest, getNotesRequest } from './clients/note.endpoints';
import {
  getAddPrecedentRequest,
  getPrecedentsRequest,
  getRemovePrecedentRequest
} from './clients/precedent.endpoints';
import {
  getAddTombstoneRequest,
  getRemoveTombstoneRequest,
  getTombstonesRequest,
  getDownloadTombstonesRequest,
  getTombstoneRequest,
  getUpdateTombstoneRequest
} from './clients/tombstone.endpoints';
import {
  getAddMandateRequest,
  getMandatesRequest,
  getRemoveMandateRequest
} from './clients/mandate.endpoints';
import {
  getAddClientDealSheetRequest,
  getClientDealSheetRequest,
  getClientDealSheetsRequest,
  getRemoveClientDealSheetRequest,
  getUpdateClientDealSheetRequest
} from './clients/deal-sheet.endpoints';
import {
  getAddClientContactRequest,
  getClientContactRequest,
  getClientContactsRequest,
  getRemoveClientContactRequest,
  getUpdateClientContactRequest
} from './clients/contact.endpoints';
import {
  getAddContactRequest,
  getRemoveContactRequest,
  getContactRequest,
  getContactsRequest,
  getUpdateContactRequest
} from './contact.endpoints';
import {
  getAddClientTypeRequest,
  getClientTypeRequest,
  getClientTypesRequest,
  getRemoveClientTypeRequest,
  getUpdateClientTypeRequest
} from './client-type.endpoints';
import {
  getAddFundRequest,
  getFundRequest,
  getFundsRequest,
  getRemoveFundRequest,
  getUpdateFundRequest
} from './fund.endpoints';
import {
  getAddInvestmentRequest,
  getInvestmentRequest,
  getInvestmentsRequest,
  getRemoveInvestmentRequest,
  getUpdateInvestmentRequest
} from './investment.endpoints';
import { getProfileRequest, getUpdatePasswordRequest } from './profile.endpoints';
import {
  getAddSectorRequest,
  getRemoveSectorRequest,
  getSectorRequest,
  getSectorsRequest,
  getUpdateSectorRequest
} from './sector.endpoints';
import {
  getAddDesignationRequest,
  getDesignationRequest,
  getDesignationsRequest,
  getRemoveDesignationRequest,
  getUpdateDesignationRequest
} from './designation.endpoints';
import {
  getRemoveUserRequest,
  getAddUserRequest,
  getUpdateUserRequest,
  getUserRequest,
  getUsersRequest
} from './user.endpoints';
import {
  getEventRequest,
  getAddEventRequest,
  getEventsRequest,
  getRemoveEventRequest,
  getUpdateEventRequest
} from './event.endpoints';
import {
  getAddEmployeeRequest,
  getEmployeeRequest,
  getEmployeesRequest,
  getRemoveEmployeeRequest,
  getUpdateEmployeeRequest,
  getDownloadEmployeesRequest as getAllDownloadEmployeesRequest
} from './employee.endpoints';

import {
  getAddEmployeeroleRequest,
  getEmployeeroleRequest,
  getEmployeerolesRequest,
  getRemoveEmployeeroleRequest,
  getUpdateEmployeeroleRequest
} from './employeerole.endpoints';

import {
  getAddDocumentRequest,
  getDocumentRequest,
  getDocumentsRequest,
  getRemoveDocumentRequest,
  getUpdateDocumentRequest
} from './document.endpoints';
import {
  getAddLeaveRequestRequest,
  getLeaveRequestRequest,
  getLeaveRequestsRequest,
  getRemoveLeaveRequestRequest,
  getUpdateLeaveRequestRequest
} from './leave-request.endpoints';
import {
  getAddExpenseClaimRequest,
  getExpenseClaimRequest,
  getExpenseClaimsRequest,
  getRemoveExpenseClaimRequest,
  getUpdateExpenseClaimRequest,
  getDownloadExpenseClaimsRequest as getAllDownloadExpenseClaimsRequest
} from './expense-claim.endpoints';
import {
  getAddPayslipRequest,
  getPayslipRequest,
  getPayslipsRequest,
  getRemovePayslipRequest,
  getUpdatePayslipRequest
} from './payslip.endpoints';
import {
  getAddTimesheetRequest,
  getRemoveTimesheetRequest,
  getTimesheetRequest,
  getTimesheetsRequest,
  getUpdateTimesheetRequest,
  getDownloadTimesheetsRequest as getAllDownloadTimesheetsRequest
} from './timesheet.endpoints';
import {
  getAddProspectRequest,
  getProspectRequest,
  getProspectsRequest,
  getRemoveProspectRequest,
  getUpdateProspectRequest
} from './prospect.endpoints';
import {
  getAddPerformanceReviewRequest,
  getPerformanceReviewRequest,
  getPerformanceReviewsRequest,
  getRemovePerformanceReviewRequest,
  getUpdatePerformanceReviewRequest
} from './performance-review.endpoints';
import {
  getAddIrp5Request,
  getIrp5Request,
  getIrp5sRequest,
  getRemoveIrp5Request,
  getUpdateIrp5Request
} from './irp5.endpoints';
import { getPrecedentsRequest as getAllPrecedentsRequest } from './precedent.endpoints';
import { getMandatesRequest as getAllMandatesRequest } from './mandate.endpoints';
import {
  getTombstonesRequest as getAllTombstonesRequest,
  getDownloadTombstonesRequest as getAllDownloadTombstonesRequest
} from './tombstone.endpoints';
import { getDealSheetsRequest } from './deal-sheet.endpoints';
import { getSearchRequest } from './search.endpoints';

export const API_HOST = process.env.REACT_APP_API_ENDPOINT; // use api endpoint provided in .env

export type RequestOptions = [string, Request];

export {
  // auth
  getAuthenticateRequest,
  getRegisterRequest,
  getConfirmationRequest,
  getSetConfirmationRequest,
  getForgotPasswordRequest,
  getResetPasswordRequest,

  // category
  getCategoriesRequest,
  getCategoryRequest,
  getAddCategoryRequest,
  getRemoveCategoryRequest,
  getUpdateCategoryRequest,

  // sub-category
  getSubCategoriesRequest,
  getSubCategoryRequest,
  getAddSubCategoryRequest,
  getRemoveSubCategoryRequest,
  getUpdateSubCategoryRequest,

  // client
  getAddClientRequest,
  getClientRequest,
  getClientsRequest,
  getRemoveClientRequest,
  getUpdateClientRequest,

  // note
  getAddNoteRequest,
  getNotesRequest,

  // client/contact
  getAddClientContactRequest,
  getClientContactRequest,
  getClientContactsRequest,
  getRemoveClientContactRequest,
  getUpdateClientContactRequest,

  // client/precedent
  getAddPrecedentRequest,
  getPrecedentsRequest,
  getRemovePrecedentRequest,

  // client/tombstone
  getAddTombstoneRequest,
  getRemoveTombstoneRequest,
  getTombstonesRequest,
  getDownloadTombstonesRequest,
  getTombstoneRequest,
  getUpdateTombstoneRequest,

  // client/mandate
  getAddMandateRequest,
  getMandatesRequest,
  getRemoveMandateRequest,

  // client/deal-sheet
  getAddClientDealSheetRequest,
  getClientDealSheetRequest,
  getClientDealSheetsRequest,
  getRemoveClientDealSheetRequest,
  getUpdateClientDealSheetRequest,

  // contact
  getAddContactRequest,
  getRemoveContactRequest,
  getContactRequest,
  getContactsRequest,
  getUpdateContactRequest,

  // client type
  getAddClientTypeRequest,
  getClientTypeRequest,
  getClientTypesRequest,
  getRemoveClientTypeRequest,
  getUpdateClientTypeRequest,

  // fund
  getAddFundRequest,
  getFundRequest,
  getFundsRequest,
  getRemoveFundRequest,
  getUpdateFundRequest,

  // investment
  getAddInvestmentRequest,
  getInvestmentRequest,
  getInvestmentsRequest,
  getRemoveInvestmentRequest,
  getUpdateInvestmentRequest,

  // profile
  getUpdatePasswordRequest,
  getProfileRequest,

  // sector
  getAddSectorRequest,
  getRemoveSectorRequest,
  getSectorRequest,
  getSectorsRequest,
  getUpdateSectorRequest,

  // designation
  getAddDesignationRequest,
  getDesignationRequest,
  getDesignationsRequest,
  getRemoveDesignationRequest,
  getUpdateDesignationRequest,

  // user
  getRemoveUserRequest,
  getAddUserRequest,
  getUpdateUserRequest,
  getUserRequest,
  getUsersRequest,

  // precedent
  getAllPrecedentsRequest,

  // mandate
  getAllMandatesRequest,

  // tombstone
  getAllTombstonesRequest,
  getAllDownloadTombstonesRequest,

  // event
  getEventRequest,
  getAddEventRequest,
  getEventsRequest,
  getRemoveEventRequest,
  getUpdateEventRequest,

  // document
  getAddDocumentRequest,
  getDocumentRequest,
  getDocumentsRequest,
  getRemoveDocumentRequest,
  getUpdateDocumentRequest,

  // deal sheet
  getDealSheetsRequest,

  // search
  getSearchRequest,

  // employee
  getAddEmployeeRequest,
  getEmployeeRequest,
  getEmployeesRequest,
  getRemoveEmployeeRequest,
  getUpdateEmployeeRequest,

  // employeerole
  getAddEmployeeroleRequest,
  getEmployeeroleRequest,
  getEmployeerolesRequest,
  getRemoveEmployeeroleRequest,
  getUpdateEmployeeroleRequest,
  getAllDownloadEmployeesRequest,

  // leave request
  getAddLeaveRequestRequest,
  getLeaveRequestRequest,
  getLeaveRequestsRequest,
  getRemoveLeaveRequestRequest,
  getUpdateLeaveRequestRequest,

  // expense claim
  getAddExpenseClaimRequest,
  getExpenseClaimRequest,
  getExpenseClaimsRequest,
  getRemoveExpenseClaimRequest,
  getUpdateExpenseClaimRequest,
  getAllDownloadExpenseClaimsRequest,

  // payslip
  getAddPayslipRequest,
  getPayslipRequest,
  getPayslipsRequest,
  getRemovePayslipRequest,
  getUpdatePayslipRequest,

  // irp5
  getAddIrp5Request,
  getIrp5Request,
  getIrp5sRequest,
  getRemoveIrp5Request,
  getUpdateIrp5Request,

  // performance review
  getAddPerformanceReviewRequest,
  getPerformanceReviewRequest,
  getPerformanceReviewsRequest,
  getRemovePerformanceReviewRequest,
  getUpdatePerformanceReviewRequest,

  // timesheet
  getAddTimesheetRequest,
  getRemoveTimesheetRequest,
  getTimesheetRequest,
  getTimesheetsRequest,
  getUpdateTimesheetRequest,
  getAllDownloadTimesheetsRequest,

  // prospect
  getAddProspectRequest,
  getProspectRequest,
  getProspectsRequest,
  getRemoveProspectRequest,
  getUpdateProspectRequest
};
