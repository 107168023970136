/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';

import { Form as FormState, useFormState, ValidationRules } from '@neslotech/hooks';
import { isUndefined, noOp } from '@neslotech/utils';

import { Client } from '../../../../interfaces/client/client.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { Tombstone } from '../../../../interfaces/client/tombstone.interface';
import { User } from '../../../../interfaces/user/user.interface';
import { SubCategory } from '../../../../interfaces/config/sub_category.interface';

import { useRemoval } from '../../../../hooks';
import { useCategories } from '../../../../hooks/clients';

import { ReactComponent as RemoveIcon } from '../../../../icons/remove-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../icons/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../../icons/pencil-icon.svg';

import DetailsCard from '../../../card/DetailsCard';
import InfoDetail from '../InfoDetail';
import Select, { OnChangeType, SelectItem } from '../../../select/Select';
import { Datepicker, OnChangeType as DateChangeType } from '../../../datepicker/Datepicker';
import TextArea from '../../../text-area/TextArea';
import Input from '../../../input/Input';

import './tombstone-form.scss';
import ReactSelect from 'react-select';

type OnComplete = () => void;
interface Props {
  users: User[];
  tombstone?: Tombstone;
  client?: Client;
  clients?: Client[];
  categories?: Category[];
  onSave: (tombstone: Tombstone, onComplete: OnComplete) => void;
}

interface TombstoneState extends FormState {
  category?: string;
  customCategory?: string;
  categoryId?: number;
  subCategoryId?: number;
  subCategory?: string;
  description?: string;
  sizeOfTransaction?: number;
  feeOfTransaction?: number;
  linkedPeople?: string;
  linkedPeopleIds?: number[] | string[];
  date?: Date | string;
  clientId?: string | number;
}

const rules: ValidationRules<TombstoneState> = {
  validates: {
    categoryId: ['isPresent'],
    date: ['isPresent']
  }
};

const formify = (tombstone: Tombstone, client?: Client): TombstoneState =>
  ({
    clientId: client?.id ?? tombstone?.client_id,
    date: isUndefined(tombstone?.date) ? undefined : new Date(tombstone?.date),
    categoryId: tombstone?.category_id,
    category: tombstone?.category,
    subCategoryId: tombstone?.sub_category_id,
    subCategory: tombstone?.sub_category,
    description: tombstone?.description,
    sizeOfTransaction: tombstone?.size_of_transaction,
    feeOfTransaction: tombstone?.fee_of_transaction,
    linkedPeople: ((tombstone?.linked_people as User[]) ?? [])
      .map((person: User) => person.full_name)
      .join(', '),
    linkedPeopleIds: ((tombstone?.linked_people as User[]) ?? []).map((person: User) => person.id)
  } as TombstoneState);

const serverify = (form: TombstoneState): Tombstone => ({
  client_id: form?.clientId,
  category_id: form.categoryId,
  category: form.customCategory,
  sub_category_id: form.subCategoryId,
  description: form.description,
  date: form.date,
  size_of_transaction: form.sizeOfTransaction,
  fee_of_transaction: form.feeOfTransaction,
  linked_people: form.linkedPeopleIds as string[]
});

const TombstoneForm = ({ categories, clients, users, tombstone, client, onSave }: Props) => {
  const [form, setForm] = useFormState<TombstoneState>(formify(tombstone), rules);
  const handleChange = (newState: OnChangeType | DateChangeType) =>
    setForm({ ...form, ...(newState as TombstoneState) });

  const clientCategories: Category[] = useCategories();
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const { setElement, setShowModal } = useRemoval();

  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(!tombstone);

  useEffect(() => {
    setForm(formify(tombstone));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tombstone]);

  useEffect(() => {
    const selectedCategory = clientCategories.find((cat) => cat.id === Number(form.categoryId));

    if (selectedCategory && selectedCategory.sub_categories) {
      const activeSubCategories = selectedCategory.sub_categories.filter(
        (subCat) => subCat['status'] === 'active'
      );
      setSubCategories(activeSubCategories);
    } else {
      setSubCategories([]);
    }
  }, [categories, form.categoryId]);

  const actions = useMemo(() => {
    const handleSave = () => {
      setLoading(true);
      const payload: Tombstone = serverify(form);
      onSave(payload, () => {
        setLoading(false);
        setEditMode(false);
      });
    };

    const handleRemove = () => {
      setElement(client);
      setShowModal(true);
    };

    return editMode ? (
      <>
        <SaveIcon onClick={!form?.valid ? noOp : handleSave} />
        {tombstone?.id ? <RemoveIcon onClick={handleRemove} /> : <></>}
      </>
    ) : (
      <>
        <EditIcon onClick={() => setEditMode(true)} />
        {tombstone?.id ? <RemoveIcon onClick={handleRemove} /> : <></>}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, editMode, form, onSave]);

  const SelectUsers = useMemo(() => {
    if (!editMode) {
      return [];
    }

    return users
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map((user: User) => ({ label: user.full_name, value: user.id }));
  }, [editMode, users]);

  const defaultSelectUsers = useMemo(() => {
    if (!editMode) {
      return [];
    }

    return users
      .filter((user: User) => form.linkedPeopleIds.map(String)?.includes(user.id.toString()))
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map((user: User) => ({ label: user.full_name, value: user.id }));
  }, [editMode, form.linkedPeopleIds, users]);

  const [selectedOptions, setSelectedOptions] = useState(defaultSelectUsers);

  const mappedClients: SelectItem[] = (clients ?? [])
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((client: Client) => ({
      label: client.name,
      value: client.id
    }));

  const mappedCategories: SelectItem[] = (categories ?? clientCategories)
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((category: Category) => ({
      label: category.name,
      value: category.name === 'Other' ? -100 : category.id
    }));

  const mappedSubCategories: SelectItem[] = useMemo(() => {
    return subCategories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((subCategory: SubCategory) => ({
        label: subCategory.name,
        value: subCategory.id
      }));
  }, [subCategories]);

  const handleSelectChange = (data: any) => {
    setSelectedOptions(data);
    handleChange({ linkedPeopleIds: data.map((item: any) => item.value) });
  };

  useEffect(() => {
    if (editMode) {
      const selectedUserOptions = users
        .filter((user: User) => form.linkedPeopleIds.map(String).includes(user.id.toString()))
        .map((user: User) => ({ label: user.full_name, value: user.id }));
      setSelectedOptions(selectedUserOptions);
    } else {
      setSelectedOptions([]); // Reset selected options when not in edit mode
    }
  }, [editMode, form.linkedPeopleIds, users]);

  const handlePersonnelRemove = (personId: string | number) => {
    const selectedUserOptions = selectedOptions.filter((user: any) => user.value !== personId);
    setSelectedOptions(selectedUserOptions);
    handleChange({ linkedPeopleIds: selectedUserOptions.map((item: any) => item.value) });
  };

  return (
    <article className="tombstone-form">
      <DetailsCard action={actions} loading={loading}>
        {!client && (
          <InfoDetail
            title="Client Name"
            editMode={editMode}
            editableElement={
              <Select
                name="clientId"
                label=""
                items={mappedClients}
                placeholder="Select Client"
                value={form?.clientId}
                onChange={handleChange}
              />
            }
          />
        )}
        {!!client && <InfoDetail title="Client Name" value={client?.name} />}
        <InfoDetail
          title="Description"
          editMode={editMode}
          value={form?.description}
          editableElement={
            <TextArea
              name="description"
              label=""
              placeholder="Enter Description"
              value={form?.description}
              onChange={handleChange}
            />
          }
        />
        <InfoDetail
          title="Date"
          value={form?.date}
          editMode={editMode}
          editableElement={
            <Datepicker
              name="date"
              value={form?.date}
              error={form?.messages?.date}
              placeholder="Select a date"
              onChange={handleChange}
            />
          }
        />
        <InfoDetail
          title="Size of Transaction"
          editMode={editMode}
          value={
            form?.sizeOfTransaction
              ? parseFloat(form?.sizeOfTransaction.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : ''
          }
          editableElement={
            <Input
              type="number"
              name="sizeOfTransaction"
              label=""
              placeholder="Enter Size of Transaction"
              value={form?.sizeOfTransaction}
              onChange={handleChange}
            />
          }
        />
        <InfoDetail
          title="Fee of Transaction"
          editMode={editMode}
          value={
            form?.feeOfTransaction
              ? parseFloat(form?.feeOfTransaction.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : ''
          }
          editableElement={
            <Input
              type="number"
              name="feeOfTransaction"
              label=""
              placeholder="Enter Fee of Transaction"
              value={form?.feeOfTransaction}
              onChange={handleChange}
            />
          }
        />
        <InfoDetail
          title="Category"
          editMode={editMode}
          value={form?.category}
          editableElement={
            <Select
              name="categoryId"
              placeholder="Select Category"
              items={mappedCategories}
              value={form?.categoryId}
              error={form?.messages?.categoryId}
              onChange={handleChange}
            />
          }
        />
        {subCategories.length > 0 && (
          <InfoDetail
            title="Subcategory"
            editMode={editMode}
            value={form?.subCategory}
            editableElement={
              <Select
                name="subCategoryId"
                placeholder="Select Subcategory"
                items={mappedSubCategories}
                value={form?.subCategoryId}
                onChange={handleChange}
              />
            }
          />
        )}
        {Number(form?.categoryId) === -100 && (
          <InfoDetail
            title="Custom Category/Tag"
            editMode={editMode}
            value={form?.customCategory}
            editableElement={
              <Input
                name="customCategory"
                placeholder="Enter a custom Category"
                value={form?.customCategory}
                error={form?.messages.customCategory}
                onChange={handleChange}
              />
            }
          />
        )}
        <InfoDetail
          title="Linked People"
          editMode={editMode}
          value={form?.linkedPeople}
          editableElement={
            <article className="select">
            <ReactSelect
              isMulti
              isSearchable={true}
              placeholder="Linked People"
              options={SelectUsers}
              onChange={handleSelectChange}
              name="linkedPeopleIds"
              defaultValue={defaultSelectUsers}
              closeMenuOnSelect={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: '#c4960c'
                }
              })}
            />
          </article>
          }
        />
        { editMode && (
        <div className="personnel-list">
          {selectedOptions.map(person => (
            <div key={person.value} className="personnel-pill">
              {person.label}
              <button onClick={() => handlePersonnelRemove(person.value)}>x</button>
            </div>
          ))}
        </div>
      )}
      </DetailsCard>
    </article>
  );
};

export default TombstoneForm;
