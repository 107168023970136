import { FileData } from '../../interfaces/config/file.interface';

import { ReactComponent as PdfIcon } from '../../icons/pdf-icon.svg';
import { ReactComponent as DocIcon } from '../../icons/doc-icon.svg';
import { ReactComponent as XlsIcon } from '../../icons/xls-icon.svg';
import { ReactComponent as JpgIcon } from '../../icons/jpg-icon.svg';
import { ReactComponent as PngIcon } from '../../icons/png-icon.svg';

interface Props {
  file: FileData;
  color?: string;
  width?: number;
  height?: number;
  onClick?: (e: Event) => void;
}

const DocumentIcon = ({ file, color, onClick, width, height }: Props) => {
  const re = /(?:\.([^.]+))?$/;
  const ext = re.exec(file.name)[1]?.toLowerCase();

  return (
    <div
      onClick={(e: any) => {
        onClick(e);
      }}
    >
      {ext === 'pdf' ? (
        <PdfIcon color={color} width={width ?? 32} height={height ?? 32} />
      ) : ext === 'doc' || ext === 'docx' ? (
        <DocIcon color={color} width={width ?? 32} height={height ?? 32} />
      ) : ext === 'xls' || ext === 'xlsx' || ext === 'csv' ? (
        <XlsIcon color={color} width={width ?? 32} height={height ?? 32} />
      ) : ext === 'jpg' || ext === 'jpeg' ? (
        <JpgIcon color={color} width={width ?? 32} height={height ?? 32} />
      ) : ext === 'png' ? (
        <PngIcon color={color} width={width ?? 32} height={height ?? 32} />
      ) : null}
    </div>
  );
};

export default DocumentIcon;
