import React, { ChangeEvent, LegacyRef, ReactNode } from 'react';

import { noOp } from '@neslotech/utils';

import './file-upload.scss';

interface Props {
  fileTypes?: string;
  fileRef: LegacyRef<HTMLInputElement>;
  children: ReactNode | ReactNode[];
  onChange: (file: File) => void;
}

export const FileUpload = ({ fileTypes, fileRef, children, onChange }: Props) => {
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const selectedFile: File = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    onChange(selectedFile);
  };

  return (
    <article className="file-upload">
      {children}
      <input accept={fileTypes} type="file" hidden ref={fileRef} onChange={onFileChange} />
    </article>
  );
};

FileUpload.defaultProps = {
  onChange: noOp,
  fileTypes: 'image/*'
};
