import React from 'react';

import { Prospect } from '../../../interfaces/employee/prospect.interface';

import ProspectCard from '../card/ProspectCard';

import './prospect-list.scss';

interface Props {
  prospects: Prospect[];
  loading: boolean;
}

const ProspectList = ({ prospects }: Props) => (
  <article className="prospect-list">
    {prospects.map((prospect: Prospect) => (
      <ProspectCard key={prospect.id} prospect={prospect} />
    ))}
  </article>
);

export default ProspectList;
