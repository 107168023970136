import { Mandate } from '../../interfaces/client/mandate.interface';

import {
  LoadMandateAction,
  OnComplete,
  RemoveMandateAction,
  SaveMandateAction,
  UpdateMandateAction,
  LoadMandatesAction
} from './mandate.types';

export class MandateActions {
  static readonly LOAD_MANDATES: string = '[MANDATE_STORE] Load mandates';
  static readonly SET_MANDATES: string = '[MANDATE_STORE] Set mandates';

  static readonly LOAD_MANDATE: string = '[MANDATE_STORE] Load mandate';
  static readonly SET_MANDATE: string = '[MANDATE_STORE] Set mandate';

  static readonly SAVE_MANDATE: string = '[MANDATE_STORE] Save mandate';
  static readonly UPDATE_MANDATE: string = '[MANDATE_STORE] Save mandate';
  static readonly REMOVE_MANDATE: string = '[MANDATE_STORE] Remove mandate';

  static loadMandates = (onComplete: OnComplete): LoadMandatesAction => ({
    type: MandateActions.LOAD_MANDATES,
    onComplete
  });

  static saveMandate = (
    id: number | string,
    payload: Mandate,
    onComplete: OnComplete
  ): SaveMandateAction => ({
    type: MandateActions.SAVE_MANDATE,
    id,
    payload,
    onComplete
  });

  static loadMandate = (id: number | string, onComplete: OnComplete): LoadMandateAction => ({
    type: MandateActions.LOAD_MANDATE,
    id,
    onComplete
  });

  static updateMandate = (
    id: number | string,
    payload: Mandate,
    onComplete: OnComplete
  ): UpdateMandateAction => ({
    type: MandateActions.UPDATE_MANDATE,
    id,
    payload,
    onComplete
  });

  static removeMandate = (id: number | string, onComplete: OnComplete): RemoveMandateAction => ({
    type: MandateActions.REMOVE_MANDATE,
    id,
    onComplete
  });
}
