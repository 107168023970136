import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector, useAuth } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Payslip } from '../../../../interfaces/employee/payslip.interface';

import { LoadPayslipsAction } from '../../../../actions/payslip/payslip.types';
import { PayslipActions } from '../../../../actions/payslip/payslip.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import PayslipList from '../../../../components/payslips/list/PayslipList';
import Button from '../../../../components/button/Button';

import RemovePayslipContainer from '../remove/RemovePayslip.container';

const PayslipsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadPayslipsAction> = useAppDispatch();
  const payslips: Payslip[] = useAppSelector(
    ({ payslip_store }: AppState) => payslip_store.payslips
  );

  const [loading, setLoading] = useState<boolean>(true);
  const { isAdmin } = useAuth();

  useEffect(() => {
    dispatch(PayslipActions.loadPayslips(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Payslips"
      action={isAdmin && <Button label="Add Payslip" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <PayslipList payslips={payslips} loading={loading} />
        <RemovePayslipContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default PayslipsContainer;
