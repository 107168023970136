import React from 'react';
import './alphabet-layout.scss';

interface AlphabetLayoutProps {
  selectedLetter: string;
  onLetterClick: (letter: string | null) => void;
}

const AlphabetLayout: React.FC<AlphabetLayoutProps> = ({ selectedLetter, onLetterClick }) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  return (
    <div className="alphabet-layout">
      {letters.map(letter => (
        <button key={letter} onClick={() => onLetterClick(letter)} className={letter === selectedLetter ? 'selected' : ''} >
          {letter}
        </button>
      ))}
	  <button onClick={() => onLetterClick(null)}>Clear selection</button>
    </div>
  );
};

export default AlphabetLayout;