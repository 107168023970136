import React from 'react';
import { RouteObject } from 'react-router-dom';

import ClientTypesCrumb from '../../crumbs/admin/client-types/ClientTypes.crumb';
import ClientTypeCrumb from '../../crumbs/admin/client-types/ClientType.crumb';
import AddClientTypeCrumb from '../../crumbs/admin/client-types/AddClientType.crumb';

import ClientTypesContainer from '../../containers/admin/client-types/list/ClientTypes.container';
import AddClientTypeContainer from '../../containers/admin/client-types/add/AddClientType.container';
import ClientTypeContainer from '../../containers/admin/client-types/details/ClientType.container';

import AdminRoute from '../AdminRoute';

const clientTypesRoutes: RouteObject[] = [
  {
    path: 'client-types',
    handle: {
      crumb: () => <ClientTypesCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <ClientTypesContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddClientTypeContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddClientTypeCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <ClientTypeContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <ClientTypeCrumb />
        }
      }
    ]
  }
];

export default clientTypesRoutes;
