import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { AddNoticeAction } from '../actions/system/system.types';
import { SystemActions } from '../actions/system/system.actions';

import { useAppDispatch, useAuth } from '../hooks';

import ProtectedRoute from './ProtectedRoute';

interface Props {
  children: ReactNode | ReactNode[];
}

const AdminRoute = ({ children }: Props) => {
  const dispatch: Dispatch<AddNoticeAction> = useAppDispatch();
  const { profile } = useAuth();

  if (!profile) {
    return <ProtectedRoute>{children}</ProtectedRoute>;
  }

  if (profile?.role !== 'admin') {
    dispatch(SystemActions.addNotice('You are not authorized to view this page!', SNACK_CRITICAL));
    return <Navigate to="/dashboard" replace />;
  }

  return <ProtectedRoute>{children}</ProtectedRoute>;
};

export default AdminRoute;
