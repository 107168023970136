import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { PerformanceReview } from '../../interfaces/employee/performance-review.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getPerformanceReviewsEndpoint = (): string => `${API_HOST}/performance_reviews`;
export const getPerformanceReviewsRequest = (): RequestOptions => [
  getPerformanceReviewsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddPerformanceReviewRequest = (payload: PerformanceReview): RequestOptions => [
  getPerformanceReviewsEndpoint(),
  getHttpPostOptions({ performance_review: payload }, getAuthHeaders())
];

const getPerformanceReviewEndpoint = (id: number | string): string =>
  `${API_HOST}/performance_reviews/${id}`;
export const getPerformanceReviewRequest = (id: number | string): RequestOptions => [
  getPerformanceReviewEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdatePerformanceReviewRequest = (
  id: number | string,
  payload: PerformanceReview
): RequestOptions => [
  getPerformanceReviewEndpoint(id),
  getHttpPutOptions({ performance_review: payload }, getAuthHeaders())
];

export const getRemovePerformanceReviewRequest = (id: string | number): RequestOptions => [
  getPerformanceReviewEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
