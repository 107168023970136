import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Category } from '../../interfaces/config/category.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getCategoriesEndpoint = (filter?: string): string =>
  filter ? `${API_HOST}/categories?filter=${filter}` : `${API_HOST}/categories`;
export const getCategoriesRequest = (filter?: string): RequestOptions => [
  getCategoriesEndpoint(filter),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddCategoryRequest = (payload: Category): RequestOptions => [
  getCategoriesEndpoint(),
  getHttpPostOptions({ category: payload }, getAuthHeaders())
];

const getCategoryEndpoint = (id: number | string): string => `${API_HOST}/categories/${id}`;
export const getCategoryRequest = (id: number | string): RequestOptions => [
  getCategoryEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateCategoryRequest = (
  id: number | string,
  payload: Category
): RequestOptions => [
  getCategoryEndpoint(id),
  getHttpPutOptions({ category: payload }, getAuthHeaders())
];

export const getRemoveCategoryRequest = (id: string | number): RequestOptions => [
  getCategoryEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
