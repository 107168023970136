import React from 'react';
import { RouteObject } from 'react-router-dom';

import EventsContainer from '../../containers/admin/events/list/Events.container';
import AddEventContainer from '../../containers/admin/events/add/AddEvent.container';
import EventContainer from '../../containers/admin/events/details/Event.container';

import EventsCrumb from '../../crumbs/admin/events/Events.crumb';
import AddEventCrumb from '../../crumbs/admin/events/AddEvent.crumb';
import EventCrumb from '../../crumbs/admin/events/Event.crumb';

import AdminRoute from '../AdminRoute';

const eventRoutes: RouteObject[] = [
  {
    path: 'events',
    handle: {
      crumb: () => <EventsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <EventsContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddEventContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddEventCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <EventContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <EventCrumb />
        }
      }
    ]
  }
];

export default eventRoutes;
