import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import RemovalProvider from '../../../../../../providers/Removal.provider';

import { DealSheet } from '../../../../../../interfaces/client/deal-sheet.interface';

import { LoadDealSheetsAction } from '../../../../../../actions/client/deal-sheet/deal-sheet.types';
import { ClientDealSheetActions } from '../../../../../../actions/client/deal-sheet/deal-sheet.actions';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import DealSheetList from '../../../../../../components/clients/details/deal-sheets/list/DealSheetList';
import Button from '../../../../../../components/button/Button';

import RemoveDealSheetContainer from '../remove/RemoveDealSheet.container';

const DealSheetsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadDealSheetsAction> = useAppDispatch();
  const { id } = useParams<string>();

  const dealSheets: DealSheet[] = useAppSelector(
    ({ client_store }: AppState) => client_store.dealSheets
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(ClientDealSheetActions.loadDealSheets(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Deal Sheets"
      action={<Button label="Add Deal Sheet" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <DealSheetList dealSheets={dealSheets} loading={loading} />
        <RemoveDealSheetContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default DealSheetsContainer;
