import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Prospect } from '../../interfaces/employee/prospect.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getProspectsEndpoint = (): string => `${API_HOST}/prospects`;
export const getProspectsRequest = (): RequestOptions => [
  getProspectsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddProspectRequest = (payload: Prospect): RequestOptions => [
  getProspectsEndpoint(),
  getHttpPostOptions({ prospect: payload }, getAuthHeaders())
];

const getProspectEndpoint = (id: number | string): string => `${API_HOST}/prospects/${id}`;
export const getProspectRequest = (id: number | string): RequestOptions => [
  getProspectEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateProspectRequest = (
  id: number | string,
  payload: Prospect
): RequestOptions => [
  getProspectEndpoint(id),
  getHttpPutOptions({ prospect: payload }, getAuthHeaders())
];

export const getRemoveProspectRequest = (id: string | number): RequestOptions => [
  getProspectEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getConvertProspectEndpoint = (id: number | string): string =>
  `${API_HOST}/prospects/${id}/convert`;
export const getConvertProspectRequest = (
  id: number | string,
  payload: Prospect
): RequestOptions => [
  getConvertProspectEndpoint(id),
  getHttpPostOptions({ prospect: payload }, getAuthHeaders())
];

const getProspectDocumentEndpoint = (id: number | string, documentId: number | string): string =>
  `${API_HOST}/prospects/${id}/documents/${documentId}`;
export const getRemoveProspectDocumentRequest = (
  id: string | number,
  documentId: number
): RequestOptions => [
  getProspectDocumentEndpoint(id, documentId),
  getHttpDeleteOptions(getAuthHeaders())
];
