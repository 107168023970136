import React from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Note } from '../../../../../../interfaces/client/note.interface';

import { useAppDispatch } from '../../../../../../hooks';

import { AddNoteAction } from '../../../../../../actions/client/note/note.types';
import { NoteActions } from '../../../../../../actions/client/note/note.actions';

import { Modal } from '../../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import NoteForm from '../../../../../../components/clients/details/notes/form/NoteForm';

interface Props {
  setShow: (value: boolean) => void;
}

const AddNoteContainer = ({ setShow }: Props) => {
  const dispatch: Dispatch<AddNoteAction> = useAppDispatch();
  const { id } = useParams();

  const onClose = () => setShow(false);

  const handleSave = (payload: Note, onComplete: () => void) => {
    dispatch(NoteActions.addNote(id as string, payload, onClose, onComplete));
  };

  return (
    <Modal>
      <ModalLayout onClose={onClose} title="Add Note">
        <NoteForm onSave={handleSave} />
      </ModalLayout>
    </Modal>
  );
};

export default AddNoteContainer;
