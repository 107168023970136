import React, { useState } from 'react';

import Button from '../../button/Button';

import './convert-modal.scss';

type OnConfirm = (onComplete: () => void) => void;

interface Props {
  title?: string;
  loading?: boolean;
  onConfirm: OnConfirm;
}

const ConvertModal = ({ title, onConfirm }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = (): void => {
    onConfirm(() => setLoading(false));
  };

  return (
    <article className="convert-modal">
      <main className="convert-modal__message">
        <p>
          Are you sure you want to convert <span>{title}</span>?
        </p>
      </main>
      <footer className="convert-modal__actions">
        <Button label="Confirm" onClick={handleConfirm} loading={loading} hollow />
      </footer>
    </article>
  );
};

export default ConvertModal;
