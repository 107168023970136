import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { ResponseError } from '../../types/response-error.interface';

import { getAddNoteRequest, getNotesRequest, RequestOptions } from '../../tools/api';

import { AddNoteAction, LoadNotesAction } from '../../actions/client/note/note.types';
import { NoteActions } from '../../actions/client/note/note.actions';
import { SystemActions } from '../../actions/system/system.actions';

export function* performLoadNotes({ id, onComplete }: LoadNotesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getNotesRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: NoteActions.SET_NOTES, notes: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadNotesRequest() {
  yield takeLatest(NoteActions.LOAD_NOTES, performLoadNotes);
}

export function* performAddNote({ id, payload, onSuccess, onComplete }: AddNoteAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddNoteRequest(id, payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('Your note has been uploaded successfully!', SNACK_SUCCESS));

    yield put(NoteActions.loadNotes(id, onComplete));

    yield call(onSuccess);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddNoteRequest() {
  yield takeLatest(NoteActions.ADD_NOTE, performAddNote);
}

export default function* noteSaga() {
  yield all([watchForLoadNotesRequest(), watchForAddNoteRequest()]);
}
