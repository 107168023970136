import React, { useState, useEffect, useMemo, MouseEventHandler } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { atcb_action } from 'add-to-calendar-button';
import { isUndefined, noOp } from '@neslotech/utils';

import { Filter } from '../../types/filter.interface';
import { Event } from '../../interfaces/event/event.interface';
import { Contact } from '../../interfaces/client/contact.interface';
import { DealSheet } from '../../interfaces/client/deal-sheet.interface';
import { Category } from '../../interfaces/config/category.interface';
import { Employee } from '../../interfaces/employee/employee.interface';
import Calendar from './Calendar';

import { filterElement } from '../../tools/filter.util';

import { useFilters } from '../../hooks/useFilters';

import { ReactComponent as ChevronRightIcon } from '../../icons/chevron-right-icon.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar-icon.svg';
import { ReactComponent as AddIcon } from '../../icons/add-icon.svg';

import { Table, TableData, TableHeader } from '../table/Table';
import FilterLayout from '../layout/filter/FilterLayout';
import { DropdownMenuItem } from '../dropdown/DropdownMenu';
import { RadioGroup } from '../radio/RadioGroup';
import Avatar from '../avatar/Avatar';
import Button from '../button/Button';

import './dashboard.scss';
import { FileData } from '../../interfaces/config/file.interface';

const contactHeaders: TableHeader[] = [
  {
    Header: 'Contact Person',
    accessor: 'key_contact'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Contact Number',
    accessor: 'contact_number'
  },
  {
    Header: 'Created By',
    accessor: 'created_by'
  },
  {
    Header: 'Date',
    accessor: 'created_at'
  },
  {
    Header: 'Last Contactor',
    accessor: 'last_contactor'
  },
  {
    Header: 'Last Date of Contact',
    accessor: 'last_contact_date'
  }
];

const dealSheetHeaders: TableHeader[] = [
  {
    Header: 'Client',
    accessor: 'avatar'
  },
  {
    Header: 'Deal Name',
    accessor: 'name'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'Exp Closing Date',
    accessor: 'closing_date'
  },
  {
    Header: 'Fee Structure',
    accessor: 'fee_structure'
  },
  {
    Header: 'Personnel',
    accessor: 'personnel'
  }
];

const eventsHeaders: TableHeader[] = [
  {
    Header: 'Event Name',
    accessor: 'avatar'
  },
  {
    Header: 'Event Link',
    accessor: 'link'
  },
  {
    Header: 'Event Date',
    accessor: 'date'
  },
  {
    Header: 'Event Itinerary',
    accessor: 'document'
  },
  {
    Header: '',
    accessor: 'action',
    disableSortBy: true
  },
  {
    Header: 'Tooltip Content',
    accessor: 'tooltipContent'
  }
];

interface Props {
  events: Event[];
  contacts: Contact[];
  dealSheets: DealSheet[];
  categories: Category[];
  employees: Employee[];
}

interface TableProps {
  rows: DealSheet[];
}

const formify = (dealSheets: DealSheet[], filters: Filter): DealSheet[] =>
  dealSheets.filter((dealSheet: DealSheet) =>
    // @ts-ignore
    filterElement(dealSheet, filters)
  );

const DealSheetTable = ({ rows }: TableProps) => {
  const navigate: NavigateFunction = useNavigate();
  const { filters, setFilterDefaults } = useFilters();

  useEffect(() => {
    setFilterDefaults({ category_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dealSheetRows: TableData[] = useMemo(() => {
    return formify(rows, filters).map<TableData>((dealSheet: DealSheet) => ({
      ...dealSheet,
      avatar: <Avatar filled image={dealSheet.client_image} name={dealSheet.client} />,
      onClick: () => navigate(`/core/clients/${dealSheet.client_id}/deal-sheets/${dealSheet.id}`)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, filters]);

  return (
    <section>
      <Table cols={dealSheetHeaders} rowData={dealSheetRows} />
    </section>
  );
};

const Dashboard = ({ events, contacts, dealSheets, categories, employees }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Category',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="category_id"
            onChange={onChange}
            value={filters?.category_id as string}
            items={categories
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((category: Category) => ({
                label: category.name,
                value: category.id
              }))}
          />
        )
      }
    ];
  }, [categories]);

  const eventRows: TableData[] = useMemo(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    return events
      .map<TableData>((event: Event) => ({
        ...event,
        tooltipContent: event.description,
        avatar: <Avatar filled image={event.image} name={event.name} />,
        document: isUndefined(event.document) ? undefined : (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <Button
              label="Download"
              onClick={() => {
                window.open((event.document as FileData).url, '_blank');
              }}
            />
          </div>
        ),
        action: (
          <div onClick={(e) => e.stopPropagation()}>
            <AddToCalendarButton
              name={event.name}
              startDate={event.date as string}
              options={['Apple', 'Google', 'Yahoo', 'iCal']}
            />
          </div>
        ),
        link: (
          <a href={event.link} target="_blank" rel="noreferrer">
            {event.link}
          </a>
        ),
        onClick: undefined
      }))
      .filter((event: any) => {
        return event.date >= formattedDate;
      })
      .sort((a: any, b: any) => (a.date > b.date ? 1 : -1));
  }, [events]);

  const contactRows: TableData[] = useMemo(() => {
    return contacts.map<TableData>((contact: Contact) => ({
      ...contact,
      onClick: contact.client_id
        ? () => navigate(`/core/clients/${contact.client_id}/contacts/${contact.id}`)
        : () => navigate(`/core/contacts?contactId=${contact.id}`)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    const button = document.getElementById('my-default-button');
    const date = (event.currentTarget as HTMLElement).dataset.date;
    const name = (event.currentTarget as HTMLElement).dataset.name;
    const config: Record<string, any> = {
      name: name,
      startDate: date,
      options: ['Apple', 'Google', 'Yahoo', 'iCal']
    };
    atcb_action(config, button);
  };

  const [isCalendarVisible, setCalendarVisibility] = useState(false);

  const toggleCalendar = () => {
    setCalendarVisibility(!isCalendarVisible);
    const calendarElement = document.querySelector('.calendar');
    if (isCalendarVisible) {
      document.getElementById('showcalendar').style.marginBottom = '0px';
    } else {
      document.getElementById('showcalendar').style.marginBottom = '30px';
    }
    if (calendarElement instanceof HTMLDivElement) {
      calendarElement.style.borderRadius = 'none';
      if (isCalendarVisible) {
        calendarElement.style.width = '50px';
        calendarElement.style.height = '50px';
        calendarElement.style.top = '-6.5rem';
        calendarElement.style.padding = '0px';
        calendarElement.style.display = 'flex';
        calendarElement.style.backgroundColor = 'rgb(69, 153, 223)';
      } else {
        calendarElement.style.display = 'block';
        calendarElement.style.top = '-7.5rem';
        calendarElement.style.width = '380px';
        calendarElement.style.height = '120%';
        calendarElement.style.padding = '20px 20px';
        calendarElement.style.backgroundColor = '#ffff';
      }
    }
    const stickyElements = document.querySelector('.sticky');

    if (stickyElements instanceof HTMLDivElement) {
      stickyElements.style.display = 'block';
      stickyElements.style.top = '-7.5rem';
      stickyElements.style.position = 'sticky';
    }
  };

  const eventss = useMemo(() => {
    type MyStyles = {
      backgroundColor: string;
      color: string;
      width: string;
      fontSize: string;
      borderRadius: string;
      height: string;
      display: string;
      flexDirection: FlexDirection;
      justifyContent: string;
      marginBottom: string;
      padding: string;
    };

    type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
    const styles: MyStyles[] = [
      {
        backgroundColor: 'rgb(69, 153, 223)',
        color: '#ffff',
        fontSize: '15px',
        borderRadius: '10px',
        width: '100%',
        height: '75px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        marginBottom: '30px',
        padding: '10px'
      },
      {
        backgroundColor: '#E25D33',
        color: '#ffff',
        width: '100%',
        fontSize: '15px',
        borderRadius: '10px',
        height: '75px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        marginBottom: '30px',
        padding: '10px'
      }
    ];

    const formatDate = (dateString: string | Date) => {
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    };
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];

    /* eslint-disable react-hooks/exhaustive-deps*/
    events = events
      .filter((event: any) => {
        return event.date >= formattedDate;
      })
      .sort((a: any, b: any) => (a.date > b.date ? 1 : -1));

    return events.map((event, index) => (
      <button
        key={event.id}
        id="my-default-button"
        data-date={event.date}
        data-name={event.name}
        className="wbutton"
        style={styles[index % styles.length]}
        onClick={handleClick}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CalendarIcon style={{ display: 'inline', marginRight: '10px' }} />
            {formatDate(event.date)}
          </div>
          <AddIcon style={{ display: 'inline', marginRight: '10px' }} />
        </div>
        <div>{event.name}</div>
      </button>
    ));
  }, [events]);

  const employeeBirthdays = useMemo(() => {
    const formatDate = (dateString: string | Date) => {
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    };

    return employees
      .filter((employee) => new Date(employee.birthday).getMonth() === calendarDate.getMonth())
      .map((item) => (
        <div className="employee_birthday_wrapper">
          <div className="employee_birthday_content">
            {`${item.first_name} ${item.last_name}`} : {formatDate(item.birthday)}
          </div>
        </div>
      ));
  }, [employees, calendarDate]);

  return (
    <article className="dashboard">
      <div className="calendar">
        <button className="calenicon" id="showcalendar" onClick={toggleCalendar}>
          <CalendarIcon />
        </button>
        {isCalendarVisible && (
          <div className="sticky">
            <Calendar
              events={events}
              employees={employees}
              calendarDate={calendarDate}
              setCalendarDate={setCalendarDate}
            />
            {eventss}
            {employeeBirthdays}
            <Button label="Add New Event" onClick={() => navigate('/admin/events/add')} />
          </div>
        )}
      </div>
      <section>
        <article className="dashboard__table">
          <header>
            <h4>Deal Sheet</h4>
          </header>
          <FilterLayout menuItems={menuItems}>
            <DealSheetTable rows={dealSheets} />
          </FilterLayout>
        </article>
      </section>
      <section>
        <article className="dashboard__table">
          <header>
            <h4>Events</h4>
          </header>
          <section>
            <Table cols={eventsHeaders} rowData={eventRows} />
          </section>
        </article>
      </section>
      <section>
        <article className="dashboard__table">
          <header>
            <h4>Recent Contacts</h4>
          </header>
          <section>
            <Table cols={contactHeaders} rowData={contactRows} />
          </section>
        </article>
      </section>
    </article>
  );
};

export default Dashboard;
