import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../types/state.interface';
import { Profile } from '../../interfaces/profile/profile.interface';

import { useAppSelector } from '../../hooks';

const ProfileCrumb = () => {
  const profile: Profile = useAppSelector(
    ({ profile_store }: AppState) => profile_store.profile
  ) as Profile;

  return <NavLink to="/profile/details">{profile?.full_name}</NavLink>;
};

export default ProfileCrumb;
