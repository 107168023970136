import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { Mandate } from '../../../../interfaces/client/mandate.interface';
import { Client } from '../../../../interfaces/client/client.interface';

import { AddMandateAction } from '../../../../actions/client/mandate/mandate.types';
import { LoadTombstonesAction } from '../../../../actions/tombstone/tombstone.types';
import { MandateActions } from '../../../../actions/client/mandate/mandate.actions';
import { ClientActions } from '../../../../actions/client/client.actions';
import { SectorActions } from '../../../../actions/sector/sector.actions';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import MandateForm from '../../../../components/clients/details/mandates/MandateForm';
import { Sector } from '../../../../interfaces/config/sector.interface';

const AddMandateContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddMandateAction | LoadTombstonesAction> = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const clients: Client[] = useAppSelector(({ client_store }) => client_store.clients);
  const sectors: Sector[] = useAppSelector(({ sector_store }) => sector_store.sectors);

  useEffect(() => {
    dispatch(SectorActions.loadSectors(noOp));
    dispatch(ClientActions.loadClients(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: Mandate): void => {
    dispatch(
      MandateActions.addMandate(payload.client_id, payload, () =>
        navigate(`/core/clients/${payload.client_id}/mandates`)
      )
    );
  };

  return (
    <PageLayout title="Add Mandate">
      {loading && <Loader filled />}
      {!loading && <MandateForm sectors={sectors} clients={clients} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddMandateContainer;
