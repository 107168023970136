import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Category } from '../../../../interfaces/config/category.interface';

import { CategoryActions } from '../../../../actions/category/category.actions';
import { AddCategoryAction } from '../../../../actions/category/category.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import CategoryForm from '../../../../components/categories/form/CategoryForm';

const AddCategoryContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddCategoryAction> = useAppDispatch();

  const onSave = (payload: Category, onComplete: () => void): void => {
    dispatch(CategoryActions.addCategory(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Category">
      <CategoryForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddCategoryContainer;
