import React from 'react';
import { RouteObject } from 'react-router-dom';

import UsersContainer from '../../containers/admin/users/list/Users.container';
import UserContainer from '../../containers/admin/users/details/User.container';
import AddUserContainer from '../../containers/admin/users/add/AddUser.container';

import UsersCrumb from '../../crumbs/admin/users/Users.crumb';
import AddUserCrumb from '../../crumbs/admin/users/AddUser.crumb';
import UserCrumb from '../../crumbs/admin/users/User.crumb';

import AdminRoute from '../AdminRoute';

const userRoutes: RouteObject[] = [
  {
    path: 'users',
    handle: {
      crumb: () => <UsersCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <UsersContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddUserContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddUserCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <UserContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <UserCrumb />
        }
      }
    ]
  }
];

export default userRoutes;
