import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Prospect } from '../interfaces/employee/prospect.interface';

import { ProspectActions } from '../actions/prospect/prospect.actions';

export interface ProspectState extends State {
  prospects: Prospect[];
  prospect?: Prospect;
}

export const initialState: ProspectState = {
  prospects: []
};

export const prospectReducer = (state: ProspectState = initialState, action: AnyAction) => {
  if (action.type === ProspectActions.SET_PROSPECTS) {
    const { prospects } = action;
    return {
      ...state,
      prospects
    };
  } else if (action.type === ProspectActions.SET_PROSPECT) {
    const { prospect } = action;
    return {
      ...state,
      prospect
    };
  }

  return state;
};
