import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Precedent } from '../../../interfaces/client/precedent.interface';

import { getAuthHeaders } from '../../auth.util';

import { API_HOST, RequestOptions } from '../index';

const getClientEndpoint = (id: number | string): string => `${API_HOST}/clients/${id}`;
const getPrecedentsEndpoint = (id: number | string): string =>
  `${getClientEndpoint(id)}/precedents`;
export const getPrecedentsRequest = (id: number | string): RequestOptions => [
  getPrecedentsEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getPrecedentEndpoint = (id: number | string, precedentId: number | string): string =>
  `${getClientEndpoint(id)}/precedents/${precedentId}`;
export const getPrecedentRequest = (
  id: number | string,
  precedentId: number | string
): RequestOptions => [getPrecedentEndpoint(id, precedentId), getHttpGetOptions(getAuthHeaders())];

export const getUpdatePrecedentRequest = (
  id: number | string,
  precedentId: number | string,
  payload: Precedent
): RequestOptions => [
  getPrecedentEndpoint(id, precedentId),
  getHttpPutOptions({ precedent: payload }, getAuthHeaders())
];

export const getAddPrecedentRequest = (id: number | string, payload: Precedent): RequestOptions => [
  getPrecedentsEndpoint(id),
  getHttpPostOptions({ precedent: payload }, getAuthHeaders())
];

export const getRemovePrecedentRequest = (
  id: number | string,
  precedentId: number | string
): RequestOptions => [
  getPrecedentEndpoint(id, precedentId),
  getHttpDeleteOptions(getAuthHeaders())
];
