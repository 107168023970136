import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Fund } from '../interfaces/fund/fund.interface';

import { FundActions } from '../actions/fund/fund.actions';

export interface FundState extends State {
  funds: Fund[];
  fund?: Fund;
}

export const initialState: FundState = {
  funds: []
};

export const fundReducer = (state: FundState = initialState, action: AnyAction) => {
  if (action.type === FundActions.SET_FUNDS) {
    const { funds } = action;
    return {
      ...state,
      funds
    };
  } else if (action.type === FundActions.SET_FUND) {
    const { fund } = action;
    return {
      ...state,
      fund
    };
  }

  return state;
};
