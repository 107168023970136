import { createContext } from 'react';

import { Client } from '../../interfaces/client/client.interface';
import { Category } from '../../interfaces/config/category.interface';
import { ClientType } from '../../interfaces/config/client-type.interface';
import { Sector } from '../../interfaces/config/sector.interface';
import { Designation } from '../../interfaces/config/designation.interface';
import { User } from '../../interfaces/user/user.interface';

export interface ClientContextProps {
  id?: string;
  loading: boolean;
  client?: Client;
  categories: Category[];
  sectors: Sector[];
  clientTypes: ClientType[];
  designations: Designation[];
  users: User[];
}

export const ClientContext = createContext<ClientContextProps>({
  loading: true,
  categories: [],
  sectors: [],
  clientTypes: [],
  designations: [],
  users: []
});
