import React from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { ResetPasswordRequest } from '../../../interfaces/auth/reset-password-request.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';

import './reset-password.scss';

interface ResetPasswordState extends StateForm {
  password: string;
  passwordConfirm: string;
}

interface CustomValidationRules extends ValidationRules<ResetPasswordState> {
  arePasswordsEqual: (value: string, form: ResetPasswordState) => string | undefined;
}

const rules: CustomValidationRules = {
  validates: {
    password: ['isPresent'],
    passwordConfirm: ['isPresent', 'arePasswordsEqual']
  },

  arePasswordsEqual(value, form) {
    if (value !== form?.password) {
      return 'Passwords do not match';
    }
  }
};

type ResetFunction = (request: ResetPasswordRequest) => void;
interface Props {
  onReset: ResetFunction;
}

const serverify = ({ password, passwordConfirm }: ResetPasswordState): ResetPasswordRequest => ({
  password,
  password_confirmation: passwordConfirm
});

const ResetPassword = ({ onReset }: Props) => {
  const [form, setForm] = useFormState<ResetPasswordState>(undefined, rules);
  const handleChange = (newState: OnChangeType) =>
    setForm({ ...form, ...(newState as ResetPasswordState) });

  const handleReset = () => {
    const request: ResetPasswordRequest = serverify(form);
    onReset(request);
  };

  return (
    <article className="reset-password">
      <Form>
        <FormRow>
          <Input
            name="password"
            type="password"
            label="Password"
            placeholder="Enter your password"
            onChange={handleChange}
            error={form?.messages?.password}
          />
        </FormRow>
        <FormRow>
          <Input
            name="passwordConfirm"
            type="password"
            label="Password Confirmation"
            placeholder="Enter your password confirmation"
            onChange={handleChange}
            error={form?.messages?.passwordConfirm}
          />
        </FormRow>
        <FormRow>
          <small className="reset-password__password-message">
            When setting a password, you are required to have a password that is at least 8
            characters in length including a capital letter, a number and a special character
          </small>
        </FormRow>
        <FormAction onClick={handleReset} label="Reset Password" disabled={!form?.valid} />
      </Form>
    </article>
  );
};

export default ResetPassword;
