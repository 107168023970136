import React, { ReactNode } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import './info-detail.scss';

interface Props {
  title: string;
  value?: string | ReactNode | Date;
  editableElement?: ReactNode;
  editMode?: boolean;
  onClick?: () => void;
}

const InfoDetail = ({ title, value, editMode, editableElement, onClick = noOp }: Props) => (
  <dl
    className={getClassNames('info-detail', { editMode, clickable: onClick !== noOp })}
    onClick={onClick}
  >
    <dt>{title}:</dt>
    {editMode && editableElement}
    {!editMode && <dd>{value instanceof Date ? value.toLocaleDateString() : value}</dd>}
  </dl>
);

export default InfoDetail;
