import { NavigateFunction } from 'react-router-dom';

import { ExpenseClaim } from '../../interfaces/employee/expense-claim.interface';

import {
  AddExpenseClaimAction,
  LoadExpenseClaimAction,
  LoadExpenseClaimsAction,
  OnComplete,
  RemoveExpenseClaimAction,
  UpdateExpenseClaimAction
} from './expense-claim.types';

export class ExpenseClaimActions {
  static readonly LOAD_EXPENSE_CLAIMS: string = '[EXPENSE_CLAIM_STORE] Load expense claims';
  static readonly DOWNLOAD_EXPENSE_CLAIMS: string = '[EXPENSE_CLAIM_STORE] Download expense claims';
  static readonly SET_EXPENSE_CLAIMS: string = '[EXPENSE_CLAIM_STORE] Set expense claims';

  static readonly LOAD_EXPENSE_CLAIM: string = '[EXPENSE_CLAIM_STORE] Load expense claim';
  static readonly SET_EXPENSE_CLAIM: string = '[EXPENSE_CLAIM_STORE] Set expense claim';

  static readonly UPDATE_EXPENSE_CLAIM: string = '[EXPENSE_CLAIM_STORE] Update expense claim';
  static readonly ADD_EXPENSE_CLAIM: string = '[EXPENSE_CLAIM_STORE] Add expense claim';
  static readonly REMOVE_EXPENSE_CLAIM: string = '[EXPENSE_CLAIM_STORE] Remove expense claim';

  static loadExpenseClaims = (onComplete: OnComplete): LoadExpenseClaimsAction => ({
    type: ExpenseClaimActions.LOAD_EXPENSE_CLAIMS,
    onComplete
  });

  static downloadExpenseClaims = (onComplete: OnComplete): LoadExpenseClaimsAction => ({
    type: ExpenseClaimActions.DOWNLOAD_EXPENSE_CLAIMS,
    onComplete
  });

  static loadExpenseClaim = (
    id: number | string,
    onComplete: OnComplete
  ): LoadExpenseClaimAction => ({
    type: ExpenseClaimActions.LOAD_EXPENSE_CLAIM,
    id,
    onComplete
  });

  static addExpenseClaim = (
    payload: ExpenseClaim,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddExpenseClaimAction => ({
    type: ExpenseClaimActions.ADD_EXPENSE_CLAIM,
    payload,
    navigate,
    onComplete
  });

  static updateExpenseClaim = (
    id: number | string,
    payload: ExpenseClaim,
    onComplete: OnComplete
  ): UpdateExpenseClaimAction => ({
    type: ExpenseClaimActions.UPDATE_EXPENSE_CLAIM,
    id,
    payload,
    onComplete
  });

  static removeExpenseClaim = (
    id: number | string,
    onComplete: OnComplete
  ): RemoveExpenseClaimAction => ({
    type: ExpenseClaimActions.REMOVE_EXPENSE_CLAIM,
    id,
    onComplete
  });
}
