import React, { useEffect, createRef, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId, isUndefined } from '@neslotech/utils';

import { toBase64 } from '../../../tools/file.util';

import { useAuth } from '../../../hooks';

import { Employee } from '../../../interfaces/employee/employee.interface';
import { FileData } from '../../../interfaces/config/file.interface';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import { Datepicker, OnChangeType as DateChangeType } from '../../datepicker/Datepicker';
import FormAction from '../../form/form-action/FormAction';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';
import Select, { OnChangeType as SelectChangeType } from '../../select/Select';
import { FileUpload } from '../../upload/FileUpload';
import Button from '../../button/Button';

import './employee-form.scss';

import { Employeerole } from '../../../interfaces/employeerole/employeerole.interface';

import { LoadEmployeerolesAction } from '../../../actions/employeerole/employeerole.types';
import { EmployeeroleActions } from '../../../actions/employeerole/employeerole.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Dispatch } from '@reduxjs/toolkit';
import { AppState } from '../../../types/state.interface';
import DocumentIcon from '../../document-icon/DocumentIcon';

type OnSave = (payload: Employee, onComplete: () => void) => void;

interface Props {
  employee?: Employee;
  onSave: OnSave;
  onRemoveDocument?: (id: number) => void;
}

export interface EmployeeState extends StateForm {
  firstName: string;
  lastName: string;
  email: string;
  startDate?: string | Date;
  daysOfLeave?: number;
  idNumber?: string;
  taxNumber?: string;
  emergencyContact?: string;
  emergencyContactNumber?: string;
  homeAddress?: string;
  status?: string;
  role?: string;
  erole?: string;
  files?: FileData[];
  employeerole?: string;
  password?: string;
  passwordConfirm?: string;
  birthday?: string | Date;
}

interface CustomValidationRules extends ValidationRules<EmployeeState> {
  arePasswordsEqual: (value: string, form: EmployeeState) => string | undefined;
}

const rules: CustomValidationRules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    email: ['isEmail'],
    password: ['isPresent'],
    passwordConfirm: ['isPresent', 'arePasswordsEqual'],
    birthday: ['isPresent']
  },

  arePasswordsEqual(value, form) {
    if (value !== form?.password) {
      return 'Passwords do not match';
    }
  }
};

const editRules: ValidationRules<EmployeeState> = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    email: ['isEmail'],
    birthday: ['isPresent']
  }
};

const formify = (employee?: Employee): EmployeeState =>
  ({
    firstName: employee?.first_name,
    lastName: employee?.last_name,
    email: employee?.email,
    idNumber: employee?.id_number,
    taxNumber: employee?.tax_number,
    daysOfLeave: employee?.days_of_leave,
    startDate:
      isUndefined(employee?.start_date) || employee?.start_date === null
        ? undefined
        : new Date(employee?.start_date),
    emergencyContact: employee?.emergency_contact,
    emergencyContactNumber: employee?.emergency_contact_number,
    mobileNumber: employee?.mobile_number,
    homeAddress: employee?.home_address,
    status: employee?.status,
    role: employee?.role,
    erole: employee?.erole,
    files: (employee?.id_documents ?? []).map((document: FileData | string) => ({
      id: (document as FileData).id,
      name: (document as FileData).name,
      url: (document as FileData).url,
      date: new Date((document as FileData).date),
      uploaded_by: (document as FileData).uploaded_by
    })),
    birthday:
      isUndefined(employee?.birthday) || employee?.birthday === null
        ? undefined
        : new Date(employee?.birthday)
  } as EmployeeState);

const serverify = (form: EmployeeState) => ({
  first_name: form.firstName,
  last_name: form.lastName,
  email: form.email,
  days_of_leave: form.daysOfLeave,
  start_date: form.startDate,
  id_number: form.idNumber,
  tax_number: form.taxNumber,
  emergency_contact: form.emergencyContact,
  emergency_contact_number: form.emergencyContactNumber,
  mobile_number: form.mobileNumber,
  home_address: form.homeAddress,
  status: form.status,
  role: form.role,
  erole: form.erole,
  id_documents: form.files,
  password: form.password,
  birthday: form.birthday
});

const EmployeeForm = ({ employee, onSave, onRemoveDocument }: Props) => {
  const dispatch: Dispatch<LoadEmployeerolesAction> = useAppDispatch();
  const employeeroles: Employeerole[] = useAppSelector(
    ({ employeerole_store }: AppState) => employeerole_store.employeeroles
  );

  useEffect(() => {
    dispatch(EmployeeroleActions.loadEmployeeroles(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editMode = employee?.email ? true : false;

  const [form, setForm] = useFormState<EmployeeState>(
    formify(employee),
    editMode ? editRules : rules
  );
  const handleChange = (
    newState: OnChangeType | DateChangeType | { files: FileData[] } | SelectChangeType
  ) => setForm({ ...form, ...(newState as EmployeeState) });

  const [loading, setLoading] = useState<boolean>(false);

  const { isAdmin } = useAuth();

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Employee = serverify(form);
    onSave(payload, () => {
      setLoading(false);
    });
  };

  const handleFileChange = async (file: File) => {
    const base64Data = await toBase64(file);
    const fileData: FileData = {
      id: generateId(),
      name: file.name,
      uploaded_by: 'you',
      date: new Date(),
      data: base64Data
    };

    const files = [...form.files, fileData];
    handleChange({ files });
  };

  const fileRef = createRef<HTMLInputElement>();
  const handleRemove = (id: number | string) => {
    const files = form.files.filter((file: FileData) => file.id !== id);
    handleChange({ files });
    if (typeof id === 'string' || !onRemoveDocument) {
      return;
    }

    onRemoveDocument(id as number);
  };

  return (
    <article className="employee-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="firstName"
              label="Employee First Name"
              placeholder="Employee First Name"
              value={form?.firstName}
              error={form?.messages.firstName}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="lastName"
              label="Employee Last Name"
              placeholder="Employee Last Name"
              value={form?.lastName}
              error={form?.messages.lastName}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="email"
              type="email"
              label="Employee Email Address"
              placeholder="Employee Email Address"
              value={form?.email}
              error={form?.messages.email}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluid>
            <Input
              type="password"
              name="password"
              label="Password"
              placeholder="Password"
              onChange={handleChange}
              value={form?.password}
              error={form?.messages.password}
            />
            <Input
              type="password"
              name="passwordConfirm"
              label="Password Confirmation"
              placeholder="Password Confirmation"
              onChange={handleChange}
              value={form?.passwordConfirm}
              error={form?.messages.passwordConfirm}
            />
          </FormRow>
          <FormRow>
            <Datepicker
              name="startDate"
              label="Employee Start Date"
              placeholder="Employee Start Date"
              value={form?.startDate}
              error={form?.messages.startDate}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="idNumber"
              label="Employee ID Number"
              placeholder="Employee ID Number"
              value={form?.idNumber}
              error={form?.messages.idNumber}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <FileUpload fileRef={fileRef} fileTypes="application/pdf" onChange={handleFileChange}>
              <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
            </FileUpload>
            {(form?.files ?? []).map((file: FileData) => (
              <UploadedFile file={file} onRemove={handleRemove} />
            ))}
          </FormRow>
          <FormRow>
            <Input
              name="taxNumber"
              label="Employee Tax Number"
              placeholder="Employee Tax Number"
              value={form?.taxNumber}
              error={form?.messages.taxNumber}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="emergencyContact"
              label="Employee Emergency Contact"
              placeholder="Employee Emergency Contact"
              value={form?.emergencyContact}
              error={form?.messages.emergencyContact}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="emergencyContactNumber"
              label="Employee Emergency Contact Number"
              placeholder="Employee Emergency Contact Number"
              value={form?.emergencyContactNumber}
              error={form?.messages.emergencyContactNumber}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="mobileNumber"
              label="Employee Mobile Number"
              placeholder="Employee Mobile Number"
              value={form?.mobileNumber}
              error={form?.messages.mobileNumber}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="homeAddress"
              label="Employee Home Address"
              placeholder="Employee Home Address"
              value={form?.homeAddress}
              error={form?.messages.homeAddress}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              type="number"
              name="daysOfLeave"
              label="Number of Days of Leave"
              placeholder="Number of Days of Leave"
              value={form?.daysOfLeave}
              error={form?.messages.daysOfLeave}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              items={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' }
              ]}
              name="status"
              label="Status"
              placeholder="Status"
              value={form?.status}
              error={form?.messages.status}
              onChange={handleChange}
            />
          </FormRow>
          {isAdmin && (
            <FormRow>
              <Select
                items={[
                  { label: 'Employee', value: 'employee' },
                  { label: 'Human Resources', value: 'human_resources' },
                  { label: 'Manager', value: 'manager' }
                ]}
                name="role"
                label="Role"
                placeholder="Role"
                value={form?.role}
                error={form?.messages.role}
                onChange={handleChange}
              />
            </FormRow>
          )}
          <FormRow>
            <Select
              name="erole"
              label="Employee Role"
              placeholder="Employee Role"
              value={form?.erole}
              items={employeeroles
                .sort((a, b) =>
                  a.employeerole.toLowerCase() > b.employeerole.toLowerCase() ? 1 : -1
                )
                .map((employeerole: Employeerole) => ({
                  label: employeerole.employeerole,
                  value: employeerole.employeerole
                }))}
              error={form?.messages.erole}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Datepicker
              name="birthday"
              label="Birthday"
              placeholder="Birthday"
              value={form?.birthday}
              error={form?.messages.birthday}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

interface FileProps {
  file: FileData;
  onRemove: (id: number | string) => void;
}

const UploadedFile = ({ file, onRemove }: FileProps) => (
  <article
    style={{ marginTop: 10 }}
    className="uploaded-file"
    onClick={() => window.open(file.url, '_blank')}
  >
    <section className="uploaded-file__name">
      <DocumentIcon file={file} />
      <p>{file.name}</p>
    </section>
    <section style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <small>
        Uploaded by {file.uploaded_by ?? 'you'} on {file.date.toLocaleDateString()}
      </small>
      <RemoveIcon
        color="#003768"
        onClick={(e) => {
          e.stopPropagation();

          onRemove(file.id);
        }}
      />
    </section>
  </article>
);

export default EmployeeForm;
