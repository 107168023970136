import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Employeerole } from '../../../interfaces/employeerole/employeerole.interface';

import { useAppSelector } from '../../../hooks';

const EmployeeroleCrumb = () => {
  const employeerole: Employeerole = useAppSelector(({ employeerole_store }: AppState) => employeerole_store.employeerole) as Employeerole;

  return <NavLink to={`/admin/employeeroles/${employeerole?.id}`}>{employeerole?.employeerole}</NavLink>;
};

export default EmployeeroleCrumb;
