import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Payslip } from '../../interfaces/employee/payslip.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getPayslipsEndpoint = (): string => `${API_HOST}/payslips`;
export const getPayslipsRequest = (): RequestOptions => [
  getPayslipsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddPayslipRequest = (payload: Payslip): RequestOptions => [
  getPayslipsEndpoint(),
  getHttpPostOptions({ payslip: payload }, getAuthHeaders())
];

const getPayslipEndpoint = (id: number | string): string => `${API_HOST}/payslips/${id}`;
export const getPayslipRequest = (id: number | string): RequestOptions => [
  getPayslipEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdatePayslipRequest = (id: number | string, payload: Payslip): RequestOptions => [
  getPayslipEndpoint(id),
  getHttpPutOptions({ payslip: payload }, getAuthHeaders())
];

export const getRemovePayslipRequest = (id: string | number): RequestOptions => [
  getPayslipEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
