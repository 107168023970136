import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { LeaveRequest } from '../../../../interfaces/employee/leave-request.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { LoadLeaveRequestsAction } from '../../../../actions/leave-request/leave-request.types';
import { LeaveRequestActions } from '../../../../actions/leave-request/leave-request.actions';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import LeaveRequestForm from '../../../../components/leave-requests/form/LeaveRequestForm';

const LeaveRequestContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadLeaveRequestsAction> = useAppDispatch();
  const leaveRequest: LeaveRequest = useAppSelector(
    ({ leave_request_store }: AppState) => leave_request_store.leaveRequest
  );
  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: LeaveRequest, onComplete: () => void): void => {
    dispatch(LeaveRequestActions.updateLeaveRequest(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(LeaveRequestActions.loadLeaveRequest(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && leaveRequest?.id === Number(id);
  }, [id, loading, leaveRequest?.id]);

  return (
    <PageLayout title={`Leave Request Details - ${doneLoading ? leaveRequest?.employee : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <LeaveRequestForm employees={employees} leaveRequest={leaveRequest} onSave={onSave} />
      )}
    </PageLayout>
  );
};

export default LeaveRequestContainer;
