import React, { useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { Client } from '../../../interfaces/client/client.interface';
import { Category } from '../../../interfaces/config/category.interface';
import { ClientType } from '../../../interfaces/config/client-type.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../select/Select';
import AvatarLayout from '../../layout/avatar/AvatarLayout';

import './client-form.scss';

type OnSave = (payload: Client, onComplete: () => void) => void;

interface Props {
  categories: Category[];
  clientTypes: ClientType[];
  client?: Client;
  onSave: OnSave;
}

export interface ClientState extends StateForm {
  name: string;
  image: string;
  customClientType?: string;
  clientType: string;
  customCategory?: string;
  clientTypeId: string | number;
  category: string | number;
}

const rules: ValidationRules<ClientState> = {
  validates: {
    name: ['isPresent'],
    clientTypeId: ['isPresent']
  }
};

const formify = (client?: Client): ClientState =>
  ({
    name: client?.name,
    image: client?.image,
    clientType: client?.client_type,
    clientTypeId: client?.client_type_id,
    category: client?.category_id
  } as ClientState);

const serverify = (form: ClientState): Client => ({
  name: form.name,
  image: form.image,
  client_type_id: form.clientTypeId,
  client_type: form.customClientType,
  category_id: form.category,
  category: form.customCategory
});

const ClientForm = ({ categories, clientTypes, client, onSave }: Props) => {
  const [form, setForm] = useFormState<ClientState>(formify(client), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as ClientState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Client = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  const mappedCategories: SelectItem[] = useMemo(() => {
    return categories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((category: Category) => ({
        label: category.name,
        value: category.name === 'Other' ? -100 : category.id
      }));
  }, [categories]);

  const mappedClientTypes: SelectItem[] = useMemo(() => {
    return clientTypes
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((clientType: ClientType) => ({
        label: clientType.name,
        value: clientType.name === 'Other' ? -100 : clientType.id
      }));
  }, [clientTypes]);

  return (
    <AvatarLayout
      title={client?.name ?? form?.name}
      subtitle={client?.client_type ?? form?.clientType}
      image={form?.image ?? client?.image}
      handleChange={handleChange}
    >
      <Form>
        <FormRow>
          <Input
            name="name"
            label="Client Name"
            placeholder="Client Name"
            value={form?.name}
            error={form?.messages.name}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluid>
          <Select
            name="clientTypeId"
            label="Client Type"
            placeholder="Select a client type"
            items={mappedClientTypes}
            value={form?.clientTypeId}
            error={form?.messages.clientTypeId}
            onChange={handleChange}
          />
          <Select
            name="category"
            label="Category"
            placeholder="Select a category"
            items={mappedCategories}
            value={form?.category}
            error={form?.messages.category}
            onChange={handleChange}
          />
        </FormRow>
        {Number(form?.category) === -100 && (
          <FormRow>
            <Input
              name="customCategory"
              label="Enter a custom Category"
              placeholder="Enter a custom Category"
              value={form?.customCategory}
              error={form?.messages.customCategory}
              onChange={handleChange}
            />
          </FormRow>
        )}
        {Number(form?.clientTypeId) === -100 && (
          <FormRow>
            <Input
              name="customClientType"
              label="Enter a custom Client Type"
              placeholder="Enter a custom Client Type"
              value={form?.customClientType}
              error={form?.messages.customClientType}
              onChange={handleChange}
            />
          </FormRow>
        )}
        <FormAction
          loading={loading}
          label="Save Changes"
          onClick={handleSubmit}
          disabled={!form?.valid}
        />
      </Form>
    </AvatarLayout>
  );
};

export default ClientForm;
