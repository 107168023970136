import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Event } from '../../../interfaces/event/event.interface';

import { useAppSelector } from '../../../hooks';

const EventCrumb = () => {
  const event: Event = useAppSelector(({ event_store }: AppState) => event_store.event);

  return <NavLink to={`/admin/events/${event?.id}`}>{event?.name}</NavLink>;
};

export default EventCrumb;
