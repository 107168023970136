import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Employeerole } from '../../../interfaces/employeerole/employeerole.interface';

import { Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

const headers: TableHeader[] = [
  {
    Header: 'Role',
    accessor: 'role'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  role: string;
}

interface Props {
  employeeroles: Employeerole[];
  loading: boolean;
}

const EmployeeroleList = ({ employeeroles, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  // alert(employeeroles);
  const rowData = useMemo(() => {
    return employeeroles.map(
      (employeerole: Employeerole) => {
        return {
          role: employeerole.employeerole,
          actions: <RemoveElement element={employeerole} />,
          onClick: () => navigate(`${employeerole.id}`)
        } as RowData;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeroles]);

  return (
    <article className="Employeerole-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No employeeroles could be found!"
        rowData={rowData}
      />
    </article>
  );
};

export default EmployeeroleList;
