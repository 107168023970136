import React, { ReactNode, useEffect, useState } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';

import { noOp } from '@neslotech/utils';

import { Client } from '../../interfaces/client/client.interface';
import { AppState } from '../../types/state.interface';
import { Category } from '../../interfaces/config/category.interface';
import { Sector } from '../../interfaces/config/sector.interface';
import { ClientType } from '../../interfaces/config/client-type.interface';
import { Designation } from '../../interfaces/config/designation.interface';
import { User } from '../../interfaces/user/user.interface';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ClientContext, ClientContextProps } from '../../contexts/client/Client.context';

import { CategoryActions } from '../../actions/category/category.actions';
import { SectorActions } from '../../actions/sector/sector.actions';
import { ClientTypeActions } from '../../actions/client-type/client-type.actions';
import { ClientActions } from '../../actions/client/client.actions';
import { DesignationActions } from '../../actions/designation/designation.actions';
import { UserActions } from '../../actions/user/user.actions';

interface Props {
  children: ReactNode[] | ReactNode;
}

const ClientProvider = ({ children }: Props) => {
  const dispatch: Dispatch = useAppDispatch();
  const { id } = useParams<string>();

  const [loading, setLoading] = useState<boolean>(true);

  const client: Client = useAppSelector(({ client_store }: AppState) => client_store.client);

  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );
  const sectors: Sector[] = useAppSelector(({ sector_store }: AppState) => sector_store.sectors);
  const clientTypes: ClientType[] = useAppSelector(
    ({ client_type_store }: AppState) => client_type_store.clientTypes
  );
  const designations: Designation[] = useAppSelector(
    ({ designation_store }: AppState) => designation_store.designations
  );
  const users: User[] = useAppSelector(({ user_store }: AppState) => user_store.users);

  useEffect(() => {
    dispatch(UserActions.loadUsers(noOp));
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(SectorActions.loadSectors(noOp));
    dispatch(ClientTypeActions.loadClientTypes(noOp));
    dispatch(DesignationActions.loadDesignations(noOp));
    dispatch(ClientActions.loadClient(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const value: ClientContextProps = {
    id,
    loading,
    client,
    categories,
    sectors,
    clientTypes,
    designations,
    users
  };

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
};

export default ClientProvider;
