import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddTimesheetRequest,
  getTimesheetRequest,
  getTimesheetsRequest,
  getRemoveTimesheetRequest,
  getUpdateTimesheetRequest,
  RequestOptions,
  getAllDownloadTimesheetsRequest
} from '../tools/api';

import {
  AddTimesheetAction,
  AddAnotherTimesheetAction,
  LoadTimesheetAction,
  LoadTimesheetsAction,
  UpdateTimesheetAction,
  DownloadTimesheetsAction
} from '../actions/timesheet/timesheet.types';

import { TimesheetActions } from '../actions/timesheet/timesheet.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadTimesheets({ onComplete }: LoadTimesheetsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getTimesheetsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: TimesheetActions.SET_TIMESHEETS, timesheets: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadTimesheetsRequest() {
  yield takeLatest(TimesheetActions.LOAD_TIMESHEETS, performLoadTimesheets);
}

export function* watchForDownloadExpenseClaimsRequest() {
  yield takeLatest(TimesheetActions.DOWNLOAD_TIMESHEETS, performDownloadExpenseClaim);
}

export function* performDownloadExpenseClaim({ id, onComplete }: DownloadTimesheetsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions =
      getAllDownloadTimesheetsRequest() as RequestOptions;

    const { data, headers } = yield call(axios, endpoint, requestOptions);

    // Get the Content-Disposition header value from the response
    const contentDisposition = headers['content-disposition'];

    // Use a regular expression to extract the file name from the header value
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';

    const csvBlob = new Blob([data], { type: 'text/csv' });
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(csvBlob);
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* performLoadTimesheet({ id, onComplete }: LoadTimesheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getTimesheetRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: TimesheetActions.SET_TIMESHEET, timesheet: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadTimesheetRequest() {
  yield takeLatest(TimesheetActions.LOAD_TIMESHEET, performLoadTimesheet);
}

export function* performUpdateTimesheet({ id, payload, onComplete }: UpdateTimesheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateTimesheetRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The timesheet has been updated successfully!', SNACK_SUCCESS)
    );

    yield put({ type: TimesheetActions.SET_TIMESHEET, timesheet: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateTimesheetRequest() {
  yield takeLatest(TimesheetActions.UPDATE_TIMESHEET, performUpdateTimesheet);
}

export function* performAddTimesheet({ payload, navigate, onComplete }: AddTimesheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddTimesheetRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new timesheet has been created successfully!', SNACK_SUCCESS)
    );

    yield put(TimesheetActions.loadTimesheets(noOp));
    yield navigate('/human-resources/timesheets');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddTimesheetRequest() {
  yield takeLatest(TimesheetActions.ADD_TIMESHEET, performAddTimesheet);
}

export function* performAddAnotherTimesheet({ payload, onComplete }: AddAnotherTimesheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddTimesheetRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new timesheet has been created successfully!', SNACK_SUCCESS)
    );
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddAnotherTimesheetRequest() {
  yield takeLatest(TimesheetActions.ADD_ANOTHER_TIMESHEET, performAddAnotherTimesheet);
}

export function* performRemoveTimesheet({ id, onComplete }: AddTimesheetAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveTimesheetRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The timesheet has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(TimesheetActions.loadTimesheets(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveTimesheetRequest() {
  yield takeLatest(TimesheetActions.REMOVE_TIMESHEET, performRemoveTimesheet);
}

export default function* timesheetSaga() {
  yield all([
    watchForLoadTimesheetsRequest(),
    watchForLoadTimesheetRequest(),
    watchForUpdateTimesheetRequest(),
    watchForAddTimesheetRequest(),
    watchForAddAnotherTimesheetRequest(),
    watchForRemoveTimesheetRequest(),
    watchForDownloadExpenseClaimsRequest()
  ]);
}
