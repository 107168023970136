import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { DealSheet } from '../../../../../interfaces/client/deal-sheet.interface';

import { Table, TableData, TableHeader } from '../../../../table/Table';
import RemoveElement from '../../../../table/RemoveElement';
import AlphabetLayout from '../../../../../components/layout/filter/AlphabetLayout'; 

import './deal-sheet-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Client',
    accessor: 'client'
  },
  {
    Header: 'Deal Name',
    accessor: 'name'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'Expected Closing Date',
    accessor: 'closing_date'
  },
  {
    Header: 'Fee Structure',
    accessor: 'fee_structure'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  client: string;
  name: string;
  category: string;
  closing_date: string;
  fee_structure: string;
}

interface Props {
  dealSheets: DealSheet[];
  loading: boolean;
}

const DealSheetList = ({ dealSheets, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const [sortedDealSheets, setSortedDealSheets] = useState(dealSheets);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {    
    if (selectedLetter) {
      dealSheets = dealSheets.filter(dealSheet => dealSheet.client[0].toUpperCase() === selectedLetter);
    }

    setSortedDealSheets(dealSheets);
  }, [dealSheets, selectedLetter]);

  const rowData = useMemo(() => {
    return sortedDealSheets.map(
      (dealSheet: DealSheet) =>
        ({
          ...dealSheet,
          actions: <RemoveElement element={dealSheet} />,
          onClick: () => navigate(`${dealSheet.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedDealSheets]);

  return (
    <>
      <article className="deal-sheet-list">
        <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
        <Table
          loading={loading}
          cols={headers}
          emptyTitle="No deal sheets could be found!"
          rowData={rowData}
        />
      </article>
    </>
  );
};

export default DealSheetList;
