import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Irp5 } from '../interfaces/employee/irp5.interface';

import { Irp5Actions } from '../actions/irp5/irp5.actions';

export interface Irp5State extends State {
  irp5s: Irp5[];
  irp5?: Irp5;
}

export const initialState: Irp5State = {
  irp5s: []
};

export const irp5Reducer = (state: Irp5State = initialState, action: AnyAction) => {
  if (action.type === Irp5Actions.SET_IRP5S) {
    const { irp5s } = action;
    return {
      ...state,
      irp5s
    };
  } else if (action.type === Irp5Actions.SET_IRP5) {
    const { irp5 } = action;
    return {
      ...state,
      irp5
    };
  }

  return state;
};
