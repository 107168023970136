import { Precedent } from '../../interfaces/client/precedent.interface';

import {
  LoadPrecedentAction,
  OnComplete,
  RemovePrecedentAction,
  SavePrecedentAction,
  UpdatePrecedentAction,
  LoadPrecedentsAction
} from './precedent.types';

export class PrecedentActions {
  static readonly LOAD_PRECEDENTS: string = '[PRECEDENT_STORE] Load precedents';
  static readonly SET_PRECEDENTS: string = '[PRECEDENT_STORE] Set precedents';

  static readonly LOAD_PRECEDENT: string = '[PRECEDENT_STORE] Load precedent';
  static readonly SET_PRECEDENT: string = '[PRECEDENT_STORE] Set precedent';

  static readonly SAVE_PRECEDENT: string = '[PRECEDENT_STORE] Save precedent';
  static readonly UPDATE_PRECEDENT: string = '[PRECEDENT_STORE] Save precedent';
  static readonly REMOVE_PRECEDENT: string = '[PRECEDENT_STORE] Remove precedent';

  static loadPrecedents = (onComplete: OnComplete): LoadPrecedentsAction => ({
    type: PrecedentActions.LOAD_PRECEDENTS,
    onComplete
  });

  static savePrecedent = (
    id: number | string,
    payload: Precedent,
    onComplete: OnComplete
  ): SavePrecedentAction => ({
    type: PrecedentActions.SAVE_PRECEDENT,
    id,
    payload,
    onComplete
  });

  static loadPrecedent = (id: number | string, onComplete: OnComplete): LoadPrecedentAction => ({
    type: PrecedentActions.LOAD_PRECEDENT,
    id,
    onComplete
  });

  static updatePrecedent = (
    id: number | string,
    payload: Precedent,
    onComplete: OnComplete
  ): UpdatePrecedentAction => ({
    type: PrecedentActions.UPDATE_PRECEDENT,
    id,
    payload,
    onComplete
  });

  static removePrecedent = (
    id: number | string,
    onComplete: OnComplete
  ): RemovePrecedentAction => ({
    type: PrecedentActions.REMOVE_PRECEDENT,
    id,
    onComplete
  });
}
