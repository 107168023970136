/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import DetailsCard from '../../../../../../components/card/DetailsCard';
import { Modal } from '../../../../../../components/modal/Modal';
import { Table, TableData, TableHeader } from '../../../../../../components/table/Table';
import { Contact, ContactHistory } from '../../../../../../interfaces/client/contact.interface';
import { ReactComponent as AddIcon } from '../../../../../../icons/add-icon.svg';
import AddContactHistoryContainer from '../add/AddContactHistory.container';
import EditContactHistoryContainer from '../edit/EditContactHistory.container';
import RemoveElement from '../../../../../../components/table/RemoveElement';

const headers: TableHeader[] = [
  {
    Header: 'Contact Date',
    accessor: 'contact_date_display'
  },
  {
    Header: 'Contactor',
    accessor: 'contactor'
  },
  {
    Header: 'Method',
    accessor: 'method'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  contact_date_display: string;
  contact_date: string;
  contactor: string;
  method: string;
}

interface Props {
  contact?: Contact;
  loading?: boolean;
}

const ContactHistories = ({ contact, loading }: Props) => {
  const [mode, setMode] = useState<string>();
  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const [currentHistory, setCurrentHistory] = useState<ContactHistory>();

  const rowData = useMemo(() => {
    return contact?.contact_histories?.map<RowData>((history: ContactHistory) => ({
      contact_date_display: history.contact_date_display.toString(),
      contact_date: history.contact_date.toString(),
      contactor: history.contactor,
      method: history.method,
      actions: <RemoveElement element={history} />,
      onClick: () => handleEditModal(history)
    }));
  }, [contact]);

  const handleEditModal = (history: ContactHistory) => {
    setCurrentHistory(history);
    handleOpenModal('edit');
  };

  const handleOpenModal = (mode: string) => {
    setMode(mode);
    setShowFormModal(true);
  };

  return (
    <>
      <DetailsCard
        title="Contact History"
        centered
        action={contact ? <AddIcon onClick={() => handleOpenModal('add')} /> : null}
        loading={loading}
      >
        <Table
          loading={loading}
          cols={headers}
          emptyTitle="No history could be found!"
          rowData={rowData}
        />
      </DetailsCard>

      {showFormModal && (
        <Modal>
          {mode === 'add' && (
            <AddContactHistoryContainer contactId={contact.id} setShow={setShowFormModal} />
          )}
          {mode === 'edit' && (
            <EditContactHistoryContainer
              contactId={contact.id}
              history={currentHistory}
              setShow={setShowFormModal}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default ContactHistories;
