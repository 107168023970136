import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Document } from '../../interfaces/document/document.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getDocumentsEndpoint = (): string => `${API_HOST}/documents`;
export const getDocumentsRequest = (): RequestOptions => [
  getDocumentsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddDocumentRequest = (payload: Document): RequestOptions => [
  getDocumentsEndpoint(),
  getHttpPostOptions({ document: payload }, getAuthHeaders())
];

const getDocumentEndpoint = (id: number | string): string => `${API_HOST}/documents/${id}`;
export const getDocumentRequest = (id: number | string): RequestOptions => [
  getDocumentEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateDocumentRequest = (
  id: number | string,
  payload: Document
): RequestOptions => [
  getDocumentEndpoint(id),
  getHttpPutOptions({ document: payload }, getAuthHeaders())
];

export const getRemoveDocumentRequest = (id: string | number): RequestOptions => [
  getDocumentEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getDocumentFileEndpoint = (id: number | string, file_id: number): string =>
  `${API_HOST}/documents/${id}/file/${file_id}`;
export const getRemoveDocumentFileRequest = (
  id: string | number,
  fileId: number
): RequestOptions => [getDocumentFileEndpoint(id, fileId), getHttpDeleteOptions(getAuthHeaders())];
