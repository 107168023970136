import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getDealSheetsEndpoint = (): string => `${API_HOST}/deal_sheets`;
export const getDealSheetsRequest = (): RequestOptions => [
  getDealSheetsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];
