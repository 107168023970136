import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Designation } from '../../../../interfaces/config/designation.interface';

import { LoadDesignationsAction } from '../../../../actions/designation/designation.types';
import { DesignationActions } from '../../../../actions/designation/designation.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import DesignationList from '../../../../components/designations/list/DesignationList';
import Button from '../../../../components/button/Button';

import RemoveDesignationContainer from '../remove/RemoveDesignation.container';

const DesignationsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadDesignationsAction> = useAppDispatch();
  const designations: Designation[] = useAppSelector(
    ({ designation_store }: AppState) => designation_store.designations
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(DesignationActions.loadDesignations(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Designations"
      action={<Button label="Add Designation" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <DesignationList designations={designations} loading={loading} />
        <RemoveDesignationContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default DesignationsContainer;
