import { Tombstone } from '../../../interfaces/client/tombstone.interface';

import {
  AddTombstoneAction,
  LoadTombstoneAction,
  LoadTombstonesAction,
  OnComplete,
  OnCompleteAddTombtone,
  RemoveTombstoneAction,
  UpdateTombstoneAction
} from './tombstone.types';

export class TombstoneActions {
  static readonly LOAD_TOMBSTONES: string = '[CLIENT_STORE] Load tombstones';
  static readonly DOWNLOAD_TOMBSTONES: string = '[CLIENT_STORE] Download tombstones';
  static readonly SET_TOMBSTONES: string = '[CLIENT_STORE] Set tombstones';

  static readonly LOAD_TOMBSTONE: string = '[CLIENT_STORE] Load tombstone';
  static readonly SET_TOMBSTONE: string = '[CLIENT_STORE] Set tombstone';

  static readonly ADD_TOMBSTONE: string = '[CLIENT_STORE] Add tombstone';
  static readonly UPDATE_TOMBSTONE: string = '[CLIENT_STORE] Update tombstone';
  static readonly REMOVE_TOMBSTONE: string = '[CLIENT_STORE] Remove tombstone';

  static loadTombstones = (id: number | string, onComplete: OnComplete): LoadTombstonesAction => ({
    type: TombstoneActions.LOAD_TOMBSTONES,
    id,
    onComplete
  });

  static downloadTombstones = (
    id: number | string,
    onComplete: OnComplete
  ): LoadTombstonesAction => ({
    type: TombstoneActions.DOWNLOAD_TOMBSTONES,
    id,
    onComplete
  });

  static loadTombstone = (
    id: number | string,
    tombstoneId: number | string,
    onComplete: OnComplete
  ): LoadTombstoneAction => ({
    type: TombstoneActions.LOAD_TOMBSTONE,
    id,
    tombstoneId,
    onComplete
  });

  static addTombstone = (
    id: number | string,
    payload: Tombstone,
    onCompleteAddTombstone: OnCompleteAddTombtone
  ): AddTombstoneAction => ({
    type: TombstoneActions.ADD_TOMBSTONE,
    id,
    payload,
    onCompleteAddTombstone
  });

  static updateTombstone = (
    id: number | string,
    tombstoneId: number | string,
    payload: Tombstone,
    onComplete: OnComplete
  ): UpdateTombstoneAction => ({
    type: TombstoneActions.UPDATE_TOMBSTONE,
    id,
    tombstoneId,
    payload,
    onComplete
  });

  static removeTombstone = (
    id: number | string,
    tombstoneId: number | string,
    onComplete: OnComplete
  ): RemoveTombstoneAction => ({
    type: TombstoneActions.REMOVE_TOMBSTONE,
    id,
    tombstoneId,
    onComplete
  });
}
