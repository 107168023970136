import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Employee } from '../../../../interfaces/employee/employee.interface';

import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { AddEmployeeAction } from '../../../../actions/employee/employee.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import EmployeeForm from '../../../../components/employees/form/EmployeeForm';

const AddEmployeeContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddEmployeeAction> = useAppDispatch();

  const onSave = (payload: Employee, onComplete: () => void): void => {
    dispatch(EmployeeActions.addEmployee(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Employee">
      <EmployeeForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddEmployeeContainer;
