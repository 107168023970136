import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Designation } from '../../../../interfaces/config/designation.interface';

import { DesignationActions } from '../../../../actions/designation/designation.actions';
import { AddDesignationAction } from '../../../../actions/designation/designation.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import DesignationForm from '../../../../components/designations/form/DesignationForm';

const AddDesignationContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddDesignationAction> = useAppDispatch();

  const onSave = (payload: Designation, onComplete: () => void): void => {
    dispatch(DesignationActions.addDesignation(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Designation">
      <DesignationForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddDesignationContainer;
