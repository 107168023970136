import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { Designation } from '../../../../interfaces/config/designation.interface';

import { RemoveDesignationAction } from '../../../../actions/designation/designation.types';
import { DesignationActions } from '../../../../actions/designation/designation.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveDesignationContainer = () => {
  const dispatch: Dispatch<RemoveDesignationAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      DesignationActions.removeDesignation((element as Designation).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Designation">
            <RemoveModal title={(element as Designation).name} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveDesignationContainer;
