import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Prospect } from '../../../../interfaces/employee/prospect.interface';

import { ProspectActions } from '../../../../actions/prospect/prospect.actions';
import { AddProspectAction } from '../../../../actions/prospect/prospect.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import ProspectForm from '../../../../components/prospects/form/ProspectForm';

const AddProspectContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddProspectAction> = useAppDispatch();

  const onSave = (payload: Prospect, onComplete: () => void): void => {
    dispatch(ProspectActions.addProspect(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Prospect">
      <ProspectForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddProspectContainer;
