import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Investment } from '../../interfaces/investment/investment.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getInvestmentsEndpoint = (fundId: number | string): string =>
  `${API_HOST}/funds/${fundId}/investments`;
export const getInvestmentsRequest = (fundId: number | string): RequestOptions => [
  getInvestmentsEndpoint(fundId),
  getHttpGetOptions(getAuthHeaders())
];

const getInvestmentEndpoint = (fundId: number | string, id: number | string): string =>
  `${API_HOST}/funds/${fundId}/investments/${id}`;
export const getInvestmentRequest = (
  fundId: number | string,
  id: number | string
): RequestOptions => [getInvestmentEndpoint(fundId, id), getHttpGetOptions(getAuthHeaders())];

export const getUpdateInvestmentRequest = (
  fundId: number | string,
  id: number | string,
  payload: Investment
): RequestOptions => [
  getInvestmentEndpoint(fundId, id),
  getHttpPutOptions({ investment: payload }, getAuthHeaders())
];

export const getAddInvestmentRequest = (
  fundId: number | string,
  payload: Investment
): RequestOptions => [
  getInvestmentsEndpoint(fundId),
  getHttpPostOptions({ investment: payload }, getAuthHeaders())
];

export const getRemoveInvestmentRequest = (
  fundId: number | string,
  id: number | string
): RequestOptions => [getInvestmentEndpoint(fundId, id), getHttpDeleteOptions(getAuthHeaders())];
