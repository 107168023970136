import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddMandateRequest,
  getMandatesRequest,
  getRemoveMandateRequest,
  RequestOptions
} from '../../tools/api';
import {
  getCompleteMandateRequest,
  getMandateRequest,
  getUpdateMandateRequest
} from '../../tools/api/clients/mandate.endpoints';

import {
  CompleteMandateAction,
  LoadMandateAction,
  RemoveMandateAction,
  AddMandateAction,
  UpdateMandateAction
} from '../../actions/client/mandate/mandate.types';
import { MandateActions } from '../../actions/client/mandate/mandate.actions';
import { SystemActions } from '../../actions/system/system.actions';

export function* performLoadMandates({ id, onComplete }: LoadMandateAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getMandatesRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: MandateActions.SET_MANDATES, mandates: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadMandatesRequest() {
  yield takeLatest(MandateActions.LOAD_MANDATES, performLoadMandates);
}

export function* performLoadMandate({ id, mandateId, onComplete }: LoadMandateAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getMandateRequest(id, mandateId);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: MandateActions.SET_MANDATE, mandate: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadMandateRequest() {
  yield takeLatest(MandateActions.LOAD_MANDATE, performLoadMandate);
}

export function* performAddMandate({ id, payload, onComplete }: AddMandateAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddMandateRequest(id, payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your mandate has been created successfully!', SNACK_SUCCESS)
    );

    yield put(MandateActions.loadMandates(id, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddMandateRequest() {
  yield takeLatest(MandateActions.ADD_MANDATE, performAddMandate);
}

export function* performUpdateMandate({ id, mandateId, payload, onComplete }: UpdateMandateAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateMandateRequest(
      id,
      mandateId,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your mandate has been updated successfully!', SNACK_SUCCESS)
    );

    yield put(MandateActions.loadMandate(id, mandateId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateMandateRequest() {
  yield takeLatest(MandateActions.UPDATE_MANDATE, performUpdateMandate);
}

export function* performRemoveMandate({ id, mandateId, onComplete }: RemoveMandateAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveMandateRequest(id, mandateId);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your mandate has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(MandateActions.loadMandates(id, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveMandateRequest() {
  yield takeLatest(MandateActions.REMOVE_MANDATE, performRemoveMandate);
}

export function* performCompleteMandate({ id, mandateId, onComplete }: CompleteMandateAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getCompleteMandateRequest(id, mandateId);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your mandate has been completed successfully!', SNACK_SUCCESS)
    );
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCompleteMandateRequest() {
  yield takeLatest(MandateActions.COMPLETE_MANDATE, performCompleteMandate);
}

export default function* mandateSaga() {
  yield all([
    watchForLoadMandatesRequest(),
    watchForLoadMandateRequest(),
    watchForUpdateMandateRequest(),
    watchForAddMandateRequest(),
    watchForRemoveMandateRequest(),
    watchForCompleteMandateRequest()
  ]);
}
