import React from 'react';
import { RouteObject } from 'react-router-dom';

import EmployeesContainer from '../../containers/human-resources/employees/list/Employees.container';
import AddEmployeeContainer from '../../containers/human-resources/employees/add/AddEmployee.container';
import EmployeeContainer from '../../containers/human-resources/employees/details/Employee.container';

import EmployeesCrumb from '../../crumbs/human-resources/employees/Employees.crumb';
import AddEmployeeCrumb from '../../crumbs/human-resources/employees/AddEmployee.crumb';
import EmployeeCrumb from '../../crumbs/human-resources/employees/Employee.crumb';

import ProtectedRoute from '../ProtectedRoute';
import EditEmployeeContainer from '../../containers/human-resources/employees/edit/EditEmployee.container';
import EditEmployeeCrumb from '../../crumbs/human-resources/employees/EditEmployee.crumb';
import EmployeeContractContainer from '../../containers/human-resources/employees/contract/EmployeeContract.container';
import EmployeeDisciplineContainer from '../../containers/human-resources/employees/discipline/EmployeeDiscipline.container';
import EmployeeOtherDocumentsContainer from '../../containers/human-resources/employees/other-documents/EmployeeOtherDocuments.container';

const employeeRoutes: RouteObject[] = [
  {
    path: 'employees',
    handle: {
      crumb: () => <EmployeesCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <EmployeesContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute adminRequired>
            <AddEmployeeContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddEmployeeCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <EmployeeContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <EmployeeCrumb />
        }
      },
      {
        path: ':id/edit',
        element: (
          <ProtectedRoute>
            <EditEmployeeContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <EditEmployeeCrumb />
        }
      },
      {
        path: ':id/contracts',
        element: (
          <ProtectedRoute>
            <EmployeeContractContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <EditEmployeeCrumb />
        }
      },
      {
        path: ':id/disciplines',
        element: (
          <ProtectedRoute>
            <EmployeeDisciplineContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <EditEmployeeCrumb />
        }
      },
      {
        path: ':id/other-documents',
        element: (
          <ProtectedRoute>
            <EmployeeOtherDocumentsContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <EditEmployeeCrumb />
        }
      }
    ]
  }
];

export default employeeRoutes;
