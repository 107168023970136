import { NavigateFunction } from 'react-router-dom';

import { Designation } from '../../interfaces/config/designation.interface';

import {
  AddDesignationAction,
  LoadDesignationAction,
  LoadDesignationsAction,
  OnComplete,
  RemoveDesignationAction,
  UpdateDesignationAction
} from './designation.types';

export class DesignationActions {
  static readonly LOAD_DESIGNATIONS: string = '[DESIGNATION_STORE] Load designations';
  static readonly SET_DESIGNATIONS: string = '[DESIGNATION_STORE] Set designations';

  static readonly LOAD_DESIGNATION: string = '[DESIGNATION_STORE] Load designation';
  static readonly SET_DESIGNATION: string = '[DESIGNATION_STORE] Set designation';

  static readonly UPDATE_DESIGNATION: string = '[DESIGNATION_STORE] Update designation';
  static readonly ADD_DESIGNATION: string = '[DESIGNATION_STORE] Add designation';
  static readonly REMOVE_DESIGNATION: string = '[DESIGNATION_STORE] Remove designation';

  static loadDesignations = (onComplete: OnComplete): LoadDesignationsAction => ({
    type: DesignationActions.LOAD_DESIGNATIONS,
    onComplete
  });

  static loadDesignation = (
    id: number | string,
    onComplete: OnComplete
  ): LoadDesignationAction => ({
    type: DesignationActions.LOAD_DESIGNATION,
    id,
    onComplete
  });

  static addDesignation = (
    payload: Designation,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddDesignationAction => ({
    type: DesignationActions.ADD_DESIGNATION,
    payload,
    navigate,
    onComplete
  });

  static updateDesignation = (
    id: number | string,
    payload: Designation,
    onComplete: OnComplete
  ): UpdateDesignationAction => ({
    type: DesignationActions.UPDATE_DESIGNATION,
    id,
    payload,
    onComplete
  });

  static removeDesignation = (
    id: number | string,
    onComplete: OnComplete
  ): RemoveDesignationAction => ({
    type: DesignationActions.REMOVE_DESIGNATION,
    id,
    onComplete
  });
}
