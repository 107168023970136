import React from 'react';
import { RouteObject } from 'react-router-dom';

import CoreCrumb from '../../crumbs/core/Core.crumb';

import clientRoutes from './client.routes';
import contactRoutes from './contact.routes';
import fundRoutes from './fund.routes';
import precedentRoutes from './precedent.routes';
import mandateRoutes from './mandate.routes';
import tombstoneRoutes from './tombstone.routes';

const coreRoutes: RouteObject[] = [
  {
    path: 'core',
    handle: {
      crumb: () => <CoreCrumb />
    },
    children: [
      ...clientRoutes,
      ...contactRoutes,
      ...fundRoutes,
      ...precedentRoutes,
      ...mandateRoutes,
      ...tombstoneRoutes
    ]
  }
];

export default coreRoutes;
