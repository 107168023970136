import React, { useEffect, useState } from 'react';

import { Client } from '../../../interfaces/client/client.interface';
import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';

import { filterElement } from '../../../tools/filter.util';

import ClientCard from '../card/ClientCard';
import AlphabetLayout from '../../../components/layout/filter/AlphabetLayout'; 

import './client-list.scss';

interface Props {
  clients: Client[];
}

const formify = (clients: Client[], filters: Filter): Client[] =>
  clients.filter((client: Client) =>
    // @ts-ignore
    filterElement(client, filters)
  );

const ClientList = ({ clients }: Props) => {
  const { filters, setFilterDefaults, sort } = useFilters();
  const [sortedClients, setSortedClients] = useState(clients);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ client_type_id: '', category_id: '', sector_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sorted = formify(clients, filters).sort(
      sort === 'descending'
        ? (a: Client, b: Client) => a.name.localeCompare(b.name)
        : (a: Client, b: Client) => b.name.localeCompare(a.name)
    );

    if (selectedLetter) {
      sorted = sorted.filter(client => client.name[0].toUpperCase() === selectedLetter);
    }

    setSortedClients(sorted);
  }, [sort, clients, filters, selectedLetter]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="client-list">
        {sortedClients.map((client: Client) => (
          <ClientCard key={client.id} client={client} />
          )
          )}
      </article>
    </>
  );
};

export default ClientList;
