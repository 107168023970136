import { ClearResultsAction, OnComplete, SearchAction } from './search.types';

export class SearchActions {
  static readonly SEARCH: string = '[SEARCH_STORE] Search for entities';
  static readonly SET_RESULTS: string = '[SEARCH_STORE] Set results';

  static readonly CLEAR_RESULTS: string = '[SEARCH_STORE] Clear results';

  static search = (term: string): SearchAction => ({
    type: SearchActions.SEARCH,
    term
  });

  static clearResults = (onComplete: OnComplete): ClearResultsAction => ({
    type: SearchActions.CLEAR_RESULTS,
    onComplete
  });
}
