import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Designation } from '../../../interfaces/config/designation.interface';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

import './designation-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  }
};

interface RowData extends TableData {
  name: string;
  description: string;
  status: string;
}

interface Props {
  designations: Designation[];
  loading: boolean;
}

const DesignationList = ({ designations, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return designations.map<RowData>((designation: Designation) => ({
      name: designation.name,
      description: designation.description,
      status: designation.status,
      actions: <RemoveElement element={designation} />,
      onClick: () => navigate(`${designation.id}`)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designations]);

  return (
    <article className="designation-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No designations could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default DesignationList;
