import React from 'react';
import { RouteObject } from 'react-router-dom';

import TombstonesContainer from '../../containers/core/tombstones/list/Tombstones.container';
import AddTombstoneContainer from '../../containers/core/tombstones/add/AddTombstone.container';

import TombstonesCrumb from '../../crumbs/core/tombstones/Tombstones.crumb';
import AddTombstoneCrumb from '../../crumbs/core/tombstones/AddTombstone.crumb';

import ProtectedRoute from '../ProtectedRoute';

const tombstoneRoutes: RouteObject[] = [
  {
    path: 'tombstones',
    handle: {
      crumb: () => <TombstonesCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <TombstonesContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        handle: {
          crumb: () => <AddTombstoneCrumb />
        },
        element: (
          <ProtectedRoute>
            <AddTombstoneContainer />
          </ProtectedRoute>
        )
      }
    ]
  }
];

export default tombstoneRoutes;
