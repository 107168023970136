import { all, fork } from '@redux-saga/core/effects';

import authSaga from './auth.saga';
import userSaga from './user.saga';
import profileSaga from './profile.saga';
import fundSaga from './fund.saga';
import investmentSaga from './investment.saga';
import categorySaga from './category.saga';
import subCategorySaga from './sub-category.saga';
import clientTypeSaga from './client-type.saga';
import sectorSaga from './sector.saga';
import designationSaga from './designation.saga';
import contactSaga from './contact.saga';
import precedentSaga from './precedent.saga';
import clientSaga from './clients/client.saga';
import noteSaga from './clients/note.saga';
import clientContactSaga from './clients/contact.saga';
import clientPrecedentSaga from './clients/precedent.saga';
import clientTombstoneSaga from './clients/tombstone.saga';
import clientMandateSaga from './clients/mandate.saga';
import clientDealSheetSaga from './clients/deal-sheet.saga';
import mandateSaga from './mandate.saga';
import tombstoneSaga from './tombstone.saga';
import eventSaga from './event.saga';
import employeeSaga from './employee.saga';
import documentSaga from './document.saga';
import dealSheetSaga from './deal-sheet.saga';
import searchSaga from './search.saga';
import leaveRequestSaga from './leave-request.saga';
import expenseClaimSaga from './expense-claim.saga';
import payslipSaga from './payslip.saga';
import irp5Saga from './irp5.saga';
import performanceReviewSaga from './performance-review.saga';
import timesheetSaga from './timesheet.saga';
import prospectSaga from './prospect.saga';
import employeeroleSaga from './employeerole.saga';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(profileSaga),
    fork(fundSaga),
    fork(investmentSaga),
    fork(categorySaga),
    fork(subCategorySaga),
    fork(clientSaga),
    fork(clientTypeSaga),
    fork(sectorSaga),
    fork(noteSaga),
    fork(clientContactSaga),
    fork(designationSaga),
    fork(contactSaga),
    fork(clientPrecedentSaga),
    fork(clientTombstoneSaga),
    fork(clientMandateSaga),
    fork(clientDealSheetSaga),
    fork(precedentSaga),
    fork(mandateSaga),
    fork(tombstoneSaga),
    fork(eventSaga),
    fork(documentSaga),
    fork(dealSheetSaga),
    fork(searchSaga),
    fork(employeeSaga),
    fork(leaveRequestSaga),
    fork(expenseClaimSaga),
    fork(payslipSaga),
    fork(irp5Saga),
    fork(performanceReviewSaga),
    fork(timesheetSaga),
    fork(prospectSaga),
    fork(employeeroleSaga)
  ]);
}
