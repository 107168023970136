import React, {  useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { Employeerole } from '../../../interfaces/employeerole/employeerole.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';

type OnSave = (payload: Employeerole, onComplete: () => void) => void;
type Mode = 'edit' | 'add';

interface Props {
  Employeerole?: Employeerole;
  mode?: Mode;
  onSave: OnSave;
  isProfile?: boolean;
}

export interface EmployeeroleState extends StateForm {
  employeerole: string;
}

const editRules: ValidationRules<EmployeeroleState> = {
  validates: {
    employeerole: ['isPresent']
  }
};

const formify = (Employeerole?: Employeerole): EmployeeroleState =>
  ({
    employeerole: Employeerole?.employeerole
  } as EmployeeroleState);

const serverify = (form: EmployeeroleState, mode: Mode) =>
  mode === 'edit'
    ? {
        employeerole: form.employeerole
      }
    : {
        employeerole: form.employeerole
      };

const EmployeeroleForm = ({ Employeerole, mode = 'edit', onSave, isProfile = false }: Props) => {

  const [form, setForm] = useFormState<EmployeeroleState>(formify(Employeerole), editRules);

  const handleChange = (newState: OnChangeType) =>
    setForm({ ...form, ...(newState as EmployeeroleState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Employeerole = serverify(form, mode);

    onSave(payload, () => setLoading(false));
  };

  return (
      <Form>
        <FormRow fluid>
          <Input
            name="employeerole"
            label="Employee Role"
            placeholder="Employee Role"
            value={form?.employeerole}
            error={form?.messages.employeerole}
            onChange={handleChange}
          />
        </FormRow>
        <FormAction
          loading={loading}
          label="Save Changes"
          onClick={handleSubmit}
          disabled={!form?.valid}
        />
      </Form>
  );
};

export default EmployeeroleForm;
