import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { statusItems } from '../../../tools/dropdown.util';

import { ClientType } from '../../../interfaces/config/client-type.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';

import './client-type-form.scss';

type OnSave = (payload: ClientType, onComplete: () => void) => void;
type Status = 'active' | 'inactive';

interface Props {
  clientType?: ClientType;
  onSave: OnSave;
}

export interface ClientTypeState extends StateForm {
  name: string;
  description: string;
  status: Status;
}

const rules: ValidationRules<ClientTypeState> = {
  validates: {
    name: ['isPresent'],
    status: ['isPresent']
  }
};

const formify = (clientType?: ClientType): ClientTypeState =>
  ({
    name: clientType?.name,
    description: clientType?.description,
    status: clientType?.status
  } as ClientTypeState);

const serverify = (form: ClientTypeState) => ({
  name: form.name,
  description: form.description,
  status: form.status
});

const ClientTypeForm = ({ clientType, onSave }: Props) => {
  const [form, setForm] = useFormState<ClientTypeState>(formify(clientType), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as ClientTypeState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: ClientType = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="client-type-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Client Type Name"
              placeholder="Client Type Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="description"
              label="Description"
              placeholder="Description"
              value={form?.description}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="status"
              label="Status"
              placeholder="Select a status"
              items={statusItems}
              value={form?.status}
              error={form?.messages.status}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default ClientTypeForm;
