import { AnyAction } from '@reduxjs/toolkit';

import { generateId } from '@neslotech/utils';

import { SystemActions } from '../actions/system/system.actions';
import { AddNoticeAction, RemoveNoticeAction } from '../actions/system/system.types';

import { State } from '../types/state.interface';

interface Notice {
  id: string;
  title: string;
  alertType: string;
}

export interface SystemState extends State {
  notices: Notice[];
}

export const initialState: SystemState = {
  notices: []
};

export function systemReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SystemActions.ADD_SYSTEM_NOTICE:
      const { title, alertType } = action as AddNoticeAction;
      return {
        ...state,
        notices: [...state.notices, { id: generateId(), title, alertType }]
      };
    case SystemActions.REMOVE_SYSTEM_NOTICE:
      const { id } = action as RemoveNoticeAction;
      return {
        ...state,
        notices: state.notices.filter((notice) => notice.id !== id)
      };
    default:
      return state;
  }
}
