import React, { createRef, useEffect, useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { noOp } from '@neslotech/utils';

import { mapConfigItems } from '../../../../tools/dropdown.util';
import { toBase64 } from '../../../../tools/file.util';

import { useRemoval } from '../../../../hooks';
import {
  useCategories,
  useClient,
  useClientTypes,
  useSectors,
  useUsers
} from '../../../../hooks/clients';

import { Client } from '../../../../interfaces/client/client.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { Sector } from '../../../../interfaces/config/sector.interface';
import { ClientType } from '../../../../interfaces/config/client-type.interface';

import { ReactComponent as SaveIcon } from '../../../../icons/save-icon.svg';
import { ReactComponent as PencilIcon } from '../../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../icons/remove-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../../icons/profile-icon.svg';

import DetailsCard from '../../../card/DetailsCard';
import InfoDetail from '../InfoDetail';
import Select, { OnChangeType, SelectItem } from '../../../select/Select';
import { default as ReactSelect } from 'react-select';
import Input from '../../../input/Input';
import { User } from '../../../../interfaces/user/user.interface';
import { FileUpload } from '../../../upload/FileUpload';
import Button from '../../../button/Button';
import { SubCategory } from '../../../../interfaces/config/sub_category.interface';

import './basic-info-detail.scss';

type OnSave = (payload: Client, onComplete: () => void) => void;

interface Props {
  onSave: OnSave;
}

interface DetailState extends StateForm {
  image?: string;
  name?: string;
  description?: string;
  category?: string;
  customCategory?: string;
  categoryId?: string;
  subCategoryId?: number;
  subCategory?: string;
  sector?: string;
  customSector?: string;
  sectorId?: string;
  customClientType?: string;
  clientType?: string;
  clientTypeId?: string;
  personnel?: string;
  personnelIds?: string[] | number[];
}

const rules: ValidationRules<DetailState> = {
  validates: {
    name: ['isPresent'],
    clientTypeId: ['isPresent'],
    categoryId: ['isPresent']
  }
};

const formify = (client: Client): DetailState =>
  ({
    image: client.image,
    name: client.name,
    description: client.description,
    category: client.category,
    categoryId: client.category_id,
    subCategoryId: client?.sub_category_id,
    subCategory: client?.sub_category,
    sector: client.sector,
    sectorId: client.sector_id,
    clientType: client.client_type,
    clientTypeId: client.client_type_id,
    personnelIds: ((client?.personnel as User[]) ?? []).map((person: User) => person.id),
    personnel: ((client?.personnel as User[]) ?? [])
      .map((person: User) => person.full_name)
      .join(', ')
  } as DetailState);

const serverify = (form: DetailState): Client => ({
  image: form.image,
  name: form.name,
  description: form.description,
  category_id: form.categoryId,
  category: form.customCategory,
  sub_category_id: form.subCategoryId,
  sector_id: form.sectorId,
  sector: form.customSector,
  client_type_id: form.clientTypeId,
  client_type: form.customClientType,
  personnel_ids: form.personnelIds
});

const BasicInfoDetail = ({ onSave }: Props) => {
  const { client } = useClient();
  const categories: Category[] = useCategories();
  const sectors: Sector[] = useSectors();
  const clientTypes: ClientType[] = useClientTypes();
  const users: User[] = useUsers();
  const { setElement, setShowModal } = useRemoval();

  const [form, setForm] = useFormState<DetailState>(formify(client), rules);
  const handleChange = (newState: OnChangeType | any ) =>
    setForm({ ...form, ...(newState as DetailState) });

  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setForm(formify(client));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    const selectedCategory = categories.find((cat) => cat.id === Number(form.categoryId));
    if (selectedCategory && selectedCategory.sub_categories) {
      const activeSubCategories = selectedCategory.sub_categories.filter(
        (subCat) => subCat['status'] === 'active'
      );
      setSubCategories(activeSubCategories);
    } else {
      setSubCategories([]);
    }
  }, [categories, form.categoryId]);

  const actions = useMemo(() => {
    const handleSave = () => {
      setLoading(true);
      const payload: Client = serverify(form);
      onSave(payload, () => {
        setLoading(false);
        setEditMode(false);
      });
    };

    const handleRemove = () => {
      setElement(client);
      setShowModal(true);
    };

    return editMode ? (
      <>
        <SaveIcon onClick={!form.valid ? noOp : handleSave} />
        <RemoveIcon onClick={handleRemove} />
      </>
    ) : (
      <>
        <PencilIcon onClick={() => setEditMode(true)} />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, editMode, form, onSave]);

  const Image = useMemo(() => {
    return form?.image || client.image ? (
      <img
        style={{ borderRadius: '50%' }}
        width={50}
        height={50}
        title={form?.name ?? client.name}
        alt={form?.name ?? client.name}
        src={form?.image ?? client.image}
      />
    ) : (
      <ProfileIcon width={50} height={50} title={form?.name ?? client.name} />
    );
  }, [client.image, client.name, form?.image, form?.name]);

  const mappedCategories: SelectItem[] = useMemo(() => {
    return mapConfigItems(categories, editMode);
  }, [editMode, categories]);

  const mappedSectors: SelectItem[] = useMemo(() => {
    return mapConfigItems(sectors, editMode);
  }, [editMode, sectors]);

  const mappedClientTypes: SelectItem[] = useMemo(() => {
    return mapConfigItems(clientTypes, editMode);
  }, [editMode, clientTypes]);

  const SelectUsers = useMemo(() => {
    if (!editMode) {
      return [];
    }

    return users
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map((user: User) => ({ label: user.full_name, value: user.id }));
  }, [editMode, users]);

  const mappedSubCategories: SelectItem[] = useMemo(() => {
    return subCategories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((subCategory: SubCategory) => ({
        label: subCategory.name,
        value: subCategory.id
      }));
  }, [subCategories]);

  const handleImageChange = async (file: File) => {
    const image = await toBase64(file);
    handleChange({ image });
  };

  const handleSelectChange = (data: any) => {
    setSelectedOptions(data);
    handleChange({ personnelIds: data.map((item: any) => item.value) });
  };
  
  const defaultSelectUsers = useMemo(() => {
    if (!editMode) {
      return [];
    }

    return users
      .filter((user: User) => form.personnelIds.map(String)?.includes(user.id.toString()))
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map((user: User) => ({ label: user.full_name, value: user.id }));
  }, [editMode, form.personnelIds, users]);

  const [selectedOptions, setSelectedOptions] = useState(defaultSelectUsers);
  
  const handlePersonnelRemove = (personId: string | number) => {
    const selectedUserOptions = selectedOptions.filter((user: any) => user.value !== personId);
    setSelectedOptions(selectedUserOptions);
    handleChange({ personnelIds: selectedUserOptions.map((item: any) => item.value) });
  };


  useEffect(() => {
    if (editMode) {
      const selectedUserOptions = users
        .filter((user: User) => form.personnelIds.map(String).includes(user.id.toString()))
        .map((user: User) => ({ label: user.full_name, value: user.id }));
      setSelectedOptions(selectedUserOptions);
    } else {
      setSelectedOptions([]); // Reset selected options when not in edit mode
    }
  }, [editMode, form.personnelIds, users]);

  const fileRef = createRef<HTMLInputElement>();

  return (
    <DetailsCard title="Basic Info" centered action={actions} loading={loading} fitted>
      {editMode && (
        <InfoDetail
          title="Client Name"
          value={client.name}
          editMode={editMode}
          editableElement={
            <Input
              name="name"
              placeholder="Client Name"
              value={form.name}
              error={form.messages.name}
              onChange={handleChange}
            />
          }
        />
      )}
      {editMode && (
        <InfoDetail
          title="Client Logo"
          value={client.image}
          editMode={editMode}
          editableElement={
            <>
              <div style={{ height: 50, width: 50 }}>{Image}</div>
              <FileUpload fileRef={fileRef} onChange={handleImageChange}>
                <Button label="Upload Logo" onClick={() => fileRef.current?.click()} />
              </FileUpload>
            </>
          }
        />
      )}
      <InfoDetail
        title="Description"
        value={client.description}
        editMode={editMode}
        editableElement={
          <Input
            name="description"
            placeholder="Description"
            value={form.description}
            error={form.messages.description}
            onChange={handleChange}
          />
        }
      />
      <InfoDetail
        title="Category"
        value={client.category}
        editMode={editMode}
        editableElement={
          <Select
            name="categoryId"
            placeholder="Select a category"
            items={mappedCategories}
            value={form.categoryId}
            error={form.messages.categoryId}
            onChange={handleChange}
          />
        }
      />
      {subCategories.length > 0 && (
        <InfoDetail
          title="Sub Category"
          value={client.sub_category}
          editMode={editMode}
          editableElement={
            <Select
              name="subCategoryId"
              placeholder="Select a sub category"
              items={mappedSubCategories}
              value={form.subCategoryId}
              error={form.messages.subCategoryId}
              onChange={handleChange}
            />
          }
        />
      )}
      {Number(form?.categoryId) === -100 && (
        <InfoDetail
          title="Custom Category/Tag"
          editMode={editMode}
          value={form?.customCategory}
          editableElement={
            <Input
              name="customCategory"
              placeholder="Enter a custom Category"
              value={form?.customCategory}
              error={form?.messages.customCategory}
              onChange={handleChange}
            />
          }
        />
      )}
      <InfoDetail
        title="Sector"
        value={client.sector}
        editMode={editMode}
        editableElement={
          <Select
            name="sectorId"
            placeholder="Select a sector"
            items={mappedSectors}
            value={form.sectorId}
            onChange={handleChange}
          />
        }
      />
      {Number(form?.sectorId) === -100 && (
        <InfoDetail
          title="Custom Sector"
          editMode={editMode}
          value={form?.customSector}
          editableElement={
            <Input
              name="customSector"
              placeholder="Enter a custom Sector"
              value={form?.customSector}
              error={form?.messages.customSector}
              onChange={handleChange}
            />
          }
        />
      )}
      <InfoDetail
        title="Client Type"
        value={client.client_type}
        editMode={editMode}
        editableElement={
          <Select
            name="clientTypeId"
            placeholder="Select a client type"
            items={mappedClientTypes}
            value={form.clientTypeId}
            error={form.messages.clientTypeId}
            onChange={handleChange}
          />
        }
      />
      {Number(form?.clientTypeId) === -100 && (
        <InfoDetail
          title="Custom Client Type"
          editMode={editMode}
          value={form?.customClientType}
          editableElement={
            <Input
              name="customClientType"
              placeholder="Enter a custom Client Type"
              value={form?.customClientType}
              error={form?.messages.customClientType}
              onChange={handleChange}
            />
          }
        />
      )}
      <InfoDetail
        title="Personnel"
        value={form.personnel}
        editMode={editMode}
        editableElement={
          <article className="select">
            <ReactSelect
              isMulti
              isSearchable={true}
              placeholder="Select Personnel"
              options={SelectUsers}
              onChange={handleSelectChange}
              name="personnelIds"
              defaultValue={defaultSelectUsers}
              closeMenuOnSelect={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: '#c4960c'
                }
              })}
            />
          </article>
        }
      />
      { editMode && (
        <div className="personnel-list">
          {selectedOptions.map(person => (
            <div key={person.value} className="personnel-pill">
              {person.label}
              <button onClick={() => handlePersonnelRemove(person.value)}>x</button>
            </div>
          ))}
        </div>
      )}
    </DetailsCard>
  );
};

export default BasicInfoDetail;
