import React, { ReactNode } from 'react';

import { preventDefault } from '@neslotech/utils';

import './form.scss';

interface Props {
  children: ReactNode;
}

const Form = ({ children }: Props) => (
  <form className="form" onSubmit={preventDefault}>
    {children}
  </form>
);

export default Form;
