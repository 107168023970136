import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { SubCategory } from '../../../../interfaces/config/sub_category.interface';

import { RemoveSubCategoryAction } from '../../../../actions/sub-category/sub-category.types';
import { SubCategoryActions } from '../../../../actions/sub-category/sub-category.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';
import { useParams } from 'react-router-dom';

interface Props {
  onRemoval?: () => void;
}

const RemoveSubCategoryContainer = ({ onRemoval }: Props) => {
  const { id } = useParams<string>();
  const dispatch: Dispatch<RemoveSubCategoryAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      SubCategoryActions.removeSubCategory(id, (element as SubCategory).id as number, () => {
        onComplete();
        onClose();

        if (onRemoval) {
          onRemoval();
        }
      })
    );
  };

  return (
    <>
      {showModal && (
        <>
          <Modal>
            <ModalLayout onClose={onClose} title="Remove Category">
              <RemoveModal title={(element as SubCategory).name} onConfirm={handleConfirm} />
            </ModalLayout>
          </Modal>
        </>
      )}
    </>
  );
};

export default RemoveSubCategoryContainer;
