import React, { useState } from 'react';
import { ClientActions } from '../../../../actions/client/client.actions';
import Button from '../../../../components/button/Button';
import Checkbox, {
  OnChangeType as OnBooleanChangeType
} from '../../../../components/checkbox/Checkbox';
import InfoDetail from '../../../../components/clients/details/InfoDetail';
import FormRow from '../../../../components/form/form-row/FormRow';
// import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../hooks';

import { ModalLayout } from '../../../../components/modal/ModalLayout';
import Select, { OnChangeType, SelectItem } from '../../../../components/select/Select';
import { useAppSelector } from '../../../../hooks';
import { Client } from '../../../../interfaces/client/client.interface';
import { ExportClientItems } from '../../../../interfaces/client/export_client_items.interface';
import { DownloadClaimsAction } from '../../../../actions/expense-claim/expense-claim.types';

interface Props {
  setShow: (value: boolean) => void;
}

const defaultExportclientItems: ExportClientItems = {
  client_id: 0,
  mandates: false,
  precedents: false,
  tombstones: false,
  dealsheets: false
};

const ExportClientContainer = ({ setShow }: Props) => {
  const dispatch: Dispatch<DownloadClaimsAction> = useAppDispatch();

  const onClose = () => setShow(false);

  const [exportClientItems, setExportClientItems] =
    useState<ExportClientItems>(defaultExportclientItems);

  const clients: Client[] = useAppSelector(({ client_store }) => client_store.clients);

  const mappedClients: SelectItem[] = [
    {
      label: 'All Clients',
      value: 0
    },
    ...(clients ?? [])
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((client: Client) => ({
        label: client.name,
        value: client.id
      }))
  ];

  const handleChange = (newState: OnChangeType | OnBooleanChangeType) => {
    setExportClientItems({
      ...exportClientItems,
      ...(newState as ExportClientItems)
    });
  };

  const onComplete = () => {};

  const exportClient = () => {
    dispatch(ClientActions.downloadClients(exportClientItems.client_id, exportClientItems, onComplete));
  };

  return (
    <ModalLayout onClose={onClose} title="Export Clients" large>
      <div className={'flex'}>
        <FormRow>
          <InfoDetail
            title="Client"
            editMode={true}
            editableElement={
              <Select
                name="client_id"
                label=""
                items={mappedClients}
                placeholder="Select Client"
                value={exportClientItems?.client_id}
                onChange={handleChange}
              />
            }
          />
        </FormRow>
        <FormRow fluid>
          <Checkbox
            name="mandates"
            label="Mandates"
            checked={exportClientItems?.mandates}
            onChange={handleChange}
          />

          <Checkbox
            name="precedents"
            label="Precedents"
            checked={exportClientItems?.precedents}
            onChange={handleChange}
          />

          <Checkbox
            name="tombstones"
            label="Tombstones"
            checked={exportClientItems?.tombstones}
            onChange={handleChange}
          />

          <Checkbox
            name="dealsheets"
            label="Dealsheets"
            checked={exportClientItems?.dealsheets}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Button hollow label="Export" onClick={exportClient} />
        </FormRow>
      </div>
    </ModalLayout>
  );
};

export default ExportClientContainer;
