import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { SubCategory } from '../../interfaces/config/sub_category.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getSubCategoriesEndpoint = (category_id: string | number): string =>
    `${API_HOST}/categories/${category_id}/sub_categories`;

export const getSubCategoriesRequest = (
  category_id: string | number
): RequestOptions => [
  getSubCategoriesEndpoint(category_id),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddSubCategoryRequest = (
  category_id: string | number,
  payload: SubCategory
): RequestOptions => [
  getSubCategoriesEndpoint(category_id),
  getHttpPostOptions({ sub_category: payload }, getAuthHeaders())
];

const getSubCategoryEndpoint = (category_id: number | string, id: number | string): string =>
  `${API_HOST}/categories/${category_id}/sub_categories/${id}`;

export const getSubCategoryRequest = (
  category_id: number | string,
  id: number | string
): RequestOptions => [getSubCategoryEndpoint(category_id, id), getHttpGetOptions(getAuthHeaders())];

export const getUpdateSubCategoryRequest = (
  category_id: number | string,
  id: number | string,
  payload: SubCategory
): RequestOptions => [
  getSubCategoryEndpoint(category_id, id),
  getHttpPutOptions({ sub_category: payload }, getAuthHeaders())
];

export const getRemoveSubCategoryRequest = (
  category_id: number | string,
  id: string | number
): RequestOptions => [
  getSubCategoryEndpoint(category_id, id),
  getHttpDeleteOptions(getAuthHeaders())
];
