import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ExpenseClaim } from '../../../interfaces/employee/expense-claim.interface';

import RemoveElement from '../../table/RemoveElement';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';

import './expense-claim-list.scss';

interface Props {
  expenseClaims: ExpenseClaim[];
  loading: boolean;
}

const headers: TableHeader[] = [
  // {
  //   Header: 'Index',
  //   accessor: 'index'
  // },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Date Submitted',
    accessor: 'createdAt'
  },
  {
    Header: 'Start Date of Claim',
    accessor: 'date'
  },
  {
    Header: 'End Date of Claim',
    accessor: 'endDate'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'Client',
    accessor: 'client'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Approved By',
    accessor: 'approvedBy'
  },
  {
    Header: 'Amount',
    accessor: 'amount'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  },
  category: {
    capitalize: true
  }
};

interface RowData extends TableData {
  index: number | string;
  name: string;
  createdAt: string;
  date: string;
  endDate: string;
  category: string;
  client: string;
  status: string;
  approvedBy: string;
  amount: string;
}

const ExpenseClaimList = ({ expenseClaims, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const formatAmont = (amount: any): string => {
    return 'R ' + parseFloat(amount).toFixed(2);
  };

  const rowData = useMemo(() => {
    return (expenseClaims ?? []).map(
      (expenseClaim: ExpenseClaim) =>
        ({
          index: expenseClaim.id,
          name: expenseClaim.employee,
          createdAt: expenseClaim.created_at,
          date: expenseClaim.date,
          endDate: expenseClaim.end_date,
          category: expenseClaim.category,
          client: expenseClaim.client,
          status: expenseClaim.status,
          approvedBy: expenseClaim.approved_by,
          amount: formatAmont(expenseClaim.amount),
          actions: <RemoveElement element={expenseClaim} />,
          onClick: () => navigate(`/human-resources/expense-claims/${expenseClaim.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseClaims]);

  return (
    <article className="expense-claim-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No expense claims could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default ExpenseClaimList;
