import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddExpenseClaimRequest,
  getAllDownloadExpenseClaimsRequest,
  getExpenseClaimRequest,
  getExpenseClaimsRequest,
  getRemoveExpenseClaimRequest,
  getUpdateExpenseClaimRequest,
  RequestOptions
} from '../tools/api';

import {
  AddExpenseClaimAction,
  LoadExpenseClaimAction,
  LoadExpenseClaimsAction,
  UpdateExpenseClaimAction
} from '../actions/expense-claim/expense-claim.types';

import { ExpenseClaimActions } from '../actions/expense-claim/expense-claim.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadExpenseClaims({ onComplete }: LoadExpenseClaimsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getExpenseClaimsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ExpenseClaimActions.SET_EXPENSE_CLAIMS, expenseClaims: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadExpenseClaimsRequest() {
  yield takeLatest(ExpenseClaimActions.LOAD_EXPENSE_CLAIMS, performLoadExpenseClaims);
}

export function* performLoadExpenseClaim({ id, onComplete }: LoadExpenseClaimAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getExpenseClaimRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ExpenseClaimActions.SET_EXPENSE_CLAIM, expenseClaim: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForDownloadExpenseClaimsRequest() {
  yield takeLatest(ExpenseClaimActions.DOWNLOAD_EXPENSE_CLAIMS, performDownloadExpenseClaim);
}

export function* performDownloadExpenseClaim({ id, onComplete }: LoadExpenseClaimAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions =
      getAllDownloadExpenseClaimsRequest() as RequestOptions;

    const { data, headers } = yield call(axios, endpoint, requestOptions);

    // Get the Content-Disposition header value from the response
    const contentDisposition = headers['content-disposition'];

    // Use a regular expression to extract the file name from the header value
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';

    const csvBlob = new Blob([data], { type: 'text/csv' });
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(csvBlob);
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadExpenseClaimRequest() {
  yield takeLatest(ExpenseClaimActions.LOAD_EXPENSE_CLAIM, performLoadExpenseClaim);
}

export function* performUpdateExpenseClaim({ id, payload, onComplete }: UpdateExpenseClaimAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateExpenseClaimRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The expense claim has been updated successfully!', SNACK_SUCCESS)
    );

    yield put({ type: ExpenseClaimActions.SET_EXPENSE_CLAIM, expenseClaim: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateExpenseClaimRequest() {
  yield takeLatest(ExpenseClaimActions.UPDATE_EXPENSE_CLAIM, performUpdateExpenseClaim);
}

export function* performAddExpenseClaim({ payload, navigate, onComplete }: AddExpenseClaimAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddExpenseClaimRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new expense claim has been created successfully!', SNACK_SUCCESS)
    );

    yield put(ExpenseClaimActions.loadExpenseClaims(noOp));
    yield navigate('/human-resources/expense-claims');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddExpenseClaimRequest() {
  yield takeLatest(ExpenseClaimActions.ADD_EXPENSE_CLAIM, performAddExpenseClaim);
}

export function* performRemoveExpenseClaim({ id, onComplete }: AddExpenseClaimAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveExpenseClaimRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The expense claim has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(ExpenseClaimActions.loadExpenseClaims(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveExpenseClaimRequest() {
  yield takeLatest(ExpenseClaimActions.REMOVE_EXPENSE_CLAIM, performRemoveExpenseClaim);
}

export default function* expenseClaimSaga() {
  yield all([
    watchForLoadExpenseClaimsRequest(),
    watchForDownloadExpenseClaimsRequest(),
    watchForLoadExpenseClaimRequest(),
    watchForUpdateExpenseClaimRequest(),
    watchForAddExpenseClaimRequest(),
    watchForRemoveExpenseClaimRequest()
  ]);
}
