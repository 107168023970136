import React, { useEffect, useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { isUndefined } from '@neslotech/utils';

import { mapConfigItems } from '../../../tools/dropdown.util';

import { Timesheet } from '../../../interfaces/employee/timesheet.interface';
import { Client } from '../../../interfaces/client/client.interface';
import { Category } from '../../../interfaces/config/category.interface';
import { Employee } from '../../../interfaces/employee/employee.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import { Datepicker, OnChangeType as DateChangeType } from '../../datepicker/Datepicker';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType, SelectItem } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';

import './timesheet-form.scss';
import { SubCategory } from '../../../interfaces/config/sub_category.interface';
import { Profile } from '../../../interfaces/profile/profile.interface';
import { useAuth } from '../../../hooks';

type OnAction = (payload: Timesheet, resetForm: boolean, onComplete: () => void) => void;

interface Props {
  employees: Employee[];
  categories: Category[];
  clients: Client[];
  timesheet?: Timesheet;
  onSave: OnAction;
  resetForm?: boolean;
}

export interface TimesheetState extends StateForm {
  employeeId: string;
  date: string | Date;
  billableHours: number;
  customCategory?: string;
  categoryId: string;
  subCategoryId?: number;
  subCategory?: string;
  clientId: string;
  description?: string;
}

const rules: ValidationRules<TimesheetState> = {
  validates: {
    date: ['isPresent'],
    billableHours: ['isPresent'],
    categoryId: ['isPresent'],
    clientId: ['isPresent'],
    employeeId: ['isPresent']
  }
};

const formify = (isAdmin: Boolean, timesheet?: Timesheet, profile?: Profile): TimesheetState =>
  ({
    date: isUndefined(timesheet?.date) ? undefined : new Date(timesheet?.date),
    description: timesheet?.description,
    billableHours: timesheet?.billable_hours,
    categoryId: timesheet?.category_id,
    subCategoryId: timesheet?.sub_category_id,
    clientId: timesheet?.client_id,
    employeeId: isAdmin ? timesheet?.user_id : profile?.id
  } as TimesheetState);

const serverify = (form: TimesheetState) => ({
  date: form.date,
  billable_hours: form.billableHours,
  description: form.description,
  category_id: form.categoryId,
  sub_category_id: form.subCategoryId,
  category: form.customCategory,
  client_id: form.clientId,
  user_id: form.employeeId
});

const TimesheetForm = ({ employees, clients, categories, timesheet, onSave, resetForm }: Props) => {
  const { isAdmin, profile } = useAuth();
  const [form, setForm] = useFormState<TimesheetState>(formify(isAdmin, timesheet, profile), rules);
  const handleChange = (newState: OnChangeType | DateChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as TimesheetState) });

  const [loading, setLoading] = useState<boolean>(false);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);

  useEffect(() => {
    const selectedCategory = categories.find((cat) => cat.id === Number(form.categoryId));
    if (selectedCategory && selectedCategory.sub_categories) {
      const activeSubCategories = selectedCategory.sub_categories.filter(
        (subCat) => subCat['status'] === 'active'
      );
      setSubCategories(activeSubCategories);
    } else {
      setSubCategories([]);
    }
  }, [categories, form.categoryId]);

  const mappedCategories = useMemo(() => {
    return mapConfigItems(categories, true);
  }, [categories]);

  const mappedSubCategories: SelectItem[] = useMemo(() => {
    return subCategories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((subCategory: SubCategory) => ({
        label: subCategory.name,
        value: subCategory.id
      }));
  }, [subCategories]);

  const mappedClients = useMemo(() => {
    return clients
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((client: Client) => ({
        label: client.name,
        value: client.id
      }));
  }, [clients]);

  const mappedEmployees = useMemo(() => {
    return employees
      .sort((a, b) => (a.full_name?.toLowerCase() > b.full_name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((employee: Employee) => ({
        label: employee.full_name,
        value: employee.id
      }));
  }, [employees]);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Timesheet = serverify(form);
    onSave(payload, false, () => setLoading(false));
  };

  const emptyTimeSheet: Timesheet = {
    date: undefined,
    billable_hours: '',
    description: '',
    category_id: '',
    sub_category_id: '',
    client_id: '',
    user_id: ''
  };

  const handleAddAnotherTimesheet = (): void => {
    setLoading(true);

    const payload: Timesheet = serverify(form);
    onSave(payload, true, () => setLoading(false));
    setForm(formify(isAdmin, emptyTimeSheet, profile));
  };

  return (
    <article className="timesheet-form">
      <DetailsCard>
        <Form>
          <FormRow>
            { isAdmin && <Select
              name="employeeId"
              label="Employee"
              placeholder="Employee"
              value={form?.employeeId}
              error={form?.messages.employeeId}
              items={mappedEmployees}
              onChange={handleChange}
            /> }
          </FormRow>
          <FormRow>
            <Datepicker
              name="date"
              label="Date"
              placeholder="Date"
              value={form?.date}
              error={form?.messages.date}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
          <Input
              label="Enter billable hours"
              name="billableHours"
              placeholder="Enter billable hours"
              value={form?.billableHours}
              error={form?.messages.billableHours}
              type="number"
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="categoryId"
              label="Category"
              placeholder="Category"
              value={form?.categoryId}
              error={form?.messages.categoryId}
              items={mappedCategories}
              onChange={handleChange}
            />
          </FormRow>
          {subCategories.length > 0 && (
            <FormRow>
              <Select
                name="subCategoryId"
                label="Sub Category"
                placeholder="Sub Category"
                value={form?.subCategoryId}
                error={form?.messages.subCategoryId}
                items={mappedSubCategories}
                onChange={handleChange}
              />
            </FormRow>
          )}
          {Number(form?.categoryId) === -100 && (
            <FormRow>
              <Input
                label="Enter a custom Category"
                name="customCategory"
                placeholder="Enter a custom Category"
                value={form?.customCategory}
                error={form?.messages.customCategory}
                onChange={handleChange}
              />
            </FormRow>
          )}
          <FormRow>
            <Select
              name="clientId"
              label="Client"
              placeholder="Client"
              value={form?.clientId}
              error={form?.messages.clientId}
              items={mappedClients}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="description"
              label="Description"
              placeholder="Description"
              value={form?.description}
              error={form?.messages.description}
              onChange={handleChange}
            />
          </FormRow>
          { resetForm && (
            <FormAction
            loading={loading}
            label="Add another timesheet"
            onClick={handleAddAnotherTimesheet}
            disabled={!form?.valid}
          />
          )}
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default TimesheetForm;
