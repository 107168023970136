import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getDealSheetsRequest, RequestOptions } from '../tools/api';

import { SystemActions } from '../actions/system/system.actions';
import { DealSheetActions } from '../actions/deal-sheet/deal-sheet.actions';
import { LoadDealSheetsAction } from '../actions/deal-sheet/deal-sheet.types';

export function* performLoadDealSheets({ clientId, onComplete }: LoadDealSheetsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getDealSheetsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: DealSheetActions.SET_DEAL_SHEETS, dealSheets: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadDealSheetsRequest() {
  yield takeLatest(DealSheetActions.LOAD_DEAL_SHEETS, performLoadDealSheets);
}

export default function* dealSheetSaga() {
  yield all([watchForLoadDealSheetsRequest()]);
}
