import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Employeerole } from '../interfaces/employeerole/employeerole.interface';

import { EmployeeroleActions } from '../actions/employeerole/employeerole.actions';

export interface EmployeeroleState extends State {
  employeeroles: Employeerole[];
  employeerole?: Employeerole;
}

export const initialState: EmployeeroleState = {
  employeeroles: []
};

export const employeeroleReducer = (state: EmployeeroleState = initialState, action: AnyAction) => {
  if (action.type === EmployeeroleActions.SET_EMPLOYEEROLES) {
    const { employeeroles } = action;
    return {
      ...state,
      employeeroles
    };
  } else if (action.type === EmployeeroleActions.SET_EMPLOYEEROLE) {
    const { employeerole } = action;
    return {
      ...state,
      employeerole
    };
  }

  return state;
};
