import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getTombstonesEndpoint = (): string => `${API_HOST}/tombstones`;
export const getTombstonesRequest = (): RequestOptions => [
  getTombstonesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getTombstonesCsvEndpoint = (): string => `${getTombstonesEndpoint()}.csv`;
export const getDownloadTombstonesRequest = () => [
  getTombstonesCsvEndpoint(),
  { ...getHttpGetOptions({ ...getAuthHeaders(), Accept: 'text/csv' }), responseType: 'blob' }
];
