import React, { useEffect, useState } from 'react';

import { filterElement } from '../../../tools/filter.util';

import { useFilters } from '../../../hooks/useFilters';

import { Tombstone } from '../../../interfaces/client/tombstone.interface';
import { Filter } from '../../../types/filter.interface';

import TombstoneCard from '../card/TombstoneCard';

import './tombstone-list.scss';
import AlphabetLayout from '../../layout/filter/AlphabetLayout';

interface Props {
  tombstones: Tombstone[];
}

const formify = (tombstones: Tombstone[], filters: Filter): Tombstone[] =>
  tombstones.filter((tombstone: Tombstone) =>
    // @ts-ignore
    filterElement(tombstone, filters)
  );

const TombstoneList = ({ tombstones }: Props) => {
  const { filters, setFilterDefaults } = useFilters();
  const [sortedTombstones, setSortedTombstones] = useState(tombstones);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ category_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sorted = formify(tombstones, filters);

    if (selectedLetter) {
      sorted = sorted.filter(tombstone => tombstone.client[0].toUpperCase() === selectedLetter);
    }

    setSortedTombstones(sorted);
  }, [tombstones, filters, selectedLetter]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="tombstone-list">
        {sortedTombstones.map((tombstone: Tombstone) => (
          <TombstoneCard key={tombstone.id} tombstone={tombstone} />
        ))}
      </article>
    </>
  );
};

export default TombstoneList;
