import React, { useEffect, useMemo, useState } from 'react';

import { filterElement } from '../../../tools/filter.util';

import { useFilters } from '../../../hooks/useFilters';

import { Tombstone } from '../../../interfaces/client/tombstone.interface';
import { Filter } from '../../../types/filter.interface';

import './tombstone-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';
import { useRemoval } from '../../../hooks';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AlphabetLayout from '../../../components/layout/filter/AlphabetLayout'; 

interface Props {
  tombstones: Tombstone[];
}

const formify = (tombstones: Tombstone[], filters: Filter): Tombstone[] =>
  tombstones.filter((tombstone: Tombstone) =>
    // @ts-ignore
    filterElement(tombstone, filters)
  );

const tombstoneHeaders: TableHeader[] = [
  {
    Header: 'Client Name',
    accessor: 'client'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'SubCategory',
    accessor: 'sub_category'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Size of Transaction',
    accessor: 'size_of_transaction_zar'
  },
  {
    Header: 'Fee of Transaction',
    accessor: 'fee_of_transaction_zar'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const TombstoneTable = ({ tombstones }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const { filters, setFilterDefaults } = useFilters();
  const [sortedTombstones, setSortedTombstones] = useState(tombstones);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ category_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setElement, setShowModal } = useRemoval();

  const handleRemove = (tombstone: Tombstone) => {
    setShowModal(true);
    setElement(tombstone);
  };

  useEffect(() => {    
    if (selectedLetter) {
      tombstones = tombstones.filter(tombstone => tombstone.client[0].toUpperCase() === selectedLetter);
      // eslint-disable-next-line no-console
      console.log('selectedLetter', selectedLetter, tombstones);
    }

    setSortedTombstones(tombstones);
  }, [tombstones, selectedLetter]);

  const tombstonesRows: TableData[] = useMemo(() => {
    return formify(sortedTombstones, filters).map<TableData>((tombstone: Tombstone) => ({
      ...tombstone,
      size_of_transaction_zar: tombstone.size_of_transaction
        ? parseFloat(tombstone.size_of_transaction.toString()).toLocaleString('en-ZA', {
            style: 'currency',
            currency: 'ZAR'
          })
        : null,
      fee_of_transaction_zar: tombstone.fee_of_transaction
        ? parseFloat(tombstone.fee_of_transaction.toString()).toLocaleString('en-ZA', {
            style: 'currency',
            currency: 'ZAR'
          })
        : null,
      action: (
        <div className="tombstone_action">
          <EditIcon
            onClick={() =>
              navigate(`/core/clients/${tombstone.client_id}/tombstones/${tombstone.id}`)
            }
          />
          <RemoveIcon onClick={() => handleRemove(tombstone)} />
        </div>
      ),
      onClick: () => {}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedTombstones, filters]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="tombstone-list">
        <Table cols={tombstoneHeaders} rowData={tombstonesRows} />
      </article>
    </>
  );
};

export default TombstoneTable;
