import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { ExpenseClaim } from '../../../../interfaces/employee/expense-claim.interface';
import { Client } from '../../../../interfaces/client/client.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { LoadExpenseClaimsAction } from '../../../../actions/expense-claim/expense-claim.types';
import { ExpenseClaimActions } from '../../../../actions/expense-claim/expense-claim.actions';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import ExpenseClaimForm from '../../../../components/expense-claims/form/ExpenseClaimForm';

const ExpenseClaimContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadExpenseClaimsAction> = useAppDispatch();
  const expenseClaim: ExpenseClaim = useAppSelector(
    ({ expense_claim_store }: AppState) => expense_claim_store.expenseClaim
  );
  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );
  const clients: Client[] = useAppSelector(({ client_store }: AppState) => client_store.clients);

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: ExpenseClaim, onComplete: () => void): void => {
    dispatch(ExpenseClaimActions.updateExpenseClaim(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(ExpenseClaimActions.loadExpenseClaim(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && expenseClaim?.id === Number(id);
  }, [id, loading, expenseClaim?.id]);

  return (
    <PageLayout title={`Expense Claim Details - ${doneLoading ? expenseClaim?.employee : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <ExpenseClaimForm
          clients={clients}
          employees={employees}
          expenseClaim={expenseClaim}
          onSave={onSave}
        />
      )}
    </PageLayout>
  );
};

export default ExpenseClaimContainer;
