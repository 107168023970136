import React from 'react';
import { RouteObject } from 'react-router-dom';

import ClientsContainer from '../../containers/core/clients/list/Clients.container';
import AddClientContainer from '../../containers/core/clients/add/AddClient.container';
import ClientContainer from '../../containers/core/clients/details/Client.container';
import DealSheetsContainer from '../../containers/core/clients/details/deal-sheets/list/DealSheets.container';
import AddDealSheetContainer from '../../containers/core/clients/details/deal-sheets/add/AddDealSheet.container';
import DealSheetContainer from '../../containers/core/clients/details/deal-sheets/details/DealSheet.container';

import ClientsCrumb from '../../crumbs/core/clients/Clients.crumb';
import AddClientCrumb from '../../crumbs/core/clients/AddClient.crumb';
import ClientCrumb from '../../crumbs/core/clients/Client.crumb';
import PrecedentCrumb from '../../crumbs/core/clients/precedent/Precedent.crumb';
import TombstoneCrumb from '../../crumbs/core/clients/tombstone/Tombstone.crumb';
import MandateCrumb from '../../crumbs/core/clients/mandate/Mandate.crumb';
import DealSheetsCrumb from '../../crumbs/core/clients/deal-sheet/DealSheets.crumb';
import AddDealSheetCrumb from '../../crumbs/core/clients/deal-sheet/AddDealSheet.crumb';
import DealSheetCrumb from '../../crumbs/core/clients/deal-sheet/DealSheet.crumb';

import ClientProvider from '../../providers/client/Client.provider';

import ProtectedRoute from '../ProtectedRoute';
import MandatesContainer from '../../containers/core/clients/details/mandates/list/Mandates.container';
import MandateContainer from '../../containers/core/clients/details/mandates/detail/Mandate.container';
import AddMandateContainer from '../../containers/core/clients/details/mandates/add/AddMandate.container';
import AddPrecedentContainer from '../../containers/core/clients/details/precedents/add/AddPrecedent.container';
import PrecedentContainer from '../../containers/core/clients/details/precedents/detail/Precedent.container';
import PrecedentsContainer from '../../containers/core/clients/details/precedents/list/Precedents.container';
import TombstonesContainer from '../../containers/core/clients/details/tombstones/list/Tombstones.container';
import AddTombstoneContainer from '../../containers/core/clients/details/tombstones/add/AddTombstone.container';
import TombstoneContainer from '../../containers/core/clients/details/tombstones/detail/Tombstone.container';
import ContactContainer from '../../containers/core/clients/details/contacts/detail/Contact.container';

const clientRoutes: RouteObject[] = [
  {
    path: 'clients',
    handle: {
      crumb: () => <ClientsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <ClientsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddClientContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddClientCrumb />
        }
      },
      {
        path: ':id',
        handle: {
          crumb: () => <ClientCrumb />
        },
        children: [
          {
            path: '',
            index: true,
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <ClientContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'precedents',
            handle: {
              crumb: () => <PrecedentCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <PrecedentsContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'precedents/add',
            handle: {
              crumb: () => <PrecedentCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <AddPrecedentContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'precedents/:precedentId',
            handle: {
              crumb: () => <PrecedentCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <PrecedentContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'tombstones',
            handle: {
              crumb: () => <TombstoneCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <TombstonesContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'tombstones/add',
            handle: {
              crumb: () => <TombstoneCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <AddTombstoneContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'tombstones/:tombstoneId',
            handle: {
              crumb: () => <TombstoneCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <TombstoneContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'mandates',
            handle: {
              crumb: () => <MandateCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <MandatesContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'mandates/add',
            handle: {
              crumb: () => <MandateCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <AddMandateContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'mandates/:mandateId',
            handle: {
              crumb: () => <MandateCrumb />
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <MandateContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'contacts/:contactId',
            handle: {
            },
            element: (
              <ProtectedRoute>
                <ClientProvider>
                  <ContactContainer />
                </ClientProvider>
              </ProtectedRoute>
            )
          },
          {
            path: 'deal-sheets',
            handle: {
              crumb: () => <DealSheetsCrumb />
            },
            children: [
              {
                path: '',
                index: true,
                element: (
                  <ProtectedRoute>
                    <ClientProvider>
                      <DealSheetsContainer />
                    </ClientProvider>
                  </ProtectedRoute>
                )
              },
              {
                path: 'add',
                element: (
                  <ProtectedRoute>
                    <ClientProvider>
                      <AddDealSheetContainer />
                    </ClientProvider>
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => <AddDealSheetCrumb />
                }
              },
              {
                path: ':dealSheetId',
                handle: {
                  crumb: () => <DealSheetCrumb />
                },
                element: (
                  <ProtectedRoute>
                    <ClientProvider>
                      <DealSheetContainer />
                    </ClientProvider>
                  </ProtectedRoute>
                )
              }
            ]
          }
        ]
      }
    ]
  }
];

export default clientRoutes;
