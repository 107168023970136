import React from 'react';
import { RouteObject } from 'react-router-dom';

import TimesheetsCrumb from '../../crumbs/human-resources/timesheets/Timesheets.crumb';
import AddTimesheetCrumb from '../../crumbs/human-resources/timesheets/AddTimesheet.crumb';
import TimesheetCrumb from '../../crumbs/human-resources/timesheets/Timesheet.crumb';

import TimesheetsContainer from '../../containers/human-resources/timesheets/list/Timesheets.container';
import TimesheetContainer from '../../containers/human-resources/timesheets/details/Timesheet.container';
import AddTimesheetContainer from '../../containers/human-resources/timesheets/add/AddTimesheetContainer.container';

import ProtectedRoute from '../ProtectedRoute';

const timesheetRoutes: RouteObject[] = [
  {
    path: 'timesheets',
    handle: {
      crumb: () => <TimesheetsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <TimesheetsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddTimesheetContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddTimesheetCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <TimesheetContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <TimesheetCrumb />
        }
      }
    ]
  }
];

export default timesheetRoutes;
