import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Tombstone } from '../../../../../../interfaces/client/tombstone.interface';
import { AddTombstoneAction } from '../../../../../../actions/client/tombstone/tombstone.types';
import { LoadTombstonesAction } from '../../../../../../actions/tombstone/tombstone.types';
import { TombstoneActions } from '../../../../../../actions/client/tombstone/tombstone.actions';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { useClient } from '../../../../../../hooks/clients';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../../components/loader/Loader';
import TombstoneForm from '../../../../../../components/clients/details/tombstones/TombstoneForm';
import { UserActions } from '../../../../../../actions/user/user.actions';
import { noOp } from '@neslotech/utils';
import { User } from '../../../../../../interfaces/user/user.interface';
import { AppState } from '../../../../../../types/state.interface';

const AddTombstoneContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddTombstoneAction | LoadTombstonesAction> = useAppDispatch();

  const { id } = useParams<string>();
  const { client, loading } = useClient();

  const users: User[] = useAppSelector(({ user_store }: AppState) => user_store.users);

  useEffect(() => {
    dispatch(UserActions.loadUsers(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCompleteAddTombstone = (data: any) : any => {
    navigate(`/core/clients/${id}/tombstones/${data.id}`);
  };

  const onSave = (payload: Tombstone): void => {
    dispatch(
      TombstoneActions.addTombstone(id, payload, onCompleteAddTombstone)
    );
  };

  return (
    <PageLayout title="Add Tombstone">
      {loading && <Loader filled />}
      {!loading && <TombstoneForm users={users} client={client} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddTombstoneContainer;
