import React from 'react';
import { RouteObject } from 'react-router-dom';

import PrecedentsContainer from '../../containers/core/precedents/list/Precedents.container';
import AddPrecedentContainer from '../../containers/core/precedents/add/AddPrecedent.container';

import PrecedentsCrumb from '../../crumbs/core/precedents/Precedents.crumb';
import AddPrecedentCrumb from '../../crumbs/core/precedents/AddPrecedent.crumb';

import ProtectedRoute from '../ProtectedRoute';

const precedentRoutes: RouteObject[] = [
  {
    path: 'precedents',
    handle: {
      crumb: () => <PrecedentsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <PrecedentsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        handle: {
          crumb: () => <AddPrecedentCrumb />
        },
        element: (
          <ProtectedRoute>
            <AddPrecedentContainer />
          </ProtectedRoute>
        )
      }
    ]
  }
];

export default precedentRoutes;
