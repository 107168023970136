import React from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { RegisterRequest } from '../../../interfaces/auth/register-request.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';

import './register.scss';

export interface RegisterState extends StateForm {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
}

interface CustomValidationRules extends ValidationRules<RegisterState> {
  arePasswordsEqual: (value: string, form: RegisterState) => string | undefined;
}

const rules: CustomValidationRules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    email: ['isPresent', 'isEmail'],
    password: ['isPresent'],
    passwordConfirm: ['isPresent', 'arePasswordsEqual']
  },

  arePasswordsEqual(value, form) {
    if (value !== form?.password) {
      return 'Passwords do not match';
    }
  }
};

type RegisterFunction = (request: RegisterRequest) => void;
interface Props {
  onRegister: RegisterFunction;
}

const serverify = (form: RegisterState): RegisterRequest => ({
  email: form.email,
  first_name: form.firstName,
  last_name: form.lastName,
  password: form.password
});

const Register = ({ onRegister }: Props) => {
  const [form, setForm] = useFormState<RegisterState>(undefined, rules);
  const handleChange = (newState: OnChangeType) =>
    setForm({ ...form, ...(newState as RegisterState) });

  const handleSubmit = (): void => {
    const request: RegisterRequest = serverify(form);
    onRegister(request);
  };

  return (
    <article className="register">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Your Email/Username"
            placeholder="Enter your email/username"
            onChange={handleChange}
            error={form?.messages?.email}
          />
        </FormRow>
        <FormRow fluid>
          <Input
            name="firstName"
            label="Your First name"
            placeholder="Enter your first name"
            onChange={handleChange}
            error={form?.messages?.firstName}
          />
          <Input
            name="lastName"
            label="Your Last name"
            placeholder="Enter your last name"
            onChange={handleChange}
            error={form?.messages?.lastName}
          />
        </FormRow>
        <FormRow fluid>
          <Input
            name="password"
            type="password"
            label="Password"
            placeholder="Enter your password"
            onChange={handleChange}
            error={form?.messages?.password}
          />
          <Input
            name="passwordConfirm"
            type="password"
            label="Password Confirmation"
            placeholder="Enter your password confirmation"
            onChange={handleChange}
            error={form?.messages?.passwordConfirm}
          />
        </FormRow>
        <FormRow>
          <small className="register__password-message">
            When setting a password, you are required to have a password that is at least 8
            characters in length including a capital letter, a number and a special character
          </small>
        </FormRow>
        <FormAction onClick={handleSubmit} label="Register" disabled={!form?.valid} />
      </Form>
    </article>
  );
};

export default Register;
