import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { ClientType } from '../../../interfaces/config/client-type.interface';

import { useAppSelector } from '../../../hooks';

const ClientTypeCrumb = () => {
  const clientType: ClientType = useAppSelector(
    ({ client_type_store }: AppState) => client_type_store.clientType
  ) as ClientType;

  return <NavLink to={`/admin/client-types/${clientType?.id}`}>{clientType?.name}</NavLink>;
};

export default ClientTypeCrumb;
