import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Timesheet } from '../../../interfaces/employee/timesheet.interface';

import { useAppSelector } from '../../../hooks';

const TimesheetCrumb = () => {
  const timesheet: Timesheet = useAppSelector(
    ({ timesheet_store }: AppState) => timesheet_store.timesheet
  );

  return (
    <NavLink to={`/human-resources/timesheets/${timesheet?.id}`}>{timesheet?.employee}</NavLink>
  );
};

export default TimesheetCrumb;
