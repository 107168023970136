import React from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Investment } from '../../../../../interfaces/investment/investment.interface';

import { RemoveInvestmentAction } from '../../../../../actions/investment/investment.types';
import { InvestmentActions } from '../../../../../actions/investment/investment.actions';

import { useAppDispatch, useRemoval } from '../../../../../hooks';

import { Modal } from '../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../../components/modal/remove/RemoveModal';

const RemoveInvestmentContainer = () => {
  const dispatch: Dispatch<RemoveInvestmentAction> = useAppDispatch();
  const { id } = useParams<string>();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      InvestmentActions.removeInvestment(id as string, (element as Investment).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Investment">
            <RemoveModal title={(element as Investment).name} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveInvestmentContainer;
