import React from 'react';
import { RouteObject } from 'react-router-dom';

import DocumentsContainer from '../../containers/admin/documents/list/Documents.container';
import AddDocumentContainer from '../../containers/admin/documents/add/AddDocument.container';
import DocumentContainer from '../../containers/admin/documents/details/Document.container';

import DocumentsCrumb from '../../crumbs/admin/documents/Documents.crumb';
import AddDocumentCrumb from '../../crumbs/admin/documents/AddDocument.crumb';
import DocumentCrumb from '../../crumbs/admin/documents/Document.crumb';

import AdminRoute from '../AdminRoute';
import ProtectedRoute from '../ProtectedRoute';

const documentRoutes: RouteObject[] = [
  {
    path: 'documents',
    handle: {
      crumb: () => <DocumentsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <DocumentsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddDocumentContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddDocumentCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <DocumentContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <DocumentCrumb />
        }
      }
    ]
  }
];

export default documentRoutes;
