import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Payslip } from '../../../../interfaces/employee/payslip.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';

import { PayslipActions } from '../../../../actions/payslip/payslip.actions';
import { AddPayslipAction } from '../../../../actions/payslip/payslip.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import PayslipForm from '../../../../components/payslips/form/PayslipForm';

const AddPayslipContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddPayslipAction | LoadEmployeesAction> = useAppDispatch();

  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: Payslip, onComplete: () => void): void => {
    dispatch(PayslipActions.addPayslip(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Payslip">
      <PayslipForm employees={employees} onSave={onSave} />
    </PageLayout>
  );
};

export default AddPayslipContainer;
