import React, { useEffect, useState } from 'react';

import { Filter } from '../../../types/filter.interface';

import { filterElement } from '../../../tools/filter.util';

import { useFilters } from '../../../hooks/useFilters';

import { Precedent } from '../../../interfaces/client/precedent.interface';

import PrecedentCard from '../card/PrecedentCard';

import './precedent-list.scss';
import AlphabetLayout from '../../layout/filter/AlphabetLayout';

interface Props {
  precedents: Precedent[];
}

const formify = (precedents: Precedent[], filters: Filter): Precedent[] =>
  precedents.filter((precedent: Precedent) =>
    // @ts-ignore
    filterElement(precedent, filters)
  );

const PrecedentList = ({ precedents }: Props) => {
  const { filters, setFilterDefaults } = useFilters();
  const [sortedPrecedents, setSortedPrecedents] = useState(precedents);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ category_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLetter) {
      precedents = formify(precedents, filters).filter(precedent => precedent.client[0].toUpperCase() === selectedLetter);
    }

    setSortedPrecedents(precedents);
  }, [precedents, selectedLetter]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="precedent-list">
        {sortedPrecedents.map((precedent: Precedent) => (
          <PrecedentCard key={precedent.id} precedent={precedent} />
        ))}
      </article>
    </>
  );
};

export default PrecedentList;
