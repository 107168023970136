import React, { ReactNode } from 'react';

import { getClassNames } from '@neslotech/utils';

import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';

import './page-layout.scss';

interface Props {
  title: string;
  action?: ReactNode;
  children: ReactNode[] | ReactNode;
  bordered?: boolean;
}

const PageLayout = ({ title, action, bordered, children }: Props) => (
  <article className="page-layout">
    <Breadcrumbs />
    <main className="page-layout__content">
      <header className={getClassNames('page-layout__header', { bordered })}>
        <h3 className="page-layout__title">{title}</h3>
        {action}
      </header>
      <section>{children}</section>
    </main>
  </article>
);

export default PageLayout;
