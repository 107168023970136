import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../../types/state.interface';

import { Fund } from '../../../../interfaces/fund/fund.interface';
import { Investment } from '../../../../interfaces/investment/investment.interface';

import { useAppSelector } from '../../../../hooks';

const InvestmentCrumb = () => {
  const fund: Fund = useAppSelector(({ fund_store }: AppState) => fund_store.fund) as Fund;
  const investment: Investment = useAppSelector(
    ({ investment_store }: AppState) => investment_store.investment
  ) as Investment;

  return (
    <NavLink to={`/core/funds/${fund?.id}/investments/${investment?.id}`}>
      {investment?.name}
    </NavLink>
  );
};

export default InvestmentCrumb;
