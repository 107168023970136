import { NavigateFunction } from 'react-router-dom';

import { Sector } from '../../interfaces/config/sector.interface';

import {
  AddSectorAction,
  LoadSectorAction,
  LoadSectorsAction,
  OnComplete,
  RemoveSectorAction,
  UpdateSectorAction
} from './sector.types';

export class SectorActions {
  static readonly LOAD_SECTORS: string = '[SECTOR_STORE] Load sectors';
  static readonly SET_SECTORS: string = '[SECTOR_STORE] Set sectors';

  static readonly LOAD_SECTOR: string = '[SECTOR_STORE] Load sector';
  static readonly SET_SECTOR: string = '[SECTOR_STORE] Set sector';

  static readonly UPDATE_SECTOR: string = '[SECTOR_STORE] Update sector';
  static readonly ADD_SECTOR: string = '[SECTOR_STORE] Add sector';
  static readonly REMOVE_SECTOR: string = '[SECTOR_STORE] Remove sector';

  static loadSectors = (onComplete: OnComplete): LoadSectorsAction => ({
    type: SectorActions.LOAD_SECTORS,
    onComplete
  });

  static loadSector = (id: number | string, onComplete: OnComplete): LoadSectorAction => ({
    type: SectorActions.LOAD_SECTOR,
    id,
    onComplete
  });

  static addSector = (
    payload: Sector,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddSectorAction => ({
    type: SectorActions.ADD_SECTOR,
    payload,
    navigate,
    onComplete
  });

  static updateSector = (
    id: number | string,
    payload: Sector,
    onComplete: OnComplete
  ): UpdateSectorAction => ({
    type: SectorActions.UPDATE_SECTOR,
    id,
    payload,
    onComplete
  });

  static removeSector = (id: number | string, onComplete: OnComplete): RemoveSectorAction => ({
    type: SectorActions.REMOVE_SECTOR,
    id,
    onComplete
  });
}
