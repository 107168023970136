import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { SubCategory } from '../../../../interfaces/config/sub_category.interface';

import { LoadCategoriesAction } from '../../../../actions/category/category.types';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import SubCategoryForm from '../../../../components/sub-categories/form/SubCategoryForm';
import { SubCategoryActions } from '../../../../actions/sub-category/sub-category.actions';

const SubCategoryContainer = () => {
  const { id, sub_category_id } = useParams<string>();

  const dispatch: Dispatch<LoadCategoriesAction> = useAppDispatch();
  const sub_category: SubCategory = useAppSelector(
    ({ sub_category_store }: AppState) => sub_category_store.sub_category
  ) as SubCategory;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: SubCategory, onComplete: () => void): void => {
    dispatch(SubCategoryActions.updateSubCategory(id as string, sub_category_id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(SubCategoryActions.loadSubCategory(id as string, sub_category_id as string, () => {
      setLoading(false);
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_category_id]);

  const doneLoading = useMemo(() => {
    return !loading && sub_category?.id === Number(sub_category_id);
  }, [sub_category_id, loading, sub_category?.id]);

  return (
    <PageLayout title={`Sub Category Details - ${doneLoading ? sub_category?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <SubCategoryForm sub_category={sub_category} onSave={onSave} />}
    </PageLayout>
  );
};

export default SubCategoryContainer;
