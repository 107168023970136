import React, { useEffect, useMemo } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../../../types/state.interface';
import { Category } from '../../../../../../interfaces/config/category.interface';
import { Filter } from '../../../../../../types/filter.interface';
import { Precedent } from '../../../../../../interfaces/client/precedent.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import RemovalProvider from '../../../../../../providers/Removal.provider';

import { LoadPrecedentsAction } from '../../../../../../actions/client/precedent/precedent.types';
import { PrecedentActions } from '../../../../../../actions/client/precedent/precedent.actions';
import { CategoryActions } from '../../../../../../actions/category/category.actions';
import { LoadSectorsAction } from '../../../../../../actions/sector/sector.types';

import { ReactComponent as ChevronRightIcon } from '../../../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import Button from '../../../../../../components/button/Button';
import PrecedentList from '../../../../../../components/precedents/list/PrecedentList';
import FilterLayout from '../../../../../../components/layout/filter/FilterLayout';
import { DropdownMenuItem } from '../../../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../../../components/radio/RadioGroup';

import RemovePrecedentContainer from '../remove/RemovePrecedent.container';

const PrecedentsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadPrecedentsAction | LoadSectorsAction> = useAppDispatch();
  const { id } = useParams<string>();

  const precedents: Precedent[] = useAppSelector(
    ({ client_store }: AppState) => client_store.precedents
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(PrecedentActions.loadPrecedents(id, noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Category',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="category_id"
            onChange={onChange}
            value={filters?.category_id as string}
            items={categories
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((category: Category) => ({
                label: category.name,
                value: category.id
              }))}
          />
        )
      }
    ];
  }, [categories]);

  return (
    <PageLayout
      title="Precedents"
      action={<Button label="Add Precedent" onClick={() => navigate('add')} />}
    >
      <FilterLayout menuItems={menuItems}>
        <RemovalProvider>
          <PrecedentList precedents={precedents} />
          <RemovePrecedentContainer />
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default PrecedentsContainer;
