export const formatCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ZAR',

    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(value);
};
