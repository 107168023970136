import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Fund } from '../../../../interfaces/fund/fund.interface';

import { LoadFundsAction } from '../../../../actions/fund/fund.types';
import { FundActions } from '../../../../actions/fund/fund.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import FundList from '../../../../components/funds/list/FundList';
import Button from '../../../../components/button/Button';

import RemoveFundContainer from '../remove/RemoveFund.container';
import RemovalProvider from '../../../../providers/Removal.provider';

const FundsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadFundsAction> = useAppDispatch();
  const funds: Fund[] = useAppSelector(({ fund_store }: AppState) => fund_store.funds);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(FundActions.loadFunds(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Fund Management"
      action={<Button label="Add Fund" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <FundList funds={funds} loading={loading} />
        <RemoveFundContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default FundsContainer;
