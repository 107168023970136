import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getSearchEndpoint = (term: string): string => `${API_HOST}/search?term=${term}`;
export const getSearchRequest = (term: string): RequestOptions => [
  getSearchEndpoint(term),
  getHttpGetOptions(getAuthHeaders())
];
