import React, { FunctionComponent, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { getClassNames, isEmpty, noOp } from '@neslotech/utils';

import { Profile } from '../../interfaces/profile/profile.interface';

import { useAuth, useSidebar } from '../../hooks';

import { ReactComponent as CoreIcon } from '../../icons/core-icon.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar-icon.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevron-up-icon.svg';
import { ReactComponent as HomeIcon } from '../../icons/home-icon.svg';
import { ReactComponent as PeopleIcon } from '../../icons/people-icon.svg';
import { ReactComponent as LogoutIcon } from '../../icons/logout-icon.svg';
// import { ReactComponent as DocumentIcon } from '../../icons/word-icon.svg';
import { ReactComponent as DocumentIcon } from '../../icons/pdf-icon.svg';

import './sidebar.scss';

interface ListItem {
  title: string;
  icon?: FunctionComponent;
  items?: ListItem[];
  path?: string;
  isolated?: boolean;
}

type GetItems = (profile?: Profile) => ListItem[];

const coreItems: GetItems = () => [
  {
    title: 'Dashboard',
    icon: HomeIcon,
    path: '/dashboard',
    isolated: true
  },
  {
    title: 'Core',
    icon: CoreIcon,
    items: [
      {
        title: 'Clients',
        path: '/core/clients'
      },
      {
        title: 'Tombstones',
        path: '/core/tombstones'
      },
      {
        title: 'Precedents',
        path: '/core/precedents'
      },
      {
        title: 'Mandates',
        path: '/core/mandates'
      },
      {
        title: 'Other Contacts',
        path: '/core/contacts',
        items: [
          {
            title: 'Fund Management',
            path: '/core/funds'
          }
        ]
      }
    ]
  }
];

const adminItems: GetItems = () => [
  {
    title: 'Administration',
    icon: CalendarIcon,
    items: [
      {
        title: 'User Management',
        path: '/admin/users'
      },
      {
        title: 'Categories',
        path: '/admin/categories'
      },
      {
        title: 'Client Types',
        path: '/admin/client-types'
      },
      {
        title: 'Sectors',
        path: '/admin/sectors'
      },
      {
        title: 'Designations',
        path: '/admin/designations'
      },
      {
        title: 'Events',
        path: '/admin/events'
      },
      {
        title: 'Employee Roles',
        path: '/admin/employeeroles'
      }
    ]
  }
];

const hrItems: GetItems = (profile?: Profile) => [
  {
    title: 'Human Resources',
    icon: PeopleIcon,
    items: [
      {
        title: 'Employees',
        path: '/human-resources/employees'
      },
      {
        title: 'Leave Requests',
        path: '/human-resources/leave-requests'
      },
      {
        title: 'Expense Claims',
        path: '/human-resources/expense-claims'
      },
      {
        title: 'Payslips',
        path: '/human-resources/payslips'
      },
      ,
      {
        title: 'Irp5s',
        path: '/human-resources/irp5s'
      },
      {
        title: 'PDRs',
        path: '/human-resources/performance-reviews'
      },
      {
        title: 'Time Sheets',
        path: '/human-resources/timesheets'
      },
      ...(profile?.role === 'admin'
        ? [
            {
              title: 'Prospects',
              path: '/human-resources/prospects'
            }
          ]
        : [])
    ]
  }
];

const companyItems: GetItems = (profile?: Profile) => [
  {
    title: 'Company Documents',
    path: '/admin/documents',
    icon: DocumentIcon
  }
];

const items: (profile?: Profile) => ListItem[] = (profile?: Profile) => {
  let items = [...coreItems()];

  if (profile?.role === 'admin') {
    items.push(...adminItems());
  }

  items.push(...hrItems(profile));

  // if (profile?.role === 'admin') {
    items.push(...companyItems());
  // }

  return items;
};

interface TitleProps {
  title: string;
  Icon?: FunctionComponent;
  inner?: boolean;
  isolated?: boolean;
}

const ItemTitle = ({ Icon, title, inner, isolated }: TitleProps) => (
  <>
    <section className="sidebar-item__title">
      {Icon && <Icon />}
      <p>{title}</p>
    </section>
    {!inner && !isolated && <ChevronIcon />}
  </>
);

interface ItemProps {
  title: string;
  Icon?: FunctionComponent;
  items?: ListItem[];
  inner?: boolean;
  path?: string;
  isolated?: boolean;
  onClick?: () => void;
}

const SidebarItem = ({
  isolated = false,
  title,
  Icon,
  items,
  inner = false,
  path,
  onClick = noOp
}: ItemProps) => {
  const { openedItem, setOpenedItem } = useSidebar();

  const innerInnerItem = useMemo(() => {
    return inner && !isEmpty(items);
  }, [inner, items]);

  const open = useMemo(() => {
    return (
      innerInnerItem ||
      title === openedItem ||
      (items ?? []).some((innerItem: ListItem) => innerItem.title === openedItem)
    );
  }, [innerInnerItem, title, openedItem, items]);

  return (
    <article className={getClassNames('sidebar-item', { inner, open, innerInner: innerInnerItem })}>
      {!path && (
        <header
          onClick={(e) => {
            e.stopPropagation();
            onClick();
            setOpenedItem(title === openedItem ? '' : title);
          }}
        >
          <ItemTitle title={title} Icon={Icon} inner={inner} isolated={isolated} />
        </header>
      )}
      {path && (
        <NavLink
          onClick={(e) => {
            e.stopPropagation();
            setOpenedItem(title);
          }}
          to={path}
          style={({ isActive }) => ({ backgroundColor: isActive ? '#c4960c' : '#003768' })}
        >
          <ItemTitle title={title} Icon={Icon} inner={inner} isolated={isolated} />
        </NavLink>
      )}
      {open && (
        <ul>
          {(items ?? []).map((item: ListItem) => (
            <SidebarItem
              inner
              key={item.title}
              title={item.title}
              path={item.path}
              items={item.items}
            />
          ))}
        </ul>
      )}
    </article>
  );
};

interface Props {
  profile?: Profile;
}

const Sidebar = ({ profile }: Props) => {
  const { onLogout } = useAuth();

  return (
    <aside className="sidebar">
      <small className="sidebar__title">Main Menu</small>
      <ul className="sidebar__section">
        {items(profile).map((item) => (
          <li key={item.title}>
            <SidebarItem
              isolated={item.isolated}
              path={item.path}
              title={item.title}
              Icon={item.icon}
              items={item.items}
            />
          </li>
        ))}
      </ul>
      <footer className="sidebar__logout">
        <SidebarItem inner title="Logout" Icon={LogoutIcon} onClick={onLogout} />
        <small>&copy; 2023 BSM. All Rights Reserved</small>
      </footer>
    </aside>
  );
};

export default Sidebar;
