import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';
import { Client } from '../../../../interfaces/client/client.interface';
import { Profile } from '../../../../interfaces/profile/profile.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { ClientType } from '../../../../interfaces/config/client-type.interface';

import { ClientActions } from '../../../../actions/client/client.actions';
import { AddClientAction } from '../../../../actions/client/client.types';
import { CategoryActions } from '../../../../actions/category/category.actions';
import { LoadCategoriesAction } from '../../../../actions/category/category.types';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import ClientForm from '../../../../components/clients/form/ClientForm';
import { Loader } from '../../../../components/loader/Loader';
import { ClientTypeActions } from '../../../../actions/client-type/client-type.actions';

const AddClientContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddClientAction | LoadCategoriesAction> = useAppDispatch();

  const profile: Profile = useAppSelector(({ profile_store }: AppState) => profile_store.profile);
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );
  const clientTypes: ClientType[] = useAppSelector(
    ({ client_type_store }: AppState) => client_type_store.clientTypes
  );

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(() => setLoading(false)));
    dispatch(ClientTypeActions.loadClientTypes(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: Client, onComplete: () => void): void => {
    dispatch(ClientActions.addClient({ ...payload, user_id: profile.id }, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Client">
      {loading && <Loader filled />}
      {!loading && <ClientForm categories={categories} clientTypes={clientTypes} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddClientContainer;
