import { NavigateFunction } from 'react-router-dom';

import { ClientType } from '../../interfaces/config/client-type.interface';

import {
  AddClientTypeAction,
  LoadClientTypeAction,
  LoadClientTypesAction,
  OnComplete,
  RemoveClientTypeAction,
  UpdateClientTypeAction
} from './client-type.types';

export class ClientTypeActions {
  static readonly LOAD_CLIENT_TYPES: string = '[CLIENT_TYPE_STORE] Load client types';
  static readonly SET_CLIENT_TYPES: string = '[CLIENT_TYPE_STORE] Set client types';

  static readonly LOAD_CLIENT_TYPE: string = '[CLIENT_TYPE_STORE] Load client type';
  static readonly SET_CLIENT_TYPE: string = '[CLIENT_TYPE_STORE] Set client type';

  static readonly UPDATE_CLIENT_TYPE: string = '[CLIENT_TYPE_STORE] Update client type';
  static readonly ADD_CLIENT_TYPE: string = '[CLIENT_TYPE_STORE] Add client type';
  static readonly REMOVE_CLIENT_TYPE: string = '[CLIENT_TYPE_STORE] Remove client type';

  static loadClientTypes = (onComplete: OnComplete): LoadClientTypesAction => ({
    type: ClientTypeActions.LOAD_CLIENT_TYPES,
    onComplete
  });

  static loadClientType = (id: number | string, onComplete: OnComplete): LoadClientTypeAction => ({
    type: ClientTypeActions.LOAD_CLIENT_TYPE,
    id,
    onComplete
  });

  static addClientType = (
    payload: ClientType,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddClientTypeAction => ({
    type: ClientTypeActions.ADD_CLIENT_TYPE,
    payload,
    navigate,
    onComplete
  });

  static updateClientType = (
    id: number | string,
    payload: ClientType,
    onComplete: OnComplete
  ): UpdateClientTypeAction => ({
    type: ClientTypeActions.UPDATE_CLIENT_TYPE,
    id,
    payload,
    onComplete
  });

  static removeClientType = (
    id: number | string,
    onComplete: OnComplete
  ): RemoveClientTypeAction => ({
    type: ClientTypeActions.REMOVE_CLIENT_TYPE,
    id,
    onComplete
  });
}
