import React from 'react';
import { RouteObject } from 'react-router-dom';

import FundsContainer from '../../containers/admin/funds/list/Funds.container';
import AddFundContainer from '../../containers/admin/funds/add/AddFund.container';
import FundContainer from '../../containers/admin/funds/details/Fund.container';

import FundsCrumb from '../../crumbs/admin/funds/Funds.crumb';
import AddFundCrumb from '../../crumbs/admin/funds/AddFund.crumb';
import FundCrumb from '../../crumbs/admin/funds/Fund.crumb';

import ProtectedRoute from '../ProtectedRoute';
import investmentRoutes from './investment.routes';

const fundRoutes: RouteObject[] = [
  {
    path: 'funds',
    handle: {
      crumb: () => <FundsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <FundsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddFundContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddFundCrumb />
        }
      },
      {
        path: ':id',
        handle: {
          crumb: () => <FundCrumb />
        },
        children: [
          {
            path: '',
            index: true,
            element: (
              <ProtectedRoute>
                <FundContainer />
              </ProtectedRoute>
            )
          },
          ...investmentRoutes
        ]
      }
    ]
  }
];

export default fundRoutes;
