import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { isEmpty } from '@neslotech/utils';

import { AppState } from '../../../../../../types/state.interface';
import { Note } from '../../../../../../interfaces/client/note.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import { NoteActions } from '../../../../../../actions/client/note/note.actions';
import { LoadNotesAction } from '../../../../../../actions/client/note/note.types';

import { ReactComponent as AddIcon } from '../../../../../../icons/add-icon.svg';

import DetailsCard from '../../../../../../components/card/DetailsCard';
import NoteDetail from '../../../../../../components/clients/details/notes/NoteDetail';

import AddNoteContainer from '../add/AddNote.container';

const NotesContainer = () => {
  const dispatch: Dispatch<LoadNotesAction> = useAppDispatch();
  const { id } = useParams();

  const notes: Note[] = useAppSelector(({ client_store }: AppState) => client_store.notes);

  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(NoteActions.loadNotes(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <DetailsCard
      title="Notes"
      centered
      action={<AddIcon onClick={() => setShowModal(true)} />}
      loading={loading}
      scrollable={!isEmpty(notes)}
      fitted
    >
      {notes.map((note: Note) => (
        <NoteDetail key={note.id} note={note} />
      ))}
      {isEmpty(notes) && <p className="details-card__empty">There are no notes to display!</p>}
      {showModal && <AddNoteContainer setShow={setShowModal} />}
    </DetailsCard>
  );
};

export default NotesContainer;
