import React, { ChangeEvent } from 'react';

import { getClassNames } from '@neslotech/utils';

import './select.scss';

type NamedChangeType = { [key: string]: string | string[] };
export type OnChangeType = NamedChangeType | string | string[];

export interface SelectItem {
  label: string;
  value: string | number;
}

interface Props {
  type?: 'text' | 'password' | 'number' | 'email';
  name: string;
  label?: string;
  placeholder: string;
  value?: string | number | string[];
  error?: string;
  disabled?: boolean;
  multiple?: boolean;
  items: SelectItem[];
  onChange: (value: OnChangeType) => void;
}

const Select = ({
  name,
  value,
  label,
  placeholder,
  error,
  disabled,
  onChange,
  items,
  multiple
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let changedValue: string | string[] = event.target.value;
    if (multiple) {
      changedValue = Array.from(event.target.selectedOptions).map((element) => element.value);
    }

    const value = getChangeValue(changedValue, name);
    onChange(value);
  };

  return (
    <article className="select">
      <section className="select__label">{label && <label htmlFor={name}>{label}</label>}</section>
      <section className={getClassNames('select__wrapper', { disabled })}>
        <select
          multiple={multiple}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
        >
          <option disabled selected value="">
            {placeholder}
          </option>
          {items.map((item: SelectItem) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </section>
      <section className="select__error">{error && <small>{error}</small>}</section>
    </article>
  );
};

const getChangeValue = (value: string | string[], name?: string): OnChangeType => {
  return name ? { [name]: value } : value;
};

export default Select;
