import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddUserRequest,
  getRemoveUserRequest,
  getUpdateUserRequest,
  getUserRequest,
  getUsersRequest,
  RequestOptions
} from '../tools/api';

import { AddUserAction, LoadUsersAction, UpdateUserAction } from '../actions/user/user.types';
import { UserActions } from '../actions/user/user.actions';
import { SystemActions } from '../actions/system/system.actions';
import { ProfileActions } from '../actions/profile/profile.actions';

import { ResponseError } from '../types/response-error.interface';
import { AuthActions } from '../actions/auth/auth.actions';

export function* performLoadUsers({ onComplete }: LoadUsersAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUsersRequest();

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: UserActions.SET_USERS, users: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadUsersRequest() {
  yield takeLatest(UserActions.LOAD_USERS, performLoadUsers);
}

export function* performLoadUser({ id, onComplete }: LoadUsersAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUserRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: UserActions.SET_USER, user: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadUserRequest() {
  yield takeLatest(UserActions.LOAD_USER, performLoadUser);
}

export function* performUpdateUser({ id, payload, onComplete }: UpdateUserAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateUserRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.full_name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: UserActions.SET_USER, user: data });

    yield put(ProfileActions.loadProfile());
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateUserRequest() {
  yield takeLatest(UserActions.UPDATE_USER, performUpdateUser);
}

export function* performAddUser({ payload, navigate, onComplete }: AddUserAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddUserRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'Successfully registered this account, the email address will receive an email to confirm the account.',
        SNACK_SUCCESS
      )
    );

    yield put(AuthActions.forgotPassword({ email: payload.email as string }));

    yield navigate('/admin/users');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddUserRequest() {
  yield takeLatest(UserActions.ADD_USER, performAddUser);
}

export function* performRemoveUser({ id, onComplete }: AddUserAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveUserRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The user has been updated successfully!', SNACK_SUCCESS));

    yield put(UserActions.loadUsers(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveUserRequest() {
  yield takeLatest(UserActions.REMOVE_USER, performRemoveUser);
}

export default function* userSaga() {
  yield all([
    watchForLoadUsersRequest(),
    watchForLoadUserRequest(),
    watchForUpdateUserRequest(),
    watchForAddUserRequest(),
    watchForRemoveUserRequest()
  ]);
}
