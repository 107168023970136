import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Mandate } from '../../../interfaces/client/mandate.interface';

import { useRemoval } from '../../../hooks';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';
import { capitalise } from '@neslotech/utils';

interface Props {
  mandate: Mandate;
}

const MandateCard = ({ mandate }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const { setElement, setShowModal } = useRemoval();

  const actions = useMemo(() => {
    const handleRemove = () => {
      setShowModal(true);
      setElement(mandate);
    };

    return (
      <>
        <EditIcon
          onClick={() => navigate(`/core/clients/${mandate.client_id}/mandates/${mandate.id}`)}
        />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandate]);

  return (
    <DetailsCard action={actions} small>
      <article className="mandate-card">
        <InfoDetail title="Client Name" value={mandate.client} />
        <InfoDetail title="Mandate Name" value={mandate.name} />
        <InfoDetail title="Sectors/Assets" value={mandate.sector} />
        <InfoDetail title="Transaction Type" value={mandate.transaction_type} />
        <InfoDetail
          title="Transaction Size"
          value={
            mandate.transaction_size
              ? parseFloat(mandate.transaction_size.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : null
          }
        />
        <InfoDetail
          title="Transaction Fee"
          value={
            mandate.transaction_fee
              ? parseFloat(mandate.transaction_fee.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : null
          }
        />
        <InfoDetail title="Status" value={capitalise(mandate.status)} />
      </article>
    </DetailsCard>
  );
};

export default MandateCard;
