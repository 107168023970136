import React, { useEffect, useMemo } from 'react';

import { Document } from '../../../interfaces/document/document.interface';
import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';

import { filterElement } from '../../../tools/filter.util';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import './document-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';
import Button from '../../button/Button';
import { useAuth, useRemoval } from '../../../hooks';

interface Props {
  documents: Document[];
}

const formify = (documents: Document[], filters: Filter): Document[] =>
  documents.filter((document: Document) =>
    // @ts-ignore
    filterElement(document, filters)
  );

const documentHeaders: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'SubCatetegory',
    accessor: 'sub_category'
  },
  {
    Header: 'Date',
    accessor: 'created_at'
  },
  {
    Header: 'Uploaded By',
    accessor: 'user'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const DocumentTable = ({ documents }: Props) => {
  const { filters, setFilterDefaults, sort } = useFilters();
  const navigate: NavigateFunction = useNavigate();
  const { setElement, setShowModal } = useRemoval();
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    documentHeaders.pop();
  }

  useEffect(() => {
    setFilterDefaults({ document_type_id: '', category_id: '', sector_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = (document: Document) => {
    setShowModal(true);
    setElement(document);
  };

  const documentsRows: TableData[] = useMemo(() => {
    return formify(documents, filters)
      .sort(
        sort === 'descending'
          ? (a: Document, b: Document) => {
              return a.name.localeCompare(b.name);
            }
          : (a: Document, b: Document) => {
              return b.name.localeCompare(a.name);
            }
      )
      .map<TableData>((document: Document) => ({
        ...document,
        deal_sheets: (
          <Button
            hollow
            label="Deal Sheets"
            onClick={() => navigate(`${document.id}/deal-sheets`)}
          />
        ),
        action: (
          <div className="document_action">
            <EditIcon onClick={() => navigate(`/admin/documents/${document.id}`)} />
            <RemoveIcon onClick={() => handleRemove(document)} />
          </div>
        ),
        onClick: () => {}
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, filters]);

  return (
    <article className="document-list">
      <Table cols={documentHeaders} rowData={documentsRows} />
    </article>
  );
};

export default DocumentTable;
