import { AddToCalendarButton } from 'add-to-calendar-button-react';
import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { LeaveRequest } from '../../../interfaces/employee/leave-request.interface';

import RemoveElement from '../../table/RemoveElement';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';

import './leave-request-list.scss';

interface Props {
  leaveRequests: LeaveRequest[];
  loading: boolean;
}

const headers: TableHeader[] = [
  // {
  //   Header: 'Index',
  //   accessor: 'index'
  // },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Start Date',
    accessor: 'startDate'
  },
  {
    Header: 'End Date',
    accessor: 'endDate'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'Total Days',
    accessor: 'total_days'
  },
  {
    Header: 'Allow Reason',
    accessor: 'allow_reason'
  },
  {
    Header: '',
    accessor: 'add_calendar',
    disableSortBy: true
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  },
  category: {
    capitalize: true
  }
};

interface RowData extends TableData {
  index: number | string;
  name: string;
  startDate: string;
  endDate: string;
  category: string;
  total_days: number;
  allow_reason: string;
  status: string;
}

const LeaveRequestList = ({ leaveRequests, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return (leaveRequests ?? []).map(
      (leaveRequest: LeaveRequest) =>
        ({
          index: leaveRequest.id,
          name: leaveRequest.employee,
          startDate: leaveRequest.start_date,
          endDate: leaveRequest.end_date,
          category: leaveRequest.category,
          status: leaveRequest.status,
          total_days: leaveRequest.total_days,
          allow_reason: leaveRequest.allow_reason,
          add_calendar: (
            <>
              {leaveRequest.status === 'approved' ? (
                <div onClick={(e) => e.stopPropagation()} style={{ marginRight: 10 }}>
                  <AddToCalendarButton
                    name={'Leave Request'}
                    startDate={leaveRequest.start_date as string}
                    endDate={leaveRequest.end_date as string}
                    options={['Apple', 'Google', 'Yahoo', 'iCal']}
                  />
                </div>
              ) : null}
            </>
          ),
          actions: <RemoveElement element={leaveRequest} />,
          onClick: () => navigate(`${leaveRequest.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveRequests]);
  return (
    <article className="leave-request-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No leave requests could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default LeaveRequestList;
