import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { ClientType } from '../../../../interfaces/config/client-type.interface';

import { RemoveClientTypeAction } from '../../../../actions/client-type/client-type.types';
import { ClientTypeActions } from '../../../../actions/client-type/client-type.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveClientTypeContainer = () => {
  const dispatch: Dispatch<RemoveClientTypeAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      ClientTypeActions.removeClientType((element as ClientType).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Client Type">
            <RemoveModal title={(element as ClientType).name} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveClientTypeContainer;
