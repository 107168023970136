import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Category } from '../../../../interfaces/config/category.interface';

import { LoadCategoriesAction } from '../../../../actions/category/category.types';
import { CategoryActions } from '../../../../actions/category/category.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import CategoryForm from '../../../../components/categories/form/CategoryForm';
import Tabs, { TabItem } from '../../../../components/tab/Tabs';
import RemovalProvider from '../../../../providers/Removal.provider';
import SubCategoryList from '../../../../components/sub-categories/list/SubCategoryList';
import RemoveSubCategoryContainer from '../../sub-categories/remove/RemoveSubCategoryContainer';

const CategoryContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadCategoriesAction> = useAppDispatch();
  const category: Category = useAppSelector(
    ({ category_store }: AppState) => category_store.category
  ) as Category;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Category, onComplete: () => void): void => {
    dispatch(CategoryActions.updateCategory(id as string, payload, onComplete));
  };

  const onReload = () => {
    dispatch(CategoryActions.loadCategory(id as string, () => setLoading(false)));
  };

  useEffect(() => {
    dispatch(CategoryActions.loadCategory(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && category?.id === Number(id);
  }, [id, loading, category?.id]);

  const tabs: TabItem[] = [
    {
      id: 0,
      title: 'Basic Info',
      Component: (
        <>
          {!doneLoading && <Loader filled />}
          {doneLoading && <CategoryForm category={category} onSave={onSave} />}
        </>
      )
    },
    {
      id: 1,
      title: 'Sub Categories',
      actionLabel: 'Add Sub Categories',
      actionPath: `/admin/categories/${category?.id}/sub-categories/add`,
      Component: (
        <RemovalProvider>
          <SubCategoryList sub_categories={category?.sub_categories} loading={false} />
          <RemoveSubCategoryContainer onRemoval={onReload} />
        </RemovalProvider>
      )
    }
  ];

  return (
    <PageLayout title={`Category Details - ${doneLoading ? category?.name : ''}`}>
      <article className="employee-detail">
        <header>
          <hr />
        </header>
        <main>
          <Tabs tabs={tabs} />
        </main>
      </article>
    </PageLayout>
  );
};

export default CategoryContainer;
