import React, { ReactNode } from 'react';

import { getClassNames } from '@neslotech/utils';

import { ReactComponent as CloseIcon } from './../../icons/close-icon.svg';

import './modal.scss';

type OnClose = () => void;

interface Props {
  title: string;
  onClose: OnClose;
  children: ReactNode | ReactNode[];
  large?: boolean;
}

export const ModalLayout = ({ title, onClose, children, large }: Props) => (
  <dialog open className={getClassNames('modal-layout', { large })}>
    <header className="modal-layout__header">
      <h3 className="modal-layout__header-title">{title}</h3>
      <span className="modal-layout__close" onClick={onClose} role="dialog">
        <CloseIcon />
      </span>
    </header>
    <section className="modal-layout__content">{children}</section>
  </dialog>
);

ModalLayout.defaultProps = {
  large: false
};
