import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Event } from '../../../../interfaces/event/event.interface';

import { LoadEventsAction } from '../../../../actions/event/event.types';
import { EventActions } from '../../../../actions/event/event.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import EventList from '../../../../components/events/list/EventList';
import Button from '../../../../components/button/Button';

import RemoveEventContainer from '../remove/RemoveEvent.container';
import Select from '../../../../components/select/Select';
import {
  getCookieListTableViewStyle,
  setCookieListTableViewStyle
} from '../../../../tools/filter.util';
import EventTable from '../../../../components/events/list/EventTabble';

const EventsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadEventsAction> = useAppDispatch();
  const events: Event[] = useAppSelector(({ event_store }: AppState) => event_store.events);

  const cookieViewStyle = getCookieListTableViewStyle();
  const [view_style, setViewStyle] = useState<string>(cookieViewStyle ?? 'list_view');

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(EventActions.loadEvents(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewChange = (object: any) => {
    setViewStyle(object.view_style);
    setCookieListTableViewStyle(object.view_style);
  };

  return (
    <PageLayout
      title="Events"
      action={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button label="Add Event" onClick={() => navigate('add')} />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '130px',
              marginLeft: '10px',
              marginBottom: '10px'
            }}
          >
            <Select
              name="view_style"
              placeholder="Select View"
              value={view_style}
              items={[
                { label: 'List View', value: 'list_view' },
                { label: 'Table View', value: 'table_view' }
              ]}
              onChange={handleViewChange}
            />
          </div>
        </div>
      }
    >
      <RemovalProvider>
        {view_style === 'list_view' ? (
          <EventList events={events} loading={loading} />
        ) : (
          <EventTable events={events} />
        )}
        <RemoveEventContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default EventsContainer;
