import React, { useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { getClassNames, isEmpty } from '@neslotech/utils';

import { Contact } from '../../../../../interfaces/client/contact.interface';
import { Designation } from '../../../../../interfaces/config/designation.interface';

import { useContactData, useDesignations } from '../../../../../hooks/clients';

import Form from '../../../../form/Form';
import FormRow from '../../../../form/form-row/FormRow';
import FormAction from '../../../../form/form-action/FormAction';
import Input, { OnChangeType } from '../../../../input/Input';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../../../select/Select';

import './contact-form.scss';

interface ContactState extends StateForm {
  keyContact?: string;
  designation?: string;
  designationId?: number | string;
  email?: string;
  contactNumber?: string;
  website?: string;
  address?: string;
  city?: string;
  country?: string;
  continent?: string;
  classification?: string;
}

interface CustomValidationRules extends ValidationRules<ContactState> {
  isValidEmail: (value: string, form: ContactState) => string | undefined;
}

const rules: CustomValidationRules = {
  validates: {
    keyContact: ['isPresent'],
    designationId: ['isPresent'],
    email: ['isValidEmail'],
    contactNumber: ['isNumeric']
  },

  isValidEmail(value, form) {
    // eslint-disable-next-line max-len
    const regexp = /^((([a-z]|\d|[!#$%&'*+/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([\-]([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([\-]([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)*)|((\x22)(((([\x20])*)?([\x20])+)?(([--\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([--\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*((([\x20])*)?([\x20])+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
    if (!regexp.test(String(value))) {
      return 'Enter a valid email address';
    }
  }
};

const formify = (contact?: Contact, last_contact?: Contact): ContactState =>
  ({
    keyContact: contact?.key_contact,
    designation: contact?.designation,
    designationId: contact?.designation_id,
    email: contact?.email,
    contactNumber: contact?.contact_number,
    website: contact?.website || last_contact?.website,
    address: contact?.address || last_contact?.address,
    city: contact?.city || last_contact?.city,
    country: contact?.country || last_contact?.country,
    continent: contact?.continent || last_contact?.continent,
    classification: contact?.classification
  } as ContactState);

const serverify = (form: ContactState): Contact => ({
  key_contact: form.keyContact,
  designation_id: form.designationId,
  email: form.email,
  contact_number: form.contactNumber,
  website: form.website,
  address: form.address,
  city: form.city,
  country: form.country,
  continent: form.continent,
  classification: form.classification
});

type OnSave = (payload: Contact, onComplete: () => void) => void;
type OnAddMandate = () => void;

interface Props {
  canAddMandate?: boolean;
  designations?: Designation[];
  onSave: OnSave;
  contact?: Contact;
  onAddMandate?: OnAddMandate;
}

const ContactForm = ({ designations, contact, onSave, canAddMandate, onAddMandate }: Props) => {
  const designationList: Designation[] = useDesignations();

  const { contacts } = useContactData();

  const last_contact = contacts[0];

  const [form, setForm] = useFormState<ContactState>(formify(contact, last_contact), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as ContactState) });

  const [loading, setLoading] = useState<boolean>(false);

  const mappedDesignations: SelectItem[] = useMemo(() => {
    const usableDesignations = isEmpty(designationList) ? designations : designationList;
    return (usableDesignations ?? [])
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((designation: Designation) => ({
        label: designation.name,
        value: designation.id
      }));
  }, [designationList, designations]);

  const handleSave = () => {
    const payload: Contact = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="contact-form">
      <Form>
        <FormRow fluid>
          <Input
            name="keyContact"
            label="Key Contact"
            placeholder="Key Contact"
            value={form?.keyContact}
            error={form?.messages?.keyContact}
            onChange={handleChange}
          />
          <Select
            name="designationId"
            label="Designation"
            placeholder="Designation"
            items={mappedDesignations}
            value={form?.designationId}
            error={form?.messages?.designationId}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluid>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            value={form?.email}
            error={form?.messages?.email}
            onChange={handleChange}
          />
          <Input
            name="contactNumber"
            label="Contact Number"
            placeholder="Contact Number"
            value={form?.contactNumber}
            error={form?.messages?.contactNumber}
            onChange={handleChange}
          />
          <Select
            name="classification"
            label="Classification"
            placeholder="Classification"
            value={form?.classification}
            error={form?.messages?.classification}
            items={[
              {
                label: 'Banker',
                value: 'banker'
              },
              {
                label: 'Client',
                value: 'client'
              },
              {
                label: 'Infrastructure',
                value: 'infrastructure'
              },
              {
                label: 'Legal Firm',
                value: 'legal_firm'
              },
              {
                label: 'Private Equity Company',
                value: 'private_equity_company'
              },
              {
                label: 'Service Provider',
                value: 'service_provider'
              }
            ]}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="website"
            label="Website"
            placeholder="Website"
            value={form?.website}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="address"
            label="Office Address"
            placeholder="Office Address"
            value={form?.address}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluid>
          <Input
            name="city"
            label="Office City"
            placeholder="Office City"
            value={form?.city}
            onChange={handleChange}
          />
          <Input
            name="country"
            label="Office Country"
            placeholder="Office Country"
            value={form?.country}
            onChange={handleChange}
          />
          <Select
            name="continent"
            label="Office Continent"
            placeholder="Office Continent"
            value={form?.continent}
            error={form?.messages?.continent}
            items={[
              {
                label: 'Asia',
                value: 'asia'
              },
              {
                label: 'Africa',
                value: 'africa'
              },
              {
                label: 'North America',
                value: 'north_america'
              },
              {
                label: 'South America',
                value: 'south_america'
              },
              {
                label: 'Antarctica',
                value: 'antarctica'
              },
              {
                label: 'Europe',
                value: 'europe'
              },
              {
                label: 'Australia',
                value: 'australia'
              }
            ]}
            onChange={handleChange}
          />
        </FormRow>
        <footer className={getClassNames('contact-form__actions', { canAddMandate })}>
          {canAddMandate && (
            <FormAction label="Add Mandate" loading={loading} onClick={onAddMandate} />
          )}
          <FormAction
            disabled={!form?.valid}
            hollow
            label="Save Contact"
            loading={loading}
            onClick={handleSave}
          />
        </footer>
      </Form>
    </article>
  );
};

export default ContactForm;
