import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddPerformanceReviewRequest,
  getPerformanceReviewRequest,
  getPerformanceReviewsRequest,
  getRemovePerformanceReviewRequest,
  getUpdatePerformanceReviewRequest,
  RequestOptions
} from '../tools/api';

import {
  AddPerformanceReviewAction,
  LoadPerformanceReviewAction,
  LoadPerformanceReviewsAction,
  UpdatePerformanceReviewAction
} from '../actions/performance-review/performance-review.types';

import { PerformanceReviewActions } from '../actions/performance-review/performance-review.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadPerformanceReviews({ onComplete }: LoadPerformanceReviewsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getPerformanceReviewsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: PerformanceReviewActions.SET_PERFORMANCE_REVIEWS, performanceReviews: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPerformanceReviewsRequest() {
  yield takeLatest(
    PerformanceReviewActions.LOAD_PERFORMANCE_REVIEWS,
    performLoadPerformanceReviews
  );
}

export function* performLoadPerformanceReview({ id, onComplete }: LoadPerformanceReviewAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getPerformanceReviewRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: PerformanceReviewActions.SET_PERFORMANCE_REVIEW, performanceReview: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPerformanceReviewRequest() {
  yield takeLatest(PerformanceReviewActions.LOAD_PERFORMANCE_REVIEW, performLoadPerformanceReview);
}

export function* performUpdatePerformanceReview({
  id,
  payload,
  onComplete
}: UpdatePerformanceReviewAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdatePerformanceReviewRequest(
      id,
      payload
    );

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'The performance review has been updated successfully!',
        SNACK_SUCCESS
      )
    );

    yield put({ type: PerformanceReviewActions.SET_PERFORMANCE_REVIEW, performanceReview: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdatePerformanceReviewRequest() {
  yield takeLatest(
    PerformanceReviewActions.UPDATE_PERFORMANCE_REVIEW,
    performUpdatePerformanceReview
  );
}

export function* performAddPerformanceReview({
  payload,
  navigate,
  onComplete
}: AddPerformanceReviewAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddPerformanceReviewRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'A new performance review has been created successfully!',
        SNACK_SUCCESS
      )
    );

    yield put(PerformanceReviewActions.loadPerformanceReviews(noOp));
    yield navigate('/human-resources/performance-reviews');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddPerformanceReviewRequest() {
  yield takeLatest(PerformanceReviewActions.ADD_PERFORMANCE_REVIEW, performAddPerformanceReview);
}

export function* performRemovePerformanceReview({ id, onComplete }: AddPerformanceReviewAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemovePerformanceReviewRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'The performance review has been removed successfully!',
        SNACK_SUCCESS
      )
    );

    yield put(PerformanceReviewActions.loadPerformanceReviews(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemovePerformanceReviewRequest() {
  yield takeLatest(
    PerformanceReviewActions.REMOVE_PERFORMANCE_REVIEW,
    performRemovePerformanceReview
  );
}

export default function* performanceReviewSaga() {
  yield all([
    watchForLoadPerformanceReviewsRequest(),
    watchForLoadPerformanceReviewRequest(),
    watchForUpdatePerformanceReviewRequest(),
    watchForAddPerformanceReviewRequest(),
    watchForRemovePerformanceReviewRequest()
  ]);
}
