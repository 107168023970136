import React from 'react';
import { RouteObject } from 'react-router-dom';

import PayslipsContainer from '../../containers/human-resources/payslips/list/Payslips.container';
import PayslipContainer from '../../containers/human-resources/payslips/details/Payslip.container';
import AddPayslipContainer from '../../containers/human-resources/payslips/add/AddPayslipContainer.container';

import PayslipsCrumb from '../../crumbs/human-resources/payslips/Payslips.crumb';
import AddPayslipCrumb from '../../crumbs/human-resources/payslips/AddPayslip.crumb';
import PayslipCrumb from '../../crumbs/human-resources/payslips/Payslip.crumb';

import ProtectedRoute from '../ProtectedRoute';

const payslipRoutes: RouteObject[] = [
  {
    path: 'payslips',
    handle: {
      crumb: () => <PayslipsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <PayslipsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute adminRequired>
            <AddPayslipContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddPayslipCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <PayslipContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <PayslipCrumb />
        }
      }
    ]
  }
];

export default payslipRoutes;
