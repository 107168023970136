import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { Payslip } from '../../../../interfaces/employee/payslip.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { LoadPayslipsAction } from '../../../../actions/payslip/payslip.types';
import { PayslipActions } from '../../../../actions/payslip/payslip.actions';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import PayslipForm from '../../../../components/payslips/form/PayslipForm';

const PayslipContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadPayslipsAction> = useAppDispatch();
  const payslip: Payslip = useAppSelector(({ payslip_store }: AppState) => payslip_store.payslip);
  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Payslip, onComplete: () => void): void => {
    dispatch(PayslipActions.updatePayslip(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(PayslipActions.loadPayslip(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && payslip?.id === Number(id);
  }, [id, loading, payslip?.id]);

  return (
    <PageLayout title={`Payslip Details - ${doneLoading ? payslip?.employee : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <PayslipForm employees={employees} payslip={payslip} onSave={onSave} />}
    </PageLayout>
  );
};

export default PayslipContainer;
