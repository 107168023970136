import React from 'react';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks';

import { Fund } from '../../../../interfaces/fund/fund.interface';

const AddInvestmentCrumb = () => {
  const fund: Fund = useAppSelector(({ fund_store }) => fund_store.fund);

  return <NavLink to={`/core/funds/${fund?.id}/investments/add`}>Add Investment</NavLink>;
};

export default AddInvestmentCrumb;
