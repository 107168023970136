import React, { ReactNode, useState } from 'react';

import { ApiElement } from '../types/api-element.interface';

import { RemovalContext, RemovalProps } from '../contexts/Removal.context';

interface Props {
  children: ReactNode[] | ReactNode;
}

const RemovalProvider = ({ children }: Props) => {
  const [element, setElement] = useState<ApiElement>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const value: RemovalProps = {
    element,
    setElement,
    showModal,
    setShowModal
  };

  return <RemovalContext.Provider value={value}>{children}</RemovalContext.Provider>;
};

export default RemovalProvider;
