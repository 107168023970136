import { Tombstone } from '../../interfaces/client/tombstone.interface';

import {
  LoadTombstoneAction,
  OnComplete,
  RemoveTombstoneAction,
  SaveTombstoneAction,
  UpdateTombstoneAction,
  LoadTombstonesAction
} from './tombstone.types';

export class TombstoneActions {
  static readonly LOAD_TOMBSTONES: string = '[TOMBSTONE_STORE] Load tombstones';
  static readonly DOWNLOAD_TOMBSTONES: string = '[TOMBSTONE_STORE] Download tombstones';
  static readonly SET_TOMBSTONES: string = '[TOMBSTONE_STORE] Set tombstones';

  static readonly LOAD_TOMBSTONE: string = '[TOMBSTONE_STORE] Load tombstone';
  static readonly SET_TOMBSTONE: string = '[TOMBSTONE_STORE] Set tombstone';

  static readonly SAVE_TOMBSTONE: string = '[TOMBSTONE_STORE] Save tombstone';
  static readonly UPDATE_TOMBSTONE: string = '[TOMBSTONE_STORE] Save tombstone';
  static readonly REMOVE_TOMBSTONE: string = '[TOMBSTONE_STORE] Remove tombstone';

  static loadTombstones = (onComplete: OnComplete): LoadTombstonesAction => ({
    type: TombstoneActions.LOAD_TOMBSTONES,
    onComplete
  });

  static downloadTombstones = (onComplete: OnComplete): LoadTombstonesAction => ({
    type: TombstoneActions.DOWNLOAD_TOMBSTONES,
    onComplete
  });

  static saveTombstone = (
    id: number | string,
    payload: Tombstone,
    onComplete: OnComplete
  ): SaveTombstoneAction => ({
    type: TombstoneActions.SAVE_TOMBSTONE,
    id,
    payload,
    onComplete
  });

  static loadTombstone = (id: number | string, onComplete: OnComplete): LoadTombstoneAction => ({
    type: TombstoneActions.LOAD_TOMBSTONE,
    id,
    onComplete
  });

  static updateTombstone = (
    id: number | string,
    payload: Tombstone,
    onComplete: OnComplete
  ): UpdateTombstoneAction => ({
    type: TombstoneActions.UPDATE_TOMBSTONE,
    id,
    payload,
    onComplete
  });

  static removeTombstone = (
    id: number | string,
    onComplete: OnComplete
  ): RemoveTombstoneAction => ({
    type: TombstoneActions.REMOVE_TOMBSTONE,
    id,
    onComplete
  });
}
