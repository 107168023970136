import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { rootSaga } from '../sagas';

import { authReducer } from './auth.reducer';
import { systemReducer } from './system.reducer';
import { userReducer } from './user.reducer';
import { profileReducer } from './profile.reducer';
import { fundReducer } from './fund.reducer';
import { investmentReducer } from './investment.reducer';
import { categoryReducer } from './category.reducer';
import { clientReducer } from './client.reducer';
import { clientTypeReducer } from './client-type.reducer';
import { sectorReducer } from './sector.reducer';
import { designationReducer } from './designation.reducer';
import { contactReducer } from './contact.reducer';
import { precedentReducer } from './precedent.reducer';
import { mandateReducer } from './mandate.reducer';
import { tombstoneReducer } from './tombstone.reducer';
import { eventReducer } from './event.reducer';
import { employeeReducer } from './employee.reducer';
import { documentReducer } from './document.reducer';
import { dealSheetReducer } from './deal-sheet.reducer';
import { searchReducer } from './search.reducer';
import { leaveRequestReducer } from './leave-request.reducer';
import { expenseClaimReducer } from './expense-claim.reducer';
import { payslipReducer } from './payslip.reducer';
import { performanceReviewReducer } from './performance-review.reducer';
import { timesheetReducer } from './timesheet.reducer';
import { prospectReducer } from './prospect.reducer';
import { employeeroleReducer } from './employeerole.reducer';
import { irp5Reducer } from './irp5.reducer';
import { subCategoryReducer } from './sub-category.reducer';

const rootReducer = combineReducers({
  auth_store: authReducer,
  system_store: systemReducer,
  user_store: userReducer,
  profile_store: profileReducer,
  fund_store: fundReducer,
  investment_store: investmentReducer,
  category_store: categoryReducer,
  sub_category_store: subCategoryReducer,
  client_store: clientReducer,
  client_type_store: clientTypeReducer,
  sector_store: sectorReducer,
  designation_store: designationReducer,
  contact_store: contactReducer,
  precedent_store: precedentReducer,
  mandate_store: mandateReducer,
  tombstone_store: tombstoneReducer,
  event_store: eventReducer,
  document_store: documentReducer,
  deal_sheet_store: dealSheetReducer,
  search_store: searchReducer,
  employee_store: employeeReducer,
  employeerole_store: employeeroleReducer,
  leave_request_store: leaveRequestReducer,
  expense_claim_store: expenseClaimReducer,
  payslip_store: payslipReducer,
  irp5_store: irp5Reducer,
  performance_review_store: performanceReviewReducer,
  timesheet_store: timesheetReducer,
  prospect_store: prospectReducer
});

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({ reducer: rootReducer, middleware: [sagaMiddleware] });

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export default store;
