import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Client } from '../../../interfaces/client/client.interface';
import { ExportClientItems } from '../../../interfaces/client/export_client_items.interface';

import { getAuthHeaders } from '../../auth.util';

import { API_HOST, RequestOptions } from '../index';

const getClientsEndpoint = (): string => `${API_HOST}/clients`;
export const getClientsRequest = (): RequestOptions => [
  getClientsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getClientEndpoint = (id: number | string): string => `${API_HOST}/clients/${id}`;
export const getClientRequest = (id: number | string): RequestOptions => [
  getClientEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getClientCsvEndpoint = (client_id: number | string): string =>
  `${getClientsEndpoint()}/${client_id}/export.csv`;
export const getDownlaodClientsRequests = (
  client_id: number | string,
  payload: ExportClientItems
) => [
  getClientCsvEndpoint(client_id),
  {
    ...getHttpPostOptions({ export_items: payload }, { ...getAuthHeaders(), Accept: 'text/csv' }),
    responseType: 'blob'
  }
];

export const getUpdateClientRequest = (id: number | string, payload: Client): RequestOptions => [
  getClientEndpoint(id),
  getHttpPutOptions({ client: payload }, getAuthHeaders())
];

export const getAddClientRequest = (payload: Client): RequestOptions => [
  getClientsEndpoint(),
  getHttpPostOptions({ client: payload }, getAuthHeaders())
];

export const getRemoveClientRequest = (id: string | number): RequestOptions => [
  getClientEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
