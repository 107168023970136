import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Employee } from '../../../interfaces/employee/employee.interface';

import { useAppSelector } from '../../../hooks';

const EmployeeCrumb = () => {
  const employee: Employee = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employee
  );

  return <NavLink to={`/human-resources/employees/${employee?.id}`}>{employee?.full_name}</NavLink>;
};

export default EmployeeCrumb;
