import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { statusItems } from '../../../../tools/dropdown.util';

import { Investment, Status } from '../../../../interfaces/investment/investment.interface';

import Form from '../../../form/Form';
import FormRow from '../../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../../input/Input';
import FormAction from '../../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType } from '../../../select/Select';
import DetailsCard from '../../../card/DetailsCard';

import './investment-form.scss';

type OnSave = (payload: Investment, onComplete: () => void) => void;

interface Props {
  investment?: Investment;
  onSave: OnSave;
}

export interface InvestmentState extends StateForm {
  name: string;
  minimum: number;
  maximum: number;
  status: Status;
  stagePreference: string;
  industryPreference: string;
  investor: string;
  industries: string;
  regions: string;
  minEquityCheque: number;
  ebidta: number;
  shareholdingPercentage: number;
}

interface CustomValidationRules extends ValidationRules<InvestmentState> {
  isMoreThan: (value: number, form: InvestmentState) => string | undefined;
  isLessThan: (value: number) => string | undefined;
}

const rules: CustomValidationRules = {
  validates: {
    name: ['isPresent'],
    minimum: ['isPresent', 'isGreaterThanZero'],
    maximum: ['isPresent', 'isGreaterThanZero', 'isMoreThan'],
    status: ['isPresent'],
    minEquityCheque: ['isGreaterThanZero'],
    ebidta: ['isGreaterThanZero'],
    shareholdingPercentage: ['isGreaterThanZero', 'isLessThan']
  },

  isMoreThan(value: number, form: InvestmentState) {
    if (value < Number(form.minimum)) {
      return 'The maximum amount must be greater than the minimum amount';
    }
  },

  isLessThan(value: number) {
    if (value > 100) {
      return 'This cannot be higher than 100%';
    }
  }
};

const formify = (investment?: Investment): InvestmentState =>
  ({
    name: investment?.name,
    minimum: investment?.minimum,
    maximum: investment?.maximum,
    status: investment?.status,
    stagePreference: investment?.stage_preference,
    industryPreference: investment?.industry_preference,
    investor: investment?.investor_type,
    industries: investment?.industries,
    regions: investment?.regions,
    minEquityCheque: investment?.min_equity_cheque,
    ebidta: investment?.ebidta,
    shareholdingPercentage: investment?.shareholding_percentage
  } as InvestmentState);

const serverify = (form: InvestmentState) => ({
  name: form?.name,
  minimum: form?.minimum,
  maximum: form?.maximum,
  status: form?.status,
  stage_preference: form.stagePreference,
  industry_preference: form.industryPreference,
  investor_type: form.investor,
  industries: form.industries,
  regions: form.regions,
  min_equity_cheque: form.minEquityCheque,
  ebidta: form.ebidta,
  shareholding_percentage: form.shareholdingPercentage
});

const InvestmentForm = ({ investment, onSave }: Props) => {
  const [form, setForm] = useFormState<InvestmentState>(formify(investment), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as InvestmentState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Investment = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="investment-form">
      <DetailsCard title="Investment Info">
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Investment Name"
              placeholder="Investment Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluid>
            <Input
              type="number"
              name="minimum"
              label="Minimum Investment"
              placeholder="Minimum Investment"
              value={form?.minimum}
              error={form?.messages.minimum}
              onChange={handleChange}
            />
            <Input
              type="number"
              name="maximum"
              label="Maximum Investment"
              placeholder="Maximum Investment"
              value={form?.maximum}
              error={form?.messages.maximum}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluid>
            <Input
              name="stagePreference"
              label="Investment Stage Preference"
              placeholder="Investment Stage Preference"
              value={form?.stagePreference}
              onChange={handleChange}
            />
            <Input
              name="industryPreference"
              label="Industry Preference"
              placeholder="Industry Preference"
              value={form?.industryPreference}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluid>
            <Input
              name="investor"
              label="Type of Investor"
              placeholder="Type of Investor"
              value={form?.investor}
              onChange={handleChange}
            />
            <Select
              name="status"
              label="Status"
              placeholder="Select a status"
              items={statusItems}
              value={form?.status}
              error={form?.messages.status}
              onChange={handleChange}
            />
          </FormRow>
        </Form>
      </DetailsCard>
      <DetailsCard title="Investment Characteristics">
        <Form>
          <FormRow>
            <Input
              name="industries"
              label="Industries"
              placeholder="Industries"
              value={form?.industries}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              name="regions"
              label="Regions"
              placeholder="Regions"
              value={form?.regions}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluid>
            <Input
              type="number"
              name="minEquityCheque"
              label="Minimum Equity Cheque"
              placeholder="Minimum Equity Cheque"
              value={form?.minEquityCheque}
              error={form?.messages.minEquityCheque}
              onChange={handleChange}
            />
            <Input
              type="number"
              name="ebidta"
              label="Minimum EBIDTA"
              placeholder="Minimum EBIDTA"
              value={form?.ebidta}
              error={form?.messages.ebidta}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Input
              type="number"
              name="shareholdingPercentage"
              label="Minimum Shareholding %"
              placeholder="Minimum Shareholding %"
              value={form?.shareholdingPercentage}
              error={form?.messages.shareholdingPercentage}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default InvestmentForm;
