import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { Credentials } from '../../../interfaces/auth/credentials.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Checkbox, { OnChangeType as OnBooleanChangeType } from '../../checkbox/Checkbox';

import './login.scss';
import { ReactComponent as EyeIcon } from '../../../icons/eye-icon.svg';
import { ReactComponent as EyeSlashIcon } from '../../../icons/eye-slash-icon.svg';

type LoginFunction = (payload: Credentials, onComplete: () => void) => void;
interface Props {
  onLogin: LoginFunction;
}

interface LoginState extends StateForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

const rules: ValidationRules<LoginState> = {
  validates: {
    email: ['isPresent', 'isEmail'],
    password: ['isPresent']
  }
};

const serverify = ({ email, password, rememberMe: remember_me }: LoginState): Credentials => ({
  email,
  password,
  remember_me
});

const Login = ({ onLogin }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useFormState<LoginState>(undefined, rules);
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChange = (newState: OnChangeType | OnBooleanChangeType) =>
    setForm({ ...form, ...(newState as LoginState) });

  const handleLogin = () => {
    setLoading(true);

    const request: Credentials = serverify(form);
    onLogin(request, () => setLoading(false));
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <article className="login">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Your Email/Username"
            placeholder="Enter your email/username"
            onChange={handleChange}
            error={form?.messages?.email}
          />
        </FormRow>
        <FormRow>
          <Input
            name="password"
            type={passwordShown ? 'text' : 'password'}
            label="Password"
            placeholder="Enter your password"
            onChange={handleChange}
            error={form?.messages?.password}
            icon={
              passwordShown ? (
                <EyeSlashIcon
                  width={24}
                  height={24}
                  style={{ marginLeft:'4px', cursor: 'pointer' }}
                  onClick={togglePassword}
                />
              ) : (
                <EyeIcon
                  width={24}
                  height={24}
                  style={{ marginLeft:'4px', cursor: 'pointer', padding: '2px' }}
                  onClick={togglePassword}
                />
              )
            }
          />
        </FormRow>
        <FormRow>
          <section className="login__actions">
            <Checkbox
              name="rememberMe"
              label="Remember Me"
              checked={form?.rememberMe}
              onChange={handleChange}
            />
            <Link to="/passwords/forgot">
              <p className="login__forgot-password">Forgot Password</p>
            </Link>
          </section>
        </FormRow>
        <FormAction loading={loading} onClick={handleLogin} label="Login" disabled={!form?.valid} />
      </Form>
    </article>
  );
};

export default Login;
