import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Precedent } from '../../../../interfaces/client/precedent.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { Filter } from '../../../../types/filter.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import RemovalProvider from '../../../../providers/Removal.provider';

import { CategoryActions } from '../../../../actions/category/category.actions';
import { PrecedentActions } from '../../../../actions/precedent/precedent.actions';

import { ReactComponent as ChevronRightIcon } from '../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import PrecedentList from '../../../../components/precedents/list/PrecedentList';
import { DropdownMenuItem } from '../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../components/radio/RadioGroup';
import FilterLayout from '../../../../components/layout/filter/FilterLayout';

import RemovePrecedentContainer from '../../clients/details/precedents/remove/RemovePrecedent.container';
import Button from '../../../../components/button/Button';
import PrecedentTable from '../../../../components/precedents/list/PrecedentTable';
import {
  getCookieListTableViewStyle,
  setCookieListTableViewStyle
} from '../../../../tools/filter.util';
import Select from '../../../../components/select/Select';

const PrecedentsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useAppDispatch();
  const cookieViewStyle = getCookieListTableViewStyle();
  const [view_style, setViewStyle] = useState<string>(cookieViewStyle ?? 'list_view');

  const [loading, setLoading] = useState<boolean>(true);

  const precedents: Precedent[] = useAppSelector(
    ({ precedent_store }: AppState) => precedent_store.precedents
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(PrecedentActions.loadPrecedents(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Category',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="category_id"
            onChange={onChange}
            value={filters?.category_id as string}
            items={categories
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((category: Category) => ({
                label: category.name,
                value: category.id
              }))}
          />
        )
      }
    ];
  }, [categories]);

  const doneLoading = useMemo(() => {
    return !loading && !!precedents;
  }, [loading, precedents]);

  const handleViewChange = (object: any) => {
    setViewStyle(object.view_style);
    setCookieListTableViewStyle(object.view_style);
  };

  return (
    <PageLayout title="Precedents" bordered>
      <FilterLayout
        menuItems={menuItems}
        actions={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button label="Add Precedent" onClick={() => navigate('add')} />
            &nbsp;
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '130px',
                marginLeft: '10px',
                marginTop: '15px'
              }}
            >
              <Select
                name="view_style"
                placeholder="Select View"
                value={view_style}
                items={[
                  { label: 'List View', value: 'list_view' },
                  { label: 'Table View', value: 'table_view' }
                ]}
                onChange={handleViewChange}
              />
            </div>
          </div>
        }
      >
        <RemovalProvider>
          {!doneLoading && <Loader filled />}
          {doneLoading &&
            (view_style === 'list_view' ? (
              <PrecedentList precedents={precedents} />
            ) : (
              <PrecedentTable precedents={precedents} />
            ))}
          <RemovePrecedentContainer />
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default PrecedentsContainer;
