import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddEventRequest,
  getRemoveEventRequest,
  getEventRequest,
  getEventsRequest,
  getUpdateEventRequest,
  RequestOptions
} from '../tools/api';

import {
  AddEventAction,
  LoadEventAction,
  LoadEventsAction,
  UpdateEventAction
} from '../actions/event/event.types';
import { EventActions } from '../actions/event/event.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadEvents({ onComplete }: LoadEventsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getEventsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: EventActions.SET_EVENTS, events: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadEventsRequest() {
  yield takeLatest(EventActions.LOAD_EVENTS, performLoadEvents);
}

export function* performLoadEvent({ id, onComplete }: LoadEventAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getEventRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: EventActions.SET_EVENT, event: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadEventRequest() {
  yield takeLatest(EventActions.LOAD_EVENT, performLoadEvent);
}

export function* performUpdateEvent({ id, payload, onComplete }: UpdateEventAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateEventRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: EventActions.SET_EVENT, event: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateEventRequest() {
  yield takeLatest(EventActions.UPDATE_EVENT, performUpdateEvent);
}

export function* performAddEvent({ payload, navigate, onComplete }: AddEventAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddEventRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('A new event has been created successfully!', SNACK_SUCCESS));

    yield navigate('/admin/events');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddEventRequest() {
  yield takeLatest(EventActions.ADD_EVENT, performAddEvent);
}

export function* performRemoveEvent({ id, onComplete }: AddEventAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveEventRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The event has been removed successfully!', SNACK_SUCCESS));

    yield put(EventActions.loadEvents(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveEventRequest() {
  yield takeLatest(EventActions.REMOVE_EVENT, performRemoveEvent);
}

export default function* eventSaga() {
  yield all([
    watchForLoadEventsRequest(),
    watchForLoadEventRequest(),
    watchForUpdateEventRequest(),
    watchForAddEventRequest(),
    watchForRemoveEventRequest()
  ]);
}
