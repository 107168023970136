/**
 * @module SnackBar Component
 * @description
 * Maintains a stack of provided `notices`. When new notices arrive, they are set to automatically
 *  remove after `NOTICE_REMOVAL_TIMEOUT`. If any notices are removed from the props, then SnackBar
 *  will trigger an exit animation, and subsequently remove from state completely.
 */
import React, { Component } from 'react';

import { noOp } from '@neslotech/utils';

import { SnackAlert } from './SnackAlert';

import './snack-bar.scss';

const NOTICE_REMOVAL_TIMEOUT = 5000;

export class SnackBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notices: props.notices
    };

    // keep track of all the timeouts we add, so we can clean up later
    this.timeouts = [];
  }

  componentDidUpdate() {
    this.addNewNotices();
    this.removeStaleNotices();
  }

  componentWillUnmount() {
    // clear all timeouts
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }

  doesNoticeExistInProps(noticeId) {
    const { notices } = this.props;
    return this.doesNoticeExist(notices, noticeId);
  }

  doesNoticeExistInState(noticeId) {
    const { notices } = this.state;
    return this.doesNoticeExist(notices, noticeId);
  }

  doesNoticeExist(notices, noticeId) {
    return notices.find(({ id }) => id === noticeId);
  }

  /**
   * Loop through all prop notices. If any are found that are not in state, add to state
   *  and set a timeout to automatically remove the notice.
   */
  addNewNotices() {
    const { notices } = this.props;
    notices.forEach((notice) => {
      if (this.doesNoticeExistInState(notice.id)) {
        return;
      }

      this.setState(
        ({ notices: allNotices }) => ({
          notices: [...allNotices, notice]
        }),
        () => {
          const newTimeout = setTimeout(() => this.closeNotice(notice.id), NOTICE_REMOVAL_TIMEOUT);
          this.timeouts.push(newTimeout);
        }
      );
    });
  }

  /**
   * Loop through notices in state. If any are found that are not present in props,
   *  it means we need to remove it from state.
   */
  removeStaleNotices() {
    const { notices } = this.state;
    notices.forEach((notice) => {
      if (!this.doesNoticeExistInProps(notice.id)) {
        this.setState(({ notices: allNotices }) => ({
          notices: allNotices.filter((_notice) => _notice.id !== notice.id)
        }));
      }
    });
  }

  /**
   * Adds a `closing` attribute onto the notice, which triggers the exit animation.
   * After a time period, remove the notice from state completely.
   */
  closeNotice(noticeId) {
    this.setState(({ notices }) => ({
      notices: notices.map((notice) => {
        if (notice.id !== noticeId) {
          return notice;
        }

        return {
          ...notice,
          closing: true
        };
      })
    }));

    const { onCloseNotice } = this.props;
    // 500ms is a safe waiting time before removal, as the exit animation lasts 200ms.
    const newTimeout = setTimeout(() => onCloseNotice(noticeId), 500);
    this.timeouts.push(newTimeout);
  }

  /** Render notices from state. */
  renderNotices() {
    const { notices } = this.state;
    return notices.map((notice) => (
      <SnackAlert
        key={notice.id}
        status={notice.alertType}
        title={notice.title}
        closing={notice.closing}
      />
    ));
  }

  render() {
    return <header className="snack-bar">{this.renderNotices()}</header>;
  }
}

SnackBar.defaultProps = {
  notices: [],
  onCloseNotice: noOp
};
