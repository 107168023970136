import React from 'react';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks';

import { Fund } from '../../../../interfaces/fund/fund.interface';

const InvestmentsCrumb = () => {
  const fund: Fund = useAppSelector(({ fund_store }) => fund_store.fund);

  return <NavLink to={`/core/funds/${fund?.id}/investments`}>Investment Management</NavLink>;
};

export default InvestmentsCrumb;
