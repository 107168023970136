import { ReactNode, useMemo, useState } from 'react';
import { getClassNames } from '@neslotech/utils';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Button from '../button/Button';

export interface TabItem {
  id: number;
  title: string;
  actionLabel?: string;
  actionPath?: string;
  Component: ReactNode;
}

interface TabsProps {
  tabs: TabItem[];
}

interface TabProps {
  id: number;
  title: string;
  active: boolean;
  setActiveIndex: (id: number) => void;
}

const Tab = ({ id, title, active, setActiveIndex }: TabProps) => (
  <article className={getClassNames('tab', { active })} onClick={() => setActiveIndex(id)}>
    <p>{title}</p>
  </article>
);

const Tabs = ({ tabs }: TabsProps) => {
  const navigate: NavigateFunction = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  const [actionLabel, actionPath, Component] = useMemo(() => {
    const tab: TabItem = tabs.find((tab: TabItem) => tab.id === activeTab);
    return [tab.actionLabel, tab.actionPath, tab.Component];
  }, [tabs, activeTab]);

  return (
    <article className="tabs">
      <header>
        <section>
          {tabs.map((tab: TabItem) => (
            <Tab
              key={tab.id}
              title={tab.title}
              id={tab.id}
              active={activeTab === tab.id}
              setActiveIndex={setActiveTab}
            />
          ))}
        </section>
        {actionLabel && <Button label={actionLabel} onClick={() => navigate(actionPath)} />}
      </header>
      <main className="tabs__content">{Component}</main>
    </article>
  );
};

export default Tabs;