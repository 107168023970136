import React from 'react';

import { Filter } from '../../types/filter.interface';

import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down-icon.svg';

import { Dropdown } from './Dropdown';
import { DropdownMenuItem } from './DropdownMenu';

import './filter-dropdown.scss';

interface Props {
  menuItems: (
    onChange: (newFilters: { [key: string]: number | string | boolean | Date }) => void,
    filters: Filter
  ) => DropdownMenuItem[];
  setFilters: (filters: Filter) => void;
  filters: Filter;
  hideButton?: boolean;
}

export const FilterDropdown = ({ menuItems, setFilters, filters, hideButton }: Props) => {
  const handleChange = (newFilters: { [key: string]: number | string | boolean | Date }) => {
    setFilters({ ...filters, ...(newFilters as unknown as Filter) });
  };

  const rowItems = menuItems(handleChange, filters);

  return hideButton ? (
    <>
      {rowItems.map((rowItem) => (
        <Dropdown justified placement="bottom" menuItems={[rowItem]} hideButton={hideButton}>
          <section className="filter-dropdown">
            <label>{rowItem.text}</label>
            <span className="filter-dropdown__icon">
              <ChevronDownIcon />
            </span>
          </section>
        </Dropdown>
      ))}
    </>
  ) : (
    <Dropdown justified placement="bottom" menuItems={rowItems} hideButton={hideButton}>
      <section className="filter-dropdown">
        <label>Filter</label>
        <span className="filter-dropdown__icon">
          <ChevronDownIcon />
        </span>
      </section>
    </Dropdown>
  );
};
