import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Contact, ContactHistory } from '../../../interfaces/client/contact.interface';

import { getAuthHeaders } from '../../auth.util';

import { API_HOST, RequestOptions } from '../index';

const getClientEndpoint = (clientId: number | string): string => `${API_HOST}/clients/${clientId}`;
const getClientContactsEndpoint = (clientId: number | string): string =>
  `${getClientEndpoint(clientId)}/contacts`;

const getClientSetPrimaryContactsEndpoint = (
  clientId: number | string,
  contactId: number | string
): string => `${getClientEndpoint(clientId)}/contacts/${contactId}/set_primary`;

export const getClientContactsRequest = (clientId: number | string): RequestOptions => [
  getClientContactsEndpoint(clientId),
  getHttpGetOptions(getAuthHeaders())
];

const getClientContactEndpoint = (clientId: number | string, id: number | string): string =>
  `${getClientContactsEndpoint(clientId)}/${id}`;

const getContactHistoriesEndpoint = (contactId: number | string): string =>
  `${API_HOST}/contacts/${contactId}/histories`;

const getContactHistoryEndpoint = (contactId: number | string, id: number | string): string =>
  `${API_HOST}/contacts/${contactId}/histories/${id}`;

export const getClientContactRequest = (
  clientId: number | string,
  id: number | string
): RequestOptions => [getClientContactEndpoint(clientId, id), getHttpGetOptions(getAuthHeaders())];

export const getUpdateClientContactRequest = (
  clientId: number | string,
  id: number | string,
  payload: Contact
): RequestOptions => [
  getClientContactEndpoint(clientId, id),
  getHttpPutOptions({ contact: payload }, getAuthHeaders())
];

export const getAddClientContactRequest = (
  clientId: number | string,
  payload: Contact
): RequestOptions => [
  getClientContactsEndpoint(clientId),
  getHttpPostOptions({ contact: payload }, getAuthHeaders())
];

export const getClientSetPrimaryContactRequest = (
  clientId: number | string,
  contactId: number | string
): RequestOptions => [
  getClientSetPrimaryContactsEndpoint(clientId, contactId),
  getHttpPostOptions({}, getAuthHeaders())
];

export const getRemoveClientContactRequest = (
  clientId: number | string,
  id: string | number
): RequestOptions => [
  getClientContactEndpoint(clientId, id),
  getHttpDeleteOptions(getAuthHeaders())
];

export const getUpdateClientContactHistoryRequest = (
  contactId: number | string,
  id: number | string,
  payload: ContactHistory
): RequestOptions => [
  getContactHistoryEndpoint(contactId, id),
  getHttpPutOptions({ contact_history: payload }, getAuthHeaders())
];

export const getAddClientContactHistoryRequest = (
  contactId: number | string,
  payload: ContactHistory
): RequestOptions => [
  getContactHistoriesEndpoint(contactId),
  getHttpPostOptions({ contact_history: payload }, getAuthHeaders())
];

export const getRemoveClientContactHistoryRequest = (
  contactId: number | string,
  id: string | number
): RequestOptions => [
  getContactHistoryEndpoint(contactId, id),
  getHttpDeleteOptions(getAuthHeaders())
];
