import React from 'react';

import { noOp } from '@neslotech/utils';

import { Profile } from '../interfaces/profile/profile.interface';

export interface AuthProps {
  token: string | null;
  profile?: Profile;
  setToken: (value: string) => void;
  onLogout: () => void;
  isAdmin: boolean;
}

export const AuthContext = React.createContext<AuthProps>({
  token: null,
  onLogout: noOp,
  setToken: noOp,
  isAdmin: false
});
