import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Mandate } from '../../../../../../interfaces/client/mandate.interface';

import { useAppDispatch, useRemoval } from '../../../../../../hooks';

import { MandateActions } from '../../../../../../actions/client/mandate/mandate.actions';
import { RemoveMandateAction } from '../../../../../../actions/client/mandate/mandate.types';

import { Modal } from '../../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../../../components/modal/remove/RemoveModal';

const RemoveMandateContainer = () => {
  const dispatch: Dispatch<RemoveMandateAction> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const { showModal, setShowModal, element } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      MandateActions.removeMandate((element as Mandate).client_id, element.id, () => {
        onComplete();
        onClose();
        navigate(`/core/clients/${(element as Mandate).client_id}/mandates`);
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Mandate">
            <RemoveModal title="this mandate" onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveMandateContainer;
