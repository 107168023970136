import React from 'react';
import { RouteObject } from 'react-router-dom';

import EmployeerolesContainer from '../../containers/admin/Employeeroles/list/Employeerole.container';
import AddEmployeeroleContainer from '../../containers/admin/Employeeroles/add/AddEmployeerole.container';
import EmployeeroleContainer from '../../containers/admin/Employeeroles/details/Employeerole.container';

import EmployeerolesCrumb from '../../crumbs/admin/employeeroles/Employeeroles.crumb';
import AddEmployeeroleCrumb from '../../crumbs/admin/employeeroles/AddEmployeerole.crumb';
import EmployeeroleCrumb from '../../crumbs/admin/employeeroles/Employeerole.crumb';

import AdminRoute from '../AdminRoute';

const employeerolesRoutes: RouteObject[] = [
  {
    path: 'employeeroles',
    handle: {
      crumb: () => <EmployeerolesCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <EmployeerolesContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddEmployeeroleContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddEmployeeroleCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <EmployeeroleContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <EmployeeroleCrumb />
        }
      }
    ]
  }
];

export default employeerolesRoutes;
