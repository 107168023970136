import React, { ReactNode } from 'react';

import { getClassNames } from '@neslotech/utils';

import { Loader } from '../loader/Loader';

import './details-card.scss';

interface Props {
  title?: string;
  fitted?: boolean;
  small?: boolean;
  fittedHeight?: boolean;
  centered?: boolean;
  loading?: boolean;
  scrollable?: boolean;
  action?: ReactNode;
  children: ReactNode | ReactNode[];
}

const DetailsCard = ({
  title,
  centered,
  fitted = false,
  fittedHeight = false,
  small = false,
  loading,
  children,
  action,
  scrollable
}: Props) => (
  <article className={getClassNames('details-card', { fitted, fittedHeight, small })}>
    <header className={getClassNames('details-card__header', { centered })}>
      {title && <h3 className="details-card__title">{title}</h3>}
      {action && <section className="details-card__action">{action}</section>}
    </header>
    <section className={getClassNames('details-card__content', { scrollable })}>
      {loading && <Loader filled />}
      {!loading && children}
    </section>
  </article>
);

export default DetailsCard;
