import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddCategoryRequest,
  getCategoriesRequest,
  getCategoryRequest,
  getRemoveCategoryRequest,
  getUpdateCategoryRequest,
  RequestOptions
} from '../tools/api';

import {
  AddCategoryAction,
  LoadCategoriesAction,
  LoadCategoryAction,
  UpdateCategoryAction
} from '../actions/category/category.types';
import { CategoryActions } from '../actions/category/category.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadCategories({ filter, onComplete }: LoadCategoriesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getCategoriesRequest(filter);

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: CategoryActions.SET_CATEGORIES, categories: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadCategoriesRequest() {
  yield takeLatest(CategoryActions.LOAD_CATEGORIES, performLoadCategories);
}

export function* performLoadCategory({ id, onComplete }: LoadCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getCategoryRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: CategoryActions.SET_CATEGORY, category: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadCategoryRequest() {
  yield takeLatest(CategoryActions.LOAD_CATEGORY, performLoadCategory);
}

export function* performUpdateCategory({ id, payload, onComplete }: UpdateCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateCategoryRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: CategoryActions.SET_CATEGORY, category: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateCategoryRequest() {
  yield takeLatest(CategoryActions.UPDATE_CATEGORY, performUpdateCategory);
}

export function* performAddCategory({ payload, navigate, onComplete }: AddCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddCategoryRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new category has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/admin/categories');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddCategoryRequest() {
  yield takeLatest(CategoryActions.ADD_CATEGORY, performAddCategory);
}

export function* performRemoveCategory({ id, onComplete }: AddCategoryAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveCategoryRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The category has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(CategoryActions.loadCategories(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveCategoryRequest() {
  yield takeLatest(CategoryActions.REMOVE_CATEGORY, performRemoveCategory);
}

export default function* categorySaga() {
  yield all([
    watchForLoadCategoriesRequest(),
    watchForLoadCategoryRequest(),
    watchForUpdateCategoryRequest(),
    watchForAddCategoryRequest(),
    watchForRemoveCategoryRequest()
  ]);
}
