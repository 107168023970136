import React from 'react';
import { RouteObject } from 'react-router-dom';

import LoginContainer from '../../containers/auth/login/Login.container';
import RegisterContainer from '../../containers/auth/register/Register.container';
import ForgotPasswordContainer from '../../containers/auth/passwords/forgot/ForgotPassword.container';
import ResetPasswordContainer from '../../containers/auth/passwords/reset/ResetPassword.container';
import ConfirmAccountContainer from '../../containers/auth/accounts/confirm/ConfirmAccount.container';
import VerifyAccountContainer from '../../containers/auth/accounts/verify/VerifyAccount.container';

const authRoutes: RouteObject[] = [
  {
    path: '/',
    element: <LoginContainer />
  },
  {
    path: 'register',
    element: <RegisterContainer />
  },
  {
    path: 'passwords/forgot',
    element: <ForgotPasswordContainer />
  },
  {
    path: 'passwords/:id/reset/:token',
    element: <ResetPasswordContainer />
  },
  {
    path: 'accounts/success',
    element: <ConfirmAccountContainer />
  },
  {
    path: 'accounts/:id/confirm/:token',
    element: <VerifyAccountContainer />
  }
];

export default authRoutes;
