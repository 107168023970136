import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';
import { Profile } from '../interfaces/profile/profile.interface';
import { ProfileActions } from '../actions/profile/profile.actions';

export interface ProfileState extends State {
  profile?: Profile;
}

export const initialState: ProfileState = {};

export const profileReducer = (state: ProfileState = initialState, action: AnyAction) => {
  if (action.type === ProfileActions.SET_PROFILE) {
    const { profile } = action;
    return {
      ...state,
      profile
    };
  }

  return state;
};
