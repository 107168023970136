import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Mandate } from '../../../interfaces/client/mandate.interface';

import { getAuthHeaders } from '../../auth.util';

import { API_HOST, RequestOptions } from '../index';

const getClientEndpoint = (id: number | string): string => `${API_HOST}/clients/${id}`;
const getMandatesEndpoint = (id: number | string): string => `${getClientEndpoint(id)}/mandates`;
export const getMandatesRequest = (id: number | string): RequestOptions => [
  getMandatesEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getMandateEndpoint = (id: number | string, mandateId: number | string): string =>
  `${getClientEndpoint(id)}/mandates/${mandateId}`;
export const getMandateRequest = (
  id: number | string,
  mandateId: number | string
): RequestOptions => [getMandateEndpoint(id, mandateId), getHttpGetOptions(getAuthHeaders())];

export const getUpdateMandateRequest = (
  id: number | string,
  mandateId: number | string,
  payload: Mandate
): RequestOptions => [
  getMandateEndpoint(id, mandateId),
  getHttpPutOptions({ mandate: payload }, getAuthHeaders())
];

export const getAddMandateRequest = (id: number | string, payload: Mandate): RequestOptions => [
  getMandatesEndpoint(id),
  getHttpPostOptions({ mandate: payload }, getAuthHeaders())
];

export const getRemoveMandateRequest = (
  id: number | string,
  mandateId: number | string
): RequestOptions => [getMandateEndpoint(id, mandateId), getHttpDeleteOptions(getAuthHeaders())];

const getMandateCompleteEndpoint = (id: number | string, mandateId: number | string): string =>
  `${getClientEndpoint(id)}/mandates/${mandateId}/complete`;
export const getCompleteMandateRequest = (
  id: number | string,
  mandateId: number | string
): RequestOptions => [
  getMandateCompleteEndpoint(id, mandateId),
  getHttpPostOptions({}, getAuthHeaders())
];
