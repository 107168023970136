import React, { useEffect, useMemo, useState } from 'react';

import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';

import { filterElement } from '../../../tools/filter.util';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import './precedent-table.scss';
import { Table, TableData, TableHeader } from '../../table/Table';
import { Precedent } from '../../../interfaces/client/precedent.interface';
import { useRemoval } from '../../../hooks';
import AlphabetLayout from '../../layout/filter/AlphabetLayout';

interface Props {
  precedents: Precedent[];
}

const formify = (precedents: Precedent[], filters: Filter): Precedent[] =>
  precedents.filter((precedent: Precedent) =>
    // @ts-ignore
    filterElement(precedent, filters)
  );

const precedentHeaders: TableHeader[] = [
  {
    Header: 'Client Name',
    accessor: 'client'
  },
  {
    Header: 'Category',
    accessor: 'category'
  },
  {
    Header: 'Sub Category',
    accessor: 'sub_category'
  },
  {
    Header: 'Date of Presentation',
    accessor: 'presentation_date'
  },
  {
    Header: '',
    accessor: 'action'
  }
];

const PrecedentTable = ({ precedents }: Props) => {
  const { filters, setFilterDefaults } = useFilters();
  const navigate: NavigateFunction = useNavigate();
  const { setElement, setShowModal } = useRemoval();
  const [sortedPrecedents, setSortedPrecedents] = useState(precedents);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ precedent_type_id: '', category_id: '', sector_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {    
    if (selectedLetter) {
      precedents = precedents.filter(precedent => precedent.client[0].toUpperCase() === selectedLetter);
    }

    setSortedPrecedents(precedents);
  }, [precedents, selectedLetter]);

  const handleRemove = (precedent: Precedent) => {
    setShowModal(true);
    setElement(precedent);
  };

  const precedentsRows: TableData[] = useMemo(() => {
    return formify(sortedPrecedents, filters).map<TableData>((precedent: Precedent) => ({
      ...precedent,
      action: (
        <div className="precedent_action">
          <EditIcon
            onClick={() =>
              navigate(`/core/clients/${precedent.client_id}/precedents/${precedent.id}`)
            }
          />
          <RemoveIcon onClick={() => handleRemove(precedent)} />
        </div>
      ),
      onClick: () => {}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedPrecedents, filters]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="precedent-list">
        <Table cols={precedentHeaders} rowData={precedentsRows} />
      </article>
    </>
  );
};

export default PrecedentTable;
