import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Sector } from '../../../interfaces/config/sector.interface';

import { useAppSelector } from '../../../hooks';

const SectorCrumb = () => {
  const sector: Sector = useAppSelector(
    ({ sector_store }: AppState) => sector_store.sector
  ) as Sector;

  return <NavLink to={`/admin/sectors/${sector?.id}`}>{sector?.name}</NavLink>;
};

export default SectorCrumb;
