import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { getClassNames, isUndefined } from '@neslotech/utils';

import { ContactHistory } from '../../../../../interfaces/client/contact.interface';

import Form from '../../../../form/Form';
import FormRow from '../../../../form/form-row/FormRow';
import FormAction from '../../../../form/form-action/FormAction';
import Input, { OnChangeType } from '../../../../input/Input';

import './contact-history-form.scss';
import { Datepicker, OnChangeType as DateChangeType } from '../../../../datepicker/Datepicker';

interface ContactHistoryState extends StateForm {
  contact_date?: string | Date;
  method?: string;
}

const rules: ValidationRules<ContactHistoryState> = {
  validates: {}
};

const formify = (contact_history?: ContactHistory): ContactHistoryState =>
  ({
    contact_date:
      isUndefined(contact_history?.contact_date) || contact_history?.contact_date === null
        ? undefined
        : new Date(contact_history?.contact_date),
    method: contact_history?.method
  } as ContactHistoryState);

const serverify = (form: ContactHistoryState): ContactHistory => ({
  contact_date: form.contact_date,
  method: form.method
});

type OnSave = (payload: ContactHistory, onComplete: () => void) => void;
type OnAddMandate = () => void;

interface Props {
  onSave: OnSave;
  contactHistory?: ContactHistory;
  onAddMandate?: OnAddMandate;
}

const ContactHistoryForm = ({ contactHistory, onSave }: Props) => {
  const [form, setForm] = useFormState<ContactHistoryState>(formify(contactHistory), rules);
  const handleChange = (newState: OnChangeType | DateChangeType) =>
    setForm({ ...form, ...(newState as ContactHistoryState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = () => {
    const payload: ContactHistory = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="contact-history-form">
      <Form>
        <FormRow>
          <Datepicker
            name="contact_date"
            label="Contact Date"
            placeholder="Contact Date"
            value={form?.contact_date}
            error={form?.messages.contact_date}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="method"
            label="Method"
            placeholder="Method"
            value={form?.method}
            onChange={handleChange}
          />
        </FormRow>
        <footer className={getClassNames('contact-history-form__actions')}>
          <FormAction
            disabled={!form?.valid}
            hollow
            label="Save Contact History"
            loading={loading}
            onClick={handleSave}
          />
        </footer>
      </Form>
    </article>
  );
};

export default ContactHistoryForm;
