import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { RequestOptions } from '../tools/api';
import {
  getAddFundRequest,
  getFundRequest,
  getFundsRequest,
  getRemoveFundRequest,
  getUpdateFundRequest
} from '../tools/api';

import { AddFundAction, LoadFundsAction, RemoveFundDocumentAction, UpdateFundAction } from '../actions/fund/fund.types';
import { FundActions } from '../actions/fund/fund.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';
import { getRemoveFundDocumentRequest } from '../tools/api/fund.endpoints';

export function* performLoadFunds({ onComplete }: LoadFundsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getFundsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: FundActions.SET_FUNDS, funds: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadFundsRequest() {
  yield takeLatest(FundActions.LOAD_FUNDS, performLoadFunds);
}

export function* performLoadFund({ id, onComplete }: LoadFundsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getFundRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: FundActions.SET_FUND, fund: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadFundRequest() {
  yield takeLatest(FundActions.LOAD_FUND, performLoadFund);
}

export function* performUpdateFund({ id, payload, onComplete }: UpdateFundAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateFundRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: FundActions.SET_FUND, fund: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateFundRequest() {
  yield takeLatest(FundActions.UPDATE_FUND, performUpdateFund);
}

export function* performAddFund({ payload, navigate, onComplete }: AddFundAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddFundRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('A new fund has been created successfully!', SNACK_SUCCESS));

    yield navigate('/core/funds');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddFundRequest() {
  yield takeLatest(FundActions.ADD_FUND, performAddFund);
}

export function* performRemoveFund({ id, onComplete }: AddFundAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveFundRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The fund has been removed successfully!', SNACK_SUCCESS));

    yield put(FundActions.loadFunds(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveFundRequest() {
  yield takeLatest(FundActions.REMOVE_FUND, performRemoveFund);
}

export function* performRemoveFundDocument({ id, documentId }: RemoveFundDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveFundDocumentRequest(
      id,
      documentId
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(
        'The employee ID document has been removed successfully!',
        SNACK_SUCCESS
      )
    );

    yield put(FundActions.loadFund(id, noOp));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveFundDocumentRequest() {
  yield takeLatest(FundActions.REMOVE_FUND_DOCUMENT, performRemoveFundDocument);
}

export default function* fundSaga() {
  yield all([
    watchForLoadFundsRequest(),
    watchForLoadFundRequest(),
    watchForUpdateFundRequest(),
    watchForAddFundRequest(),
    watchForRemoveFundRequest(),
    watchForRemoveFundDocumentRequest()
  ]);
}
