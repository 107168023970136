import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Client } from '../interfaces/client/client.interface';
import { Note } from '../interfaces/client/note.interface';
import { Contact } from '../interfaces/client/contact.interface';
import { Precedent } from '../interfaces/client/precedent.interface';
import { Tombstone } from '../interfaces/client/tombstone.interface';
import { Mandate } from '../interfaces/client/mandate.interface';
import { DealSheet } from '../interfaces/client/deal-sheet.interface';

import { ClientActions } from '../actions/client/client.actions';
import { NoteActions } from '../actions/client/note/note.actions';
import { ClientContactActions } from '../actions/client/contact/contact.actions';
import { PrecedentActions } from '../actions/client/precedent/precedent.actions';
import { TombstoneActions } from '../actions/client/tombstone/tombstone.actions';
import { MandateActions } from '../actions/client/mandate/mandate.actions';
import { ClientDealSheetActions } from '../actions/client/deal-sheet/deal-sheet.actions';

export interface ClientState extends State {
  clients: Client[];
  client?: Client;
  notes: Note[];
  contacts: Contact[];
  dealSheets: DealSheet[];
  contact?: Contact;
  precedent?: Precedent;
  precedents: Precedent[];
  tombstone?: Tombstone;
  tombstones: Tombstone[];
  mandates: Mandate[];
  mandate?: Mandate;
  dealSheet?: DealSheet;
}

export const initialState: ClientState = {
  clients: [],
  notes: [],
  contacts: [],
  dealSheets: [],
  mandates: [],
  precedents: [],
  tombstones: []
};

export const clientReducer = (state: ClientState = initialState, action: AnyAction) => {
  switch (action.type) {
    case ClientActions.SET_CLIENTS:
      const { clients } = action;
      return {
        ...state,
        clients
      };
    case ClientActions.SET_CLIENT:
      const { client } = action;
      return {
        ...state,
        client
      };
    case NoteActions.SET_NOTES:
      const { notes } = action;
      return {
        ...state,
        notes
      };
    case ClientContactActions.SET_CONTACTS:
      const { contacts } = action;
      return {
        ...state,
        contacts
      };
    case ClientContactActions.SET_CONTACT:
      const { contact } = action;
      return {
        ...state,
        contact
      };
    case PrecedentActions.SET_PRECEDENTS:
      const { precedents } = action;
      return {
        ...state,
        precedents
      };
    case PrecedentActions.SET_PRECEDENT:
      const { precedent } = action;
      return {
        ...state,
        precedent
      };
    case TombstoneActions.SET_TOMBSTONES:
      const { tombstones } = action;
      return {
        ...state,
        tombstones
      };
    case TombstoneActions.SET_TOMBSTONE:
      const { tombstone } = action;
      return {
        ...state,
        tombstone
      };
    case MandateActions.SET_MANDATES:
      const { mandates } = action;
      return {
        ...state,
        mandates
      };
    case MandateActions.SET_MANDATE:
      const { mandate } = action;
      return {
        ...state,
        mandate
      };
    case ClientDealSheetActions.SET_DEAL_SHEETS:
      const { dealSheets } = action;
      return {
        ...state,
        dealSheets
      };
    case ClientDealSheetActions.SET_DEAL_SHEET:
      const { dealSheet } = action;
      return {
        ...state,
        dealSheet
      };
    default:
      return state;
  }
};
