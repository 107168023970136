import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Event } from '../interfaces/event/event.interface';

import { EventActions } from '../actions/event/event.actions';

export interface EventState extends State {
  events: Event[];
  event?: Event;
}

export const initialState: EventState = {
  events: []
};

export const eventReducer = (state: EventState = initialState, action: AnyAction) => {
  if (action.type === EventActions.SET_EVENTS) {
    const { events } = action;
    return {
      ...state,
      events
    };
  } else if (action.type === EventActions.SET_EVENT) {
    const { event } = action;
    return {
      ...state,
      event
    };
  }

  return state;
};
