import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import { Sector } from '../../../../interfaces/config/sector.interface';

import { LoadSectorsAction } from '../../../../actions/sector/sector.types';
import { SectorActions } from '../../../../actions/sector/sector.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import SectorList from '../../../../components/sectors/list/SectorList';
import Button from '../../../../components/button/Button';

import RemoveSectorContainer from '../remove/RemoveSector.container';

const SectorsContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadSectorsAction> = useAppDispatch();
  const sectors: Sector[] = useAppSelector(({ sector_store }: AppState) => sector_store.sectors);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(SectorActions.loadSectors(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Sectors"
      action={<Button label="Add Sector" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <SectorList sectors={sectors} loading={loading} />
        <RemoveSectorContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default SectorsContainer;
