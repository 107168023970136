import React from 'react';
import { RouteObject } from 'react-router-dom';

import AdminCrumb from '../../crumbs/admin/Admin.crumb';

import userRoutes from './user.routes';
import categoryRoutes from './category.routes';
import clientTypesRoutes from './client-type.routes';
import sectorRoutes from './sector.routes';
import designationRoutes from './designation.routes';
import eventRoutes from './event.routes';
import documentRoutes from './document.routes';
import employeerolesRoutes from './employeeroles.routes';
import subCategoryRoutes from './sub-category.routes';

const adminRoutes: RouteObject[] = [
  {
    path: 'admin',
    handle: {
      crumb: () => <AdminCrumb />
    },
    children: [
      ...userRoutes,
      ...categoryRoutes,
      ...subCategoryRoutes,
      ...clientTypesRoutes,
      ...sectorRoutes,
      ...designationRoutes,
      ...eventRoutes,
      ...documentRoutes,
      ...employeerolesRoutes
    ]
  }
];

export default adminRoutes;
