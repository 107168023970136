import { createContext } from 'react';

import { noOp } from '@neslotech/utils';

import { Contact } from '../../interfaces/client/contact.interface';

export interface ContactProps {
  contacts: Contact[];
  contact?: Contact;
  index: number;
  loading: boolean;
  handleIndexChange: (value: number) => void;
  contactOverride?: Contact;
  setContact?: (contact: Contact) => void;
}

export const ContactContext = createContext<ContactProps>({
  contacts: [],
  loading: true,
  index: 0,
  handleIndexChange: noOp
});
