import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Action, Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch } from '../../../../hooks';

import { ForgotPasswordRequest } from '../../../../interfaces/auth/forgot-password-request.interface';

import { AuthActions } from '../../../../actions/auth/auth.actions';

import AuthLayout from '../../../../components/layout/auth/AuthLayout';
import ForgotPassword from '../../../../components/auth/forgot-password/ForgotPassword';

const ForgotPasswordContainer = () => {
  const dispatch: Dispatch<Action> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const handleForgotPassword = (request: ForgotPasswordRequest) => {
    dispatch(AuthActions.forgotPassword(request, navigate));
  };

  return (
    <AuthLayout
      title="Forgotten Password"
      subtitle="Enter your email and we will send you a link to reset your forgotten password"
      linkTo="/"
      linkText="Remember your BSM password? Sign in"
    >
      <ForgotPassword onForgotPassword={handleForgotPassword} />
    </AuthLayout>
  );
};

export default ForgotPasswordContainer;
