import React, { useEffect, useMemo, useState } from 'react';
import {
  Column,
  HeaderGroup,
  Row,
  TableInstance,
  TableState,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useSortBy,
  UseSortByInstanceProps,
  useTable
} from 'react-table';
import { Tooltip } from 'react-tooltip';

import { getClassNames, isEmpty } from '@neslotech/utils';

import { ReactComponent as SortBothIcon } from './../../icons/sort-both-icon.svg';
import { ReactComponent as SortBottomIcon } from './../../icons/sort-down-icon.svg';
import { ReactComponent as SortTopIcon } from './../../icons/sort-up-icon.svg';

import PaginationControls from '../pagination/PaginationControls';
import { Loader } from '../loader/Loader';

import './table.scss';
import { useSearchParams } from 'react-router-dom';

const renderSortingIcons = (column: HeaderGroup<TableData> & SortByProp) => {
  if (!column.canSort) {
    return;
  }

  const isSortedDesc = column.isSortedDesc ? <SortBottomIcon /> : <SortTopIcon />;
  return column.isSorted ? isSortedDesc : <SortBothIcon />;
};

type SortByProp = {
  getSortByToggleProps?: Function;
  canSort?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
};

/**
 * Renders the header row, applying react-table properties to `tr` and `th`.
 * @param {Object[]} headerGroups - Header row, containing header column properties
 * @param {Object} headerModifiers - Object with keys being the column id,
 *  containing class modifiers for the header column.
 */
const renderHeaders = (
  headerGroups: (HeaderGroup<TableData> & SortByProp)[],
  headerModifiers: Modifier
) =>
  headerGroups.map((headerGroup) => (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column: HeaderGroup<TableData> & SortByProp) => (
        <>
          {column.Header === 'Tooltip Content' ? null : (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps?.())}
              className={getClassNames('table-heading', headerModifiers[column.id])}
            >
              {column.render('Header')}
              <span className="table-heading__sort-icon">{renderSortingIcons(column)}</span>
            </th>
          )}
        </>
      ))}
    </tr>
  ));

/**
 * Renders each data row, and their `td` elements, applying react-table properties.
 * @param {Object[]} page - A collection of data rows, contained within the current page
 * @param {function} prepareRow - react-table provided function to execute on a row before display
 * @param {Object} columnModifiers - Object with keys being the column id,
 *  containing class modifiers for a column of data.
 */
const renderRows = (
  page: Row<TableData>[],
  prepareRow: (row: Row<TableData>) => void,
  columnModifiers: Modifier
) =>
  page.map((row, index) => {
    prepareRow(row);

    const tooltipId = `tr_${index}`;
    const tooltipCell: any = row.cells.filter((item) => {
      return item.column.Header === 'Tooltip Content';
    });
    const tooltipContent = tooltipCell ? tooltipCell[0]?.value : null;
    const { onClick } = row.original;

    return (
      <>
        {tooltipContent ? (
          <>
            <tr
              data-tooltip-id={tooltipId}
              className="table-row"
              {...row.getRowProps()}
              onClick={onClick}
            >
              {row.cells.map((cell) => (
                <>
                  {cell.column.Header === 'Tooltip Content' ? null : (
                    <td
                      {...cell.getCellProps()}
                      className={getClassNames('table-cell', columnModifiers[cell.column.id])}
                    >
                      {cell.render('Cell')}
                    </td>
                  )}
                </>
              ))}
            </tr>

            <Tooltip
              id={tooltipId}
              place="top"
              content={tooltipContent}
              style={{ maxWidth: '100%', zIndex: 100, whiteSpace: 'pre-wrap' }}
            />
          </>
        ) : (
          <tr className="table-row" {...row.getRowProps()} onClick={onClick}>
            {row.cells.map((cell) => (
              <td
                {...cell.getCellProps()}
                className={getClassNames('table-cell', columnModifiers[cell.column.id])}
              >
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        )}
      </>
    );
  });

/** Only render pagination controls if there is more than one page. */
const renderPaginationControls = ({
  canPreviousPage,
  canNextPage,
  pageCount = 0,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex
}: Partial<TableInstanceWithHooks<object>> & { pageIndex: number }) =>
  pageCount > 1 && (
    <div className="table__pagination">
      <PaginationControls
        pageIndex={pageIndex}
        pageCount={pageCount}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        gotoPage={gotoPage}
      />
    </div>
  );

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

export interface TableData {
  onClick?: (data: object) => void;
}

export type Modifier = { [key: string]: { [key: string]: boolean } };
export type TableHeader = Column & { disableSortBy?: boolean };

interface Props {
  cols: TableHeader[];
  headerModifiers: Modifier;
  columnModifiers: Modifier;
  pageLength: number;
  rowData: TableData[];
  loading: boolean;
  emptyTitle: string;
  shouldSetPageIndex?: boolean;
}

export const Table = ({
  cols,
  rowData,
  headerModifiers,
  columnModifiers,
  pageLength,
  emptyTitle,
  loading,
  shouldSetPageIndex = false
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // react-table requires us to use memoisation on the columns and data to gain speed optimisations
  const columns = useMemo(() => cols, [cols]);
  const data = useMemo(() => rowData, [rowData]);

  const initialState = {
    pageSize: pageLength,
    pageIndex: parseInt(searchParams.get('page') || '0', 10)
  };

  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setPageSize
  } = useTable(
    {
      columns,
      data,
      initialState: { ...initialState, pageSize: recordsPerPage } as TableState
    },
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<TableData>;

  useEffect(() => {
    if (shouldSetPageIndex){
      setSearchParams({ page: pageIndex.toString() });
    }
  }, [pageIndex, setSearchParams, shouldSetPageIndex]);

  useEffect(() => {
    setPageSize(recordsPerPage);
  }, [recordsPerPage, setPageSize]);

  const handleRecordsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordsPerPage(Number(event.target.value));
  };

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead className="table__header">{renderHeaders(headerGroups, headerModifiers)}</thead>
        <tbody {...getTableBodyProps()} className="table__body">
          {loading && (
            <tr>
              <td colSpan={cols.length}>
                <Loader filled />
              </td>
            </tr>
          )}
          {!loading && renderRows(page, prepareRow, columnModifiers)}
        </tbody>
      </table>
      <select value={recordsPerPage} onChange={handleRecordsPerPageChange} className="table-dropdown">
        <option value={10}>Show 10</option>
        <option value={20}>Show 20</option>
        <option value={50}>Show 50</option>
      </select>
      {isEmpty(page) && (
        <div className="table-row--empty">
          <h2>{emptyTitle}</h2>
        </div>
      )}
      {renderPaginationControls({
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        pageIndex
      })}
    </>
  );
};

Table.defaultProps = {
  rowData: [],
  headerModifiers: {},
  columnModifiers: {},
  pageLength: 10,
  emptyTitle: 'Could not be found',
  loading: false,
  dark: false
};
