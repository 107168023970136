import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../../../types/state.interface';
import { Precedent } from '../../../../../../interfaces/client/precedent.interface';

import RemovalProvider from '../../../../../../providers/Removal.provider';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { useClient } from '../../../../../../hooks/clients';

import { PrecedentActions } from '../../../../../../actions/client/precedent/precedent.actions';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../../components/loader/Loader';
import PrecedentForm from '../../../../../../components/clients/details/precedents/PrecedentForm';

import RemovePrecedentContainer from '../remove/RemovePrecedent.container';

const PrecedentContainer = () => {
  const dispatch: Dispatch = useAppDispatch();

  const { id, precedentId } = useParams<string>();

  const { client } = useClient();

  const [loading, setLoading] = useState<boolean>(true);

  const precedent: Precedent = useAppSelector(
    ({ client_store }: AppState) => client_store.precedent
  );

  useEffect(() => {
    dispatch(
      PrecedentActions.loadPrecedent(client?.id ?? id, precedentId ?? id, () => setLoading(false))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id]);

  const doneLoading = useMemo(() => {
    return !loading && client?.id === Number(id) && !!precedent;
  }, [loading, client?.id, id, precedent]);

  const handleOnSave = (payload: Precedent, onComplete: () => void) => {
    dispatch(
      PrecedentActions.updatePrecedent(client?.id ?? id, precedentId ?? id, payload, onComplete)
    );
  };

  return (
    <PageLayout title={`${client?.name} - Precedent`} bordered>
      <RemovalProvider>
        {!doneLoading && <Loader filled />}
        {doneLoading && (
          <PrecedentForm precedent={precedent} onSave={handleOnSave} client={client} />
        )}
        <RemovePrecedentContainer />
      </RemovalProvider>
    </PageLayout>
  );
};

export default PrecedentContainer;
