import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Document } from '../../../../interfaces/document/document.interface';
import { Category } from '../../../../interfaces/config/category.interface';

import { LoadDocumentsAction } from '../../../../actions/document/document.types';
import { DocumentActions } from '../../../../actions/document/document.actions';
import { CategoryActions } from '../../../../actions/category/category.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import DocumentForm from '../../../../components/documents/form/DocumentForm';

const DocumentContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadDocumentsAction> = useAppDispatch();

  const document: Document = useAppSelector(
    ({ document_store }: AppState) => document_store.document
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Document, onComplete: () => void): void => {
    dispatch(DocumentActions.updateDocument(id as string, payload, onComplete));
  };

  const onRemoveDocumentFile = (fileId: number, onComplete: () => void) => {
    dispatch(DocumentActions.removeDocumentFile(id, fileId, onComplete));
  };

  useEffect(() => {
    dispatch(CategoryActions.loadDocumentCategories(noOp));
    dispatch(DocumentActions.loadDocument(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && document?.id === Number(id);
  }, [id, loading, document?.id]);

  return (
    <PageLayout title={`Company Document Details - ${doneLoading ? document?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <DocumentForm
          categories={categories}
          document={document}
          onSave={onSave}
          onRemoveDocumentFile={onRemoveDocumentFile}
        />
      )}
    </PageLayout>
  );
};

export default DocumentContainer;
