import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { formatCurrency } from '../../../../tools/currency.util';

import { Investment } from '../../../../interfaces/investment/investment.interface';

import { Modifier, Table, TableData, TableHeader } from '../../../table/Table';
import RemoveElement from '../../../table/RemoveElement';

import './investment-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Minimum Investment',
    accessor: 'minimum'
  },
  {
    Header: 'Maximum Investment',
    accessor: 'maximum'
  },
  {
    Header: 'Shareholder %',
    accessor: 'percentage'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  }
};

interface RowData extends TableData {
  name: string;
  minimum: string;
  maximum: string;
  percentage: string;
  status: string;
}

interface Props {
  investments: Investment[];
  loading: boolean;
}

const InvestmentList = ({ investments, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return investments.map(
      (investment: Investment) =>
        ({
          name: investment.name,
          minimum: formatCurrency(Number(investment.minimum)),
          maximum: formatCurrency(Number(investment.maximum)),
          status: investment.status,
          percentage: `${investment.shareholding_percentage ?? 0}%`,
          actions: <RemoveElement element={investment} />,
          onClick: () => navigate(`${investment.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investments]);

  return (
    <article className="investment-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No investments could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default InvestmentList;
