import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Category } from '../../../../interfaces/config/category.interface';
import { Document } from '../../../../interfaces/document/document.interface';
import { AppState } from '../../../../types/state.interface';

import { DocumentActions } from '../../../../actions/document/document.actions';
import { AddDocumentAction } from '../../../../actions/document/document.types';
import { CategoryActions } from '../../../../actions/category/category.actions';
import { LoadCategoriesAction } from '../../../../actions/category/category.types';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import DocumentForm from '../../../../components/documents/form/DocumentForm';
import { Loader } from '../../../../components/loader/Loader';

const AddDocumentContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddDocumentAction | LoadCategoriesAction> = useAppDispatch();

  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(CategoryActions.loadDocumentCategories(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: Document, onComplete: () => void): void => {
    dispatch(DocumentActions.addDocument(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Company Document">
      {loading && <Loader filled />}
      {!loading && <DocumentForm categories={categories} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddDocumentContainer;
