import React, { createRef, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId, isUndefined } from '@neslotech/utils';

import { Event } from '../../../interfaces/event/event.interface';
import { FileData } from '../../../interfaces/config/file.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import { Datepicker, OnChangeType as DateChangeType } from '../../datepicker/Datepicker';
import FormAction from '../../form/form-action/FormAction';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';

import './event-form.scss';
import { toBase64 } from '../../../tools/file.util';
import Button from '../../button/Button';
import { FileUpload } from '../../upload/FileUpload';
import AvatarLayout from '../../layout/avatar/AvatarLayout';
import DocumentIcon from '../../document-icon/DocumentIcon';

type OnSave = (payload: Event, onComplete: () => void) => void;

interface Props {
  event?: Event;
  onSave: OnSave;
}

export interface EventState extends StateForm {
  name: string;
  link: string;
  date: string | Date;
  description: string;
  document: FileData;
  image: string;
}

const rules: ValidationRules<EventState> = {
  validates: {
    name: ['isPresent'],
    link: ['isPresent'],
    date: ['isPresent']
  }
};

const formify = (event?: Event): EventState =>
  ({
    name: event?.name,
    description: event?.description,
    link: event?.link,
    date: isUndefined(event?.date) ? undefined : new Date(event?.date),
    document: isUndefined(event?.document)
      ? ''
      : {
          name: (event.document as FileData).name,
          url: (event.document as FileData).url,
          date: new Date((event.document as FileData)?.date),
          uploaded_by: (event.document as FileData)?.uploaded_by
        },
    image: event?.image
  } as EventState);

const serverify = (form: EventState) => ({
  name: form.name,
  description: form.description,
  link: form.link,
  date: form.date,
  document: form.document.data,
  image: form.image
});

const EventForm = ({ event, onSave }: Props) => {
  const [form, setForm] = useFormState<EventState>(formify(event), rules);
  const handleChange = (newState: OnChangeType | DateChangeType | { document: FileData }) =>
    setForm({ ...form, ...(newState as EventState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Event = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  const handleFileChange = async (file: File) => {
    const base64Data = await toBase64(file);
    const fileData: FileData = {
      id: generateId(),
      name: file.name,
      uploaded_by: 'you',
      date: new Date(),
      data: base64Data
    };

    handleChange({ document: fileData });
  };

  const fileRef = createRef<HTMLInputElement>();

  return (
    <article className="event-form">
      <AvatarLayout
        title={event?.name ?? form?.name}
        subtitle={event?.link ?? form?.link}
        image={form?.image ?? event?.image}
        handleChange={handleChange}
      >
        <DetailsCard>
          <Form>
            <FormRow>
              <Input
                name="name"
                label="Event Name"
                placeholder="Event Name"
                value={form?.name}
                error={form?.messages.name}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <Input
                name="link"
                label="Event Link"
                placeholder="https://example.com"
                value={form?.link}
                error={form?.messages.link}
                type="link"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <Datepicker
                name="date"
                label="Event Date"
                placeholder="Event Date"
                value={form?.date}
                error={form?.messages.date}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <TextArea
                name="description"
                label="Description"
                placeholder="Description"
                value={form?.description}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <FileUpload fileRef={fileRef} fileTypes="application/pdf" onChange={handleFileChange}>
                <Button
                  label={!form?.document ? 'Upload' : 'Replace current itinerary'}
                  hollow
                  onClick={() => fileRef.current.click()}
                />
              </FileUpload>

              {!!form?.document && <UploadedFile file={form?.document} />}
            </FormRow>
            <FormAction
              loading={loading}
              label="Save Changes"
              onClick={handleSubmit}
              disabled={!form?.valid}
            />
          </Form>
        </DetailsCard>
      </AvatarLayout>
    </article>
  );
};

interface FileProps {
  file: FileData;
}

const UploadedFile = ({ file }: FileProps) => {
  const openFile = () => {
    window.open(file.url, '_blank');
  };

  return (
    <article className="uploaded-file" onClick={openFile} style={{ marginTop: 10 }}>
      <section className="uploaded-file__name">
        <DocumentIcon file={file} />
        <p>{file.name}</p>
      </section>
      <small>
        Uploaded by {file.uploaded_by ?? 'you'} on {new Date(file.date).toLocaleDateString()}
      </small>
    </article>
  );
};

export default EventForm;
