import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Sector } from '../../interfaces/config/sector.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getSectorsEndpoint = (): string => `${API_HOST}/sectors`;
export const getSectorsRequest = (): RequestOptions => [
  getSectorsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddSectorRequest = (payload: Sector): RequestOptions => [
  getSectorsEndpoint(),
  getHttpPostOptions({ sector: payload }, getAuthHeaders())
];

const getSectorEndpoint = (id: number | string): string => `${API_HOST}/sectors/${id}`;
export const getSectorRequest = (id: number | string): RequestOptions => [
  getSectorEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateSectorRequest = (id: number | string, payload: Sector): RequestOptions => [
  getSectorEndpoint(id),
  getHttpPutOptions({ sector: payload }, getAuthHeaders())
];

export const getRemoveSectorRequest = (id: string | number): RequestOptions => [
  getSectorEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
