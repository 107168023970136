import React, { ReactElement, useState } from 'react';

import { AppState } from '../types/state.interface';

import { Profile } from '../interfaces/profile/profile.interface';

import { getAuthTokenCookie, removeAuthTokenCookie } from '../tools/auth.util';

import { useAppSelector } from '../hooks';
import { AuthContext, AuthProps } from '../contexts/Auth.context';

interface Props {
  children: ReactElement[] | ReactElement;
}

const AuthProvider = ({ children }: Props) => {
  const [token, setToken] = useState<string | null>(getAuthTokenCookie());

  const profile: Profile = useAppSelector(
    ({ profile_store }: AppState) => profile_store.profile
  ) as Profile;

  const handleLogout = (): void => {
    setToken(null);
    removeAuthTokenCookie();
  };

  const value: AuthProps = {
    token,
    setToken,
    profile,
    onLogout: handleLogout,
    isAdmin: profile?.role === 'admin'
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
