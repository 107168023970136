import React, { ReactNode } from 'react';

import { getClassNames } from '@neslotech/utils';

import './form-row.scss';

interface Props {
  fluid: boolean;
  children: ReactNode;
}

const FormRow = ({ fluid, children }: Props) => (
  <article className={getClassNames('form-row', { fluid })}>{children}</article>
);

FormRow.defaultProps = {
  fluid: false
};

export default FormRow;
