import { ResetPasswordRequest } from '../../interfaces/auth/reset-password-request.interface';

import { LoadProfileAction, OnComplete, UpdatePasswordAction } from './profile.types';

export class ProfileActions {
  static readonly LOAD_PROFILE: string = '[PROFILE_STORE] Load profile';
  static readonly SET_PROFILE: string = '[PROFILE_STORE] Set profile';
  static readonly UPDATE_PASSWORD: string = '[PROFILE_STORE] Update password';

  static loadProfile = (onComplete?: OnComplete): LoadProfileAction => ({
    type: ProfileActions.LOAD_PROFILE,
    onComplete
  });

  static updatePassword = (
    request: ResetPasswordRequest,
    onComplete?: OnComplete
  ): UpdatePasswordAction => ({
    type: ProfileActions.UPDATE_PASSWORD,
    request,
    onComplete
  });
}
