import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Action, Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch } from '../../../../hooks';

import { AuthActions } from '../../../../actions/auth/auth.actions';

import AuthLayout from '../../../../components/layout/auth/AuthLayout';
import { Loader } from '../../../../components/loader/Loader';

const VerifyAccountContainer = () => {
  const dispatch: Dispatch<Action> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const { id, token } = useParams();

  useEffect(() => {
    dispatch(AuthActions.verifyAccount(id as string, token as string, navigate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout
      title="Account Verification"
      subtitle="Sit tight while we are verifying your account."
      linkTo="/"
      linkText="Remember your BSM password? Sign in"
    >
      <Loader filled />
    </AuthLayout>
  );
};

export default VerifyAccountContainer;
