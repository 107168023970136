import React, { createRef, useEffect, useMemo, useState } from 'react';

import { Form as FormState, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId, isUndefined, noOp } from '@neslotech/utils';

import { Client } from '../../../../interfaces/client/client.interface';
import { FileData } from '../../../../interfaces/config/file.interface';
import { Mandate } from '../../../../interfaces/client/mandate.interface';
import { Sector } from '../../../../interfaces/config/sector.interface';

import { toBase64 } from '../../../../tools/file.util';

import { useRemoval } from '../../../../hooks';
import { useSectors } from '../../../../hooks/clients';

// import { ReactComponent as WordIcon } from '../../../../icons/word-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../icons/save-icon.svg';
import { ReactComponent as PencilIcon } from '../../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../icons/remove-icon.svg';

import DetailsCard from '../../../card/DetailsCard';
import InfoDetail from '../InfoDetail';
import Select, { OnChangeType, SelectItem } from '../../../select/Select';
import Button from '../../../button/Button';
import { Datepicker, OnChangeType as DateChangeType } from '../../../datepicker/Datepicker';
import { FileUpload } from '../../../upload/FileUpload';
import Input from '../../../input/Input';

import './mandate-form.scss';
import DocumentIcon from '../../../document-icon/DocumentIcon';

interface FileProps {
  file: FileData;
}

const UploadedFile = ({ file }: FileProps) => {
  const openFile = () => {
    window.open(file.url, '_blank');
  };

  return (
    <article className="uploaded-file" onClick={openFile}>
      <section className="uploaded-file__name">
        <DocumentIcon file={file} />
        <p>{file.name}</p>
      </section>
      <small>
        Uploaded by {file.uploaded_by ?? 'you'} on {new Date(file.date).toLocaleDateString()}
      </small>
    </article>
  );
};

type OnComplete = () => void;
interface Props {
  mandate?: Mandate;
  clients?: Client[];
  client?: Client;
  sectors?: Sector[];
  onSave: (mandate: Mandate, onComplete: OnComplete) => void;
  onComplete?: (onComplete: OnComplete) => void;
}

interface MandateState extends FormState {
  name?: string;
  signed?: string | Date;
  startDate?: string | Date;
  expectedEndDate?: string | Date;
  endDate?: string | Date;
  fee?: number;
  sectorId?: number;
  sector?: string;
  clientId?: number;
  customSector?: string;
  transactionType?: string;
  transactionSize?: number;
  transactionFee?: number;
  documents?: FileData[];
}

const rules: ValidationRules<MandateState> = {
  validates: {
    name: ['isPresent'],
    sectorId: ['isPresent']
  }
};

const formify = (mandate: Mandate, client?: Client): MandateState =>
  ({
    name: mandate?.name,
    clientId: client?.id ?? mandate?.client_id,
    signed:
      isUndefined(mandate?.signed) || mandate?.signed === null
        ? undefined
        : new Date(mandate?.signed),
    startDate:
      isUndefined(mandate?.start_date) || mandate?.start_date === null
        ? undefined
        : new Date(mandate?.start_date),
    expectedEndDate:
      isUndefined(mandate?.expected_end_date) || mandate?.expected_end_date === null
        ? undefined
        : new Date(mandate?.expected_end_date),
    endDate:
      isUndefined(mandate?.end_date) || mandate?.end_date === null
        ? undefined
        : new Date(mandate?.end_date),
    fee: mandate?.fee,
    sectorId: mandate?.sector_id,
    sector: mandate?.sector,
    transactionType: mandate?.transaction_type,
    transactionSize: mandate?.transaction_size,
    transactionFee: mandate?.transaction_fee,
    documents: mandate?.documents ?? []
  } as MandateState);

const serverify = (form: MandateState): Mandate => ({
  name: form?.name,
  client_id: form?.clientId,
  signed: form?.signed,
  start_date: form?.startDate,
  expected_end_date: form?.expectedEndDate,
  end_date: form?.endDate,
  fee: form?.fee,
  sector_id: form?.sectorId,
  sector: form?.customSector,
  transaction_type: form?.transactionType,
  transaction_size: form?.transactionSize,
  transaction_fee: form?.transactionFee,
  documents: form.documents
});

const MandateForm = ({ mandate, sectors, clients, client, onSave, onComplete }: Props) => {
  const [form, setForm] = useFormState<MandateState>(formify(mandate), rules);
  const handleChange = (newState: OnChangeType | DateChangeType) =>
    setForm({ ...form, ...(newState as MandateState) });

  const fileRef = createRef<HTMLInputElement>();

  const clientSectors: Sector[] = useSectors();
  const { setElement, setShowModal } = useRemoval();

  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(!mandate);

  useEffect(() => {
    setForm(formify(mandate, client));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandate]);

  const mappedSectors: SelectItem[] = (sectors ?? clientSectors)
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((sector: Sector) => ({
      label: sector.name,
      value: sector.name === 'Other' ? -100 : sector.id
    }));

  const mappedClients: SelectItem[] = (clients ?? [])
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((client: Client) => ({
      label: client.name,
      value: client.id
    }));

  const actions = useMemo(() => {
    const handleSave = () => {
      setLoading(true);
      const payload: Mandate = serverify(form);
      onSave(payload, () => {
        setLoading(false);
        setEditMode(false);
      });
    };

    const handleRemove = () => {
      setElement(mandate);
      setShowModal(true);
    };

    return editMode ? (
      <>
        <SaveIcon onClick={!form?.valid ? noOp : handleSave} />
        {mandate?.id ? <RemoveIcon onClick={handleRemove} /> : <></>}
      </>
    ) : (
      <>
        <PencilIcon onClick={() => setEditMode(true)} />
        {mandate?.id ? <RemoveIcon onClick={handleRemove} /> : <></>}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, editMode, form, onSave]);

  const handleFileChange = async (file: File) => {
    const base64Data = await toBase64(file);
    handleChange({
      documents: [
        ...form?.documents,
        { id: generateId(), name: file.name, data: base64Data, date: new Date() }
      ]
    } as MandateState);
  };

  const handleComplete = () => {
    onComplete(() => setLoading(false));
  };

  return (
    <article className="mandate-form">
      <section className="mandate-form__block">
        <DetailsCard action={actions} loading={loading}>
          {!client && (
            <InfoDetail
              title="Client Name"
              editMode={editMode}
              editableElement={
                <Select
                  name="clientId"
                  label=""
                  items={mappedClients}
                  placeholder="Select Client"
                  value={form?.clientId}
                  onChange={handleChange}
                />
              }
            />
          )}
          {!!client && <InfoDetail title="Client Name" value={client?.name} />}
          <InfoDetail
            title="Mandate Name"
            editMode={editMode}
            value={form?.name}
            editableElement={
              <Input
                name="name"
                label=""
                placeholder="Enter Name"
                value={form?.name}
                error={form?.messages?.name}
                onChange={handleChange}
              />
            }
          />
          <InfoDetail
            title="Signed"
            editMode={editMode}
            value={form?.signed}
            editableElement={
              <Datepicker
                name="signed"
                placeholder="Enter Signed"
                value={form?.signed}
                onChange={handleChange}
              />
            }
          />
          <InfoDetail
            title="Start Date"
            value={form?.startDate}
            editMode={editMode}
            editableElement={
              <Datepicker
                name="startDate"
                value={form?.startDate}
                placeholder="Select start date"
                onChange={handleChange}
              />
            }
          />
          <InfoDetail
            title="Expected End Date"
            value={form?.expectedEndDate}
            editMode={editMode}
            editableElement={
              <Datepicker
                name="expectedEndDate"
                value={form?.expectedEndDate}
                placeholder="Select expected end date"
                onChange={handleChange}
              />
            }
          />
          <InfoDetail
            title="End Date"
            value={form?.endDate}
            editMode={editMode}
            editableElement={
              <Datepicker
                name="endDate"
                value={form?.endDate}
                placeholder="Select end date"
                onChange={handleChange}
              />
            }
          />
          <InfoDetail
            title="Total Fee"
            editMode={editMode}
            value={
              form?.fee
              ? parseFloat(form?.fee.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : ''
            }
            editableElement={
              <Input
                type="number"
                name="fee"
                label=""
                placeholder="Enter Fee"
                value={form?.fee}
                onChange={handleChange}
              />
            }
          />
        </DetailsCard>
        <DetailsCard>
          <InfoDetail
            title="Sectors / Assets"
            editMode={editMode}
            value={form?.sector}
            editableElement={
              <Select
                name="sectorId"
                label=""
                placeholder="Select sector"
                items={mappedSectors}
                value={form?.sectorId}
                error={form?.messages?.sectorId}
                onChange={handleChange}
              />
            }
          />
          {Number(form?.sectorId) === -100 && (
            <InfoDetail
              title="Custom Sectors / Assets"
              editMode={editMode}
              value={form?.customSector}
              editableElement={
                <Input
                  name="customSector"
                  placeholder="Enter a custom Sector / Asset"
                  value={form?.customSector}
                  error={form?.messages.customSector}
                  onChange={handleChange}
                />
              }
            />
          )}
          <InfoDetail
            title="Transaction Type"
            editMode={editMode}
            value={form?.transactionType}
            editableElement={
              <Input
                name="transactionType"
                label=""
                placeholder="Enter transaction type"
                value={form?.transactionType}
                onChange={handleChange}
              />
            }
          />
          <InfoDetail
            title="Transaction Size"
            editMode={editMode}
            value={
              form?.transactionSize
              ? parseFloat(form?.transactionSize.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : ''
            }
            editableElement={
              <Input
                type="number"
                name="transactionSize"
                label=""
                placeholder="Enter transaction size"
                value={form?.transactionSize}
                onChange={handleChange}
              />
            }
          />
          <InfoDetail
            title="Transaction Fee"
            editMode={editMode}
            value={
              form?.transactionFee
              ? parseFloat(form?.transactionFee.toString()).toLocaleString('en-ZA', {
                  style: 'currency',
                  currency: 'ZAR'
                })
              : ''
            }
            editableElement={
              <Input
                type="number"
                name="transactionFee"
                label=""
                placeholder="Enter transaction size"
                value={form?.transactionFee}
                onChange={handleChange}
              />
            }
          />
        </DetailsCard>
      </section>
      <section className="mandate-form__block">
        <DetailsCard title="Uploaded Files" fittedHeight fitted>
          <section className="mandate-form__uploads">
            {(form?.documents ?? []).map((upload: FileData) => (
              <UploadedFile file={upload} />
            ))}
          </section>
          {editMode && (
            <footer className="mandate-form__actions">
              <FileUpload fileRef={fileRef} fileTypes="*/*" onChange={handleFileChange}>
                <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
              </FileUpload>
            </footer>
          )}
        </DetailsCard>
        {!editMode && mandate?.status === 'open' && (
          <Button loading={loading} label="Complete Transaction" onClick={handleComplete} />
        )}
      </section>
    </article>
  );
};

export default MandateForm;
