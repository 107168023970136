import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { ClientType } from '../../../../interfaces/config/client-type.interface';

import { ClientTypeActions } from '../../../../actions/client-type/client-type.actions';
import { AddClientTypeAction } from '../../../../actions/client-type/client-type.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import ClientTypeForm from '../../../../components/client-types/form/ClientTypeForm';

const AddClientTypeContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddClientTypeAction> = useAppDispatch();

  const onSave = (payload: ClientType, onComplete: () => void): void => {
    dispatch(ClientTypeActions.addClientType(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Client Type">
      <ClientTypeForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddClientTypeContainer;
