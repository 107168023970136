import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { Payslip } from '../interfaces/employee/payslip.interface';

import { PayslipActions } from '../actions/payslip/payslip.actions';

export interface PayslipState extends State {
  payslips: Payslip[];
  payslip?: Payslip;
}

export const initialState: PayslipState = {
  payslips: []
};

export const payslipReducer = (state: PayslipState = initialState, action: AnyAction) => {
  if (action.type === PayslipActions.SET_PAYSLIPS) {
    const { payslips } = action;
    return {
      ...state,
      payslips
    };
  } else if (action.type === PayslipActions.SET_PAYSLIP) {
    const { payslip } = action;
    return {
      ...state,
      payslip
    };
  }

  return state;
};
