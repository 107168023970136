import React from 'react';
import { RouteObject } from 'react-router-dom';

import LeaveRequestsContainer from '../../containers/human-resources/leave-requests/list/LeaveRequests.container';
import AddLeaveRequestContainer from '../../containers/human-resources/leave-requests/add/AddLeaveRequest.container';
import LeaveRequestContainer from '../../containers/human-resources/leave-requests/details/LeaveRequest.container';

import LeaveRequestsCrumb from '../../crumbs/human-resources/leave-requests/LeaveRequests.crumb';
import AddLeaveRequestCrumb from '../../crumbs/human-resources/leave-requests/AddLeaveRequest.crumb';
import LeaveRequestCrumb from '../../crumbs/human-resources/leave-requests/LeaveRequest.crumb';

import ProtectedRoute from '../ProtectedRoute';

const leaveRequestRoutes: RouteObject[] = [
  {
    path: 'leave-requests',
    handle: {
      crumb: () => <LeaveRequestsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <LeaveRequestsContainer />
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddLeaveRequestContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <AddLeaveRequestCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <LeaveRequestContainer />
          </ProtectedRoute>
        ),
        handle: {
          crumb: () => <LeaveRequestCrumb />
        }
      }
    ]
  }
];

export default leaveRequestRoutes;
