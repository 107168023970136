import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { ExpenseClaim } from '../../interfaces/employee/expense-claim.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getExpenseClaimsEndpoint = (): string => `${API_HOST}/expense_claims`;
export const getExpenseClaimsRequest = (): RequestOptions => [
  getExpenseClaimsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getExpenseClaimsCsvEndpoint = (): string => `${getExpenseClaimsEndpoint()}.csv`;
export const getDownloadExpenseClaimsRequest = () => [
  getExpenseClaimsCsvEndpoint(),
  { ...getHttpGetOptions({ ...getAuthHeaders(), Accept: 'text/csv' }), responseType: 'blob' }
];

export const getAddExpenseClaimRequest = (payload: ExpenseClaim): RequestOptions => [
  getExpenseClaimsEndpoint(),
  getHttpPostOptions({ expense_claim: payload }, getAuthHeaders())
];

const getExpenseClaimEndpoint = (id: number | string): string => `${API_HOST}/expense_claims/${id}`;
export const getExpenseClaimRequest = (id: number | string): RequestOptions => [
  getExpenseClaimEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateExpenseClaimRequest = (
  id: number | string,
  payload: ExpenseClaim
): RequestOptions => [
  getExpenseClaimEndpoint(id),
  getHttpPutOptions({ expense_claim: payload }, getAuthHeaders())
];

export const getRemoveExpenseClaimRequest = (id: string | number): RequestOptions => [
  getExpenseClaimEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
