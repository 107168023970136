import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppState } from '../../../types/state.interface';

import { Document } from '../../../interfaces/document/document.interface';

import { useAppSelector } from '../../../hooks';

const DocumentCrumb = () => {
  const document: Document = useAppSelector(
    ({ document_store }: AppState) => document_store.document
  );

  return <NavLink to={`/admin/documents/${document?.id}`}>{document?.name}</NavLink>;
};

export default DocumentCrumb;
