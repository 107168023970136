import React, { useMemo, useState } from 'react';

import { useFormState, ValidationRules } from '@neslotech/hooks';
import { Form as StateForm } from '@neslotech/hooks';

import { Client } from '../../../../../interfaces/client/client.interface';
import { Category } from '../../../../../interfaces/config/category.interface';
import { ClientType } from '../../../../../interfaces/config/client-type.interface';

import Input, { OnChangeType } from '../../../../input/Input';
import Form from '../../../../form/Form';
import FormRow from '../../../../form/form-row/FormRow';
import FormAction from '../../../../form/form-action/FormAction';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../../../select/Select';

import './add-mandate.scss';

interface AddMandateState extends StateForm {
  name?: string;
  clientTypeId?: string | number;
  category?: string | number;
}

const rules: ValidationRules<AddMandateState> = {
  validates: {
    name: ['isPresent']
  }
};

const serverify = (form: AddMandateState): Client => ({
  name: form.name,
  client_type_id: form.clientTypeId,
  category_id: form.category
});

interface Props {
  categories: Category[];
  clientTypes: ClientType[];
  onSave: (payload: Client, onComplete: () => void) => void;
}

const AddMandateForm = ({ categories, clientTypes, onSave }: Props) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as AddMandateState) });

  const [loading, setLoading] = useState<boolean>(false);

  const mappedCategories: SelectItem[] = useMemo(() => {
    return categories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((category: Category) => ({
        label: category.name,
        value: category.id
      }));
  }, [categories]);

  const mappedClientTypes: SelectItem[] = useMemo(() => {
    return clientTypes
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((clientType: ClientType) => ({
        label: clientType.name,
        value: clientType.id
      }));
  }, [clientTypes]);

  const handleSave = () => {
    setLoading(true);
    const payload: Client = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="add-mandate-form">
      <p>Once this mandate has been captured, this contact will be converted to a Client.</p>
      <p>Please provide the necessary details to convert this contact to a Client.</p>
      <Form>
        <FormRow fluid>
          <Input
            name="name"
            label="Client Name"
            placeholder="Client Name"
            value={form?.name}
            error={form?.messages?.name}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluid>
          <Select
            name="clientTypeId"
            label="Client Type"
            placeholder="Select a client type"
            items={mappedClientTypes}
            value={form?.clientTypeId}
            error={form?.messages.clientTypeId}
            onChange={handleChange}
          />
          <Select
            name="category"
            label="Category"
            placeholder="Select a category"
            items={mappedCategories}
            value={form?.category}
            error={form?.messages.category}
            onChange={handleChange}
          />
        </FormRow>
        <FormAction
          disabled={!form?.valid}
          loading={loading}
          label="Save Client"
          onClick={handleSave}
        />
      </Form>
    </article>
  );
};

export default AddMandateForm;
