import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { ClientType } from '../interfaces/config/client-type.interface';

import { ClientTypeActions } from '../actions/client-type/client-type.actions';

export interface ClientTypeState extends State {
  clientTypes: ClientType[];
  clientType?: ClientType;
}

export const initialState: ClientTypeState = {
  clientTypes: []
};

export const clientTypeReducer = (state: ClientTypeState = initialState, action: AnyAction) => {
  if (action.type === ClientTypeActions.SET_CLIENT_TYPES) {
    const { clientTypes } = action;
    return {
      ...state,
      clientTypes
    };
  } else if (action.type === ClientTypeActions.SET_CLIENT_TYPE) {
    const { clientType } = action;
    return {
      ...state,
      clientType
    };
  }

  return state;
};
