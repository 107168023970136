import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getProfileRequest, getUpdatePasswordRequest, RequestOptions } from '../tools/api';

import { SystemActions } from '../actions/system/system.actions';
import { ProfileActions } from '../actions/profile/profile.actions';
import { LoadProfileAction, UpdatePasswordAction } from '../actions/profile/profile.types';

export function* performLoadProfile({ onComplete }: LoadProfileAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getProfileRequest();

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ProfileActions.SET_PROFILE, profile: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadProfileRequest() {
  yield takeLatest(ProfileActions.LOAD_PROFILE, performLoadProfile);
}

export function* performUpdatePassword({ request, onComplete }: UpdatePasswordAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdatePasswordRequest(request);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('Your password has been updated successfully!'));

    yield put({ type: ProfileActions.SET_PROFILE, profile: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForUpdatePasswordRequest() {
  yield takeLatest(ProfileActions.UPDATE_PASSWORD, performUpdatePassword);
}

export default function* profileSaga() {
  yield all([watchForLoadProfileRequest(), watchForUpdatePasswordRequest()]);
}
