import React from 'react';
import { RouteObject } from 'react-router-dom';

import CategoriesContainer from '../../containers/admin/sub-categories/list/SubCategories.container';
import AddCategoryContainer from '../../containers/admin/sub-categories/add/AddSubCategory.container';
import CategoryContainer from '../../containers/admin/sub-categories/details/SubCategory.container';

// import SubCategoriesCrumb from '../../crumbs/admin/sub-categories/SubCategories.crumb';
import AddSubCategoryCrumb from '../../crumbs/admin/sub-categories/AddSubCategory.crumb';
import SubCategoryCrumb from '../../crumbs/admin/sub-categories/SubCategory.crumb';

import AdminRoute from '../AdminRoute';

const subCategoryRoutes: RouteObject[] = [
  {
    path: 'sub-categories',
    handle: {
      // crumb: () => <SubCategoriesCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <CategoriesContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddCategoryContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddSubCategoryCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <CategoryContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <SubCategoryCrumb />
        }
      }
    ]
  }
];

export default subCategoryRoutes;
