import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';
import { Timesheet } from '../../../../interfaces/employee/timesheet.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { Client } from '../../../../interfaces/client/client.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { LoadTimesheetsAction } from '../../../../actions/timesheet/timesheet.types';
import { TimesheetActions } from '../../../../actions/timesheet/timesheet.actions';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { CategoryActions } from '../../../../actions/category/category.actions';
import { ClientActions } from '../../../../actions/client/client.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import TimesheetForm from '../../../../components/timesheets/form/TimesheetForm';

const TimesheetContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadTimesheetsAction> = useAppDispatch();

  const timesheet: Timesheet = useAppSelector(
    ({ timesheet_store }: AppState) => timesheet_store.timesheet
  );
  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );
  const clients: Client[] = useAppSelector(({ client_store }: AppState) => client_store.clients);

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(ClientActions.loadClients(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Timesheet, resetForm: boolean, onComplete: () => void): void => {
    dispatch(TimesheetActions.updateTimesheet(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(TimesheetActions.loadTimesheet(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && timesheet?.id === Number(id);
  }, [id, loading, timesheet?.id]);

  return (
    <PageLayout title={`Timesheet Details - ${doneLoading ? timesheet?.employee : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <TimesheetForm
          timesheet={timesheet}
          employees={employees}
          categories={categories}
          clients={clients}
          onSave={onSave}
        />
      )}
    </PageLayout>
  );
};

export default TimesheetContainer;
