import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Irp5 } from '../../../interfaces/employee/irp5.interface';
import { FileData } from '../../../interfaces/config/file.interface';

import { Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

// import { ReactComponent as DocumentIcon } from '../../../icons/word-icon.svg';
import { ReactComponent as DocumentIcon } from '../../../icons/pdf-icon.svg';

import './irp5-list.scss';

interface Props {
  irp5s: Irp5[];
  loading: boolean;
}

const headers: TableHeader[] = [
  // {
  //   Header: 'Index',
  //   accessor: 'index'
  // },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Start Date',
    accessor: 'start_date'
  },
  {
    Header: 'End Date',
    accessor: 'end_date'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  index: number | string;
  name: string;
  start_date: string;
  end_date: string;
}

const Irp5List = ({ irp5s, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const rowData = useMemo(() => {
    return (irp5s ?? []).map(
      (irp5: Irp5) =>
        ({
          index: irp5.id,
          name: irp5.employee,
          start_date: irp5.start_date,
          end_date: irp5.end_date,
          actions: (
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <DocumentIcon
                color="#003768"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open((irp5.document as FileData).url, '_blank');
                }}
              />
              <RemoveElement element={irp5} />
            </div>
          ),
          onClick: () => navigate(`/human-resources/irp5s/${irp5.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [irp5s]);

  return (
    <article className="irp5-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No irp5s could be found!"
        rowData={rowData}
      />
    </article>
  );
};

export default Irp5List;
