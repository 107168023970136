import React from 'react';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../hooks';

import { AuthActions } from '../../../actions/auth/auth.actions';

import { RegisterRequest } from '../../../interfaces/auth/register-request.interface';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Register from '../../../components/auth/register/Register';

const RegisterContainer = () => {
  const dispatch: Dispatch<Action> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const handleRegister = (request: RegisterRequest): void => {
    dispatch(AuthActions.register(request, navigate));
  };

  return (
    <AuthLayout
      title="Sign Up"
      subtitle="Sign up for a new account to access our website"
      linkTo="/"
      linkText="Already have a BSM account? Sign in"
    >
      <Register onRegister={handleRegister} />
    </AuthLayout>
  );
};

export default RegisterContainer;
