import React, { ChangeEvent } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import './text-area.scss';

type NamedChangeType = { [key: string]: string };
export type OnChangeType = NamedChangeType | string;

interface Props {
  name: string;
  label: string;
  placeholder: string;
  value?: string | number;
  error?: string;
  disabled?: boolean;
  rows?: number;
  onChange: (value: OnChangeType) => void;
}

const TextArea = ({ name, rows, value, label, placeholder, error, disabled, onChange }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = getChangeValue(event.target.value, name);
    onChange(value);
  };

  return (
    <article className="text-area">
      <section className="text-area__label">
        <label htmlFor={name}>{label}</label>
      </section>
      <section className={getClassNames('text-area__wrapper', { disabled })}>
        <textarea
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          rows={rows}
        />
      </section>
      <section className="text-area__error">{error && <small>{error}</small>}</section>
    </article>
  );
};

const getChangeValue = (value: string, name?: string): OnChangeType => {
  return name ? { [name]: value } : value;
};

TextArea.defaultProps = {
  rows: 5,
  disabled: false,
  onChange: noOp
};

export default TextArea;
