import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch } from '../../../hooks';

import { ResetPasswordRequest } from '../../../interfaces/auth/reset-password-request.interface';

import { UpdatePasswordAction } from '../../../actions/profile/profile.types';
import { ProfileActions } from '../../../actions/profile/profile.actions';

import PageLayout from '../../../components/layout/page/PageLayout';
import ResetPassword from '../../../components/auth/reset-password/ResetPassword';
import DetailsCard from '../../../components/card/DetailsCard';

const ProfilePasswordContainer = () => {
  const dispatch: Dispatch<UpdatePasswordAction> = useAppDispatch();

  const onSave = (payload: ResetPasswordRequest): void => {
    dispatch(ProfileActions.updatePassword(payload));
  };

  return (
    <PageLayout title="Change Password">
      <DetailsCard>
        <ResetPassword onReset={onSave} />
      </DetailsCard>
    </PageLayout>
  );
};

export default ProfilePasswordContainer;
