import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { Employeerole } from '../../../../interfaces/employeerole/employeerole.interface';

import { RemoveEmployeeroleAction } from '../../../../actions/employeerole/employeerole.types';
import { EmployeeroleActions } from '../../../../actions/employeerole/employeerole.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveEmployeeroleContainer = () => {
  const dispatch: Dispatch<RemoveEmployeeroleAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      EmployeeroleActions.removeEmployeerole((element as Employeerole).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Employee Role">
            <RemoveModal title={(element as Employeerole).employeerole} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveEmployeeroleContainer;
