import { useContext } from 'react';

import { ClientContext } from '../../contexts/client/Client.context';
import { ContactContext, ContactProps } from '../../contexts/client/Contact.context';

import { Client } from '../../interfaces/client/client.interface';
import { Category } from '../../interfaces/config/category.interface';
import { Sector } from '../../interfaces/config/sector.interface';
import { ClientType } from '../../interfaces/config/client-type.interface';
import { User } from '../../interfaces/user/user.interface';

export interface ClientData {
  id: string;
  loading: boolean;
  client: Client;
}
const useClient = (): ClientData => {
  const ctx = useContext(ClientContext);

  return { client: ctx.client, id: ctx.id, loading: ctx.loading };
};

const useCategories = (): Category[] => useContext(ClientContext).categories;
const useSectors = (): Sector[] => useContext(ClientContext).sectors;
const useClientTypes = (): ClientType[] => useContext(ClientContext).clientTypes;
const useDesignations = (): ClientType[] => useContext(ClientContext).designations;
const useUsers = (): User[] => useContext(ClientContext).users;

const useContactData = (): ContactProps => useContext(ContactContext);

export {
  useClient,
  useCategories,
  useSectors,
  useClientTypes,
  useContactData,
  useDesignations,
  useUsers
};
