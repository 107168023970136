import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Event } from '../../../../interfaces/event/event.interface';

import { EventActions } from '../../../../actions/event/event.actions';
import { AddEventAction } from '../../../../actions/event/event.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import EventForm from '../../../../components/events/form/EventForm';

const AddEventContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddEventAction> = useAppDispatch();

  const onSave = (payload: Event, onComplete: () => void): void => {
    dispatch(EventActions.addEvent(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Event">
      <EventForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddEventContainer;
