import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { Contact } from '../../../../../../../interfaces/client/contact.interface';
import { Client } from '../../../../../../../interfaces/client/client.interface';
import { Category } from '../../../../../../../interfaces/config/category.interface';
import { ClientType } from '../../../../../../../interfaces/config/client-type.interface';

import { ContactActions } from '../../../../../../../actions/contact/contact.actions';
import { ClientTypeActions } from '../../../../../../../actions/client-type/client-type.actions';
import { CategoryActions } from '../../../../../../../actions/category/category.actions';

import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';

import { ModalLayout } from '../../../../../../../components/modal/ModalLayout';
import AddMandateForm from '../../../../../../../components/clients/details/contacts/add-mandate/AddMandateForm';

interface Props {
  contact?: Contact;
  setShow: (value: boolean) => void;
}

const AddMandateContainer = ({ contact, setShow }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useAppDispatch();

  const categories: Category[] = useAppSelector(({ category_store }) => category_store.categories);
  const clientTypes: ClientType[] = useAppSelector(
    ({ client_type_store }) => client_type_store.clientTypes
  );

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(ClientTypeActions.loadClientTypes(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => setShow(false);

  const handleSave = (payload: Client, onComplete: () => void) => {
    dispatch(
      ContactActions.addMandateToContact(contact.id, payload, (clientId: number | string) => {
        onComplete();
        setShow(false);
        navigate(`/core/clients/${clientId}`);
      })
    );
  };

  return (
    <ModalLayout onClose={onClose} title="Add Mandate - Client Details" large>
      <AddMandateForm categories={categories} clientTypes={clientTypes} onSave={handleSave} />
    </ModalLayout>
  );
};

export default AddMandateContainer;
