import React, { useEffect } from 'react';

import { Contact } from '../../../interfaces/client/contact.interface';
import { Filter } from '../../../types/filter.interface';

import { filterElement } from '../../../tools/filter.util';

import { useRemoval } from '../../../hooks';
import { useFilters } from '../../../hooks/useFilters';

import ContactCard from '../card/ContactCard';

import './contact-list.scss';

interface Props {
  contacts: Contact[];
  onEdit: (contact: Contact) => void;
}

const formify = (contacts: Contact[], filters: Filter): Contact[] =>
  contacts.filter((contact: Contact) =>
    // @ts-ignore
    filterElement(contact, filters)
  );

const ContactList = ({ contacts, onEdit }: Props) => {
  const { setElement, setShowModal } = useRemoval();
  const { filters, setFilterDefaults } = useFilters();

  useEffect(() => {
    setFilterDefaults({ classification: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = (contact: Contact) => {
    setElement(contact);
    setShowModal(true);
  };

  return (
    <article className="contact-list">
      {formify(contacts, filters).map((contact: Contact) => (
        <ContactCard
          key={contact.id}
          contact={contact}
          onRemove={() => handleRemove(contact)}
          onView={() => onEdit(contact)}
        />
      ))}
    </article>
  );
};

export default ContactList;
