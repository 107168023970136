import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Fund } from '../../../../interfaces/fund/fund.interface';

import { FundActions } from '../../../../actions/fund/fund.actions';
import { AddFundAction } from '../../../../actions/fund/fund.types';

import { useAppDispatch } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import FundForm from '../../../../components/funds/form/FundForm';

const AddFundContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddFundAction> = useAppDispatch();

  const onSave = (payload: Fund, onComplete: () => void): void => {
    dispatch(FundActions.addFund(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Fund">
      <FundForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddFundContainer;
