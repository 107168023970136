import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Fund } from '../../../../interfaces/fund/fund.interface';

import { LoadFundsAction, RemoveFundDocumentAction } from '../../../../actions/fund/fund.types';
import { FundActions } from '../../../../actions/fund/fund.actions';

import { Loader } from '../../../../components/loader/Loader';
import FundDocumentForm from '../../../../components/funds/form/FundDocumentForm';

const FundDocumentsContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadFundsAction | RemoveFundDocumentAction> = useAppDispatch();

  const fund: Fund = useAppSelector(({ fund_store }: AppState) => fund_store.fund) as Fund;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Fund, onComplete: () => void) => {
    dispatch(FundActions.updateFund(id as string, payload, onComplete));
  };

  const onRemoveDocument = (documentId: number) => {
    dispatch(FundActions.removeFundDocument(id as string, documentId));
  };

  useEffect(() => {
    dispatch(FundActions.loadFund(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && fund?.id === Number(id);
  }, [id, loading, fund?.id]);

  return (
    <article className="page-layout">
      {!doneLoading && <Loader filled />}
      {doneLoading && (
        <FundDocumentForm
          type="documents"
          documents={fund.documents}
          onSave={onSave}
          fund={fund}
          onRemoveDocument={onRemoveDocument}
        />
      )}
    </article>
  );
};

export default FundDocumentsContainer;
