import { NavigateFunction } from 'react-router-dom';

import { Document } from '../../interfaces/document/document.interface';

import {
  AddDocumentAction,
  LoadDocumentAction,
  LoadDocumentsAction,
  OnComplete,
  RemoveDocumentAction,
  RemoveDocumentFileAction,
  UpdateDocumentAction
} from './document.types';

export class DocumentActions {
  static readonly LOAD_DOCUMENTS: string = '[DOCUMENT_STORE] Load documents';
  static readonly SET_DOCUMENTS: string = '[DOCUMENT_STORE] Set documents';

  static readonly LOAD_DOCUMENT: string = '[DOCUMENT_STORE] Load document';
  static readonly SET_DOCUMENT: string = '[DOCUMENT_STORE] Set document';

  static readonly UPDATE_DOCUMENT: string = '[DOCUMENT_STORE] Update document';
  static readonly ADD_DOCUMENT: string = '[DOCUMENT_STORE] Add document';
  static readonly REMOVE_DOCUMENT: string = '[DOCUMENT_STORE] Remove document';
  static readonly REMOVE_DOCUMENT_FILE: string = '[DOCUMENT_STORE] Remove document file';

  static loadDocuments = (onComplete: OnComplete): LoadDocumentsAction => ({
    type: DocumentActions.LOAD_DOCUMENTS,
    onComplete
  });

  static loadDocument = (id: number | string, onComplete: OnComplete): LoadDocumentAction => ({
    type: DocumentActions.LOAD_DOCUMENT,
    id,
    onComplete
  });

  static addDocument = (
    payload: Document,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddDocumentAction => ({
    type: DocumentActions.ADD_DOCUMENT,
    payload,
    navigate,
    onComplete
  });

  static updateDocument = (
    id: number | string,
    payload: Document,
    onComplete: OnComplete
  ): UpdateDocumentAction => ({
    type: DocumentActions.UPDATE_DOCUMENT,
    id,
    payload,
    onComplete
  });

  static removeDocument = (id: number | string, onComplete: OnComplete): RemoveDocumentAction => ({
    type: DocumentActions.REMOVE_DOCUMENT,
    id,
    onComplete
  });

  static removeDocumentFile = (
    id: number | string,
    fileId: number | string,
    onComplete: OnComplete
  ): RemoveDocumentFileAction => ({
    type: DocumentActions.REMOVE_DOCUMENT_FILE,
    id,
    fileId,
    onComplete
  });
}
