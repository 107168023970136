import { NavigateFunction } from 'react-router-dom';

import { Fund } from '../../interfaces/fund/fund.interface';

import {
  AddFundAction,
  LoadFundAction,
  LoadFundsAction,
  OnComplete,
  RemoveFundAction,
  RemoveFundDocumentAction,
  UpdateFundAction
} from './fund.types';

export class FundActions {
  static readonly LOAD_FUNDS: string = '[FUND_STORE] Load funds';
  static readonly SET_FUNDS: string = '[FUND_STORE] Set funds';

  static readonly LOAD_FUND: string = '[FUND_STORE] Load fund';
  static readonly SET_FUND: string = '[FUND_STORE] Set fund';

  static readonly UPDATE_FUND: string = '[FUND_STORE] Update fund';
  static readonly ADD_FUND: string = '[FUND_STORE] Add fund';
  static readonly REMOVE_FUND: string = '[FUND_STORE] Remove fund';
  static readonly REMOVE_FUND_DOCUMENT: string = '[FUND_STORE] Remove fund document';

  static loadFunds = (onComplete: OnComplete): LoadFundsAction => ({
    type: FundActions.LOAD_FUNDS,
    onComplete
  });

  static loadFund = (id: number | string, onComplete: OnComplete): LoadFundAction => ({
    type: FundActions.LOAD_FUND,
    id,
    onComplete
  });

  static addFund = (
    payload: Fund,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddFundAction => ({
    type: FundActions.ADD_FUND,
    payload,
    navigate,
    onComplete
  });

  static updateFund = (
    id: number | string,
    payload: Fund,
    onComplete: OnComplete
  ): UpdateFundAction => ({
    type: FundActions.UPDATE_FUND,
    id,
    payload,
    onComplete
  });

  static removeFund = (id: number | string, onComplete: OnComplete): RemoveFundAction => ({
    type: FundActions.REMOVE_FUND,
    id,
    onComplete
  });

  static removeFundDocument = (
    id: number | string,
    documentId: number
  ): RemoveFundDocumentAction => ({
    type: FundActions.REMOVE_FUND_DOCUMENT,
    id,
    documentId
  });
}
