import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Payslip } from '../../../interfaces/employee/payslip.interface';
import { FileData } from '../../../interfaces/config/file.interface';

import { Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

// import { ReactComponent as DocumentIcon } from '../../../icons/word-icon.svg';
import { ReactComponent as DocumentIcon } from '../../../icons/pdf-icon.svg';

import './payslip-list.scss';

interface Props {
  payslips: Payslip[];
  loading: boolean;
}

const headers: TableHeader[] = [
  // {
  //   Header: 'Index',
  //   accessor: 'index'
  // },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Start Date',
    accessor: 'start_date'
  },
  {
    Header: 'End Date',
    accessor: 'end_date'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

interface RowData extends TableData {
  index: number | string;
  name: string;
  start_date: string;
  end_date: string;
}

const PayslipList = ({ payslips, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return (payslips ?? []).map(
      (payslip: Payslip) =>
        ({
          index: payslip.id,
          name: payslip.employee,
          start_date: payslip.start_date,
          end_date: payslip.end_date,
          actions: (
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <DocumentIcon
                color="#003768"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open((payslip.document as FileData).url, '_blank');
                }}
              />
              <RemoveElement element={payslip} />
            </div>
          ),
          onClick: () => navigate(`/human-resources/payslips/${payslip.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payslips]);

  return (
    <article className="payslip-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No payslips could be found!"
        rowData={rowData}
      />
    </article>
  );
};

export default PayslipList;
