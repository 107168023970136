import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddPrecedentRequest,
  getPrecedentsRequest,
  getRemovePrecedentRequest,
  RequestOptions
} from '../../tools/api';
import {
  getPrecedentRequest,
  getUpdatePrecedentRequest
} from '../../tools/api/clients/precedent.endpoints';

import {
  AddPrecedentAction,
  LoadPrecedentAction,
  RemovePrecedentAction,
  UpdatePrecedentAction
} from '../../actions/client/precedent/precedent.types';
import { PrecedentActions } from '../../actions/client/precedent/precedent.actions';
import { SystemActions } from '../../actions/system/system.actions';

export function* performLoadPrecedents({ id, onComplete }: LoadPrecedentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getPrecedentsRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: PrecedentActions.SET_PRECEDENTS, precedents: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPrecedentsRequest() {
  yield takeLatest(PrecedentActions.LOAD_PRECEDENTS, performLoadPrecedents);
}

export function* performLoadPrecedent({ id, precedentId, onComplete }: LoadPrecedentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getPrecedentRequest(id, precedentId);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: PrecedentActions.SET_PRECEDENT, precedent: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPrecedentRequest() {
  yield takeLatest(PrecedentActions.LOAD_PRECEDENT, performLoadPrecedent);
}

export function* performAddPrecedent({ id, payload, onComplete }: AddPrecedentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddPrecedentRequest(id, payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your precedent has been created successfully!', SNACK_SUCCESS)
    );

    yield put(PrecedentActions.loadPrecedents(id, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddPrecedentRequest() {
  yield takeLatest(PrecedentActions.ADD_PRECEDENT, performAddPrecedent);
}

export function* performUpdatePrecedent({
  id,
  precedentId,
  payload,
  onComplete
}: UpdatePrecedentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdatePrecedentRequest(
      id,
      precedentId,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your precedent has been updated successfully!', SNACK_SUCCESS)
    );

    yield put(PrecedentActions.loadPrecedent(id, precedentId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdatePrecedentRequest() {
  yield takeLatest(PrecedentActions.UPDATE_PRECEDENT, performUpdatePrecedent);
}

export function* performRemovePrecedent({ id, precedentId, onComplete }: RemovePrecedentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemovePrecedentRequest(id, precedentId);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your precedent has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(PrecedentActions.loadPrecedents(id, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemovePrecedentRequest() {
  yield takeLatest(PrecedentActions.REMOVE_PRECEDENT, performRemovePrecedent);
}

export default function* precedentSaga() {
  yield all([
    watchForLoadPrecedentsRequest(),
    watchForLoadPrecedentRequest(),
    watchForUpdatePrecedentRequest(),
    watchForAddPrecedentRequest(),
    watchForRemovePrecedentRequest()
  ]);
}
