import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Employee } from '../../../interfaces/employee/employee.interface';

import { useRemoval } from '../../../hooks';

import { ReactComponent as EditIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import { capitalise } from '@neslotech/utils';

import DetailsCard from '../../card/DetailsCard';
import InfoDetail from '../../clients/details/InfoDetail';
import { ButtonGroup } from '../../button-group/ButtonGroup';
import Button from '../../button/Button';

interface Props {
  employee: Employee;
}

const EmployeeCard = ({ employee }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const { setElement, setShowModal } = useRemoval();

  const actions = useMemo(() => {
    const handleRemove = () => {
      setShowModal(true);
      setElement(employee);
    };

    return (
      <>
        <EditIcon onClick={() => navigate(`/human-resources/employees/${employee.id}`)} />
        <RemoveIcon onClick={handleRemove} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  return (
    <DetailsCard action={actions} small>
      <article className="employee-card">
        <InfoDetail title="Employee Name" value={employee.full_name} />
        <InfoDetail title="Role" value={capitalise(employee.role)} />
        <InfoDetail title="Employee Role" value={capitalise(employee.erole)} />
        <ButtonGroup>
          <Button
            hollow
            label="Contract"
            onClick={() => navigate(`/human-resources/employees/${employee.id}/contracts`)}
          />
          <Button
            hollow
            label="Disciplinary"
            onClick={() => navigate(`/human-resources/employees/${employee.id}/disciplines`)}
          />
          <Button
            hollow
            label="Documents"
            onClick={() => navigate(`/human-resources/employees/${employee.id}/other-documents`)}
          />
        </ButtonGroup>
      </article>
    </DetailsCard>
  );
};

export default EmployeeCard;
