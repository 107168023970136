import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { statusItems } from '../../../tools/dropdown.util';

import { Category } from '../../../interfaces/config/category.interface';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import Select, { OnChangeType as SelectChangeType } from '../../select/Select';
import DetailsCard from '../../card/DetailsCard';
import TextArea from '../../text-area/TextArea';

import './category-form.scss';

type OnSave = (payload: Category, onComplete: () => void) => void;
type Status = 'active' | 'inactive';

interface Props {
  category?: Category;
  onSave: OnSave;
}

export interface CategoryState extends StateForm {
  name: string;
  description: string;
  status: Status;
  categoryType: string;
}

const rules: ValidationRules<CategoryState> = {
  validates: {
    name: ['isPresent'],
    status: ['isPresent'],
    categoryType: ['isPresent']
  }
};

const formify = (category?: Category): CategoryState =>
  ({
    name: category?.name,
    description: category?.description,
    status: category?.status,
    categoryType: category?.category_type
  } as CategoryState);

const serverify = (form: CategoryState) => ({
  name: form.name,
  description: form.description,
  status: form.status,
  category_type: form.categoryType
});

const CategoryForm = ({ category, onSave }: Props) => {
  const [form, setForm] = useFormState<CategoryState>(formify(category), rules);
  const handleChange = (newState: OnChangeType | SelectChangeType) =>
    setForm({ ...form, ...(newState as CategoryState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Category = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="category-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Category Name"
              placeholder="Category Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              name="description"
              label="Description"
              placeholder="Description"
              value={form?.description}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="status"
              label="Status"
              placeholder="Select a status"
              items={statusItems}
              value={form?.status}
              error={form?.messages.status}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="categoryType"
              label="Category Type"
              placeholder="Select a category type"
              items={[
                { label: 'Document', value: 'document' },
                { label: 'General', value: 'general' }
              ]}
              value={form?.categoryType}
              error={form?.messages.categoryType}
              onChange={handleChange}
            />
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

export default CategoryForm;
