import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { formatCurrency } from '../../../../tools/currency.util';

import { AppState } from '../../../../types/state.interface';
import { Tombstone } from '../../../../interfaces/client/tombstone.interface';
import { Category } from '../../../../interfaces/config/category.interface';
import { Filter } from '../../../../types/filter.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import RemovalProvider from '../../../../providers/Removal.provider';

import { TombstoneActions } from '../../../../actions/tombstone/tombstone.actions';
import { CategoryActions } from '../../../../actions/category/category.actions';

import { ReactComponent as ChevronRightIcon } from '../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import TombstoneList from '../../../../components/tombstones/list/TombstoneList';
import { DropdownMenuItem } from '../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../components/radio/RadioGroup';
import FilterLayout from '../../../../components/layout/filter/FilterLayout';
import Button from '../../../../components/button/Button';

import RemoveTombstoneContainer from '../../clients/details/tombstones/remove/RemoveTombstone.container';
import TombstoneTable from '../../../../components/tombstones/list/TombstoneTable';
import { Datepicker } from '../../../../components/datepicker/Datepicker';
import Select from '../../../../components/select/Select';
import { getCookieListTableViewStyle, setCookieListTableViewStyle } from '../../../../tools/filter.util';

const TombstonesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const cookieViewStyle = getCookieListTableViewStyle();
  const [view_style, setViewStyle] = useState<string>(cookieViewStyle ?? 'list_view');

  const tombstones: Tombstone[] = useAppSelector(
    ({ tombstone_store }: AppState) => tombstone_store.tombstones
  );
  const categories: Category[] = useAppSelector(
    ({ category_store }: AppState) => category_store.categories
  );

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(TombstoneActions.loadTombstones(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tombstoneTotal = useMemo(() => {
    return tombstones
      .filter((tombstone: Tombstone) => !isNaN(tombstone.size_of_transaction))
      .reduce((accum, tombstone) => accum + Number(tombstone.size_of_transaction), 0);
  }, [tombstones]);

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean | Date }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Category',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="category_id"
            onChange={onChange}
            value={filters?.category_id as string}
            items={categories
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((category: Category) => ({
                label: category.name,
                value: category.id
              }))}
          />
        )
      },
      {
        text: 'Date Range',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <>
            <Datepicker
              name="start_date"
              placeholder="Select Start Date"
              // @ts-ignore
              onChange={onChange}
              value={filters?.start_date as string}
            />
            <Datepicker
              name="end_date"
              placeholder="Select End Date"
              // @ts-ignore
              onChange={onChange}
              value={filters?.end_date as string}
            />
          </>
        )
      }
    ];
  }, [categories]);

  const doneLoading = useMemo(() => {
    return !loading && !!tombstones;
  }, [loading, tombstones]);

  const handleViewChange = (object: any) => {
    setViewStyle(object.view_style);
    setCookieListTableViewStyle(object.view_style);
  };

  return (
    <PageLayout
      title="Tombstones"
      bordered
      action={<Button label="Add Tombstone" onClick={() => navigate('add')} />}
    >
      <FilterLayout
        menuItems={menuItems}
        hideButton={true}
        actions={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginRight: 10 }}>Tombstone Total Fee: {formatCurrency(tombstoneTotal)}</p>
            <Button
              label="Export"
              onClick={() => dispatch(TombstoneActions.downloadTombstones(noOp))}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '130px',
                marginLeft: '10px',
                marginTop: '15px'
              }}
            >
              <Select
                name="view_style"
                placeholder="Select View"
                value={view_style}
                items={[
                  { label: 'List View', value: 'list_view' },
                  { label: 'Table View', value: 'table_view' }
                ]}
                onChange={handleViewChange}
              />
            </div>
          </div>
        }
      >
        <RemovalProvider>
          {!doneLoading && <Loader filled />}
          {doneLoading &&
            (view_style === 'list_view' ? (
              <TombstoneList tombstones={tombstones} />
            ) : (
              <TombstoneTable tombstones={tombstones} />
            ))}
          <RemoveTombstoneContainer />
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default TombstonesContainer;
