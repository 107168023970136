import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Investment } from '../../../../../interfaces/investment/investment.interface';

import { InvestmentActions } from '../../../../../actions/investment/investment.actions';
import { AddInvestmentAction } from '../../../../../actions/investment/investment.types';

import { useAppDispatch } from '../../../../../hooks';

import PageLayout from '../../../../../components/layout/page/PageLayout';
import InvestmentForm from '../../../../../components/funds/investments/form/InvestmentForm';

const AddInvestmentContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddInvestmentAction> = useAppDispatch();
  const { id } = useParams();

  const onSave = (payload: Investment, onComplete: () => void): void => {
    dispatch(
      InvestmentActions.addInvestment(
        id as string,
        { ...payload, fund_id: id },
        navigate,
        onComplete
      )
    );
  };

  return (
    <PageLayout title="Add Fund">
      <InvestmentForm onSave={onSave} />
    </PageLayout>
  );
};

export default AddInvestmentContainer;
