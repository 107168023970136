import React, { ReactNode } from 'react';

import { getClassNames } from '@neslotech/utils';

import './button-group.scss';

interface Props {
  children: ReactNode | ReactNode[];
  fluid?: boolean;
  spaced?: boolean;
  centered?: boolean;
}

export const ButtonGroup = ({ children, fluid, spaced, centered }: Props) => (
  <section className={getClassNames('button-group', { fluid, spaced, centered })}>
    {children}
  </section>
);

ButtonGroup.defaultProps = {
  fluid: false,
  spaced: false
};
