import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../../../types/state.interface';
import { Filter } from '../../../../../../types/filter.interface';

import { formatCurrency } from '../../../../../../tools/currency.util';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import RemovalProvider from '../../../../../../providers/Removal.provider';

import { Mandate } from '../../../../../../interfaces/client/mandate.interface';
import { Sector } from '../../../../../../interfaces/config/sector.interface';

import { LoadMandatesAction } from '../../../../../../actions/client/mandate/mandate.types';
import { MandateActions } from '../../../../../../actions/client/mandate/mandate.actions';
import { SectorActions } from '../../../../../../actions/sector/sector.actions';
import { LoadSectorsAction } from '../../../../../../actions/sector/sector.types';

import { ReactComponent as ChevronRightIcon } from '../../../../../../icons/chevron-right-icon.svg';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import Button from '../../../../../../components/button/Button';
import MandateList from '../../../../../../components/mandates/list/MandateList';
import FilterLayout from '../../../../../../components/layout/filter/FilterLayout';
import { DropdownMenuItem } from '../../../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../../../components/radio/RadioGroup';

import RemoveMandateContainer from '../remove/RemoveMandate.container';

const MandatesContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadMandatesAction | LoadSectorsAction> = useAppDispatch();
  const { id } = useParams<string>();

  const mandates: Mandate[] = useAppSelector(({ client_store }: AppState) => client_store.mandates);
  const sectors: Sector[] = useAppSelector(({ sector_store }: AppState) => sector_store.sectors);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(SectorActions.loadSectors(noOp));
    dispatch(MandateActions.loadMandates(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mandateTotal = useMemo(() => {
    return mandates
      .filter((mandate: Mandate) => !isNaN(mandate.fee_value))
      .reduce((accum, mandate) => accum + Number(mandate.fee_value), 0);
  }, [mandates]);

  const menuItems = useMemo(() => {
    return (
      onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
      filters: Filter
    ): DropdownMenuItem[] => [
      {
        text: 'Status',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="status"
            onChange={onChange}
            value={filters?.status as string}
            items={[
              { label: 'Completed', value: 'completed' },
              { label: 'Open', value: 'open' }
            ]}
          />
        )
      },
      {
        text: 'Sector',
        onClick: noOp,
        suffix: <ChevronRightIcon />,
        expandable: true,
        expandedItems: (
          <RadioGroup
            name="sector_id"
            onChange={onChange}
            value={filters?.sector_id as string}
            items={sectors
              .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
              .map((sector: Sector) => ({
                label: sector.name,
                value: sector.id
              }))}
          />
        )
      }
    ];
  }, [sectors]);

  return (
    <PageLayout
      title="Mandates"
      action={<Button label="Add Mandate" onClick={() => navigate('add')} />}
    >
      <FilterLayout
        menuItems={menuItems}
        actions={<p>Mandate Total Fee: {formatCurrency(mandateTotal)}</p>}
      >
        <RemovalProvider>
          <MandateList mandates={mandates} loading={loading} />
          <RemoveMandateContainer />
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default MandatesContainer;
