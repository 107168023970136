import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddPayslipRequest,
  getPayslipRequest,
  getPayslipsRequest,
  getRemovePayslipRequest,
  getUpdatePayslipRequest,
  RequestOptions
} from '../tools/api';

import {
  AddPayslipAction,
  LoadPayslipAction,
  LoadPayslipsAction,
  UpdatePayslipAction
} from '../actions/payslip/payslip.types';

import { PayslipActions } from '../actions/payslip/payslip.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadPayslips({ onComplete }: LoadPayslipsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getPayslipsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: PayslipActions.SET_PAYSLIPS, payslips: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPayslipsRequest() {
  yield takeLatest(PayslipActions.LOAD_PAYSLIPS, performLoadPayslips);
}

export function* performLoadPayslip({ id, onComplete }: LoadPayslipAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getPayslipRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: PayslipActions.SET_PAYSLIP, payslip: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPayslipRequest() {
  yield takeLatest(PayslipActions.LOAD_PAYSLIP, performLoadPayslip);
}

export function* performUpdatePayslip({ id, payload, onComplete }: UpdatePayslipAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdatePayslipRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The payslip has been updated successfully!', SNACK_SUCCESS));

    yield put({ type: PayslipActions.SET_PAYSLIP, payslip: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdatePayslipRequest() {
  yield takeLatest(PayslipActions.UPDATE_PAYSLIP, performUpdatePayslip);
}

export function* performAddPayslip({ payload, navigate, onComplete }: AddPayslipAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddPayslipRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new payslip has been created successfully!', SNACK_SUCCESS)
    );

    yield put(PayslipActions.loadPayslips(noOp));
    yield navigate('/human-resources/payslips');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddPayslipRequest() {
  yield takeLatest(PayslipActions.ADD_PAYSLIP, performAddPayslip);
}

export function* performRemovePayslip({ id, onComplete }: AddPayslipAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemovePayslipRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(SystemActions.addNotice('The payslip has been removed successfully!', SNACK_SUCCESS));

    yield put(PayslipActions.loadPayslips(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemovePayslipRequest() {
  yield takeLatest(PayslipActions.REMOVE_PAYSLIP, performRemovePayslip);
}

export default function* payslipSaga() {
  yield all([
    watchForLoadPayslipsRequest(),
    watchForLoadPayslipRequest(),
    watchForUpdatePayslipRequest(),
    watchForAddPayslipRequest(),
    watchForRemovePayslipRequest()
  ]);
}
