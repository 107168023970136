import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { User } from '../../../../interfaces/user/user.interface';

import { LoadUsersAction } from '../../../../actions/user/user.types';
import { UserActions } from '../../../../actions/user/user.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import UserForm from '../../../../components/users/form/UserForm';

const UserContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadUsersAction> = useAppDispatch();
  
  const user: User = useAppSelector(({ user_store }: AppState) => user_store.user) as User;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: User, onComplete: () => void): void => {
    dispatch(UserActions.updateUser(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(UserActions.loadUser(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && user?.id === Number(id);
  }, [id, loading, user?.id]);

  return (
    <PageLayout title={`User Details - ${doneLoading ? user?.full_name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <UserForm user={user} onSave={onSave} />}
    </PageLayout>
  );
};

export default UserContainer;
