import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Event } from '../../../../interfaces/event/event.interface';

import { LoadEventsAction } from '../../../../actions/event/event.types';
import { EventActions } from '../../../../actions/event/event.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import EventForm from '../../../../components/events/form/EventForm';

const EventContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadEventsAction> = useAppDispatch();
  const event: Event = useAppSelector(({ event_store }: AppState) => event_store.event) as Event;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Event, onComplete: () => void): void => {
    dispatch(EventActions.updateEvent(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(EventActions.loadEvent(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && event?.id === Number(id);
  }, [id, loading, event?.id]);

  return (
    <PageLayout title={`Event Details - ${doneLoading ? event?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <EventForm event={event} onSave={onSave} />}
    </PageLayout>
  );
};

export default EventContainer;
