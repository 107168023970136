import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { DealSheet } from '../../../../../../interfaces/client/deal-sheet.interface';
import { Tombstone } from '../../../../../../interfaces/client/tombstone.interface';

import { RootState } from '../../../../../../reducers';

import { ClientDealSheetActions } from '../../../../../../actions/client/deal-sheet/deal-sheet.actions';
import { AddDealSheetAction } from '../../../../../../actions/client/deal-sheet/deal-sheet.types';
import { TombstoneActions } from '../../../../../../actions/tombstone/tombstone.actions';
import { LoadTombstonesAction } from '../../../../../../actions/tombstone/tombstone.types';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import PageLayout from '../../../../../../components/layout/page/PageLayout';
import DealSheetForm from '../../../../../../components/clients/details/deal-sheets/form/DealSheetForm';
import { Loader } from '../../../../../../components/loader/Loader';

const AddDealSheetContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddDealSheetAction | LoadTombstonesAction> = useAppDispatch();

  const { id } = useParams<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const tombstones: Tombstone[] = useAppSelector(
    ({ tombstone_store }: RootState) => tombstone_store.tombstones
  );

  useEffect(() => {
    dispatch(TombstoneActions.loadTombstones(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: DealSheet, onComplete: () => void): void => {
    dispatch(
      ClientDealSheetActions.addDealSheet(
        id,
        payload,
        () => navigate(`/core/clients/${id}/deal-sheets`),
        onComplete
      )
    );
  };

  return (
    <PageLayout title="Add Deal Sheet">
      {loading && <Loader filled />}
      {!loading && <DealSheetForm tombstones={tombstones} onSave={onSave} />}
    </PageLayout>
  );
};

export default AddDealSheetContainer;
