import { isEmpty } from '@neslotech/utils';

import { Filter } from '../types/filter.interface';
import Cookies from 'js-cookie';

export const isEmptyFilter = (filters: Filter): boolean => {
  if (!filters) {
    return true;
  }

  const keys = Object.keys(filters);
  if (isEmpty(keys)) {
    return true;
  }

  return keys.every((key: string) => isEmpty(filters[key]));
};

export const filterElement = (element: { [key: string]: unknown }, filters: Filter) => {
  if (!filters) {
    return true;
  }

  return Object.keys(filters).every((key) => {
    const value = filters[key];
    if (value === '') {
      return true;
    }

    if (key === 'start_date') {
      return Date.parse(element['date'] as string) >= Date.parse(filters[key] as string);
    }

    if (key === 'end_date') {
      return Date.parse(element['date'] as string) <= Date.parse(filters[key] as string);
    }

    return element[key] === filters[key];
  });
};


/** Sets the auth token cookie */
export const setCookieListTableViewStyle = (view_style: string): void => {
  Cookies.set('table-list-view', view_style);
};

/** Get the auth token cookie */
export const getCookieListTableViewStyle = (): string => {
  return Cookies.get('table-list-view') as string;
};
