import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../../hooks';

import { Investment } from '../../../../../interfaces/investment/investment.interface';

import { LoadInvestmentsAction } from '../../../../../actions/investment/investment.types';
import { InvestmentActions } from '../../../../../actions/investment/investment.actions';

import PageLayout from '../../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../../components/loader/Loader';
import InvestmentForm from '../../../../../components/funds/investments/form/InvestmentForm';

const InvestmentContainer = () => {
  const { id, investmentId } = useParams<string>();

  const dispatch: Dispatch<LoadInvestmentsAction> = useAppDispatch();
  const investment: Investment = useAppSelector(
    ({ investment_store }: AppState) => investment_store.investment
  ) as Investment;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Investment, onComplete: () => void): void => {
    dispatch(
      InvestmentActions.updateInvestment(
        id as string,
        investmentId as string,
        { ...payload, fund_id: id },
        onComplete
      )
    );
  };

  useEffect(() => {
    dispatch(
      InvestmentActions.loadInvestment(id as string, investmentId as string, () =>
        setLoading(false)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, investmentId]);

  const doneLoading = useMemo(() => {
    return !loading && investment?.id === Number(investmentId);
  }, [investmentId, loading, investment?.id]);

  return (
    <PageLayout title={`Investment Details - ${doneLoading ? investment?.name : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <InvestmentForm investment={investment} onSave={onSave} />}
    </PageLayout>
  );
};

export default InvestmentContainer;
