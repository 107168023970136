import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Contact } from '../../../../../../interfaces/client/contact.interface';
import { useContactData } from '../../../../../../hooks/clients';
import './contacts.scss';
import { Table, TableData, TableHeader } from '../../../../../../components/table/Table';
import RemoveElement from '../../../../../../components/table/RemoveElement';

const contactHeaders: TableHeader[] = [
  {
    Header: 'Contact Person',
    accessor: 'key_contact'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Contact Number',
    accessor: 'contact_number'
  },
  {
    Header: 'Created By',
    accessor: 'created_by'
  }
  ,
  {
    Header: 'is Primary',
    accessor: 'is_primary'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const ContactsContainer = () => {
  const navigate: NavigateFunction = useNavigate();

  const { contacts } = useContactData();

  const contactRows: TableData[] = useMemo(() => {
    return contacts.map<TableData>((contact: Contact) => ({
      ...contact,
      is_primary: contact.is_primary === 1 ? 'True' : 'False',
      actions: <RemoveElement element={contact} />,
      onClick: () => navigate(`/core/clients/${contact.client_id}/contacts/${contact.id}`)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  return (
    <>
      <Table cols={contactHeaders} rowData={contactRows} />
    </>
  );
};

export default ContactsContainer;
