import { LoadDealSheetsAction, OnComplete } from './deal-sheet.types';

export class DealSheetActions {
  static readonly LOAD_DEAL_SHEETS: string = '[DEAL_SHEET_STORE] Load deal sheets';
  static readonly SET_DEAL_SHEETS: string = '[DEAL_SHEET_STORE] Set deal sheets';

  static loadDealSheets = (onComplete: OnComplete): LoadDealSheetsAction => ({
    type: DealSheetActions.LOAD_DEAL_SHEETS,
    onComplete
  });
}
