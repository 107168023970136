import { NavigateFunction } from 'react-router-dom';

import { SubCategory } from '../../interfaces/config/sub_category.interface';

import {
  AddSubCategoryAction,
  LoadSubCategoriesAction,
  LoadSubCategoryAction,
  LoadDocumentSubCategoriesAction,
  OnComplete,
  RemoveSubCategoryAction,
  UpdateSubCategoryAction
} from './sub-category.types';

export class SubCategoryActions {
  static readonly LOAD_SUB_CATEGORIES: string = '[SUB_CATEGORY_STORE] Load sub categories';
  static readonly SET_SUB_CATEGORIES: string = '[SUB_CATEGORY_STORE] Set sub categories';

  static readonly LOAD_SUB_CATEGORY: string = '[SUB_CATEGORY_STORE] Load sub category';
  static readonly SET_SUB_CATEGORY: string = '[SUB_CATEGORY_STORE] Set sub category';

  static readonly UPDATE_SUB_CATEGORY: string = '[SUB_CATEGORY_STORE] Update sub category';
  static readonly ADD_SUB_CATEGORY: string = '[SUB_CATEGORY_STORE] Add sub category';
  static readonly REMOVE_SUB_CATEGORY: string = '[SUB_CATEGORY_STORE] Remove sub category';

  static loadSubCategories = (
    onComplete: OnComplete,
    category_id?: number
  ): LoadSubCategoriesAction => ({
    type: SubCategoryActions.LOAD_SUB_CATEGORIES,
    category_id:category_id,
    onComplete
  });

  static loadDocumentSubCategories = (onComplete: OnComplete): LoadDocumentSubCategoriesAction => ({
    type: SubCategoryActions.LOAD_SUB_CATEGORIES,
    filter: 'document',
    onComplete
  });

  static loadSubCategory = (
    category_id: number | string,
    id: number | string,
    onComplete: OnComplete
  ): LoadSubCategoryAction => ({
    type: SubCategoryActions.LOAD_SUB_CATEGORY,
    category_id,
    id,
    onComplete
  });

  static addSubCategory = (
    category_id: string | number,
    payload: SubCategory,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddSubCategoryAction => ({
    type: SubCategoryActions.ADD_SUB_CATEGORY,
    category_id,
    payload,
    navigate,
    onComplete
  });

  static updateSubCategory = (
    category_id: number | string,
    id: number | string,
    payload: SubCategory,
    onComplete: OnComplete
  ): UpdateSubCategoryAction => ({
    type: SubCategoryActions.UPDATE_SUB_CATEGORY,
    category_id,
    id,
    payload,
    onComplete
  });

  static removeSubCategory = (
    category_id: number | string,
    id: number | string,
    onComplete: OnComplete
  ): RemoveSubCategoryAction => ({
    type: SubCategoryActions.REMOVE_SUB_CATEGORY,
    category_id,
    id,
    onComplete
  });
}
