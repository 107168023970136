import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { Prospect } from '../../../../interfaces/employee/prospect.interface';

import { RemoveProspectAction } from '../../../../actions/prospect/prospect.types';
import { ProspectActions } from '../../../../actions/prospect/prospect.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveProspectContainer = () => {
  const dispatch: Dispatch<RemoveProspectAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      ProspectActions.removeProspect((element as Prospect).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Prospect">
            <RemoveModal title={(element as Prospect).full_name} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveProspectContainer;
