import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { Fund } from '../../../../interfaces/fund/fund.interface';

import { RemoveFundAction } from '../../../../actions/fund/fund.types';
import { FundActions } from '../../../../actions/fund/fund.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveFundContainer = () => {
  const dispatch: Dispatch<RemoveFundAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      FundActions.removeFund((element as Fund).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Fund">
            <RemoveModal title={(element as Fund).name} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveFundContainer;
