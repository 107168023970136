import React, { createRef, useEffect, useMemo, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId } from '@neslotech/utils';

import { toBase64 } from '../../../tools/file.util';

import { Document } from '../../../interfaces/document/document.interface';
import { Category } from '../../../interfaces/config/category.interface';
import { FileData } from '../../../interfaces/config/file.interface';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import Input, { OnChangeType } from '../../input/Input';
import FormAction from '../../form/form-action/FormAction';
import DetailsCard from '../../card/DetailsCard';
import Select, { SelectItem, OnChangeType as SelectChangeType } from '../../select/Select';
import Button from '../../button/Button';

import './document-form.scss';
import { SubCategory } from '../../../interfaces/config/sub_category.interface';
import { MultiFileUpload } from '../../upload/MultiFileUpload';

import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';
import DocumentIcon from '../../document-icon/DocumentIcon';

type OnSave = (payload: Document, onComplete: () => void) => void;

interface Props {
  document?: Document;
  categories: Category[];
  onSave: OnSave;
  onRemoveDocumentFile?: (id: number, onComplete: () => void) => void;
}

export interface DocumentState extends StateForm {
  name: string;
  type: string;
  category?: string;
  customCategory?: string;
  subCategoryId?: number;
  subCategory?: string;
  categoryId?: number;
  data?: FileData[];
}

const rules: ValidationRules<DocumentState> = {
  validates: {
    name: ['isPresent'],
    type: ['isPresent'],
    categoryId: ['isPresent'] //,
    // file: ['isPresent']
  }
};

const formify = (document?: Document): DocumentState =>
  ({
    name: document?.name,
    type: document?.document_type,
    categoryId: document?.category_id,
    category: document?.category,
    subCategoryId: document?.sub_category_id,
    subCategory: document?.sub_category,
    data: (document?.data ?? []).map((document: FileData | string) => ({
      id: (document as FileData).id,
      name: (document as FileData).name,
      url: (document as FileData).data,
      date: new Date((document as FileData).date),
      uploaded_by: (document as FileData).uploaded_by
    }))
  } as DocumentState);

const serverify = (form: DocumentState): Document => ({
  name: form.name,
  document_type: form.type,
  category_id: form.categoryId,
  category: form.customCategory,
  sub_category_id: form.subCategoryId,
  data: form.data
});

const typeItems: SelectItem[] = [
  {
    label: 'Contract',
    value: 'contract'
  },
  {
    label: 'Info',
    value: 'info'
  },
  {
    label: 'Presentation',
    value: 'presentation'
  }
];

const DocumentForm = ({ document, categories, onSave, onRemoveDocumentFile }: Props) => {
  const [form, setForm] = useFormState<DocumentState>(formify(document), rules);
  const handleChange = (newState: OnChangeType | { data: FileData[] } | SelectChangeType) => {
    return setForm({ ...form, ...(newState as DocumentState) });
  };

  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const handleFileChange = async (fileList: FileList) => {
    let data = [...form.data];
    Array.from(fileList).forEach(async (file) => {
      const base64Data = await toBase64(file);
      const fileData: FileData = {
        id: generateId(),
        name: file.name,
        uploaded_by: 'you',
        date: new Date(),
        data: base64Data
      };

      data = [...data, fileData];
      handleChange({ data });
    });
  };

  useEffect(() => {
    const selectedCategory = categories.find((cat) => cat.id === Number(form.categoryId));
    if (selectedCategory && selectedCategory.sub_categories) {
      const activeSubCategories = selectedCategory.sub_categories.filter(
        (subCat) => subCat['status'] === 'active'
      );
      setSubCategories(activeSubCategories);
    } else {
      setSubCategories([]);
    }
  }, [categories, form.categoryId]);

  const fileRef = createRef<HTMLInputElement>();

  const [loading, setLoading] = useState<boolean>(false);

  const mappedCategories: SelectItem[] = useMemo(() => {
    return categories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((category: Category) => ({
        value: category.name === 'Other' ? -100 : category.id,
        label: category.name
      }));
  }, [categories]);

  const mappedSubCategories: SelectItem[] = useMemo(() => {
    return subCategories
      .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      .map<SelectItem>((subCategory: SubCategory) => ({
        label: subCategory.name,
        value: subCategory.id
      }));
  }, [subCategories]);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Document = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  const handleRemove = (id: number | string) => {
    const data = form.data.filter((file: FileData) => file.id !== id);
    handleChange({ data: data });

    if (typeof id === 'string' || !onRemoveDocumentFile) {
      return;
    }

    onRemoveDocumentFile(id as number, () => {});
  };

  return (
    <article className="document-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <Input
              name="name"
              label="Document Name"
              placeholder="Document Name"
              value={form?.name}
              error={form?.messages.name}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="type"
              label="Document Type"
              placeholder="Document Type"
              value={form?.type}
              error={form?.messages.type}
              items={typeItems}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Select
              name="categoryId"
              label="Category"
              placeholder="Select Category"
              value={form?.categoryId}
              error={form?.messages.categoryId}
              items={mappedCategories}
              onChange={handleChange}
            />
          </FormRow>
          {subCategories.length > 0 && (
            <FormRow>
              <Select
                name="subCategoryId"
                label="Subcategory"
                placeholder="Select Subcategory"
                value={form?.subCategoryId}
                error={form?.messages.subCategoryId}
                items={mappedSubCategories}
                onChange={handleChange}
              />
            </FormRow>
          )}
          {Number(form?.categoryId) === -100 && (
            <FormRow>
              <Input
                name="customCategory"
                label="Enter a custom Category"
                placeholder="Enter a custom Category"
                value={form?.customCategory}
                error={form?.messages.customCategory}
                onChange={handleChange}
              />
            </FormRow>
          )}
          <FormRow>
            <MultiFileUpload fileRef={fileRef} fileTypes="*/*" onChange={handleFileChange}>
              <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
            </MultiFileUpload>
            <br />
            {(form?.data ?? []).map((file: FileData) => (
              <UploadedFile file={file} onRemove={handleRemove} />
            ))}
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

interface FileProps {
  file: FileData;
  onRemove: (id: number | string) => void;
}

const UploadedFile = ({ file, onRemove }: FileProps) => (
  <article className="uploaded-file" onClick={() => window.open(file.url, '_blank')}>
    <section className="uploaded-file__name">
      <DocumentIcon file={file} />
      <p>{file.name}</p>
    </section>
    <small>
      Uploaded by { file.uploaded_by } on {file.date.toLocaleDateString()}
    </small>
    <RemoveIcon
      color="#003768"
      onClick={(e) => {
        e.stopPropagation();
        onRemove(file.id);
      }}
    />
  </article>
);

export default DocumentForm;
