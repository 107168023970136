import { NavigateFunction } from 'react-router-dom';

import { Employee } from '../../interfaces/employee/employee.interface';

import {
  AddEmployeeAction,
  DownloadEmployeesAction,
  LoadEmployeeAction,
  LoadEmployeesAction,
  OnComplete,
  RemoveEmployeeAction,
  RemoveEmployeeDocumentAction,
  UpdateEmployeeAction
} from './employee.types';

export class EmployeeActions {
  static readonly LOAD_EMPLOYEES: string = '[EMPLOYEE_STORE] Load employees';
  static readonly DOWNLOAD_EMPLOYEES: string = '[EMPLOYEE_STORE] Download employees';
  static readonly SET_EMPLOYEES: string = '[EMPLOYEE_STORE] Set employees';

  static readonly LOAD_EMPLOYEE: string = '[EMPLOYEE_STORE] Load employee';
  static readonly SET_EMPLOYEE: string = '[EMPLOYEE_STORE] Set employee';

  static readonly UPDATE_EMPLOYEE: string = '[EMPLOYEE_STORE] Update employee';
  static readonly ADD_EMPLOYEE: string = '[EMPLOYEE_STORE] Add employee';
  static readonly REMOVE_EMPLOYEE: string = '[EMPLOYEE_STORE] Remove employee';
  static readonly REMOVE_EMPLOYEE_DOCUMENT: string = '[EMPLOYEE_STORE] Remove employee document';

  static loadEmployees = (onComplete: OnComplete): LoadEmployeesAction => ({
    type: EmployeeActions.LOAD_EMPLOYEES,
    onComplete
  });

  static downloadEmployees = (onComplete: OnComplete): DownloadEmployeesAction => ({
    type: EmployeeActions.DOWNLOAD_EMPLOYEES,
    onComplete
  });

  static loadEmployee = (id: number | string, onComplete: OnComplete): LoadEmployeeAction => ({
    type: EmployeeActions.LOAD_EMPLOYEE,
    id,
    onComplete
  });

  static addEmployee = (
    payload: Employee,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddEmployeeAction => ({
    type: EmployeeActions.ADD_EMPLOYEE,
    payload,
    navigate,
    onComplete
  });

  static updateEmployee = (
    id: number | string,
    payload: Employee,
    onComplete: OnComplete
  ): UpdateEmployeeAction => ({
    type: EmployeeActions.UPDATE_EMPLOYEE,
    id,
    payload,
    onComplete
  });

  static removeEmployee = (id: number | string, onComplete: OnComplete): RemoveEmployeeAction => ({
    type: EmployeeActions.REMOVE_EMPLOYEE,
    id,
    onComplete
  });

  static removeEmployeeDocument = (
    id: number | string,
    documentId: number
  ): RemoveEmployeeDocumentAction => ({
    type: EmployeeActions.REMOVE_EMPLOYEE_DOCUMENT,
    id,
    documentId
  });
}
