import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddTombstoneRequest,
  getRemoveTombstoneRequest,
  getTombstoneRequest,
  getTombstonesRequest,
  getUpdateTombstoneRequest,
  getDownloadTombstonesRequest,
  RequestOptions
} from '../../tools/api';

import {
  AddTombstoneAction,
  LoadTombstoneAction,
  RemoveTombstoneAction,
  UpdateTombstoneAction
} from '../../actions/client/tombstone/tombstone.types';
import { TombstoneActions } from '../../actions/client/tombstone/tombstone.actions';
import { SystemActions } from '../../actions/system/system.actions';

export function* performLoadTombstones({ id, onComplete }: LoadTombstoneAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getTombstonesRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: TombstoneActions.SET_TOMBSTONES, tombstones: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadTombstonesRequest() {
  yield takeLatest(TombstoneActions.LOAD_TOMBSTONES, performLoadTombstones);
}

export function* performDownloadTombstones({ id, onComplete }: LoadTombstoneAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getDownloadTombstonesRequest(
      id
    ) as RequestOptions;

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: TombstoneActions.SET_TOMBSTONES, tombstones: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForDownloadTombstonesRequest() {
  yield takeLatest(TombstoneActions.DOWNLOAD_TOMBSTONES, performDownloadTombstones);
}

export function* performLoadTombstone({ id, tombstoneId, onComplete }: LoadTombstoneAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getTombstoneRequest(id, tombstoneId);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: TombstoneActions.SET_TOMBSTONE, tombstone: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadTombstoneRequest() {
  yield takeLatest(TombstoneActions.LOAD_TOMBSTONE, performLoadTombstone);
}

export function* performAddTombstone({ id, payload, onCompleteAddTombstone }: AddTombstoneAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddTombstoneRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your tombstone has been created successfully!', SNACK_SUCCESS)
    );

    yield put(TombstoneActions.loadTombstones(id, onCompleteAddTombstone(data)));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onCompleteAddTombstone(null));
  }
}

export function* watchForAddTombstoneRequest() {
  yield takeLatest(TombstoneActions.ADD_TOMBSTONE, performAddTombstone);
}

export function* performUpdateTombstone({
  id,
  tombstoneId,
  payload,
  onComplete
}: UpdateTombstoneAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateTombstoneRequest(
      id,
      tombstoneId,
      payload
    );

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('Your tombstone has been updated successfully!', SNACK_SUCCESS)
    );

    yield put(TombstoneActions.loadTombstone(id, tombstoneId, onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateTombstoneRequest() {
  yield takeLatest(TombstoneActions.UPDATE_TOMBSTONE, performUpdateTombstone);
}

export function* performRemoveTombstone({ id, tombstoneId, onComplete }: RemoveTombstoneAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveTombstoneRequest(id, tombstoneId);

    const { data, headers } = yield call(axios, endpoint, requestOptions);

    // Get the Content-Disposition header value from the response
    const contentDisposition = headers['content-disposition'];

    // Use a regular expression to extract the file name from the header value
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';

    const csvBlob = new Blob([data], { type: 'text/csv' });
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(csvBlob);
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveTombstoneRequest() {
  yield takeLatest(TombstoneActions.REMOVE_TOMBSTONE, performRemoveTombstone);
}

export default function* tombstoneSaga() {
  yield all([
    watchForLoadTombstonesRequest(),
    watchForLoadTombstoneRequest(),
    watchForUpdateTombstoneRequest(),
    watchForAddTombstoneRequest(),
    watchForRemoveTombstoneRequest(),
    watchForDownloadTombstonesRequest()
  ]);
}
