import React, { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { PerformanceReview } from '../../../../interfaces/employee/performance-review.interface';
import { Employee } from '../../../../interfaces/employee/employee.interface';

import { PerformanceReviewActions } from '../../../../actions/performance-review/performance-review.actions';
import { AddPerformanceReviewAction } from '../../../../actions/performance-review/performance-review.types';
import { EmployeeActions } from '../../../../actions/employee/employee.actions';
import { LoadEmployeesAction } from '../../../../actions/employee/employee.types';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import PageLayout from '../../../../components/layout/page/PageLayout';
import PerformanceReviewForm from '../../../../components/performance-reviews/form/PerformanceReviewForm';

const AddPerformanceReviewContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AddPerformanceReviewAction | LoadEmployeesAction> = useAppDispatch();

  const employees: Employee[] = useAppSelector(
    ({ employee_store }: AppState) => employee_store.employees
  );

  useEffect(() => {
    dispatch(EmployeeActions.loadEmployees(noOp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (payload: PerformanceReview, onComplete: () => void): void => {
    dispatch(PerformanceReviewActions.addPerformanceReview(payload, navigate, onComplete));
  };

  return (
    <PageLayout title="Add Performance Review">
      <PerformanceReviewForm employees={employees} onSave={onSave} />
    </PageLayout>
  );
};

export default AddPerformanceReviewContainer;
