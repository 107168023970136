import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Irp5 } from '../../interfaces/employee/irp5.interface';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getIrp5sEndpoint = (): string => `${API_HOST}/irp5s`;
export const getIrp5sRequest = (): RequestOptions => [
  getIrp5sEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddIrp5Request = (payload: Irp5): RequestOptions => [
  getIrp5sEndpoint(),
  getHttpPostOptions({ irp5: payload }, getAuthHeaders())
];

const getIrp5Endpoint = (id: number | string): string => `${API_HOST}/irp5s/${id}`;
export const getIrp5Request = (id: number | string): RequestOptions => [
  getIrp5Endpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateIrp5Request = (id: number | string, payload: Irp5): RequestOptions => [
  getIrp5Endpoint(id),
  getHttpPutOptions({ irp5: payload }, getAuthHeaders())
];

export const getRemoveIrp5Request = (id: string | number): RequestOptions => [
  getIrp5Endpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
