import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Tombstone } from '../../../../../../interfaces/client/tombstone.interface';

import { useAppDispatch, useRemoval } from '../../../../../../hooks';

import { TombstoneActions } from '../../../../../../actions/client/tombstone/tombstone.actions';
import { RemoveTombstoneAction } from '../../../../../../actions/client/tombstone/tombstone.types';

import { Modal } from '../../../../../../components/modal/Modal';
import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../../../components/modal/remove/RemoveModal';

const RemoveTombstoneContainer = () => {
  const dispatch: Dispatch<RemoveTombstoneAction> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const { showModal, setShowModal, element } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      TombstoneActions.removeTombstone((element as Tombstone).client_id, element.id, () => {
        onComplete();
        onClose();
        navigate(`/core/clients/${(element as Tombstone).client_id}/tombstones`);
      })
    );
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Tombstone">
            <RemoveModal title="this tombstone" onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveTombstoneContainer;
