import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddClientTypeRequest,
  getClientTypeRequest,
  getClientTypesRequest,
  getRemoveClientTypeRequest,
  getUpdateClientTypeRequest,
  RequestOptions
} from '../tools/api';

import {
  AddClientTypeAction,
  LoadClientTypeAction,
  LoadClientTypesAction,
  UpdateClientTypeAction
} from '../actions/client-type/client-type.types';
import { ClientTypeActions } from '../actions/client-type/client-type.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';

export function* performLoadClientTypes({ onComplete }: LoadClientTypesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientTypesRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ClientTypeActions.SET_CLIENT_TYPES, clientTypes: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadClientTypesRequest() {
  yield takeLatest(ClientTypeActions.LOAD_CLIENT_TYPES, performLoadClientTypes);
}

export function* performLoadClientType({ id, onComplete }: LoadClientTypeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getClientTypeRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: ClientTypeActions.SET_CLIENT_TYPE, clientType: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadClientTypeRequest() {
  yield takeLatest(ClientTypeActions.LOAD_CLIENT_TYPE, performLoadClientType);
}

export function* performUpdateClientType({ id, payload, onComplete }: UpdateClientTypeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateClientTypeRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: ClientTypeActions.SET_CLIENT_TYPE, clientType: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateClientTypeRequest() {
  yield takeLatest(ClientTypeActions.UPDATE_CLIENT_TYPE, performUpdateClientType);
}

export function* performAddClientType({ payload, navigate, onComplete }: AddClientTypeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddClientTypeRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new client type has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/admin/client-types');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddClientTypeRequest() {
  yield takeLatest(ClientTypeActions.ADD_CLIENT_TYPE, performAddClientType);
}

export function* performRemoveClientType({ id, onComplete }: AddClientTypeAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveClientTypeRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The client type has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(ClientTypeActions.loadClientTypes(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveClientTypeRequest() {
  yield takeLatest(ClientTypeActions.REMOVE_CLIENT_TYPE, performRemoveClientType);
}

export default function* clientTypeSaga() {
  yield all([
    watchForLoadClientTypesRequest(),
    watchForLoadClientTypeRequest(),
    watchForUpdateClientTypeRequest(),
    watchForAddClientTypeRequest(),
    watchForRemoveClientTypeRequest()
  ]);
}
