import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddDocumentRequest,
  getRemoveDocumentRequest,
  getDocumentRequest,
  getDocumentsRequest,
  getUpdateDocumentRequest,
  RequestOptions
} from '../tools/api';

import {
  AddDocumentAction,
  LoadDocumentAction,
  LoadDocumentsAction,
  UpdateDocumentAction
} from '../actions/document/document.types';
import { DocumentActions } from '../actions/document/document.actions';
import { SystemActions } from '../actions/system/system.actions';

import { ResponseError } from '../types/response-error.interface';
import { getRemoveDocumentFileRequest } from '../tools/api/document.endpoints';

export function* performLoadDocuments({ onComplete }: LoadDocumentsAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getDocumentsRequest();

    // make the request, set the response in redux
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: DocumentActions.SET_DOCUMENTS, documents: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadDocumentsRequest() {
  yield takeLatest(DocumentActions.LOAD_DOCUMENTS, performLoadDocuments);
}

export function* performLoadDocument({ id, onComplete }: LoadDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getDocumentRequest(id);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: DocumentActions.SET_DOCUMENT, document: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadDocumentRequest() {
  yield takeLatest(DocumentActions.LOAD_DOCUMENT, performLoadDocument);
}

export function* performUpdateDocument({ id, payload, onComplete }: UpdateDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getUpdateDocumentRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice(`${data.name} has been updated successfully!`, SNACK_SUCCESS)
    );

    yield put({ type: DocumentActions.SET_DOCUMENT, document: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateDocumentRequest() {
  yield takeLatest(DocumentActions.UPDATE_DOCUMENT, performUpdateDocument);
}

export function* performAddDocument({ payload, navigate, onComplete }: AddDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAddDocumentRequest(payload);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('A new document has been created successfully!', SNACK_SUCCESS)
    );

    yield navigate('/admin/documents');
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddDocumentRequest() {
  yield takeLatest(DocumentActions.ADD_DOCUMENT, performAddDocument);
}

export function* performRemoveDocument({ id, onComplete }: AddDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveDocumentRequest(id);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The document has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(DocumentActions.loadDocuments(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* performRemoveDocumentFile({ id, fileId, onComplete }: AddDocumentAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getRemoveDocumentFileRequest(id, fileId);

    // make the request, no need to check the response
    yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put(
      SystemActions.addNotice('The document file has been removed successfully!', SNACK_SUCCESS)
    );

    yield put(DocumentActions.loadDocuments(onComplete));
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = ((error as AxiosError).response as ResponseError).data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRemoveDocumentRequest() {
  yield takeLatest(DocumentActions.REMOVE_DOCUMENT, performRemoveDocument);
}

export function* watchForRemoveDocumentFileRequest() {
  yield takeLatest(DocumentActions.REMOVE_DOCUMENT_FILE, performRemoveDocumentFile);
}

export default function* documentSaga() {
  yield all([
    watchForLoadDocumentsRequest(),
    watchForLoadDocumentRequest(),
    watchForUpdateDocumentRequest(),
    watchForAddDocumentRequest(),
    watchForRemoveDocumentRequest(),
    watchForRemoveDocumentFileRequest()
  ]);
}
