import React from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { Contact } from '../../../../../../interfaces/client/contact.interface';
import { Designation } from '../../../../../../interfaces/config/designation.interface';
import { AppState } from '../../../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks';

import { AddContactAction } from '../../../../../../actions/contact/contact.types';
import { ClientContactActions } from '../../../../../../actions/client/contact/contact.actions';
import { ContactActions } from '../../../../../../actions/contact/contact.actions';

import { ModalLayout } from '../../../../../../components/modal/ModalLayout';
import ContactForm from '../../../../../../components/clients/details/contacts/form/ContactForm';

interface Props {
  setShow: (value: boolean) => void;
}

const AddContactContainer = ({ setShow }: Props) => {
  const dispatch: Dispatch<AddContactAction> = useAppDispatch();
  const { id } = useParams();

  const designations: Designation[] = useAppSelector(
    ({ designation_store }: AppState) => designation_store.designations
  );

  const onClose = () => setShow(false);

  const handleSave = (payload: Contact, onComplete: () => void) => {
    const isClientContact = window.location.pathname.includes('core/clients');
    if (isClientContact && id) {
      dispatch(ClientContactActions.addContact(id, payload, onClose, onComplete));
    } else {
      dispatch(ContactActions.addContact(payload, onClose, onComplete));
    }
  };

  return (
    <ModalLayout onClose={onClose} title="Add Contact" large>
      <ContactForm designations={designations} onSave={handleSave} />
    </ModalLayout>
  );
};

export default AddContactContainer;
