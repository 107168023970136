import React from 'react';

import { Event } from '../../../interfaces/event/event.interface';

import EventCard from '../card/EventCard';

import './event-list.scss';

interface Props {
  events: Event[];
  loading: boolean;
}

const EventList = ({ events }: Props) => (
  <article className="event-list">
    {events.map((event: Event) => (
      <EventCard key={event.id} event={event} />
    ))}
  </article>
);

export default EventList;
