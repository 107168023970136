import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { User } from '../interfaces/user/user.interface';

import { UserActions } from '../actions/user/user.actions';

export interface UserState extends State {
  users: User[];
  user?: User;
}

export const initialState: UserState = {
  users: []
};

export const userReducer = (state: UserState = initialState, action: AnyAction) => {
  if (action.type === UserActions.SET_USERS) {
    const { users } = action;
    return {
      ...state,
      users
    };
  } else if (action.type === UserActions.SET_USER) {
    const { user } = action;
    return {
      ...state,
      user
    };
  }

  return state;
};
