import React from 'react';

import { noOp } from '@neslotech/utils';

export interface SidebarProps {
  openedItem: string;
  setOpenedItem: (item: string) => void;
}

export const SidebarContext = React.createContext<SidebarProps>({
  openedItem: '',
  setOpenedItem: noOp
});
