import { Contact } from '../../interfaces/client/contact.interface';

import {
  AddContactAction,
  AddMandateToContactAction,
  LoadContactAction,
  LoadContactsAction,
  OnComplete,
  OnCompleteWithId,
  OnSuccess,
  RemoveContactAction,
  UpdateContactAction
} from './contact.types';
import { Client } from '../../interfaces/client/client.interface';

export class ContactActions {
  static readonly LOAD_CONTACTS: string = '[CONTACT_STORE] Load contacts';
  static readonly SET_CONTACTS: string = '[CONTACT_STORE] Set contacts';

  static readonly LOAD_CONTACT: string = '[CONTACT_STORE] Load contact';
  static readonly SET_CONTACT: string = '[CONTACT_STORE] Set contact';

  static readonly UPDATE_CONTACT: string = '[CONTACT_STORE] Update contact';
  static readonly ADD_CONTACT: string = '[CONTACT_STORE] Add contact';
  static readonly REMOVE_CONTACT: string = '[CONTACT_STORE] Remove contact';

  static readonly ADD_MANDATE: string = '[CONTACT_STORE] Add mandate to contact';

  static loadContacts = (filter: boolean, onComplete: OnComplete): LoadContactsAction => ({
    type: ContactActions.LOAD_CONTACTS,
    filter,
    onComplete
  });

  static loadContact = (id: number | string, onComplete: OnComplete): LoadContactAction => ({
    type: ContactActions.LOAD_CONTACT,
    id,
    onComplete
  });

  static addContact = (
    payload: Contact,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): AddContactAction => ({
    type: ContactActions.ADD_CONTACT,
    payload,
    onSuccess,
    onComplete
  });

  static updateContact = (
    id: number | string,
    payload: Contact,
    onSuccess: OnSuccess,
    onComplete: OnComplete
  ): UpdateContactAction => ({
    type: ContactActions.UPDATE_CONTACT,
    id,
    payload,
    onSuccess,
    onComplete
  });

  static removeContact = (id: number | string, onComplete: OnComplete): RemoveContactAction => ({
    type: ContactActions.REMOVE_CONTACT,
    id,
    onComplete
  });

  static addMandateToContact = (
    id: number | string,
    payload: Client,
    onComplete: OnCompleteWithId
  ): AddMandateToContactAction => ({
    type: ContactActions.ADD_MANDATE,
    id,
    payload,
    onComplete
  });
}
