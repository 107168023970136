import React, { useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';

import { Note } from '../../../../../interfaces/client/note.interface';

import Form from '../../../../form/Form';
import FormRow from '../../../../form/form-row/FormRow';
import TextArea, { OnChangeType } from '../../../../text-area/TextArea';
import FormAction from '../../../../form/form-action/FormAction';

import './note-form.scss';

interface NoteState extends StateForm {
  note: string;
}

const rules: ValidationRules<NoteState> = {
  validates: {
    note: ['isPresent']
  }
};

const serverify = (form: NoteState): Note => ({
  note: form.note
});

type OnSave = (payload: Note, onComplete: () => void) => void;
interface Props {
  onSave: OnSave;
}

const NoteForm = ({ onSave }: Props) => {
  const [form, setForm] = useFormState<NoteState>(undefined, rules);
  const handleChange = (newState: OnChangeType) => setForm({ ...form, ...(newState as NoteState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = () => {
    const payload: Note = serverify(form);
    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="note-form">
      <Form>
        <FormRow>
          <TextArea
            name="note"
            label="Enter your note"
            placeholder="Enter your note"
            onChange={handleChange}
          />
        </FormRow>
        <FormAction hollow label="Save Note" loading={loading} onClick={handleSave} />
      </Form>
    </article>
  );
};

export default NoteForm;
