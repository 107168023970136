import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST, RequestOptions } from './index';

const getPrecedentsEndpoint = (): string => `${API_HOST}/precedents`;
export const getPrecedentsRequest = (): RequestOptions => [
  getPrecedentsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];
