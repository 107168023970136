import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { isEmpty, noOp } from '@neslotech/utils';

import { AppState } from '../../../../types/state.interface';
import { Contact } from '../../../../interfaces/client/contact.interface';
import { Filter } from '../../../../types/filter.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import RemovalProvider from '../../../../providers/Removal.provider';

import { ContactActions } from '../../../../actions/contact/contact.actions';
import { LoadContactsAction } from '../../../../actions/contact/contact.types';
import { DesignationActions } from '../../../../actions/designation/designation.actions';
import { LoadDesignationsAction } from '../../../../actions/designation/designation.types';

import { ReactComponent as ChevronRightIcon } from '../../../../icons/chevron-right-icon.svg';

import { Loader } from '../../../../components/loader/Loader';
import PageLayout from '../../../../components/layout/page/PageLayout';
import ContactList from '../../../../components/contacts/list/ContactList';
import Button from '../../../../components/button/Button';
import { Modal } from '../../../../components/modal/Modal';
import FilterLayout from '../../../../components/layout/filter/FilterLayout';
import { DropdownMenuItem } from '../../../../components/dropdown/DropdownMenu';
import { RadioGroup } from '../../../../components/radio/RadioGroup';

import AddContactContainer from '../../clients/details/contacts/add/AddContact.container';
import EditContactContainer from '../../clients/details/contacts/edit/EditContact.container';
import RemoveContactContainer from '../../clients/details/contacts/remove/RemoveContact.container';

type Mode = 'add' | 'edit';

const menuItems = (
  onChange: (newFilters: { [key: string]: number | string | boolean }) => void,
  filters: Filter
): DropdownMenuItem[] => [
  {
    text: 'Classification',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        name="classification"
        onChange={onChange}
        value={filters?.classification as string}
        items={[
          { label: 'Banker', value: 'banker' },
          { label: 'Infrastructure', value: 'infrastructure' },
          { label: 'Legal Firm', value: 'legal_firm' },
          { label: 'Private Equity Company', value: 'private_equity_company' },
          { label: 'Service Provider', value: 'service_provider' }
        ]}
      />
    )
  }
];

const ContactsContainer = () => {
  const dispatch: Dispatch<LoadContactsAction | LoadDesignationsAction> = useAppDispatch();
  const [searchParams] = useSearchParams();

  const contacts: Contact[] = useAppSelector(
    ({ contact_store }: AppState) => contact_store.contacts
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [contact, setContact] = useState<Contact>();
  const [mode, setMode] = useState<Mode>();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(DesignationActions.loadDesignations(noOp));
    dispatch(ContactActions.loadContacts(true, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(contacts) && searchParams.has('contactId')) {
      const contactId: string = searchParams.get('contactId');
      const matchingContact = contacts.find((contact: Contact) => contact.id === Number(contactId));

      handleOnEdit(matchingContact);
    }
  }, [contacts, searchParams]);

  const handleOnAdd = () => {
    setMode('add');
    setShowModal(true);
  };

  const handleOnEdit = (contact: Contact) => {
    setContact(contact);
    setMode('edit');
    setShowModal(true);
  };

  return (
    <PageLayout title="Other Contacts">
      <FilterLayout
        menuItems={menuItems}
        actions={<Button label="Add Contact" onClick={handleOnAdd} />}
      >
        <RemovalProvider>
          {loading && <Loader filled />}
          {!loading && <ContactList onEdit={handleOnEdit} contacts={contacts} />}
          <RemoveContactContainer />
          {showModal && (
            <Modal>
              {mode === 'add' && <AddContactContainer setShow={setShowModal} />}
              {mode === 'edit' && <EditContactContainer contact={contact} setShow={setShowModal} />}
            </Modal>
          )}
        </RemovalProvider>
      </FilterLayout>
    </PageLayout>
  );
};

export default ContactsContainer;
