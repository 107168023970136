import React, { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ClientType } from '../../../interfaces/config/client-type.interface';
import { Modifier, Table, TableData, TableHeader } from '../../table/Table';
import RemoveElement from '../../table/RemoveElement';

import './client-type-list.scss';

const headers: TableHeader[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
];

const columnModifiers: Modifier = {
  status: {
    capitalize: true
  }
};

interface RowData extends TableData {
  name: string;
  description: string;
  status: string;
}

interface Props {
  clientTypes: ClientType[];
  loading: boolean;
}

const ClientTypeList = ({ clientTypes, loading }: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const rowData = useMemo(() => {
    return clientTypes.map(
      (clientType: ClientType) =>
        ({
          name: clientType.name,
          description: clientType.description,
          status: clientType.status,
          actions: <RemoveElement element={clientType} />,
          onClick: () => navigate(`${clientType.id}`)
        } as RowData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTypes]);

  return (
    <article className="client-type-list">
      <Table
        loading={loading}
        cols={headers}
        emptyTitle="No client types could be found!"
        rowData={rowData}
        columnModifiers={columnModifiers}
      />
    </article>
  );
};

export default ClientTypeList;
