import React from 'react';
import { RouteObject } from 'react-router-dom';

import DashboardContainer from '../../containers/dashboard/Dashboard.container';

import DashboardCrumb from '../../crumbs/dashboard/Dashboard.crumb';

import ProtectedRoute from '../ProtectedRoute';

const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <ProtectedRoute>
        <DashboardContainer />
      </ProtectedRoute>
    ),
    handle: {
      crumb: () => <DashboardCrumb />
    }
  }
];

export default dashboardRoutes;
