import { SelectItem } from '../components/select/Select';
import { ConfigItem } from '../interfaces/config/config-item.interface';

export const statusItems: SelectItem[] = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  }
];

export const mapConfigItems = (items: ConfigItem[], editMode: boolean): SelectItem[] => {
  if (!editMode) {
    return [];
  }

  return items
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map<SelectItem>((item: ConfigItem) => ({
      label: item.name,
      value: item.name === 'Other' ? -100 : item.id
    }));
};
