import React, { createRef, useState } from 'react';

import { Form as StateForm, useFormState, ValidationRules } from '@neslotech/hooks';
import { generateId } from '@neslotech/utils';

import { toBase64 } from '../../../tools/file.util';

import { FileData } from '../../../interfaces/config/file.interface';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/remove-icon.svg';

import Form from '../../form/Form';
import FormRow from '../../form/form-row/FormRow';
import FormAction from '../../form/form-action/FormAction';
import DetailsCard from '../../card/DetailsCard';
import Button from '../../button/Button';

import { MultiFileUpload } from '../../upload/MultiFileUpload';
import DocumentIcon from '../../document-icon/DocumentIcon';
import { Prospect } from '../../../interfaces/employee/prospect.interface';

type OnSave = (payload: Prospect, onComplete: () => void) => void;

interface Props {
  prospect: Prospect;
  type: string;
  documents?: FileData[] | string[];
  onSave: OnSave;
  onRemoveDocument?: (id: number) => void;
}

export interface ProspectDocumentState extends StateForm {
  files?: FileData[];
}

const rules: ValidationRules<ProspectDocumentState> = {
  validates: {}
};

const formify = (documents?: FileData[]): ProspectDocumentState =>
  ({
    files: (documents ?? []).map((document: FileData | string) => ({
      id: (document as FileData).id,
      name: (document as FileData).name,
      url: (document as FileData).url,
      date: new Date((document as FileData).date),
      uploaded_by: (document as FileData).uploaded_by
    }))
  } as ProspectDocumentState);

const serverify = (prospect: Prospect, form: ProspectDocumentState, key: string) => ({
  ...prospect,
  [key]: form.files
});

const ProspectDocumentForm = ({ prospect, type, documents, onSave, onRemoveDocument }: Props) => {
  const [form, setForm] = useFormState<ProspectDocumentState>(
    formify(documents as FileData[]),
    rules
  );
  const handleChange = (newState: { files: FileData[] }) =>
    setForm({ ...form, ...(newState as ProspectDocumentState) });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const payload: Prospect = serverify(prospect, form, type);
    onSave(payload, () => setLoading(false));
  };

  const handleFileChange = async (fileList: FileList) => {
    let files = [...form.files];
    Array.from(fileList).forEach(async (file) => {
      const base64Data = await toBase64(file);
      const fileData: FileData = {
        id: generateId(),
        name: file.name,
        uploaded_by: 'you',
        date: new Date(),
        data: base64Data
      };

      files = [...files, fileData];
      handleChange({ files });
    });
  };

  const fileRef = createRef<HTMLInputElement>();
  const handleRemove = (id: number | string) => {
    const files = form.files.filter((file: FileData) => file.id !== id);
    handleChange({ files });
    if (typeof id === 'string' || !onRemoveDocument) {
      return;
    }

    onRemoveDocument(id as number);
  };

  return (
    <article className="prospect-form">
      <DetailsCard>
        <Form>
          <FormRow>
            <MultiFileUpload
              fileRef={fileRef}
              fileTypes="application/pdf"
              onChange={handleFileChange}
            >
              <Button label="Upload" hollow onClick={() => fileRef.current.click()} />
            </MultiFileUpload>
            {(form?.files ?? []).map((file: FileData) => (
              <UploadedFile file={file} onRemove={handleRemove} />
            ))}
          </FormRow>
          <FormAction
            loading={loading}
            label="Save Changes"
            onClick={handleSubmit}
            disabled={!form?.valid}
          />
        </Form>
      </DetailsCard>
    </article>
  );
};

interface FileProps {
  file: FileData;
  onRemove: (id: number | string) => void;
}

const UploadedFile = ({ file, onRemove }: FileProps) => (
  <article
    style={{ marginTop: 10 }}
    className="uploaded-file"
    onClick={() => window.open(file.url, '_blank')}
  >
    <section className="uploaded-file__name">
      <DocumentIcon file={file} />
      <p>{file.name}</p>
    </section>
    <section style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <small>
        Uploaded by {file.uploaded_by ?? 'you'} on {file.date.toLocaleDateString()}
      </small>
      <RemoveIcon
        color="#003768"
        onClick={(e) => {
          e.stopPropagation();

          onRemove(file.id);
        }}
      />
    </section>
  </article>
);

export default ProspectDocumentForm;
