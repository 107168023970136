import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';

import { User } from '../../../../interfaces/user/user.interface';

import { RemoveUserAction } from '../../../../actions/user/user.types';
import { UserActions } from '../../../../actions/user/user.actions';

import { useAppDispatch, useRemoval } from '../../../../hooks';

import { Modal } from '../../../../components/modal/Modal';
import { ModalLayout } from '../../../../components/modal/ModalLayout';
import RemoveModal from '../../../../components/modal/remove/RemoveModal';

const RemoveUserContainer = () => {
  const dispatch: Dispatch<RemoveUserAction> = useAppDispatch();

  const { element, showModal, setShowModal } = useRemoval();

  const onClose = () => setShowModal(false);

  const handleConfirm = (onComplete: () => void) => {
    dispatch(
      UserActions.removeUser((element as User).id as number, () => {
        onComplete();
        onClose();
      })
    );
  };

  const title = showModal && (element as User).status === 'active' ? 'Deactivate User' : 'Activate User';

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title={title}>
            <RemoveModal title={(element as User).full_name} status={(element as User).status} onConfirm={handleConfirm} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveUserContainer;
