import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { Tombstone } from '../../../interfaces/client/tombstone.interface';

import { getAuthHeaders } from '../../auth.util';

import { API_HOST, RequestOptions } from '../index';

const getClientEndpoint = (id: number | string): string => `${API_HOST}/clients/${id}`;
const getTombstonesEndpoint = (id: number | string): string =>
  `${getClientEndpoint(id)}/tombstones`;
export const getTombstonesRequest = (id: number | string): RequestOptions => [
  getTombstonesEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getTombstonesCsvEndpoint = (id: number | string): string =>
  `${getTombstonesEndpoint(id)}.csv`;
export const getDownloadTombstonesRequest = (id: number | string) => [
  getTombstonesCsvEndpoint(id),
  { ...getHttpGetOptions({ ...getAuthHeaders(), Accept: 'text/csv' }), responseType: 'blob' }
];

const getTombstoneEndpoint = (id: number | string, tombstoneId: number | string): string =>
  `${getClientEndpoint(id)}/tombstones/${tombstoneId}`;
export const getTombstoneRequest = (
  id: number | string,
  tombstoneId: number | string
): RequestOptions => [getTombstoneEndpoint(id, tombstoneId), getHttpGetOptions(getAuthHeaders())];

export const getUpdateTombstoneRequest = (
  id: number | string,
  tombstoneId: number | string,
  payload: Tombstone
): RequestOptions => [
  getTombstoneEndpoint(id, tombstoneId),
  getHttpPutOptions({ tombstone: payload }, getAuthHeaders())
];

export const getAddTombstoneRequest = (id: number | string, payload: Tombstone): RequestOptions => [
  getTombstonesEndpoint(id),
  getHttpPostOptions({ tombstone: payload }, getAuthHeaders())
];

export const getRemoveTombstoneRequest = (
  id: number | string,
  tombstoneId: number | string
): RequestOptions => [
  getTombstoneEndpoint(id, tombstoneId),
  getHttpDeleteOptions(getAuthHeaders())
];
