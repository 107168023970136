import React from 'react';
import { RouteObject } from 'react-router-dom';

import DesignationsContainer from '../../containers/admin/designations/list/Designations.container';
import AddDesignationContainer from '../../containers/admin/designations/add/AddDesignation.container';
import DesignationContainer from '../../containers/admin/designations/details/Designation.container';

import DesignationsCrumb from '../../crumbs/admin/designations/Designations.crumb';
import AddDesignationCrumb from '../../crumbs/admin/designations/AddDesignation.crumb';
import DesignationCrumb from '../../crumbs/admin/designations/Designation.crumb';

import AdminRoute from '../AdminRoute';

const designationRoutes: RouteObject[] = [
  {
    path: 'designations',
    handle: {
      crumb: () => <DesignationsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <AdminRoute>
            <DesignationsContainer />
          </AdminRoute>
        )
      },
      {
        path: 'add',
        element: (
          <AdminRoute>
            <AddDesignationContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <AddDesignationCrumb />
        }
      },
      {
        path: ':id',
        element: (
          <AdminRoute>
            <DesignationContainer />
          </AdminRoute>
        ),
        handle: {
          crumb: () => <DesignationCrumb />
        }
      }
    ]
  }
];

export default designationRoutes;
