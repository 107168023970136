import axios, { AxiosError, AxiosStatic } from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getAllMandatesRequest, RequestOptions } from '../tools/api';

import { LoadMandatesAction } from '../actions/mandate/mandate.types';
import { MandateActions } from '../actions/mandate/mandate.actions';
import { SystemActions } from '../actions/system/system.actions';

export function* performLoadMandates({ onComplete }: LoadMandatesAction) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions]: RequestOptions = getAllMandatesRequest();

    // make the request, no need to check the response
    const { data } = yield call<AxiosStatic>(axios, endpoint, requestOptions);

    yield put({ type: MandateActions.SET_MANDATES, mandates: data });
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    const message = error.response.data.message;
    yield put(SystemActions.addNotice(message, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadMandatesRequest() {
  yield takeLatest(MandateActions.LOAD_MANDATES, performLoadMandates);
}

export default function* mandateSaga() {
  yield all([watchForLoadMandatesRequest()]);
}
