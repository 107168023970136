import { AnyAction } from '@reduxjs/toolkit';

import { State } from '../types/state.interface';

import { PerformanceReview } from '../interfaces/employee/performance-review.interface';

import { PerformanceReviewActions } from '../actions/performance-review/performance-review.actions';

export interface PerformanceReviewState extends State {
  performanceReviews: PerformanceReview[];
  performanceReview?: PerformanceReview;
}

export const initialState: PerformanceReviewState = {
  performanceReviews: []
};

export const performanceReviewReducer = (
  state: PerformanceReviewState = initialState,
  action: AnyAction
) => {
  if (action.type === PerformanceReviewActions.SET_PERFORMANCE_REVIEWS) {
    const { performanceReviews } = action;
    return {
      ...state,
      performanceReviews
    };
  } else if (action.type === PerformanceReviewActions.SET_PERFORMANCE_REVIEW) {
    const { performanceReview } = action;
    return {
      ...state,
      performanceReview
    };
  }

  return state;
};
