import React, { useEffect, useState } from 'react';

import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';
import { filterElement } from '../../../tools/filter.util';

import { Mandate } from '../../../interfaces/client/mandate.interface';

import MandateCard from '../card/MandateCard';
import { Loader } from '../../loader/Loader';

import './mandate-list.scss';
import AlphabetLayout from '../../layout/filter/AlphabetLayout';

interface Props {
  mandates: Mandate[];
  loading: boolean;
}

const formify = (mandates: Mandate[], filters: Filter): Mandate[] =>
  mandates.filter((mandate: Mandate) =>
    // @ts-ignore
    filterElement(mandate, filters)
  );

const MandateList = ({ mandates, loading }: Props) => {
  const { filters, setFilterDefaults } = useFilters();
  const [sortedMandates, setSortedMandates] = useState(mandates);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    setFilterDefaults({ status: '', sector_id: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLetter) {
      mandates = formify(mandates, filters).filter(mandate => mandate.name[0].toUpperCase() === selectedLetter);
    }

    setSortedMandates(mandates);
  }, [mandates, filters, selectedLetter]);

  return (
    <>
      <AlphabetLayout onLetterClick={setSelectedLetter} selectedLetter={selectedLetter} />
      <article className="mandate-list">
        {loading && <Loader filled />}
        {sortedMandates.map((mandate: Mandate) => (
          <MandateCard key={mandate.id} mandate={mandate} />
        ))}
      </article>
    </>
  );
};

export default MandateList;
