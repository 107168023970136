import { NavigateFunction } from 'react-router-dom';

import { Investment } from '../../interfaces/investment/investment.interface';

import {
  AddInvestmentAction,
  LoadInvestmentAction,
  LoadInvestmentsAction,
  OnComplete,
  RemoveInvestmentAction,
  UpdateInvestmentAction
} from './investment.types';

export class InvestmentActions {
  static readonly LOAD_INVESTMENTS: string = '[INVESTMENT_STORE] Load investments';
  static readonly SET_INVESTMENTS: string = '[INVESTMENT_STORE] Set investments';

  static readonly LOAD_INVESTMENT: string = '[INVESTMENT_STORE] Load investment';
  static readonly SET_INVESTMENT: string = '[INVESTMENT_STORE] Set investment';

  static readonly UPDATE_INVESTMENT: string = '[INVESTMENT_STORE] Update investment';
  static readonly ADD_INVESTMENT: string = '[INVESTMENT_STORE] Add investment';
  static readonly REMOVE_INVESTMENT: string = '[INVESTMENT_STORE] Remove investment';

  static loadInvestments = (
    fundId: number | string,
    onComplete: OnComplete
  ): LoadInvestmentsAction => ({
    type: InvestmentActions.LOAD_INVESTMENTS,
    fundId,
    onComplete
  });

  static loadInvestment = (
    fundId: number | string,
    id: number | string,
    onComplete: OnComplete
  ): LoadInvestmentAction => ({
    type: InvestmentActions.LOAD_INVESTMENT,
    fundId,
    id,
    onComplete
  });

  static addInvestment = (
    fundId: number | string,
    payload: Investment,
    navigate: NavigateFunction,
    onComplete: OnComplete
  ): AddInvestmentAction => ({
    type: InvestmentActions.ADD_INVESTMENT,
    fundId,
    payload,
    navigate,
    onComplete
  });

  static updateInvestment = (
    fundId: number | string,
    id: number | string,
    payload: Investment,
    onComplete: OnComplete
  ): UpdateInvestmentAction => ({
    type: InvestmentActions.UPDATE_INVESTMENT,
    fundId,
    id,
    payload,
    onComplete
  });

  static removeInvestment = (
    fundId: number | string,
    id: number | string,
    onComplete: OnComplete
  ): RemoveInvestmentAction => ({
    type: InvestmentActions.REMOVE_INVESTMENT,
    fundId,
    id,
    onComplete
  });
}
