import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector } from '../../../../hooks';

import { Employeerole } from '../../../../interfaces/employeerole/employeerole.interface';

import { LoadEmployeerolesAction } from '../../../../actions/employeerole/employeerole.types';
import { EmployeeroleActions } from '../../../../actions/employeerole/employeerole.actions';

import PageLayout from '../../../../components/layout/page/PageLayout';
import { Loader } from '../../../../components/loader/Loader';
import EmployeeroleForm from '../../../../components/employeeroles/form/EmployeeroleForm';

const EmployeeroleContainer = () => {
  const { id } = useParams<string>();

  const dispatch: Dispatch<LoadEmployeerolesAction> = useAppDispatch();
  const employeerole: Employeerole = useAppSelector(({ employeerole_store }: AppState) => employeerole_store.employeerole) as Employeerole;

  const [loading, setLoading] = useState<boolean>(true);

  const onSave = (payload: Employeerole, onComplete: () => void): void => {
    dispatch(EmployeeroleActions.updateEmployeerole(id as string, payload, onComplete));
  };

  useEffect(() => {
    dispatch(EmployeeroleActions.loadEmployeerole(id as string, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doneLoading = useMemo(() => {
    return !loading && employeerole?.id === Number(id);
  }, [id, loading, employeerole?.id]);

  return (
    <PageLayout title={`Employee Role Details - ${doneLoading ? employeerole?.employeerole : ''}`}>
      {!doneLoading && <Loader filled />}
      {doneLoading && <EmployeeroleForm Employeerole={employeerole} onSave={onSave} />}
    </PageLayout>
  );
};

export default EmployeeroleContainer;
