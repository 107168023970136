import React from 'react';
import { RouteObject } from 'react-router-dom';

import ContactsContainer from '../../containers/core/contacts/list/Contacts.container';

import ContactsCrumb from '../../crumbs/core/contacts/Contacts.crumb';

import ProtectedRoute from '../ProtectedRoute';

const contactRoutes: RouteObject[] = [
  {
    path: 'contacts',
    handle: {
      crumb: () => <ContactsCrumb />
    },
    children: [
      {
        path: '',
        index: true,
        element: (
          <ProtectedRoute>
            <ContactsContainer />
          </ProtectedRoute>
        )
      }
    ]
  }
];

export default contactRoutes;
