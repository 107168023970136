import React, { ReactNode, useMemo } from 'react';

import { noOp } from '@neslotech/utils';

import { Filter } from '../../../types/filter.interface';

import { useFilters } from '../../../hooks/useFilters';
import FilterProvider from '../../../providers/Filter.provider';

import { FilterDropdown } from '../../dropdown/FilterDropdown';
import { ClearFilters } from '../../dropdown/filter/ClearFilters';
import { DropdownMenuItem } from '../../dropdown/DropdownMenu';

import './filter-layout.scss';
import Select, { OnChangeType } from '../../select/Select';

export type FilterItems = (
  onChange: (newFilters: { [key: string]: number | string | boolean | Date }) => void,
  filters: Filter
) => DropdownMenuItem[];

interface SectionProps {
  actions: ReactNode | ReactNode[];
  menuItems: FilterItems;
  sortable?: boolean;
  hideButton?: boolean;
}

const FiltersSection = ({ actions, menuItems, sortable, hideButton }: SectionProps) => {
  const { setFilters, filtering, onClearFilters, filters, getFilter, sort, setSort } = useFilters();

  const filterLabel = useMemo(() => {
    return Object.entries(filters ?? {})
      .filter(([_, value]) => !!value)
      .map(([key, value]) => getFilter(key, value))
      .join(', ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <header className="filter-layout__header">
      <section className="filter-layout__filters">
        {sortable && (
          <Select
            name="sort"
            placeholder="Sort"
            value={sort}
            items={[
              { label: 'Sort Ascending (Z - A)', value: 'ascending' },
              { label: 'Sort Descending (A - Z)', value: 'descending' }
            ]}
            onChange={(newSort: OnChangeType) => setSort((newSort as any).sort)}
          />
        )}

        {menuItems && (
          <FilterDropdown
            hideButton={hideButton}
            setFilters={setFilters}
            menuItems={menuItems}
            filters={filters}
          />
        )}

        {filtering && <ClearFilters onClick={onClearFilters} />}

        <section className="filter-layout__filter-label">{filterLabel}</section>
      </section>
      <div className="filter-layout__actions">{actions}</div>
    </header>
  );
};

interface Props {
  actions?: ReactNode | ReactNode[];
  menuItems: FilterItems;
  sortable?: boolean;
  children: ReactNode | ReactNode[];
  hideButton?: boolean;
}

const FilterLayout = ({
  actions,
  menuItems,
  sortable,
  children,
  hideButton
}: Props) => (
  <section className="filter-layout">
    <FilterProvider>
      <FiltersSection
        sortable={sortable}
        menuItems={menuItems}
        hideButton={hideButton}
        actions={actions}
      />
      <main className="filter-layout__content">{children}</main>
    </FilterProvider>
  </section>
);

FilterLayout.defaultProps = {
  filters: noOp
};

export default FilterLayout;
